// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { set } from "object-path-immutable";
import _isEmpty from "lodash.isempty";
import dayjs from "dayjs";

// Utils
import { _isTrue } from "utils";

// Fields
import { ContentLoader, ScreenSize } from "fields";

// Lib
import { DateTime, OrdersManagement } from "lib/functions";

const { _checkIfStoreAcceptOrders } = OrdersManagement;

const _getTodayHours = async ({
  membership = {},
  openHours = {},
  special_hours = {},
  stripeConnectInfo = {},
  timeZone = "America/New_York",
}) => {
  const { _convertExtraHours, _convertHoursToLocal, GetTodayDay } = DateTime;
  const { status: membershipStatus = "active" } = membership;
  const { stripeAccountID = "" } = stripeConnectInfo;

  const { date: today_date, name_of_the_day } = await GetTodayDay();

  // -----------------------------------------
  // Check special hours
  if (!_isEmpty(special_hours)) {
    const today_has_special_hours =
      Object.values(special_hours).find((value = {}) => {
        const { date } = value;
        return date === today_date;
      }) || {};
    if (!_isEmpty(today_has_special_hours)) {
      const { is_closed } = today_has_special_hours;
      if (_isTrue(is_closed))
        return { isShopClosed: true, today: name_of_the_day, today_date };
      else {
        const { end_at = "", start_at = "" } = today_has_special_hours;
        const today_hours = {
          isShopClosed: false,
          shopTodayExtraHours: {},
          shopTodayOpenHours: {
            localEndAt: end_at,
            localEndAt12Hr: dayjs("1/1/1 " + end_at).format("hh:mm A"),
            localStartAt: start_at,
            localStartAt12Hr: dayjs("1/1/1 " + start_at).format("hh:mm A"),
          },
          today: name_of_the_day,
        };
        const { shouldAcceptOrder = false } = await _checkIfStoreAcceptOrders({
          timeZone,
          todayHours: today_hours,
        });
        return {
          ...today_hours,
          canShopAcceptOrders:
            shouldAcceptOrder &&
            membershipStatus === "active" &&
            Boolean(stripeAccountID),
          notInOpenHours: !shouldAcceptOrder,
          today_date,
        };
      }
    }
  }

  // -----------------------------------------
  // Check regular open hours
  const todayOpenHours = openHours[name_of_the_day] || {};
  const { extraHours = {}, isClosedAllDay = false } = todayOpenHours;
  if (_isTrue(isClosedAllDay)) {
    return { isShopClosed: true, today: name_of_the_day, today_date };
  }

  // Convert main open hours
  let todayHours = {
    isShopClosed: false,
    shopTodayExtraHours: await _convertExtraHours({ extraHours, timeZone }),
    shopTodayOpenHours: await _convertHoursToLocal({
      hours: todayOpenHours,
      timeZone,
    }),
    today: name_of_the_day,
  };

  const { shouldAcceptOrder = false } = await _checkIfStoreAcceptOrders({
    timeZone,
    todayHours,
  });

  return {
    ...todayHours,
    canShopAcceptOrders:
      shouldAcceptOrder &&
      membershipStatus === "active" &&
      Boolean(stripeAccountID),
    notInOpenHours: !shouldAcceptOrder,
    today_date,
  };
};

const _customizeMenuItems = ({ items = {} }) =>
  Object.entries(items).reduce((result, entry) => {
    const { itemIsOnSale = {} } = entry[1];
    return itemIsOnSale.true ? set(result, entry[0], entry[1]) : result;
  }, {});

const _customizeMenuGroups = ({ groups = {}, showItemsOnSaleOnly = false }) => {
  if (!showItemsOnSaleOnly) return groups;
  return Object.keys(groups).reduce((result, groupID) => {
    const { items } = groups[groupID];
    const onSaleItems = _customizeMenuItems({ items });
    if (Object.keys(onSaleItems).length === 0) return result;
    return set(result, groupID, {
      ...groups[groupID],
      items: onSaleItems,
    });
  }, {});
};

const _customizeMenu = ({ menu = {}, showItemsOnSaleOnly = false }) => {
  const { groups = {} } = menu;
  return set(
    menu,
    "groups",
    _customizeMenuGroups({ groups, showItemsOnSaleOnly })
  );
};

const _createMobileMenuSkeleton = () => {
  const groups = Array(3).fill(3);
  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <ContentLoader.MobileMenuGroup height={20} width={150} />
      </div>
      {groups.map((val, index) => (
        <div style={{ marginBottom: "15px" }} key={index}>
          <div style={{ marginBottom: "30px" }}>
            <ContentLoader.MobileMenuGroup />
          </div>
          <ContentLoader.MobileMenuItems />
        </div>
      ))}
    </div>
  );
};

const _createDesktopMenuSkeleton = () => {
  const groups = Array(4).fill(4);
  return (
    <div>
      {groups.map((val, index) => (
        <div key={index} style={{ marginBottom: "35px" }}>
          <div style={{ marginBottom: "10px" }}>
            <ContentLoader.MobileMenuGroup height={20} width={150} />
          </div>
          <ContentLoader.DesktopMenuItemsLoader />
        </div>
      ))}
    </div>
  );
};

const _createMenuSkeleton = () => (
  <div>
    <ScreenSize deviceType="mobile">{_createMobileMenuSkeleton()}</ScreenSize>
    <ScreenSize deviceType="tablet">{_createMobileMenuSkeleton()}</ScreenSize>
    <ScreenSize deviceType="desktop">{_createDesktopMenuSkeleton()}</ScreenSize>
  </div>
);

export { _getTodayHours, _createMenuSkeleton, _customizeMenu };
