// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

// Style
import ButtonStyle from "components/Modals/style.module.scss";

// Components
import { Modals } from "components";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Fields
import { Button, Input } from "fields";

// Lib
import { LocalStorage, ShowConfirmNotif } from "lib/functions";
import { Customers } from "lib/services";
const { ChangeCustomerProfile } = Customers.PostRequests;

class ProfileModule extends React.Component {
  confirmNotif = null;
  state = {};

  componentDidMount() {
    const { customerProfile = {} } = this.props.context;
    this.setState({ customerProfile });
  }

  onInputChange = (fieldID, fieldValue) => {
    const { customerProfile = {} } = this.state;
    this.setState({
      customerProfile: set(customerProfile, fieldID, fieldValue),
    });
  };

  onSaveCustomerProfile = async () => {
    const { customerProfile } = this.state;
    this.setState({ isLoading: true });
    const { success } = await ChangeCustomerProfile({
      customerProfile,
      uuid: LocalStorage.getItemFromStorage("uuid"),
    });
    this.setState({ isLoading: false });
    this.confirmNotif = ShowConfirmNotif({
      message: success ? "Updated your profile" : "Please try again",
      type: success ? "success" : "error",
    });
    if (success) {
      const { onChangeCustomerProfile } = this.props.context;
      onChangeCustomerProfile(customerProfile);
    }
  };

  render() {
    const { customerProfile = {} } = this.state;
    return (
      <div>
        {this.state.isLoading && <Modals.LoadingModal />}
        <Input.TextInput
          label="Name"
          name="Customer's Name"
          onChange={(val) => this.onInputChange("name", val)}
          value={customerProfile.name || ""}
          required
        />
        <Button
          className={ButtonStyle.saveButton}
          name="Save customer's profile"
          onClick={this.onSaveCustomerProfile}
          status={customerProfile.name ? "active" : "inactive"}
        >
          Save
        </Button>
      </div>
    );
  }
}

ProfileModule.propTypes = {
  context: PropTypes.shape({
    customerProfile: PropTypes.object,
    onChangeCustomerProfile: PropTypes.func.isRequired,
  }),
};

export default withContext(CustomerInterfaceConsumer)(ProfileModule);
