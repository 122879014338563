// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Fields
import { FullPageModal } from "fields";

class MobileNavOptions extends React.Component {
  render() {
    return (
      <FullPageModal
        contentLabel="mobile navbar options modal"
        contentContainerClassname={Style.contentContainer}
        modalClassname={Style.modalClassname}
        onCloseModal={() => {}}
        showHeader={false}
        showCloseButton={false}
      >
        {this.props.children}
      </FullPageModal>
    );
  }
}

export default withContext(CustomerInterfaceConsumer)(MobileNavOptions);
