// BUTI DINERS, INC. All right Reserved ©

const MAX_SUB_NAVBAR_ITEMS = 5;

const MOBILE_NAVBAR_ITEMS = [
  {
    id: "foodMenu",
    label: "menu",
  },
  {
    id: "deals",
    label: "deals",
  },
  {
    id: "cart",
    label: "cart",
  },
];

// ----------------------------------------------------------
// Admin interface: sub navbar items in Orders
const ORDERS_NAVBAR_ITEMS = [
  {
    id: "activeOrders",
    label: "New Orders",
  },
  {
    id: "pastOrders",
    label: "Past Orders",
  },
];

export { MAX_SUB_NAVBAR_ITEMS, MOBILE_NAVBAR_ITEMS, ORDERS_NAVBAR_ITEMS };
