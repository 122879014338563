// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styled from "styled-components";

import BenefitsPill from "./BenefitsPill";

// Utils
import { _isTrue } from "utils";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Assets
// import watermark_white from "assets/Images/skipli_watermark_white.png";

class ShopHeroImage extends React.Component {
  state = {};

  renderButtons = () => {
    const { heroImage = {}, themeColor = "" } = this.props;
    const {
      showOrderNowButton = "true",
      showSeeMenuButton = "true",
      textPosition = "left",
    } = heroImage;

    const OrderNowBtn = styled(Button)`
      background: ${themeColor};
    `;

    if (!_isTrue(showOrderNowButton) && !_isTrue(showSeeMenuButton))
      return null;

    return (
      <div className={cx(Style.buttons, Style[textPosition])}>
        {_isTrue(showOrderNowButton) && (
          <OrderNowBtn
            className={Style.orderButton}
            hasShadow
            name="order online button"
            onClick={this.props.onRedirectToOrderOnline}
          >
            Order Now
          </OrderNowBtn>
        )}
        {_isTrue(showSeeMenuButton) && (
          <Button
            className={Style.seeMenuButton}
            hasShadow
            name="see menu button"
            onClick={() => this.props.onScrollTo("menu")}
          >
            See Menu
          </Button>
        )}
      </div>
    );
  };

  render() {
    const { heroImage = {} } = this.props;
    const { heading, imageURL, message, textPosition } = heroImage;
    // const watermark_position = textPosition === "right" ? "left" : "right";
    return (
      <div>
        <div className={Style.container}>
          <img alt="" className={Style.heroImage} src={imageURL} />
          {(heading || message) && <div className={Style.filter} />}
          {/* <img
            alt="Skipli watermark"
            className={cx(Style.watermark, Style[watermark_position])}
            src={watermark_white}
          /> */}
          <div className={cx(Style.textContainer, Style[textPosition])}>
            {heading && <h1 className={Style.heading}>{heading}</h1>}
            {message && <p className={Style.message}>{message}</p>}
            {this.renderButtons()}
          </div>
        </div>
        <BenefitsPill />
      </div>
    );
  }
}

ShopHeroImage.propTypes = {
  heroImage: PropTypes.exact({
    imageURL: PropTypes.string,
    heading: PropTypes.string,
    message: PropTypes.string,
    textPosition: PropTypes.oneOf(["center", "left", "right"]),
    showOrderNowButton: PropTypes.string,
    showSeeMenuButton: PropTypes.string,
  }),
  onRedirectToOrderOnline: PropTypes.func.isRequired,
  onScrollTo: PropTypes.func.isRequired,
  themeColor: PropTypes.string,
};

ShopHeroImage.defaultProps = {
  heroImage: {
    heading: "We serve great food!",
    imageURL: "",
    message:
      "We are making delicious food and fashionable choice of drink. We aim to inspire people in everyday's life and to brighten minds through the art of food.",
    textPosition: "left",
  },
};

export default ShopHeroImage;
