// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ReactPlayer from "react-player/lazy";

import { FEATURES } from "./constants";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Assets
import { CheckIcon, ChevronArrowIcon } from "assets/Icons";

class HomePageTakeBackControl extends React.Component {
  renderFeatures = () =>
    Object.keys(FEATURES).map((feature_id, index) => {
      const { label, list, title, video_url } = FEATURES[feature_id];
      return (
        <div className={Style.feature_container} key={feature_id}>
          <div className={Style.feature_video_container}>
            <div className={Style.feature_video}>
              <ReactPlayer
                className={Style.video_player}
                config={{ file: { attributes: { preload: null } } }}
                height="auto"
                loop={true}
                muted={true}
                playing={true}
                playsinline={true}
                width="inherit"
                url={video_url}
              />
            </div>
          </div>
          <div className={Style.feature_description}>
            <div className={Style.feature_label}>
              {index + 1} • {label}
            </div>
            <h3 className={Style.feature_title}>{title}</h3>
            <div>{this.renderList(list)}</div>
            <Button
              className={Style.startNowBtn}
              hasShadow
              name="get started"
              onClick={() => this.props.history.push("/get-started")}
            >
              Learn More <ChevronArrowIcon className={Style.arrowIcon} />
            </Button>
          </div>
        </div>
      );
    });

  renderList = (list = []) =>
    list.map((item, index) => (
      <div className={Style.list_item} key={index}>
        <div className={Style.check_icon}>
          <CheckIcon />
        </div>
        {item}
      </div>
    ));

  render() {
    return (
      <div className={MainWebsiteStyle.sectionContainer}>
        <h2 className={Style.title}>
          <span>Get Back</span> Control
        </h2>
        <p className={Style.description}>
          Own 100% of your online presence from the third-party companies
          that charge you crazy fees, keep your data, and take advantage of your hard-earned
          reputation. Win an unfair game with a powerful tool like SKIPLI.
        </p>
        <div>{this.renderFeatures()}</div>
      </div>
    );
  }
}

HomePageTakeBackControl.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(HomePageTakeBackControl);
