// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { CenterModal, LoadingSpinner } from "fields";

const LoadingModal = (props) => (
  <CenterModal
    contentLabel="Loading Information"
    onCloseModal={() => {}}
    modalBoxClassname={cx(Style.modalBox, props.modalBoxClassname)}
    contentContainerClassname={Style.contentContainer}
    overlayClassname={Style.overlay}
    showCloseButton={false}
    shouldCloseOnOverlayClick={false}
  >
    <LoadingSpinner
      message={props.message}
      messageClassname={Style.message}
      messagePosition={props.messagePosition}
    />
  </CenterModal>
);

LoadingModal.propTypes = {
  message: PropTypes.string,
  messagePosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  modalBoxClassname: PropTypes.string,
};

LoadingModal.defaultProps = {
  message: "Loading...",
  messagePosition: "bottom",
};

export default LoadingModal;
