// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

import Platform from "./Platform";
import ShopSpecific from "./ShopSpecific";

// Style
import Style from "./style.module.scss";

// Fields
import { PageMsg } from "fields";

// Lib
import { TEST_SHOP_IDS } from "lib/_constants";
import { LocalStorage } from "lib/functions";
import { Customers, Merchants, LIVE_MODE_ENABLED } from "lib/services";

// Icons
import { SkipliLogoWithTextIcon } from "assets/Icons";

class Main extends Component {
  state = { customerProfile: null };

  componentDidMount = () => {
    // Get required information
    const customerUid = LocalStorage.getItemFromStorage("uuid");
    if (!customerUid) this.onCreateCustomerNewUid();
    else this.onGetCustomerProfile(customerUid);

    // Check if the shop should be displayed
    this.onSetShopID(_get(this.props, "match.params.shopID") || "");
  };

  componentDidUpdate = (prevProps) => {
    const prevShopID = _get(prevProps, "match.params.shopID") || "";
    const shopID = _get(this.props, "match.params.shopID") || "";
    if (prevShopID !== shopID) this.onSetShopID(shopID);
  };

  onCreateCustomerNewUid = async () => {
    const { CreateUniqueIDForCustomer } = Customers.GetRequests;
    const { customerProfile, uuid } = await CreateUniqueIDForCustomer().catch(
      this.onSystemFailed
    );
    if (customerProfile && uuid) {
      const { saveItemIntoStorage } = LocalStorage;
      this.setState({ customerProfile }, () =>
        saveItemIntoStorage("uuid", uuid)
      );
    }
  };

  onGetCustomerProfile = async (uuid) => {
    const { GetCustomerProfile } = Customers.GetRequests;
    try {
      const { customerProfile, success = true } = await GetCustomerProfile({
        uuid,
      });
      if (success) this.setState({ customerProfile });
      else this.onCreateCustomerNewUid();
    } catch {
      this.onSystemFailed();
    }
  };

  onGetShopBasicInfo = async (shopID) => {
    const { GetShopBasicInfo } = Merchants.GetRequests;
    const { shopBasicInfo = {}, success } = await GetShopBasicInfo({
      shopID,
    });
    if (!success) this.setState({ isSystemFailed: true });
    else this.setState({ shopBasicInfo });
  };

  onSetShopID = (shopID = "") => {
    this.setState({ shopID }, () => {
      if (shopID) {
        if (LIVE_MODE_ENABLED() && TEST_SHOP_IDS.includes(shopID))
          this.setState({ showTestShopInLiveModeWarning: true });
        else if (!LIVE_MODE_ENABLED() && !TEST_SHOP_IDS.includes(shopID))
          this.setState({ showLiveShopInTestModeWarning: true });
        else this.onGetShopBasicInfo(shopID);
      }
    });
  };

  onSystemFailed = () => this.setState({ isSystemFailed: true });

  renderContent = () => {
    const { shopID } = this.state;
    if (!shopID) return <Platform />;
    const { customerProfile, shopBasicInfo = {} } = this.state;
    if (Object.keys(shopBasicInfo).length === 0) return;
    return (
      <ShopSpecific
        customerProfile={customerProfile}
        order_id={_get(this.props, "match.params.order_id") || ""}
        shopBasicInfo={shopBasicInfo}
        shopID={shopID}
        showCateringMenu={this.props.showCateringMenu}
        showShopWebsite={this.props.showShopWebsite}
        show_shop_survey={this.props.shop_survey}
        show_shop_order_tracking={this.props.show_shop_order_tracking}
      />
    );
  };

  render() {
    if (this.state.isSystemFailed) return <SystemFailureMsg />;
    else if (this.state.showLiveShopInTestModeWarning)
      return <LiveShopNotAllowed />;
    else if (this.state.showTestShopInLiveModeWarning)
      return <TestShopNotAllowed />;
    else if (!this.state.customerProfile) return <GreetingMsg />;
    return <div>{this.renderContent()}</div>;
  }
}

Main.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  showCateringMenu: PropTypes.bool,
};

Main.defaultProps = {
  showCateringMenu: false,
};

const GreetingMsg = () => (
  <div className={Style.background}>
    <div className={Style.skipliLogoContainer}>
      <SkipliLogoWithTextIcon />
    </div>
  </div>
);

const LiveShopNotAllowed = () => (
  <PageMsg>
    <div className={Style.greetingMsg}>
      <h1>Access Denied!</h1>
      <p>This shop is only allowed in LIVE mode. Contact SKIPLI for access.</p>
    </div>
  </PageMsg>
);

const SystemFailureMsg = () => (
  <PageMsg>
    <div className={Style.greetingMsg}>
      <h1>Our sincere apology!</h1>
      <p>
        The system is currently unavailable. Please refresh the page or get help
        from the restaurant staff.
      </p>
    </div>
  </PageMsg>
);

const TestShopNotAllowed = () => (
  <PageMsg>
    <div className={Style.greetingMsg}>
      <h1>Access Denied!</h1>
      <p>This shop is only allowed in DEV mode. Contact SKIPLI for access.</p>
    </div>
  </PageMsg>
);

export default Main;
