// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withContext, CustomerInterfaceConsumer } from "context";

// Style
import Style from "./style.module.scss";

// Components
import { CustomerMenuElements } from "components";

// Components
import { PageMsg } from "fields";

// Lib
import {
  CUSTOMER_REWARD_TYPES,
  CUSTOMER_REWARD_TYPES_IMG_URLS,
} from "lib/_constants";

class CustomerRewards extends React.Component {
  state = {
    currentStage: "allRewards",
    selectedRewardType: "",
  };

  renderCustomerRewards = () =>
    Object.entries(CUSTOMER_REWARD_TYPES).map((entry) => {
      if (entry[0] === "ON_SALE_ITEMS") {
        const { menuInfo = {} } = this.props;
        const { groups = {} } = menuInfo;
        if (Object.keys(groups).length === 0) return null;
      }
      return (
        <button
          onClick={() => this.onSelectReward(entry[0])}
          className={Style.listItem}
          key={entry[0]}
        >
          <div className={Style.imageContainer}>
            <img
              className={Style.image}
              alt={`${CUSTOMER_REWARD_TYPES[entry[0]]} img`}
              src={CUSTOMER_REWARD_TYPES_IMG_URLS[entry[0]]}
            />
            <div className={Style.rewardLabel}>{entry[1]}</div>
          </div>
        </button>
      );
    });

  onSelectReward = (rewardType) =>
    this.setState({
      currentStage: "selectedReward",
      selectedRewardType: rewardType,
    });

  onClickBackButton = () =>
    this.setState({ currentStage: "allRewards", selectedRewardType: "" });

  renderSelectedReward = () => {
    switch (this.state.selectedRewardType) {
      case "ON_SALE_ITEMS":
        return (
          <CustomerMenuElements.Menu
            menuID={this.props.menuID}
            menuInfo={this.props.menuInfo}
          />
        );
      case "COUPONS":
        return <PageMsg>This feature will be available soon</PageMsg>;
      default:
        return null;
    }
  };

  renderContent = () => {
    const { currentStage } = this.state;
    switch (currentStage) {
      case "allRewards":
        return (
          <div>
            <div className={Style.list}> {this.renderCustomerRewards()} </div>
          </div>
        );
      case "selectedReward":
        return (
          <div>
            <div style={{ textAlign: "center" }}>
              <button
                className={Style.seeAllRewardsBtn}
                onClick={this.onClickBackButton}
              >
                See All Rewards
              </button>
            </div>
            <div style={{ paddingTop: "20px" }}>
              {this.renderSelectedReward()}
            </div>
          </div>
        );
      default:
        return;
    }
  };

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

CustomerRewards.propTypes = {
  menuID: PropTypes.string,
  menuInfo: PropTypes.object,
};

CustomerRewards.defaultProps = {
  menuID: "",
  menuInfo: {},
};

export default withContext(CustomerInterfaceConsumer)(CustomerRewards);
