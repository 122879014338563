// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Header from "./Header";
import HeroImage from "./HeroImage";
import Menu from "./Menu";
import OrderingChannels from "./OrderingChannels";
import SkipliFooter from "./SkipliFooter";

// Utils
import { _isTrue, _scrollTo, _updatePageTitleAndDescription } from "utils";

// Style
import Style from "./style.module.scss";

// Lib
import { FRONT_END_BASE_URL } from "lib/services";

class WebsiteBuilder extends React.Component {
  refs = {};

  componentDidMount = () => {
    const { seo = {} } = this.props;
    const { metaDescription = "", pageTitle = "" } = seo;
    const { address = "", name = "" } = this.props.shopBasicInfo;
    const text = `${name} (${address}) Delivery Online | Order Pickup | Menu & Prices`;
    _updatePageTitleAndDescription({
      description: `${text}${metaDescription ? `| ${metaDescription}` : ""}`,
      title: pageTitle || text,
    });
  };

  onRedirectToOrderOnline = () => {
    const { shopID } = this.props;
    window.location.href = `${FRONT_END_BASE_URL}/shop/${shopID}`;
  };

  onScrollTo = (refID = "") => {
    if (refID) {
      const ref = this.refs[refID];
      ref && _scrollTo({ ref });
    }
  };

  onUpdateRefs = ({ refID = "", ref }) => {
    if (refID) {
      this.refs = set(this.refs, refID, ref);
    }
  };

  render() {
    const {
      general = {},
      publishedVersion = {},
      shopBasicInfo,
      shopID,
    } = this.props;
    const { isPageVisible, themeColor = "" } = general;
    const { components = {} } = publishedVersion;

    if (!_isTrue(isPageVisible) || Object.keys(components).length === 0)
      return this.onRedirectToOrderOnline();

    const {
      contactUs = {},
      header = {},
      heroImage = {},
      aboutUs = {},
      orderingChannels = {},
      menu = {},
    } = components;

    return (
      <div className={Style.container}>
        <Header
          header={header}
          onRedirectToOrderOnline={this.onRedirectToOrderOnline}
          onScrollTo={this.onScrollTo}
          shopBasicInfo={shopBasicInfo}
          themeColor={themeColor}
        />
        <div ref={(ref) => this.onUpdateRefs({ refID: "home", ref })}>
          <HeroImage
            heroImage={heroImage}
            onRedirectToOrderOnline={this.onRedirectToOrderOnline}
            onScrollTo={this.onScrollTo}
            themeColor={themeColor}
          />
        </div>
        <div ref={(ref) => this.onUpdateRefs({ refID: "menu", ref })}>
          <Menu
            menu={menu}
            onRedirectToOrderOnline={this.onRedirectToOrderOnline}
            shopID={shopID}
            themeColor={themeColor}
          />
        </div>
        <div ref={(ref) => this.onUpdateRefs({ refID: "about", ref })}>
          <AboutUs
            aboutUs={aboutUs}
            onRedirectToOrderOnline={this.onRedirectToOrderOnline}
            themeColor={themeColor}
          />
        </div>
        <div>
          <OrderingChannels
            onChange={(newOrderingChannels) =>
              this.onChange({
                componentId: "orderingChannels",
                component: newOrderingChannels,
              })
            }
            onRedirectToOrderOnline={this.onRedirectToOrderOnline}
            orderingChannels={orderingChannels}
            shopID={shopID}
            themeColor={themeColor}
          />
        </div>
        <div ref={(ref) => this.onUpdateRefs({ refID: "contactUs", ref })}>
          <ContactUs
            contactUs={contactUs}
            shopBasicInfo={shopBasicInfo}
            themeColor={themeColor}
          />
        </div>
        <SkipliFooter />
      </div>
    );
  }
}

WebsiteBuilder.propTypes = {
  general: PropTypes.shape({
    themeColor: PropTypes.string.isRequired,
  }),
  publishedVersion: PropTypes.shape({
    components: PropTypes.object,
  }),
  seo: PropTypes.shape({
    metaDescription: PropTypes.string,
    pageTitle: PropTypes.string,
  }),
  shopBasicInfo: PropTypes.object.isRequired,
  shopID: PropTypes.string.isRequired,
};

export default WebsiteBuilder;
