// BUTI Corp All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Components
import { LabelOption } from "components/Modals/FoodFiltersModal";

// Style
import Style from "./style.module.scss";

// Lib
import { FOOD_PREFERENCES } from "lib/_constants";

const AllergensList = ({ allergensList }) => {
  const { options } = FOOD_PREFERENCES.find(({ id }) => id === "allergies");
  const allergenOptions = options || {};
  const labelOptionStyle = {};

  const renderAllergens = () =>
    Object.keys(allergensList).map((allergenID) => {
      const allergenInfo = allergenOptions[allergenID];
      if (!allergenInfo) return null;
      const { icon, label } = allergenInfo;
      return (
        <div className={Style.allergen} key={allergenID}>
          <LabelOption
            icon={<div className={Style.allergenIcon}>{icon}</div>}
            label={label}
            style={labelOptionStyle}
          />
        </div>
      );
    });

  return <div className={Style.allergensContainer}>{renderAllergens()}</div>;
};

AllergensList.propTypes = {
  allergensList: PropTypes.object.isRequired,
};

export default AllergensList;
