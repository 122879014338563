// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icons
import { CheckIcon } from "assets/Icons";

const ImageUsageDisclaimer = (props) => (
  <div>
    <p className={Style.disclaimer}>
      I certify that the image I chose to display is not subject to any
      copyrights or intellectual property infringement.
      <br />
      <br />
      By clicking "Save", I agree to waive SKIPLI of any responsibility to
      ensure the image copyright and I will be responsible for any infringement
      penalties should they arise.
    </p>
    <button className={Style.agreeButton} onClick={props.onAgreeToTerms}>
      <div className={cx(Style.checkbox, props.termsAgreed && Style.checked)}>
        {props.termsAgreed && <CheckIcon className={Style.checkIcon} />}
      </div>
      <span>I agree</span>
    </button>
  </div>
);

ImageUsageDisclaimer.propTypes = {
  onAgreeToTerms: PropTypes.func.isRequired,
  termsAgreed: PropTypes.bool,
};

ImageUsageDisclaimer.defaultProps = {
  termsAgreed: false,
};

export default ImageUsageDisclaimer;
