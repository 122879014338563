// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import DesktopSite from "./DesktopSite";
import MobileSite from "./MobileSite";

// Style
import Style from "./style.module.scss";

// Views
import { ErrorPage } from "views";

// Fields
import { ScreenSize } from "fields";

class Menu extends React.Component {
  render() {
    const { groups = {} } = this.props.menuInfo;
    if (Object.keys(groups).length === 0)
      return (
        <ErrorPage
          className={Style.menuNotFound}
          message="Menu not found"
          show_skipli_logo={false}
        />
      );
    const mobile = <MobileSite {...this.props} />;
    return (
      <React.Fragment>
        <ScreenSize deviceType="mobile">{mobile}</ScreenSize>
        <ScreenSize deviceType="tablet">{mobile}</ScreenSize>
        <ScreenSize deviceType="desktop">
          <DesktopSite {...this.props} />
        </ScreenSize>
      </React.Fragment>
    );
  }
}

Menu.propTypes = {
  menuID: PropTypes.string.isRequired,
  menuInfo: PropTypes.shape({
    groups: PropTypes.object,
    menuName: PropTypes.string.isRequired,
  }).isRequired,
};

export default Menu;
