// BUTI DINERS, INC. All right Reserved ©
import React from "react";

// Icons
import {
  FacebookIcon,
  LinkedInIcon,
  PinterestIcon,
  YoutubeIcon,
} from "assets/Icons";

const ABOUT_US = [
  {
    name: "Online Ordering",
    href: "/",
  },
  {
    name: "Website Examples",
    href: "/website-examples",
  },
  {
    name: "Contactless Ordering",
    href: "/contactless",
  },
  {
    name: "Testimonials",
    href: "/testimonials",
  },
  {
    name: "Blog",
    href: "/blog",
  },
];

const PAYMENTS = [
  {
    name: "Apple Pay",
    href: "https://www.apple.com/apple-pay/",
  },
  {
    name: "Google Pay",
    href: "https://pay.google.com/about/",
  },
  {
    name: "Stripe",
    href: "https://stripe.com",
  },
];

const LEGAL = [
  {
    name: "Add Your Restaurant",
    href: "/get-started",
  },
  {
    name: "Terms of Use",
    href: "/terms",
  },
  {
    name: "Privacy Policy",
    href: "/privacy",
  },
];

const SKIPLI_SOCIAL_MEDIA = {
  facebook: {
    icon: <FacebookIcon />,
    href: "https://www.facebook.com/skiplirestaurants",
  },
  youtube: {
    icon: <YoutubeIcon />,
    href: "https://www.youtube.com/channel/UC2oEyUd8268RTv6O0rd54TA",
  },
  pinterest: {
    icon: <PinterestIcon />,
    href: "https://www.pinterest.com/skiplimarketing/",
  },
  linkedin: {
    icon: <LinkedInIcon />,
    href: "https://www.linkedin.com/company/skiplinow",
  },
};

export { ABOUT_US, LEGAL, PAYMENTS, SKIPLI_SOCIAL_MEDIA };
