// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import Button from "fields/Button";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class AutoPhoneIntro extends React.Component {
  renderButtons = () => (
    <div className={Style.buttons}>
      <Button
        className={Style.startNowBtn}
        hasShadow
        name="get started"
        onClick={() => this.props.history.push("/get-started")}
      >
        Get a Free Demo <ChevronArrowIcon className={Style.arrowIcon} />
      </Button>
      {/* <Button
        className={Style.learnMoreBtn}
        color="white"
        name="what skipli website design offers"
        onClick={() => this.props.context.onScrollTo("auto_phone_how_it_works")}
      >
        How It Works? <ChevronArrowIcon className={Style.arrowIcon} />
      </Button> */}
    </div>
  );

  render() {
    return (
      <div className={Style.container}>
        <div className={Style.text_column}>
          <div className={Style.text_content}>
            <h1 className={Style.heading}>
              Auto Phone Answering for <span>Restaurants.</span>
            </h1>
            <div className={Style.paragraph}>
              <div>Never miss a call</div>
              <div>Never miss a sale</div>
            </div>
            <p className={Style.description}>
              <span>
                90% of people whose calls are not answered won’t call back.
              </span>{" "}
              SKIPLI automated 24/7 call answering handle all incoming calls at
              the same time. Get more orders, send online menu, give directions
              & hours without missing a customer.
            </p>
            {this.renderButtons()}
          </div>
        </div>
        <div className={Style.image_column}>
          <img
            alt=""
            className={Style.hero_image}
            src="https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/Auto+Phone+Introduction+Graphic.png"
          />
        </div>
      </div>
    );
  }
}

AutoPhoneIntro.propTypes = {
  context: PropTypes.shape({
    onScrollTo: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(AutoPhoneIntro)
);
