// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import { CONTACT_FIELDS, SOCIAL_MEDIA } from "./constants";

// Utils
import { _formatPhoneNumber } from "utils";

// Style
import Style from "./style.module.scss";

// Components
import { ShopHoursTable } from "components";

// Fields
import { Link } from "fields";

class ContactUs extends React.Component {
  state = {};

  renderBusinessHours = () => {
    const { shopBasicInfo = {} } = this.props;
    const { openHours = {}, timeZone } = shopBasicInfo;
    return (
      <div className={Style.businessHoursColumn}>
        <div className={Style.columnHeading}>Business Hours</div>
        <ShopHoursTable
          classNames={{ weekDay: Style.day }}
          hours={openHours}
          timeZone={timeZone}
        />
      </div>
    );
  };

  renderContactFields = () => {
    const { contactUs = {}, shopBasicInfo = {} } = this.props;
    return Object.keys(CONTACT_FIELDS).map((fieldId) => {
      const { icon, target } = CONTACT_FIELDS[fieldId];
      let fieldValue;

      if (fieldId === "address") {
        const { address } = shopBasicInfo;
        fieldValue = address;
      } else if (fieldId === "phoneNumber") {
        const { phoneNumber } = shopBasicInfo;
        fieldValue = _formatPhoneNumber(phoneNumber);
      } else {
        fieldValue = contactUs[fieldId];
      }

      if (!fieldValue) return null;

      let href = "";
      if (fieldId === "phoneNumber") href = `tel:${fieldValue}`;
      else if (fieldId === "email") href = `mailto:${fieldValue}`;
      else if (fieldId === "address")
        href = `https://google.com/maps/place/${fieldValue}`;

      return (
        <React.Fragment key={fieldId}>
          <Link className={Style.contactInfoLink} href={href} target={target}>
            <div className={Style.contactInfo}>
              {icon}
              <p className={Style.contact_info_value}>{fieldValue}</p>
            </div>
          </Link>
        </React.Fragment>
      );
    });
  };

  renderLogo = () => {
    const { shopBasicInfo = {} } = this.props;
    const { logoURL = "", name = "" } = shopBasicInfo;
    return (
      <div className={Style.shopLogoContainer}>
        {logoURL && <img alt="" className={Style.shopLogo} src={logoURL} />}
        {name}
      </div>
    );
    /* <Link className={Style.skipliLink} href="https://skiplinow.com">
          Copyright © 2023 <br />
          <div className={Style.madeWithLove}>
            Made with <HeartIcon /> by{" "}
            <span className={Style.skipliWebsite}>SKIPLI</span>
          </div>
        </Link> */
  };

  renderSocialMediaLinks = () => {
    const { shopBasicInfo = {} } = this.props;
    const { social_media_profiles = {} } = shopBasicInfo;
    return Object.keys(SOCIAL_MEDIA).map((media_id) => {
      const { profile_href = "" } = social_media_profiles[media_id] || {};
      if (!profile_href) return null;
      const { icon } = SOCIAL_MEDIA[media_id];
      return (
        <React.Fragment key={media_id}>
          <Link className={Style.mediaLink} href={profile_href}>
            <div className={Style.social_media_icon}>{icon}</div>
          </Link>
        </React.Fragment>
      );
    });
  };

  render() {
    return (
      <div className={Style.container}>
        <div className={Style.content}>
          {this.renderLogo()}
          <div className={Style.contact_info_row}>
            <div className={Style.social_media_column}>
              <div className={Style.columnHeading}>Social</div>
              <div className={Style.social_media_icons_container}>
                {this.renderSocialMediaLinks()}
              </div>
            </div>
            <div className={Style.contactInfoColumn}>
              <div className={Style.columnHeading}>Contact</div>
              {this.renderContactFields()}
            </div>
            {this.renderBusinessHours()}
          </div>
        </div>
      </div>
    );
  }
}

ContactUs.propTypes = {
  contactUs: PropTypes.shape({
    address: PropTypes.string,
    email: PropTypes.string,
    instagram: PropTypes.string,
    facebook: PropTypes.string,
    openHours: PropTypes.object,
    phoneNumber: PropTypes.string,
    youtube: PropTypes.string,
    twitter: PropTypes.string,
    yelp: PropTypes.string,
  }),
  shopBasicInfo: PropTypes.shape({
    logoURL: PropTypes.string,
    name: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
  themeColor: PropTypes.string,
};

export default ContactUs;
