// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ContentLoader from "react-content-loader";

const DesktopBlogLoader = (props) => (
  <ContentLoader
    width={1000}
    height={160}
    speed={4}
    backgroundColor="#E0E0E0"
    foregroundColor="#F2F2F2"
    {...props}
  >
    <rect x="1" y="4" rx="0" ry="0" width="100" height="15" />
    <rect x="1" y="48" rx="0" ry="0" width="578" height="24" />
    <rect x="1" y="96" rx="0" ry="0" width="690" height="15" />
    <rect x="1" y="137" rx="0" ry="0" width="684" height="15" />
    <rect x="16" y="169" rx="0" ry="0" width="80" height="12" />
    <rect x="125" y="169" rx="0" ry="0" width="80" height="12" />
    <rect x="840" y="13" rx="0" ry="0" width="260" height="148" />
  </ContentLoader>
);

export default DesktopBlogLoader;
