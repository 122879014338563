// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash.isempty";
import Img from "react-cool-img";
import _get from "lodash.get";

// import { SAMPLE_DOORDASH_DELIVERY_DETAILS } from "./constants";

// Views
import { ErrorPage } from "views";

// Style
import Style from "./style.module.scss";

// Fields
import { PageMsg } from "fields";

// Components
import { OrderTrackingModules } from "components";

// Assets
import { SkipliLogoWithTextIcon } from "assets/Icons";

// Lib
import { BUTI, Customers } from "lib/services";

const { GetOrderDetails } = BUTI.GetRequests;
const { GetDoordashDeliveryDetails } = Customers.GetRequests;

class OrderTracking extends React.Component {
  state = { courier_delivery_details: {}, order_details: {} };

  componentDidMount = () =>
    this.setState(
      { is_finding_order_details: true },
      this._findShopOrderDetails
    );

  _findShopOrderDetails = async () => {
    const { order_id, shop_id } = this.props;
    try {
      const { orderDetails: order_details = {} } = await GetOrderDetails({
        orderID: order_id,
        shopID: shop_id,
      });
      this.setState({ order_details }, () => {
        const { orderDeliveryTypeID } = order_details;
        if (orderDeliveryTypeID === "deliver") {
          const { deliveryDetails = {} } = order_details;
          const { courier } = deliveryDetails;
          if (courier === "doordash") {
            const { courierConfirmation = {} } = deliveryDetails;
            const { id = "" } = courierConfirmation;
            this._getDoordashDeliveryDetails({ delivery_id: id });
          }
        }
      });
    } catch {
      this.setState({ order_details: {} });
    } finally {
      this.setState({ is_finding_order_details: false });
    }
  };

  _getDoordashDeliveryDetails = async ({ delivery_id }) => {
    try {
      const data = await GetDoordashDeliveryDetails({ delivery_id });
      this.setState({
        courier_delivery_details: data,
        // courier_delivery_details: SAMPLE_DOORDASH_DELIVERY_DETAILS,
      });
    } catch {
      this.setState({ courier_delivery_details: {} });
    }
  };

  renderIllustration = (order_details = {}) => {
    const { orderDeliveryTypeID } = order_details;
    switch (orderDeliveryTypeID) {
      case "deliver":
        return (
          <Img
            alt=""
            src="https://skipli.s3.amazonaws.com/Shop+Order+Tracking/Food+Delivery+1.png"
          />
        );
      case "pickUp":
        return (
          <Img
            alt=""
            src="https://skipli.s3.amazonaws.com/Shop+Order+Tracking/Food+Pickup.png"
          />
        );
      case "inStore":
        return (
          <Img
            alt=""
            src="https://skipli.s3.amazonaws.com/Shop+Order+Tracking/Food+Dine+In.png"
          />
        );
      default:
        return null;
    }
  };

  renderNavigationBar = () => (
    <div className={Style.navigation_bar}>
      <div className={Style.navigation_bar_content}>
        <div className={Style.skipli_logo}>
          <SkipliLogoWithTextIcon />
        </div>
        {/* <button className={Style.help_button} onClick={() => {}}>
          Help
        </button> */}
      </div>
    </div>
  );

  render() {
    const { order_id, shop_basic_info, shop_id } = this.props;
    const { courier_delivery_details = {}, order_details = {} } = this.state;
    if (this.state.is_finding_order_details)
      return <PageMsg>Finding your order...</PageMsg>;
    else if (!order_id || _isEmpty(order_details))
      return (
        <ErrorPage
          back_button={{ label: "Place new order", url: `/shop/${shop_id}` }}
          error_code={404}
          header="Order not found!"
          image_src="https://skipli.s3.amazonaws.com/Skipli+Website/Error+Page/purr.png"
        />
      );

    const { lat = "", lng = "" } =
      _get(courier_delivery_details, "dasher.location") || {};
    const google_map_link = `https://maps.google.com/maps?q=${String(
      lat
    )},${String(lng)}&z=14&amp&output=embed`;

    return (
      <div>
        {this.renderNavigationBar()}
        <div className={Style.container}>
          <div className={Style.order_details_container}>
            <OrderTrackingModules.OrderDetails
              courier_delivery_details={courier_delivery_details}
              order_details={order_details}
              order_id={order_id}
              shop_basic_info={shop_basic_info}
              shop_id={shop_id}
            />
          </div>
          <div className={Style.illustration_container}>
            {lat && lng ? (
              <iframe
                className={Style.iframe}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                scrolling="no"
                src={google_map_link}
                title="Driver real-time position"
              />
            ) : (
              this.renderIllustration(order_details)
            )}
          </div>
        </div>
      </div>
    );
  }
}

OrderTracking.propTypes = {
  order_id: PropTypes.string.isRequired,
  shop_basic_info: PropTypes.object.isRequired,
  shop_id: PropTypes.string.isRequired,
};

export default OrderTracking;
