// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { Input, USStateSeletor } from "fields";

const DELIVERY_DESTINATION_FORM = {
  streetAddress1: {
    placeholder: "Street Address",
    required: true,
  },
  streetAddress2: {
    placeholder: "Apt, Suite, Unit, etc.",
  },
  city: {
    placeholder: "City",
    required: true,
  },
  state: {
    placeholder: "State",
    required: true,
  },
  zip: {
    placeholder: "Zip",
    required: true,
    type: "number",
  },
  notes: {
    hasMultipleLines: true,
    label: "Notes for Driver",
    placeholder: "Notes for Driver",
  },
};

class DeliveryAddressForm extends React.Component {
  onChangeInputValue = (fieldID, fieldValue) => {
    if (fieldID === "notes") this.props.onChangeDeliveryNotes(fieldValue);
    else this.props.onChangeDeliveryAddress(fieldID, fieldValue);
  };

  onGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;
          console.log(lat, lng);
        },
        () => console.log("Failed")
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  onGetFieldValue = (fieldID) => {
    if (fieldID === "notes") return this.props.deliveryNotes || "";
    const { deliveryAddress = {} } = this.props;
    return deliveryAddress[fieldID] || "";
  };

  // renderHeading = () => (
  //   <div className={Style.headingContainer}>
  //     <h2 className={Style.heading}>Your Address</h2>
  //     <Button
  //       className={Style.useCurrentLocationBtn}
  //       name="use current location"
  //       onClick={this.onGetCurrentLocation}
  //     >
  //       <TargetIcon />
  //       Use current location
  //     </Button>
  //   </div>
  // );

  renderInputFields = () =>
    Object.entries(DELIVERY_DESTINATION_FORM).map((entry) => {
      const fieldID = entry[0];
      const fieldData = entry[1];
      const field_value = this.onGetFieldValue(fieldID);
      if (fieldID === "state") {
        return (
          <div
            className={cx(Style.fieldContainer, Style[fieldID])}
            key={fieldID}
          >
            <USStateSeletor
              {...fieldData}
              on_select_state={(abbreviation) =>
                this.onChangeInputValue(fieldID, abbreviation)
              }
              selected_state={field_value}
            />
          </div>
        );
      }
      return (
        <div className={cx(Style.fieldContainer, Style[fieldID])} key={fieldID}>
          <Input.TextInput
            {...fieldData}
            name={fieldID}
            onChange={(val) => this.onChangeInputValue(fieldID, val)}
            onKeyUp={this.props.onKeyUpAddress}
            value={field_value}
          />
        </div>
      );
    });

  render() {
    return (
      <div>
        <h2 className={Style.heading}>Your Address</h2>
        <div className={Style.form}>{this.renderInputFields()}</div>
      </div>
    );
  }
}

DeliveryAddressForm.propTypes = {
  deliveryAddress: PropTypes.shape({
    streetAddress1: PropTypes.string,
    streetAddress2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.any,
  }),
  deliveryNotes: PropTypes.string,
  onChangeDeliveryAddress: PropTypes.func.isRequired,
  onChangeDeliveryNotes: PropTypes.func.isRequired,
  onKeyUpAddress: PropTypes.func.isRequired,
};

DeliveryAddressForm.defaultProps = {
  deliveryNotes: "",
};

export default DeliveryAddressForm;
