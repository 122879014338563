// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import {
  Chatwoot,
  Drift,
  LiveChatLoaderProvider,
  Messenger,
} from "react-live-chat-loader";

// Style
import Style from "./style.module.scss";

class LiveChatLoader extends React.Component {
  renderProvider = () => {
    switch (this.props.provider) {
      case "chatwoot":
        return <Chatwoot />;
      case "draft":
        return <Drift />;
      case "messenger":
        return <Messenger />;
      default:
        return;
    }
  };

  render() {
    if (!this.props.provider) return;
    return (
      <div className={Style.live_chat_loader_provider}>
        <LiveChatLoaderProvider
          appID={this.props.app_id}
          beforeInit={this.props.beforeInit}
          onReady={this.props.onReady}
          provider={this.props.provider}
          providerKey={this.props.provider_key}
          idlePeriod={this.props.idle_period}
        >
          {this.renderProvider()}
        </LiveChatLoaderProvider>
      </div>
    );
  }
}

LiveChatLoader.propTypes = {
  app_id: PropTypes.string,
  idle_period: PropTypes.number,
  onReady: PropTypes.func,
  provider: PropTypes.oneOf(["chatwoot", "drift", "messenger"]).isRequired,
  provider_key: PropTypes.string.isRequired,
};

LiveChatLoader.defaultProps = {
  beforeInit: () => console.log("Initiating live chat"),
  idle_period: 10,
  onReady: () => {},
};

export default LiveChatLoader;
