// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import CheckboxAndRadioGroup from "./index";

class CheckBoxGroup extends React.Component {
  render() {
    return <CheckboxAndRadioGroup {...this.props} kind="checkbox" />;
  }
}

CheckBoxGroup.propTypes = {
  heading: PropTypes.string,
  headingClassname: PropTypes.string,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  selectedOptionIds: PropTypes.array,
};

CheckBoxGroup.defaultProps = {
  readOnly: false,
  required: false,
};

export default CheckBoxGroup;
