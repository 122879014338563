// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { Checkbox, RadioButton } from "fields";

class CheckboxAndRadioGroup extends React.Component {
  onClickOption = (optionId) => {
    const { onClick, readOnly = false } = this.props;
    if (onClick && !readOnly) onClick(optionId);
  };

  renderCheckbox = (props = {}) => {
    const { selectedOptionIds = [] } = this.props;
    return (
      <Checkbox {...props} checked={selectedOptionIds.includes(props.id)} />
    );
  };

  renderRadio = (props = {}) => {
    const { selectedOptionId = "" } = this.props;
    return <RadioButton {...props} checked={selectedOptionId === props.id} />;
  };

  renderOptions = () => {
    const {
      kind = "radio",
      optionClassName = "",
      options = {},
      readOnly = false,
    } = this.props;
    return Object.keys(options).map((id) => {
      const { description, label } = options[id];
      const props = {
        description,
        descriptionClassName: this.props.descriptionClassName,
        id,
        label,
        labelClassName: this.props.labelClassName,
        onClick: this.onClickOption,
        readOnly,
      };
      return (
        <div className={cx(Style.option, optionClassName)} key={id}>
          {kind === "checkbox"
            ? this.renderCheckbox(props)
            : this.renderRadio(props)}
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <p className={cx(Style.heading, this.props.headingClassname)}>
          {this.props.heading}
          {this.props.required ? (
            <span className={Style.requiredSign}>required</span>
          ) : (
            <span className={Style.optionalSign}>(optional)</span>
          )}
        </p>
        <div className={Style.optionsContainer}>{this.renderOptions()}</div>
      </div>
    );
  }
}

CheckboxAndRadioGroup.propTypes = {
  heading: PropTypes.string,
  headingClassname: PropTypes.string,
  kind: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  optionClassName: PropTypes.string,
  options: PropTypes.object.isRequired,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  selectedOptionId: PropTypes.string,
  selectedOptionIds: PropTypes.array,
};

CheckboxAndRadioGroup.defaultProps = {
  optionClassName: "",
  readOnly: false,
  required: false,
};

export default CheckboxAndRadioGroup;
