// BUTI DINERS, INC. All right Reserved ©

import _orderBy from "lodash.orderby";
import _get from "lodash.get";

const _sortMenuItems = ({ items = {} }) =>
  _orderBy(
    Object.keys(items),
    [
      (item_id) => {
        const value = _get(items, `${item_id}.itemIsOutOfStock.true`);
        return value === "checked" ? 0 : 1;
      },
      (item_id) => _get(items, `${item_id}.itemName`) || "",
    ],
    ["desc", "asc"]
  );

const filteredCategories = ({ categories = {}, today = "", time = "" }) =>
  Object.keys(categories)
    .filter((key) => {
      const time_setting = categories[key].time_setting;
      if (!time_setting) {
        return true;
      }
      const {
        end_time,
        start_time,
        selected_date_options,
        time_selection,
      } = time_setting;
      const date_match =
        !selected_date_options || selected_date_options.includes(today);

      const time_match =
        time_selection !== "custom_time" ||
        (time >= start_time && time <= end_time);

      return date_match && time_match;
    })
    .reduce((obj, key) => {
      obj[key] = categories[key];
      return obj;
    }, {});

export { _sortMenuItems, filteredCategories };
