// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ContentLoader from "react-content-loader";

const MobileBlogLoader = (props) => (
  <ContentLoader
    height={150}
    width={350}
    speed={1}
    backgroundColor="#E0E0E0"
    foregroundColor="#F2F2F2"
    {...props}
  >
    <rect x="250" y="3" rx="0" ry="0" width="78" height="81" />
    <rect x="0" y="-1" rx="0" ry="0" width="67" height="8" />
    <rect x="-1" y="14" rx="0" ry="0" width="200" height="15" />
    <rect x="-2" y="37" rx="0" ry="0" width="180" height="11" />
    <rect x="-1" y="56" rx="0" ry="0" width="220" height="13" />
    <rect x="5" y="80" rx="0" ry="0" width="35" height="7" />
    <rect x="0" y="97" rx="0" ry="0" width="350" height="1" />
  </ContentLoader>
);

export default MobileBlogLoader;
