// BUTI DINERS, INC. All right Reserved ©

import PaymentMethodsModule from "./PaymentMethodsModule";
import PastOrdersModule from "./PastOrdersModule";
import ProfileModule from "./ProfileModule";

const CustomerProfileModules = {
  PaymentMethodsModule,
  PastOrdersModule,
  ProfileModule
};

export default CustomerProfileModules;
