// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import GuestsServed from "./GuestsServed";
import Introduction from "./Introduction";
import HowItWorks from "./HowItWorks";
import WhyItWorks from "./WhyItWorks";
import ProvenResults from "./ProvenResults";

// Utils
import { _updatePageTitleAndDescription } from "utils";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Components
import { TrustedByCarousel, TrustedByLogosGallery } from "components";

class AutoPhone extends React.Component {
  componentDidMount = () => {
    _updatePageTitleAndDescription({
      description:
        "SKIPLI's automation intelligently routes restaurant phone calls to online actions. Get more online orders, spend less time on the phone, and reduce the burden on staff from phone calls.",
      title: "SKIPLI | Auto Phone for Restaurants",
    });
  };

  render() {
    return (
      <div className={Style.pageContainer}>
        <Introduction />
        <TrustedByCarousel />
        <GuestsServed />
        <HowItWorks />
        <WhyItWorks />
        <ProvenResults />
        <TrustedByLogosGallery />
      </div>
    );
  }
}

AutoPhone.propTypes = {
  context: PropTypes.shape({
    onUpdateRefs: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(withRouter(AutoPhone));
