// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Partners from "./Partners";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Button } from "fields";

// Lib
import { FRONT_END_BASE_URL } from "lib/services";

// Assets
import {
  ArrowIcon,
  DeliverIcon,
  DoordashIcon,
  GrubhubIcon,
  LunchBagIcon,
  PostmatesIcon,
  SkipliLogoWithTextIcon,
  UberEatsIcon,
} from "assets/Icons";

const WELL_KNOWN_ORDERING_CHANNELS = {
  skipli: { logo: <SkipliLogoWithTextIcon /> },
  doordash: { logo: <DoordashIcon /> },
  postmates: { logo: <PostmatesIcon /> },
  grubhub: { logo: <GrubhubIcon /> },
  ubereats: { logo: <UberEatsIcon /> },
};

class OrderingChannels extends React.Component {
  state = {};

  onGetOrderingChannels = () => {
    const { shopID } = this.props;
    const { orderingChannels = {} } = this.props;
    return {
      ...orderingChannels,
      skipli: {
        url: `${FRONT_END_BASE_URL}/shop/${shopID}`,
      },
    };
  };

  onHideEditModal = () => this.setState({ showEditModal: false });

  onShowEditModal = () => this.setState({ showEditModal: true });

  renderEditModal = () => {
    const { WebsiteBuilderEdit } = Modals;
    return (
      <WebsiteBuilderEdit.OrderingChannels
        onChange={this.props.onChange}
        onCloseModal={this.onHideEditModal}
        orderingChannels={this.onGetOrderingChannels()}
        WELL_KNOWN_ORDERING_CHANNELS={WELL_KNOWN_ORDERING_CHANNELS}
      />
    );
  };

  renderOrderButtons = () => {
    const { themeColor } = this.props;
    const OrderArrow = styled(Button)`
      color: ${themeColor};
    `;
    const Arrow = styled(ArrowIcon)`
      fill: ${themeColor};
    `;
    return (
      <div
        className={Style.buttons}
        onClick={this.props.onRedirectToOrderOnline}
      >
        <div className={Style.orderDeliveryButton} name="order delivery">
          <div className={Style.deliverIcon}>
            <DeliverIcon />
          </div>
          <div className={Style.buttonText}>
            <h3>Delivery</h3>
            <p>
              We bring the order directly to you so you can stay home and relax.
            </p>
          </div>
          <OrderArrow className={Style.orderArrow} name="place delivery order">
            Order Delivery <Arrow />
          </OrderArrow>
        </div>
        <div className={Style.orderTakeoutButton} name="order pick up">
          <div className={Style.lunchBagIcon}>
            <LunchBagIcon />
          </div>
          <div className={Style.buttonText}>
            <h3>Takeout</h3>
            <p>You pick up the order at your time and skip the line.</p>
          </div>
          <OrderArrow className={Style.orderArrow} name="place takeout order">
            Order Takeout <Arrow />
          </OrderArrow>
        </div>
      </div>
    );
  };

  render() {
    const channels = this.onGetOrderingChannels();
    const channels_with_url_count = Object.values(channels).filter(
      ({ url }) => url
    );
    return (
      <div className={Style.container}>
        <h2 className={Style.heading}>Skip the line. Order ahead</h2>
        {this.renderOrderButtons()}
        {channels_with_url_count.length > 1 && (
          <div>
            <h3 className={Style.deliveryPartnersHeading}>
              Our delivery partners
            </h3>
            <Partners
              partners={channels}
              WELL_KNOWN_ORDERING_CHANNELS={WELL_KNOWN_ORDERING_CHANNELS}
            />
          </div>
        )}
      </div>
    );
  }
}

OrderingChannels.propTypes = {
  onChange: PropTypes.func.isRequired,
  onRedirectToOrderOnline: PropTypes.func.isRequired,
  orderingChannels: PropTypes.shape({
    skipli: PropTypes.shape({ url: PropTypes.string }),
    doordash: PropTypes.shape({ url: PropTypes.string }),
    postmates: PropTypes.shape({ url: PropTypes.string }),
    grubhub: PropTypes.shape({ url: PropTypes.string }),
    ubereats: PropTypes.shape({ url: PropTypes.string }),
  }),
  shopID: PropTypes.string.isRequired,
  themeColor: PropTypes.string,
};

export default OrderingChannels;
