// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { Redirect } from "react-router";
import _get from "lodash.get";

import Main from "main";
import { ErrorPage, ReceiptRedirect } from "views";

const ROUTES = (routeName = "", props = {}) => {
  switch (routeName) {
    case "businessCardShop":
      return <Redirect to="/shop/-Lmv88GKhkCZZF8DH7pJ" />;
    case "catering":
      return <Main {...props} showCateringMenu />;
    case "shopWebsite":
      return <Main {...props} showShopWebsite />;
    case "shop_survey":
      return <Main {...props} shop_survey />;
    case "order_tracking":
      return <Main {...props} show_shop_order_tracking />;
    case "customer":
    case "deals":
    case "cart":
    case "foodMenu":
    case "service":
      return <Main {...props} activeNavbarItemId={routeName} />;
    case "autoPhone":
    case "blog":
    case "blog_post":
    case "browseFood":
    case "contactless":
    case "getstarted":
    case "googleFrontPage":
    case "home_page":
    case "pricing":
    case "privacy":
    case "terms":
    case "testimonials":
    case "virtualRestaurant":
    case "websiteDesign":
    case "websiteExamples":
      return <Main {...props} />;
    case "receipts":
      return <ReceiptRedirect {...props} />;
    case "shopAdmin":
      const shopID = _get(props, "match.params.shopID");
      if (!shopID) return <ErrorPage />;
      else {
        window.location.replace(`https://admin.skiplinow.com/${shopID}`);
        return null;
      }
    default:
      return () => <ErrorPage />;
  }
};

export default ROUTES;
