// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import cx from "classnames";

import { BENEFITS } from "./constants";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class HomePageAllInOnePlatform extends React.Component {
  renderBenefits = () =>
    Object.keys(BENEFITS).map((benefit_id) => {
      const { list = [], title, video_url } = BENEFITS[
        benefit_id
      ];
      return (
        <div className={Style.benefit_container} key={benefit_id}>
          <div className={Style.benefit_text_container}>
            <h3 className={Style.benefit_title}>{title}</h3>
            <div>{this.renderList(list)}</div>
            {/* <div className={Style.replaces_container}>
              <div>Better than:</div>
              <div className={Style.replaces_list}>
                {this.renderReplaces(replaces)}
              </div>
            </div> */}
            <Button
              className={Style.startNowBtn}
              hasShadow
              name="get started"
              onClick={() => this.props.history.push("/get-started")}
            >
              Learn More <ChevronArrowIcon className={Style.arrowIcon} />
            </Button>
          </div>
          <div className={Style.benefit_video_container}>
            <div className={Style.benefit_video}>
              <ReactPlayer
                className={Style.video_player}
                config={{ file: { attributes: { preload: null } } }}
                height="auto"
                loop={true}
                muted={true}
                playing={true}
                playsinline={true}
                width="inherit"
                url={video_url}
              />
            </div>
          </div>
        </div>
      );
    });

  renderList = (list = []) =>
    list.map(({ description, label }) => (
      <div className={Style.list_item} key={label}>
        <h4>{label}</h4>
        <p>{description}</p>
      </div>
    ));

  renderReplaces = (replaces = []) =>
    replaces.map((company_name) => (
      <div className={Style.company_name} key={company_name}>
        {company_name}
      </div>
    ));

  render() {
    return (
      <div className={Style.background}>
        <div
          className={cx(
            MainWebsiteStyle.sectionContainer,
            Style.section_container
          )}
        >
          <h2 className={Style.title}>All-In-One Platform</h2>
          <div>{this.renderBenefits()}</div>
        </div>
      </div>
    );
  }
}

HomePageAllInOnePlatform.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(HomePageAllInOnePlatform);
