// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Img } from "fields";

// Lib
import { FoodMenuFuncs } from "lib/functions";

// Icons
import { ArrowIcon, PlusIcon } from "assets/Icons";

class ShopMenu extends React.Component {
  state = { loadedItems: {} };

  componentDidMount = () => {
    const { menu = {} } = this.props;
    const { items = {} } = menu;
    this.onGetItemsDetails({ items });
  };

  onGetItemsDetails = async ({ items }) => {
    const { GetInformationForItems } = FoodMenuFuncs;
    const loadedItems = await GetInformationForItems({
      items,
      shopID: this.props.shopID,
    });
    this.setState({ loadedItems });
  };

  renderMenuItems = () => {
    const { themeColor = "#3aafa9" } = this.props;
    const { loadedItems = {} } = this.state;
    const PlusIconWithThemeColor = styled(PlusIcon)`
      fill: ${themeColor};
    `;
    return Object.keys(loadedItems).map((itemID) => {
      const { itemImages = {}, itemDescription, itemName } = loadedItems[
        itemID
      ];
      const { full = {} } = itemImages;
      const { imageURL = "" } = full;
      return (
        <div
          className={Style.itemContainer}
          key={itemID}
          onClick={this.props.onRedirectToOrderOnline}
        >
          <Img alt="" className={Style.itemImage} src={imageURL} />
          <div className={Style.text_area}>
            <h3 className={Style.item_name}>{itemName}</h3>
            <p className={Style.item_description}>{itemDescription}</p>
          </div>
          <Button className={Style.add_item_button}>
            Add item <PlusIconWithThemeColor />
          </Button>
        </div>
      );
    });
  };

  render() {
    const { themeColor = "#3aafa9" } = this.props;
    const { loadedItems = {} } = this.state;
    const SeeFullMenuBtn = styled(Button)`
      background: ${themeColor};
    `;

    return (
      <div className={Style.container}>
        <h2 className={Style.heading}>Our Menu</h2>
        <div className={Style.menuItems}>{this.renderMenuItems()}</div>
        {Object.keys(loadedItems).length > 0 && (
          <SeeFullMenuBtn
            className={Style.seeFullMenuBtn}
            name="see full menu"
            onClick={this.props.onRedirectToOrderOnline}
          >
            See Full Menu <ArrowIcon />
          </SeeFullMenuBtn>
        )}
      </div>
    );
  }
}

ShopMenu.propTypes = {
  menu: PropTypes.exact({
    items: PropTypes.object,
  }),
  onRedirectToOrderOnline: PropTypes.func.isRequired,
  shopID: PropTypes.string.isRequired,
  themeColor: PropTypes.string,
};

export default ShopMenu;
