// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import cx from "classnames";

import Modifiers from "./Modifiers";

// Style
import Style from "./style.module.scss";

// Fields
import { Img } from "fields";

// Components
import { CustomerMenuElements } from "components";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Lib
import { FoodMenuFuncs } from "lib/functions";
import { Merchants } from "lib/services";

// Assets
import { PlusIcon } from "assets/Icons";

class CartItem extends Component {
  state = { showItemDetails: false };
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.onGetItemInfo();
  }

  componentWillUnmount() {
    this.setState({ showItemDetails: false });
    this._isMounted = false;
  }

  onGetItemInfo = async () => {
    const { GetMenuItemInformation } = Merchants.GetRequests;
    if (!this.state.itemInfo) {
      const { shopID = "" } = this.props.context;
      if (shopID) {
        const { itemID } = this.props.detailsOfItemInCart;
        const { itemInfo } = await GetMenuItemInformation({
          itemID,
          shopID,
        }).catch(() => this.setState({ itemInfo: null }));
        if (itemInfo && this._isMounted)
          this.setState({ itemInfo }, () => this.onGetItemImage(itemInfo));
      }
    }
  };

  onGetItemImage = async (itemInfo) => {
    const imageURL = _get(itemInfo, "itemImages.full.imageURL") || "";
    if (this._isMounted)
      this.setState({
        itemImage: imageURL && (
          <Img
            className={Style.itemDetailImg}
            lazyLoadClassname={Style.itemDetailLazyLoadImg}
            src={_get(itemInfo, "itemImages.full.imageURL") || ""}
          />
        ),
      });
  };

  onHideItemDetails = () => this.setState({ showItemDetails: false });

  onRemoveItemFromCart = () =>
    this.props.onRemoveItemFromCart(this.props.idOfItemInCart);

  onShowItemDetails = () => this.setState({ showItemDetails: true });

  renderCustomerNote = (customerInstruction) =>
    this.props.highlightCustomerNote ? (
      <mark style={{ fontWeight: "500" }}>{customerInstruction}</mark>
    ) : (
      <div>{customerInstruction}</div>
    );

  renderEditItemOptions = () => (
    <div className={Style.editItemOptions}>
      <button className={Style.editButton} onClick={this.onShowItemDetails}>
        Edit
      </button>
      <button
        className={Style.removeButton}
        onClick={this.onRemoveItemFromCart}
      >
        Remove
      </button>
    </div>
  );

  renderItemDetailsModal = () => {
    const { detailsOfItemInCart } = this.props;
    const { itemInfo } = this.state;
    return (
      itemInfo && (
        <CustomerMenuElements.MenuItemDetail
          categoryModifierGroups={detailsOfItemInCart.modifierGroups || {}}
          detailsOfItemInCart={detailsOfItemInCart}
          idOfItemInCart={this.props.idOfItemInCart}
          isItemInCart={true}
          itemID={detailsOfItemInCart.itemID}
          itemImage={this.state.itemImage}
          itemInfo={itemInfo}
          onCloseModal={this.onHideItemDetails}
        />
      )
    );
  };

  renderOnSaleAmount = (itemSaleRate) => {
    const { orderType = "", viewerType } = this.props;
    const result = (
      <div
        className={Style.itemExtraInfo}
        style={{ marginBottom: "5px", fontWeight: "500" }}
      >
        {itemSaleRate}% discount applied
      </div>
    );
    if (viewerType === "customer") return result;
    return orderType === "past" && result;
  };

  renderItemInfoColumn = () => {
    const { detailsOfItemInCart, showRemoveIcon, viewerType } = this.props;
    const {
      customerInstruction,
      itemIsOnSale = false,
      itemSaleRate = 0,
      itemSimpleDescription = {},
    } = detailsOfItemInCart;
    const { itemName } = itemSimpleDescription;
    return (
      <div className={Style.itemInfoColumn}>
        <div className={Style.itemDetailsColumn}>
          <div className={Style.itemName}>{itemName}</div>
          {itemIsOnSale && this.renderOnSaleAmount(itemSaleRate)}
          <div className={Style.itemExtraInfo}>
            <Modifiers modifierGroups={detailsOfItemInCart.modifierGroups} />
          </div>
          {customerInstruction && (
            <div className={cx(Style.itemExtraInfo, Style.customerInstruction)}>
              <div>Note:</div>
              {this.renderCustomerNote(customerInstruction)}
            </div>
          )}
          {viewerType === "customer" && (
            <p className={Style.ifSoldOut}>If sold out, Chef recommendation</p>
          )}
          {showRemoveIcon && this.renderEditItemOptions()}
        </div>
      </div>
    );
  };

  renderPriceColumn = () => {
    const { CalcTotalPriceBeforeTaxForItem } = FoodMenuFuncs;
    const { detailsOfItemInCart = {} } = this.props;
    const { totalPriceBeforeTax } = CalcTotalPriceBeforeTaxForItem({
      detailsOfItemInCart,
    });
    return <div className={Style.priceCol}>${totalPriceBeforeTax}</div>;
  };

  render() {
    const showItemDetails = this.state.showItemDetails && this.state.itemInfo;
    const { detailsOfItemInCart } = this.props;
    const { quantity } = detailsOfItemInCart;
    return (
      <React.Fragment>
        {showItemDetails && this.props.allowShowingItemDetailsModal && (
          <div>{this.renderItemDetailsModal()}</div>
        )}
        <div className={Style.container}>
          <div className={Style.itemCount}>
            {quantity}
            <PlusIcon />
          </div>
          <div className={Style.itemInCart}>
            {this.renderItemInfoColumn()}
            {this.props.showPrice && this.renderPriceColumn()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CartItem.propTypes = {
  allowShowingItemDetailsModal: PropTypes.bool,
  context: PropTypes.shape({ shopID: PropTypes.string }),
  detailsOfItemInCart: PropTypes.shape({
    customerInstruction: PropTypes.string,
    itemID: PropTypes.string.isRequired,
    itemSimpleDescription: PropTypes.shape({
      itemName: PropTypes.string.isRequired,
      itemPrice: PropTypes.any,
    }),
    quantity: PropTypes.number.isRequired,
  }).isRequired,
  highlightCustomerNote: PropTypes.bool,
  idOfItemInCart: PropTypes.string.isRequired,
  onRemoveItemFromCart: PropTypes.func,
  orderType: PropTypes.oneOf(["active", "past"]),
  showRemoveIcon: PropTypes.bool,
  showPrice: PropTypes.bool,
  viewerType: PropTypes.oneOf(["customer", "merchant"]),
};

CartItem.defaultProps = {
  allowShowingItemDetailsModal: true,
  highlightCustomerNote: false,
  showRemoveIcon: true,
  showPrice: true,
  viewerType: "customer",
};

export default withContext(CustomerInterfaceConsumer)(CartItem);
