// BUTI DINERS, INC. All right Reserved ©

import React from "react";

import { SUPPORT_FEATURES } from "./constants";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Assets
import { CheckIcon } from "assets/Icons";

class TwentyFourSevenSupport extends React.Component {
  renderSupportFeatures = () =>
    SUPPORT_FEATURES.map((feature, index) => (
      <div className={Style.feature} key={index}>
        <div className={Style.check_icon}>
          <CheckIcon />
        </div>
        <div className={Style.feature_name}>{feature}</div>
      </div>
    ));

  render() {
    return (
      <div className={Style.background}>
        <div className={MainWebsiteStyle.sectionContainer}>
          <h2 className={Style.title}>World-class Support</h2>
          <p className={Style.description}>
            Our support is based in the United States. We’re here for you to make your restaurant successful and grow.
          </p>
          <div className={Style.features_container}>
            {this.renderSupportFeatures()}
          </div>
        </div>
      </div>
    );
  }
}

export default TwentyFourSevenSupport;
