// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import { HOW_WE_CAN_HELP, REFERENCES } from "./HelperFunctions";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Link } from "fields";

// Assets
import { PhoneIcon } from "assets/Icons";

class TestimonialsReferences extends React.Component {
  refs = {};

  renderHowWeCanHelp = () => (
    <div className={Style.howWeHelp}>
      <h2>How Skipli can help your business</h2>
      {HOW_WE_CAN_HELP.map((element, index) => {
        const { description, title } = element;
        return (
          <div className={Style.benefit} key={index}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        );
      })}
      <Button
        className={Style.signUpButton}
        name="sign up"
        onClick={() => this.props.history.push("/get-started")}
      >
        Get a free demo
      </Button>
    </div>
  );

  renderReferences = () =>
    REFERENCES.map((ref, index) => {
      const {
        image,
        description,
        restaurantName,
        location,
        menuLink = "",
        referenceClassName = "",
        textStyle,
      } = ref;
      return (
        <div
          className={cx(
            Style.reference,
            index % 2 === 1 && Style.reversed,
            referenceClassName
          )}
          key={index}
        >
          {image}
          <div className={Style.text} style={textStyle}>
            <p>“{description}”</p>
            <div className={Style.shopNameContainer}>
              <h2 className={Style.shopName}>
                {restaurantName}
                <br />
                {location}
              </h2>
              <Button
                className={Style.viewMenuButton}
                hasShadow
                name="view menu"
                onClick={() => window.open(menuLink, "_blank")}
              >
                See Example
              </Button>
            </div>
          </div>
        </div>
      );
    });

  render() {
    return (
      <div>
        <div className={Style.topSignUpContainer}>
          <div className={Style.topButtons}>
            <Button
              className={Style.topSignUpButton}
              name="sign up"
              onClick={() => this.props.history.push("/get-started")}
            >
              Get free demo
            </Button>
            <Link
              className={Style.topCallButton}
              href={`tel:4156109521`}
              target="_self"
            >
              <PhoneIcon /> (415) 610-9521
            </Link>
          </div>
        </div>
        <div className={Style.referencesContainer}>
          {this.renderReferences()}
        </div>
        {this.renderHowWeCanHelp()}
      </div>
    );
  }
}

TestimonialsReferences.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(TestimonialsReferences);
