// BUTI DINERS, INC. All right Reserved ©

// Menu
import DesktopMenuItemsLoader from "./Menu/DesktopMenuItemsLoader";
import MobileMenuItems from "./Menu/MobileMenuItemsLoader";
import MobileMenuGroup from "./Menu/MobileMenuGroupLoader";

// Modifier
import ModifierLoader from "./MenuModifier/ModifierLoader";

// Shop
import DesktopShopLoader from "./Shop/DesktopShopLoader";
import MobileShopLoader from "./Shop/MobileShopLoader";

// Blog
import DesktopBlogLoader from "./BlogList/DesktopBlogLoader";
import MobileBlogLoader from "./BlogList/MobileBlogLoader.js";

// Article
import ResponsivePostDetail from "./PostDetail/ResponsivePostDetail";

const ContentLoader = {
  DesktopMenuItemsLoader,
  DesktopShopLoader,
  MobileMenuGroup,
  MobileMenuItems,
  MobileShopLoader,
  ModifierLoader,
  DesktopBlogLoader,
  MobileBlogLoader,
  ResponsivePostDetail,
};

export default ContentLoader;
