// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

class SubmitButton extends Component {
  state = { buttonStatus: "active" };

  renderPriceColumn = () => {
    const {
      itemIsOnSale,
      totalPriceBeforeTax,
      totalPriceBeforeTaxWithoutSale,
    } = this.props;
    if (!itemIsOnSale)
      return <div style={{ fontWeight: "400" }}>${totalPriceBeforeTax}</div>;
    return (
      <React.Fragment>
        <div className={Style.totalPriceBeforeSale}>
          ${totalPriceBeforeTaxWithoutSale}
        </div>
        <div className={Style.totalPriceAfterSale}>${totalPriceBeforeTax}</div>
      </React.Fragment>
    );
  };

  render() {
    const { isLoadingModifierGroups } = this.props;
    return (
      <div>
        <Button
          className={Style.submitButton}
          name="item detail footer button"
          onClick={this.props.onClick}
          status={isLoadingModifierGroups ? "inactive" : "active"}
        >
          {isLoadingModifierGroups ? (
            "Finding best price for you..."
          ) : (
            <React.Fragment>
              <strong>{this.props.isItemInCart ? "Update" : "Add Item"}</strong>
              <div className={Style.priceCol}>{this.renderPriceColumn()}</div>
            </React.Fragment>
          )}
        </Button>
      </div>
    );
  }
}

SubmitButton.propTypes = {
  isItemInCart: PropTypes.bool.isRequired,
  isLoadingModifierGroups: PropTypes.bool,
  itemIsOnSale: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  totalPriceBeforeTax: PropTypes.string.isRequired,
  totalPriceBeforeTaxWithoutSale: PropTypes.string,
};

SubmitButton.defaultProps = {
  isLoadingModifierGroups: false,
  itemIsOnSale: false,
  totalPriceBeforeTax: "0",
};

export default SubmitButton;
