// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

// Fields
import { Link } from "fields";

const StripeBrand = () => (
  <div className={Style.container}>
    <p className={Style.stripeBrand}>
      Powered by <strong>SKIPLI</strong>
    </p>
    <p className={Style.links}>
      <span style={{ marginRight: "15px" }}>
        <Link className={Style.link} href="https://www.skiplinow.com/terms">
          Terms
        </Link>
      </span>
      <span>
        <Link className={Style.link} href="https://www.skiplinow.com/privacy">
          Privacy
        </Link>
      </span>
    </p>
  </div>
);

export default StripeBrand;
