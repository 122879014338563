// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { withRouter } from "react-router-dom";

import { HOW_IT_WORKS_STEPS } from "./constants";

// Style
import Style from "./style.module.scss";

// Fields
import Button from "fields/Button";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class AutoPhoneHowItWorks extends React.Component {
  renderStepsList = () =>
    HOW_IT_WORKS_STEPS.map((step, index) => {
      const { img_src, name } = step;
      return (
        <div key={shortid.generate()}>
          <div className={Style.step_container}>
            <div className={Style.image}>
              <img alt="" src={img_src} />
            </div>
            <div>
              <div className={Style.step_count}>Step {index + 1}</div>
              <div className={Style.step_name}>{name}</div>
            </div>
          </div>
          {index < HOW_IT_WORKS_STEPS.length - 1 && (
            <div className={Style.dot} />
          )}
        </div>
      );
    });

  render() {
    return (
      <div className={Style.container}>
        <h2 className={Style.title}>
          Control your online presence from first impression to final
          transaction
        </h2>
        <div className={Style.diagram_container}>
          <img
            alt=""
            src="https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/Auto+Phone+How+It+Works+1.png"
          />
        </div>
        <div className={Style.steps_list}>{this.renderStepsList()}</div>
        <Button
          className={Style.get_live_demo_button}
          name="Get a live demo button"
          onClick={() => this.props.history.push("/get-started")}
        >
          Get a live demo <ChevronArrowIcon className={Style.arrow_icon} />
        </Button>
      </div>
    );
  }
}

AutoPhoneHowItWorks.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AutoPhoneHowItWorks);
