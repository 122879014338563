// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { ABOUT_US, LEGAL, PAYMENTS, SKIPLI_SOCIAL_MEDIA } from "./constants";

// Style
import Style from "./style.module.scss";

// Icons
import { SkipliLogoWithTextIcon } from "assets/Icons";

// Fields
import { Button, Link } from "fields";

class GlobalFooter extends React.Component {
  renderRow1 = () => (
    <div className={cx(Style.row, Style.row_1)}>
      <p className={Style.row_1_paragraph}>
        The <span>all-in-one system</span> for restaurants to
        increase online ordering sales, save money on fees,
        recruit quality staff, and impress customers.
      </p>
      <Button
        className={Style.get_free_demo_button}
        name="get a free demo"
        onClick={() =>
          window.open("https://skiplinow.com/get-started", "_blank")
        }
      >
        Get A Free Demo
      </Button>
    </div>
  );

  renderRow2 = () => (
    <div className={cx(Style.row, Style.row_2)}>
      <div className={Style.row_2_column}>
        <h5>Sales</h5>
        <div className={Style.link}>
          <Link href={"tel:4156109521"} target="_self">
            (415) 610-9521
          </Link>
        </div>
        <div className={Style.link}>
          <Link href="mailto:sales@skiplinow.com">sales@skiplinow.com</Link>
        </div>
      </div>
      <div className={Style.row_2_column}>
        <h5>Guest Support</h5>
        <div className={Style.link}>
          <Link href={"tel:6789991039"} target="_self">
            (678) 999-1039
          </Link>
        </div>
        <div className={Style.link}>
          <Link href="mailto:support@skiplinow.com">support@skiplinow.com</Link>
        </div>
      </div>
      <div className={Style.row_2_column}>
        <h5>Merchant Support</h5>
        <div className={Style.link}>
          <Link href="mailto:business@skiplinow.com">
            business@skiplinow.com
          </Link>
        </div>
      </div>
      <div className={Style.row_2_column}>
        <h5>Careers</h5>
        <div className={Style.link}>
          <Link href="mailto:careers@skiplinow.com">careers@skiplinow.com</Link>
        </div>
      </div>
    </div>
  );

  renderRow3 = () => (
    <div className={cx(Style.row, Style.row_2)}>
      <div className={Style.row_2_column}>
        <h5>About Us</h5>
        {ABOUT_US.map(({ href, name }) => (
          <div className={Style.link} key={name}>
            <Link href={href} target="_self">
              {name}
            </Link>
          </div>
        ))}
      </div>
      <div className={Style.row_2_column}>
        <h5>Payments</h5>
        {PAYMENTS.map(({ href, name }) => (
          <div className={Style.link} key={name}>
            <Link href={href}>{name}</Link>
          </div>
        ))}
      </div>
      <div className={Style.row_2_column}>
        <h5>Social Media</h5>
        <div className={Style.socialMedia}>
          {Object.keys(SKIPLI_SOCIAL_MEDIA).map((social_media_company) => {
            const { href, icon } = SKIPLI_SOCIAL_MEDIA[social_media_company];
            return (
              <React.Fragment key={social_media_company}>
                <Link href={href}>
                  <div className={Style.socialMediaIcon}>{icon}</div>
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );

  renderRow4 = () => (
    <div className={Style.row_4}>
      <div className={Style.skipli_logo_container}>
        <SkipliLogoWithTextIcon />
      </div>
      <div className={Style.legal_links_container}>
        {LEGAL.map(({ href, name }) => (
          <div className={Style.legal_link} key={name}>
            <Link href={href}>{name}</Link>
          </div>
        ))}
      </div>
    </div>
  );

  render() {
    const { class_names = {} } = this.props;
    return (
      <div className={cx(Style.container, class_names.container)}>
        <div className={Style.content}>
          {this.renderRow1()}
          {this.renderRow2()}
          {this.renderRow3()}
          {this.renderRow4()}
        </div>
      </div>
    );
  }
}
GlobalFooter.propTypes = {
  class_names: PropTypes.shape({
    container: PropTypes.string,
  }),
  history: PropTypes.object.isRequired,
};

GlobalFooter.defaultProps = {
  class_names: {},
};

export default withRouter(GlobalFooter);
