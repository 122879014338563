// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { ComingSoonButton } from "fields";

// Assets
import { ChevronArrowIcon, ClockIcon, StarFullIcon } from "assets/Icons";

class OrderingPageShopIntroduction extends React.Component {
  state = {};

  onGetTodayOpenHours = (today_hours = {}) => {
    const { isShopClosed = false } = today_hours;
    if (isShopClosed) return <div className={Style.closedToday}>Closed</div>;
    const { shopTodayExtraHours = {}, shopTodayOpenHours = {} } = today_hours;
    const { localEndAt12Hr = "", localStartAt12Hr = "" } = shopTodayOpenHours;
    if (!localStartAt12Hr && !localEndAt12Hr) return " - ";
    return (
      <div className={Style.openHours}>
        <div>
          {localStartAt12Hr} - {localEndAt12Hr}
        </div>
        {Object.keys(shopTodayExtraHours).length > 0 &&
          this.renderExtraHours(shopTodayExtraHours)}
        <div className={Style.arrow_icon}>
          <ChevronArrowIcon />
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { shop_basic_info = {}, today_hours } = this.props;
    const { address, name } = shop_basic_info;
    const { membership = {} } = shop_basic_info;
    const { status } = membership;
    return (
      <div
        onClick={() => this.setState({ show_about_shop_modal: true })}
        style={{ cursor: "pointer" }}
      >
        <div className={Style.shop_name}>
          <h1>Ordering from</h1>
          <h2>{name}</h2>
        </div>
        <p className={Style.address}>{address}</p>
        {status === "comingSoon" ? (
          <ComingSoonButton className={Style.comingSoonButton} />
        ) : (
          <React.Fragment>
            <div className={Style.ratings_container}>
              <div className={Style.ratings}>
                4.4 <StarFullIcon /> (1,000+ ratings)
              </div>
              <div className={Style.dot}>•</div>
              <div>$0 Pickup Fee</div>
            </div>
            <div className={Style.ratings_container}>
              <div className={Style.desktop_clock_icon}>
                <ClockIcon />
              </div>
              Open: {this.onGetTodayOpenHours(today_hours)}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderLogo = (logoURL = "") =>
    !this.props.shop_logo_not_found && (
      <div className={cx(Style.shop_logo_container, Style.minimized)}>
        <img
          alt=""
          className={Style.shop_logo}
          onError={this.props.on_shop_logo_not_found}
          src={logoURL}
        />
      </div>
    );

  renderExtraHours = (shopTodayExtraHours) =>
    Object.keys(shopTodayExtraHours).map((timeId, index) => {
      const {
        localEndAt12Hr = "",
        localStartAt12Hr = "",
      } = shopTodayExtraHours[timeId];
      return (
        (localStartAt12Hr || localEndAt12Hr) && (
          <React.Fragment key={index}>
            <div className={Style.dot}>•</div>
            <div>
              {localStartAt12Hr} - {localEndAt12Hr}
            </div>
          </React.Fragment>
        )
      );
    });

  render() {
    const { shop_basic_info = {}, today_hours } = this.props;
    return (
      <React.Fragment>
        {this.state.show_about_shop_modal && (
          <Modals.AboutShop
            onCloseModal={() => this.setState({ show_about_shop_modal: false })}
            shop_basic_info={shop_basic_info}
            today_hours={today_hours}
          />
        )}
        {this.renderContent()}
      </React.Fragment>
    );
  }
}

OrderingPageShopIntroduction.propTypes = {
  on_shop_logo_not_found: PropTypes.func,
  shop_basic_info: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  shop_logo_not_found: PropTypes.bool,
  thumbnail_img_not_found: PropTypes.bool,
  today_hours: PropTypes.object,
};

OrderingPageShopIntroduction.defaultProps = {
  on_shop_logo_not_found: () => {},
  shop_logo_not_found: false,
  thumbnail_img_not_found: false,
};

export default OrderingPageShopIntroduction;
