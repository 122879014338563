// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

import { SKIPLI_INFO_OPTIONS, _createMainMenuOptions } from "./helperFunctions";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Link, ResponsiveModal } from "fields";

// Assets
import { ChevronArrowIcon, SkipliLogoWithTextIcon } from "assets/Icons";

class OrderingPageMainMenu extends React.Component {
  renderMainMenuOptions = () => {
    const shop_name = _get(this.props.context, "shopBasicInfo.name");
    const main_menu_options = _createMainMenuOptions({ shop_name });
    return Object.keys(main_menu_options).map((option_id) => {
      const { icon, label } = main_menu_options[option_id];
      return (
        <div
          className={Style.option}
          key={option_id}
          onClick={() => this.props.onSelectOption(option_id)}
        >
          <div className={Style.option_label_container}>
            <div className={Style.option_icon}>{icon}</div>
            <div className={Style.option_label}>{label}</div>
          </div>
          <div className={Style.arrowIcon}>
            <ChevronArrowIcon />
          </div>
        </div>
      );
    });
  };

  renderSkipliInfoOptions = () =>
    Object.keys(SKIPLI_INFO_OPTIONS).map((option_id) => {
      const { label, url } = SKIPLI_INFO_OPTIONS[option_id];
      return (
        <div className={Style.skipli_info_option} key={option_id}>
          <Link href={url}>{label}</Link>
        </div>
      );
    });

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: Style.modal }}
        contentLabel="Ordering page main menu"
        onCloseModal={this.props.onCloseModal}
      >
        <div>
          {this.renderMainMenuOptions()}
          <div className={Style.divider} />
          <div className={Style.skipli_logo}>
            <SkipliLogoWithTextIcon />
          </div>
          {this.renderSkipliInfoOptions()}
        </div>
      </ResponsiveModal>
    );
  }
}

OrderingPageMainMenu.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSelectOption: PropTypes.func.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(OrderingPageMainMenu);
