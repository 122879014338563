// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";

import { _sortMenuItems } from "../HelperFunctions";

// Style
import Style from "./style.module.scss";

// Components
import { CustomerMenuElements } from "components";

class DesktopMenuGroup extends React.Component {
  onChange = (isVisible) => this.props.onSetGroupVisibility(isVisible);

  render() {
    const { items = {} } = this.props;
    return Object.keys(items).length === 0 ? null : (
      <VisibilitySensor
        onChange={this.onChange}
        offset={{ top: 140 }}
        partialVisibility
      >
        <div>
          <h2 className={Style.categoryName}>{this.props.groupName}</h2>
          <CustomerMenuElements.ListOfItems
            categoryModifierGroups={this.props.modifierGroups}
            items={items}
            sortedItemIDs={_sortMenuItems({ items })}
          />
        </div>
      </VisibilitySensor>
    );
  }
}

DesktopMenuGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  modifierGroups: PropTypes.object,
};

export default DesktopMenuGroup;
