// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

// Assets
import { InfoIcon } from "assets/Icons";

const _renderTopBanner = () => (
  <div className={Style.bannerContainer}>
    <div className={Style.infoIcon}>
      <InfoIcon />
    </div>
    <div className={Style.bannerText}>
      Order here for best price, faster service, savings on fees, and to support
      local business.
    </div>
  </div>
);

export { _renderTopBanner };
