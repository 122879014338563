// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { Link, withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Functions
import {
  _createBlogSkeleton,
  _getShortParagraph,
  _formatDate,
  RawHTML,
  _readTime,
} from "./functions";

// Fields
import { Button } from "fields";

// Assets
import { NewWindowIcon } from "assets/Icons";

// Service
import { Customers } from "lib/services";
const { GetAllBlogForCustomer } = Customers.GetRequests;

class BlogPostList extends React.Component {
  state = { posts: [] };

  componentDidMount = () => this.handleGetBlog();

  handleGetBlog = async () => {
    this.setState({ isFindingPost: true });
    try {
      const { posts = [] } = await GetAllBlogForCustomer();
      this.setState({ posts });
    } catch {
      this.setState({ fetch_blogs_failed: true });
    } finally {
      this.setState({ isFindingPost: false });
    }
  };

  renderPost = (post = {}) => {
    const { categories, content, date, featured_image, title } = post;
    const tag_post = Object.keys(categories)[0] || "";
    return (
      <div>
        <RawHTML className={Style.pub_date}>{_formatDate(date)}</RawHTML>
        <div className={Style.article_preview_info}>
          <div className={Style.article_preview_text}>
            <div>
              <h2 className={Style.article_title}>{title}</h2>
              <RawHTML className={Style.article_description} tag="p">
                {_getShortParagraph(content)}
              </RawHTML>
              <Button
                className={Style.view_full_article_button}
                name="view full article"
              >
                View full article
                <div className={Style.new_window_icon}>
                  <NewWindowIcon />
                </div>
              </Button>
            </div>
            <div className={Style.article_extra_info}>
              <div className={Style.tag_post}>{tag_post}</div>
              <div className={Style.read_time}>
                {_readTime(content)} min read
              </div>
            </div>
          </div>
          {featured_image && (
            <img
              alt={title}
              className={Style.featured_image}
              src={featured_image}
            />
          )}
        </div>
      </div>
    );
  };

  renderPostsList = () => {
    const { posts = [] } = this.state;
    return posts.map((item) => {
      const { ID, slug } = item;
      return (
        <div className={Style.link_container} key={ID}>
          <Link
            className={Style.article}
            target="_blank"
            to={{ pathname: `/blog/${slug}`, state: { post_info: item } }}
          >
            {this.renderPost(item)}
          </Link>
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        {this.state.isFindingPost
          ? _createBlogSkeleton()
          : this.renderPostsList()}
      </div>
    );
  }
}

export default withRouter(BlogPostList);
