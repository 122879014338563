// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Assets
import { CloseIcon, PlusIcon } from "assets/Icons";

class SplitOverlay extends React.Component {
  state = {};

  onClickItem = ({ item_description = "", item_name }) =>
    item_description &&
    this.setState({
      selected_item_name:
        this.state.selected_item_name === item_name ? "" : item_name,
    });

  renderActionButton = () => {
    const { action_button = {} } = this.props;
    const {
      action = () => this.props.history.push("/get-started"),
      text = "Start now",
    } = action_button;
    return (
      <Button className={Style.actionButton} name="start now" onClick={action}>
        {text}
      </Button>
    );
  };

  renderList = (list = []) =>
    list.map(({ item_description = "", item_name }) => {
      const is_item_selected = this.state.selected_item_name === item_name;
      return (
        <div
          className={Style.listItem}
          key={item_name}
          onClick={() => this.onClickItem({ item_description, item_name })}
        >
          <div className={Style.iconContainer}>
            {is_item_selected ? (
              <CloseIcon className={Style.closeIcon} />
            ) : (
              <PlusIcon className={Style.plusIcon} />
            )}
          </div>
          <div>
            <p
              className={cx(Style.itemName, is_item_selected && Style.selected)}
            >
              {item_name}
            </p>
            {is_item_selected && (
              <p className={Style.itemDescription}>{item_description}</p>
            )}
          </div>
        </div>
      );
    });

  render() {
    const { description, image, label, list, reverse } = this.props;
    return (
      <div className={cx(Style.container, reverse && Style.reverse)}>
        <div className={Style.text_column}>
          <div className={Style.text_content}>
            <h2 className={Style.heading}>{label}</h2>
            {description && <p className={Style.paragraph}>{description}</p>}
            <div className={Style.listContainer}>{this.renderList(list)}</div>
            {this.renderActionButton()}
          </div>
        </div>
        <div className={Style.image_column}>
          <img alt="" className={Style.hero_image} src={image} />
        </div>
      </div>
    );
  }
}

SplitOverlay.propTypes = {
  history: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.arrayOf({
    text: PropTypes.string.isRequired,
  }).isRequired,
  reverse: PropTypes.bool,
};

SplitOverlay.defaultProps = {
  reverse: false,
};

export default withRouter(SplitOverlay);
