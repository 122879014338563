// BUTI DINERS, INC. All right Reserved ©

const HOW_IT_WORKS_STEPS = [
  {
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/How+It+works+Step+1.png",
    name: "Customer calls your restaurant",
  },
  {
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/How+It+works+Step+2.png",
    name:
      "Automated voice greeting, provide several options to meet what they need",
  },
  {
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/How+It+works+Step+3.png",
    name: "Send customer SMS message with direction & menu links",
  },
  {
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/How+It+works+Step+4.png",
    name: "Customer places & pay for online orders on their phone",
  },
  {
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/How+It+works+Step+5.png",
    name: "Customer is notified via SMS message when order is ready",
  },
  {
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/How+It+works+Step+6.png",
    name:
      "Customer receives an invite to give good reviews on Google & social media",
  },
  {
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/How+It+works+Step+7.png",
    name:
      "Good reviews are posted. Bad reviews are saved in dashboard for you to view",
  },
  {
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/How+It+works+Step+8.png",
    name: "Automated marketing messages remind customer to revisit",
  },
];

export { HOW_IT_WORKS_STEPS };
