// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

class ResponsiveModalContentDescription extends React.Component {
  render() {
    const { classNames = {}, contentDescriptionProps = {} } = this.props;
    const {
      contentDescription,
      showContentDescription = false,
      title,
    } = contentDescriptionProps;
    if (!showContentDescription || !contentDescription) return null;
    return (
      <div
        className={cx(
          Style.contentDescriptionContainer,
          classNames.contentDescriptionContainer
        )}
      >
        {title && <h2 className={Style.title}>{title}</h2>}
        <div className={Style.contentDescription}>{contentDescription}</div>
      </div>
    );
  }
}

ResponsiveModalContentDescription.defaultProps = {
  classNames: PropTypes.shape({
    contentDescriptionContainer: PropTypes.string,
  }),
  contentDescriptionProps: PropTypes.shape({
    contentDescription: PropTypes.any,
    showContentDescription: PropTypes.bool,
    title: PropTypes.string,
  }),
};

ResponsiveModalContentDescription.defaultProps = {
  classNames: {},
  contentDescriptionProps: {
    contentDescription: null,
    showContentDescription: false,
    title: "Did you know?",
  },
};

export default ResponsiveModalContentDescription;
