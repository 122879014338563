// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import PageContentContainer from "./PageContentContainer";
import { _getTodayHours, _createMenuSkeleton } from "./HelperFunctions";

// Utils
import { _scrollTo, _updatePageTitleAndDescription } from "utils";

// Views
import { Cart, OrderTracking, ShopSurvey, ShopWebsite } from "views";

// Style
import Style from "./style.module.scss";

// Components
import { CustomerMenuElements, Modals, Navbars } from "components";

// Fields
import { PageMsg } from "fields";

// Context
import { CustomerInterfaceProvider } from "context";

// Lib
import { LocalStorage } from "lib/functions";
import { Customers } from "lib/services";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavbarItemId: "foodMenu",
      categoriesVisibility: { 0: true },
      customerMenus: {},
      customerProfile: props.customerProfile,
      order_fulfillment_method: "pickUp",
    };
    this.refs = {};
  }

  componentDidMount = () => {
    this.onChangeDocumentTitleDescription();
    this.onGetTodayHours();
    this.onGetCustomerCart();
    this.onGetMenusForCustomer();
  };

  onChangeCustomerCart = (cart = {}) => this.setState({ cart });

  onChangeCustomerProfile = (customerProfile) =>
    this.setState({ customerProfile });

  onChangeDocumentTitleDescription = () => {
    const { address = "", name = "" } = this.props.shopBasicInfo;
    const text = `${name} (${address}) Delivery Online | Order Pickup | Menu & Prices | Skipli`;
    _updatePageTitleAndDescription({
      description: text,
      title: text,
    });
  };

  onChangeGlobalNavItem = (newItemId) => {
    if (newItemId === "customerProfile") this.onShowUserProfileModal();
    else this.setState({ activeNavbarItemId: newItemId });
  };

  onChangeOrderFulfillmentMethod = (new_fulfillment_method) =>
    this.setState({ order_fulfillment_method: new_fulfillment_method });

  onGetCustomerCart = async () => {
    const { GetCustomerCart } = Customers.GetRequests;
    const { cart = {} } = await GetCustomerCart({
      getCateringCart: this.props.showCateringMenu,
      shopID: this.props.shopID,
      uuid: LocalStorage.getItemFromStorage("uuid"),
    });
    this.setState({ cart });
  };

  onGetMenusForCustomer = async () => {
    const { GetMenusForCustomer } = Customers.GetRequests;
    this.setState({ isLoadingCustomerMenus: true });
    const { customerMenus } = await GetMenusForCustomer({
      shopID: this.props.shopID,
      showCateringMenu: this.props.showCateringMenu,
    });
    this.setState({ customerMenus, isLoadingCustomerMenus: false });
  };

  onGetTodayHours = async () => {
    const todayHours = await _getTodayHours(this.props.shopBasicInfo);
    this.setState({ todayHours });
  };

  onHideUserProfileModal = () => this.setState({ showUserProfileModal: false });

  onPickDisplayMenu = () => {
    const { customerMenus = {} } = this.state;
    if (Object.keys(customerMenus).length === 0)
      return { menuID: "", menuInfo: {} };
    const menuID = Object.keys(customerMenus)[0];
    return { menuID, menuInfo: customerMenus[menuID] };
  };

  onSetCategoryRef = (categoryID, ref) => {
    this.refs = set(this.refs, categoryID, ref);
  };

  onSetCategoryVisibility = (categoryIndex, inViewport) =>
    this.setState({
      categoriesVisibility: set(
        this.state.categoriesVisibility,
        categoryIndex,
        inViewport
      ),
    });

  onScrollToCategory = (categoryID) =>
    _scrollTo({ ref: this.refs[categoryID], top: "-190px" });

  onShowUserProfileModal = () => this.setState({ showUserProfileModal: true });

  renderCustomerMenus = () => {
    if (this.state.isLoadingCustomerMenus)
      return (
        <div className={Style.menuSkeletonContainer}>
          {_createMenuSkeleton()}
        </div>
      );
    const { menuID = "", menuInfo = {} } = this.onPickDisplayMenu();
    if (!menuID) return <PageMsg>No menu available</PageMsg>;
    return (
      <CustomerInterfaceProvider
        value={{
          cart: this.state.cart,
          onChangeGlobalNavItem: this.onChangeGlobalNavItem,
          onChangeCustomerCart: this.onChangeCustomerCart,
          onSetCategoryRef: this.onSetCategoryRef,
          onSetCategoryVisibility: this.onSetCategoryVisibility,
          showCateringMenu: this.props.showCateringMenu,
          shopBasicInfo: this.props.shopBasicInfo,
          shopID: this.props.shopID,
          todayHours: this.state.todayHours,
        }}
      >
        <CustomerMenuElements.Menu menuID={menuID} menuInfo={menuInfo} />
      </CustomerInterfaceProvider>
    );
  };

  renderCustomerCart = () => (
    <CustomerInterfaceProvider
      value={{
        cart: this.state.cart,
        checkoutSessionID: this.props.checkoutSessionID,
        customerProfile: this.state.customerProfile,
        onChangeCustomerCart: this.onChangeCustomerCart,
        onChangeCustomerProfile: this.onChangeCustomerProfile,
        onChangeGlobalNavItem: this.onChangeGlobalNavItem,
        onChangeOrderFulfillmentMethod: this.onChangeOrderFulfillmentMethod,
        onShowUserProfileModal: this.onShowUserProfileModal,
        order_fulfillment_method: this.state.order_fulfillment_method,
        shopBasicInfo: this.props.shopBasicInfo,
        shopID: this.props.shopID,
        showCateringMenu: this.props.showCateringMenu,
        todayHours: this.state.todayHours,
      }}
    >
      <Cart />
    </CustomerInterfaceProvider>
  );

  renderCustomerProfileModal = () => (
    <CustomerInterfaceProvider
      value={{
        customerProfile: this.state.customerProfile,
        onChangeCustomerProfile: this.onChangeCustomerProfile,
        shopBasicInfo: this.props.shopBasicInfo,
        shopID: this.props.shopID,
      }}
    >
      <Modals.CustomerProfileModal onCloseModal={this.onHideUserProfileModal} />
    </CustomerInterfaceProvider>
  );

  renderGlobalNavbar = () => {
    const { name, logoURL } = this.props.shopBasicInfo;
    return (
      <CustomerInterfaceProvider
        value={{
          cart: this.state.cart,
          customerProfile: this.state.customerProfile,
          onChangeGlobalNavItem: this.onChangeGlobalNavItem,
          onChangeCustomerProfile: this.onChangeCustomerProfile,
          onHideUserProfileModal: this.onHideUserProfileModal,
          onShowUserProfileModal: this.onShowUserProfileModal,
          showUserProfileModal: this.state.showUserProfileModal,
          shopBasicInfo: this.props.shopBasicInfo,
          shopID: this.props.shopID,
          todayHours: this.state.todayHours,
        }}
      >
        <Navbars.CustomerGlobalNavbar
          activeNavbarItemId={this.state.activeNavbarItemId}
          logoURL={logoURL}
          shopName={name}
        />
      </CustomerInterfaceProvider>
    );
  };

  renderModals = () => {
    switch (this.state.activeNavbarItemId) {
      case "cart": {
        if (this.state.cart) return this.renderCustomerCart();
        return;
      }
      default:
        return null;
    }
  };

  renderShopOrderTracking = () => (
    <OrderTracking
      order_id={this.props.order_id}
      shop_basic_info={this.props.shopBasicInfo}
      shop_id={this.props.shopID}
    />
  );

  renderShopSurvey = () => (
    <ShopSurvey
      shopBasicInfo={this.props.shopBasicInfo}
      shopID={this.props.shopID}
    />
  );

  renderShopWebsite = () => (
    <ShopWebsite
      shopBasicInfo={this.props.shopBasicInfo}
      shopID={this.props.shopID}
    />
  );

  render() {
    if (this.props.showShopWebsite) return this.renderShopWebsite();
    else if (this.props.show_shop_survey) return this.renderShopSurvey();
    else if (this.props.show_shop_order_tracking)
      return this.renderShopOrderTracking();
    else {
      const { menuInfo = {} } = this.onPickDisplayMenu();
      return (
        <React.Fragment>
          {this.renderModals()}
          {this.state.showUserProfileModal && this.renderCustomerProfileModal()}

          <div className={Style.mobileBackgroundImg}>
            {this.renderGlobalNavbar()}
            <PageContentContainer
              categoriesVisibility={this.state.categoriesVisibility}
              isLoadingCustomerMenus={this.state.isLoadingCustomerMenus}
              menuInfo={menuInfo}
              onChangeOrderFulfillmentMethod={
                this.onChangeOrderFulfillmentMethod
              }
              onScrollToCategory={this.onScrollToCategory}
              order_fulfillment_method={this.state.order_fulfillment_method}
              shopBasicInfo={this.props.shopBasicInfo}
              shop_id={this.props.shopID}
              todayHours={this.state.todayHours}
            >
              {this.renderCustomerMenus()}
            </PageContentContainer>
          </div>
        </React.Fragment>
      );
    }
  }
}

Main.propTypes = {
  customerProfile: PropTypes.object,
  shopBasicInfo: PropTypes.object,
  shopID: PropTypes.string,
  showCateringMenu: PropTypes.bool,
  showShopWebsite: PropTypes.bool,
  show_shop_survey: PropTypes.bool,
};

Main.defaultProps = {
  shopBasicInfo: {},
  showCateringMenu: false,
  showShopWebsite: false,
  show_shop_survey: false,
};

export default Main;
