// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Components
import { CreditCard } from "components";

// Fields
// import { ExpansionPanel } from "fields";

class StoredPaymentMethods extends React.Component {
  renderMethod = (card = {}, paymentMethodID) => {
    const { fingerprint } = card;
    const { selectedPaymentMethodFingerprint } = this.props;
    return (
      <CreditCard
        card={card}
        isCardEditable={this.props.isCardEditable}
        isCardSelected={fingerprint === selectedPaymentMethodFingerprint}
        onRemoveCard={() => this.props.onRemovePaymentMethod(paymentMethodID)}
        onSelect={this.props.onSelect}
      />
    );
  };

  renderPaymentMethods = () => {
    const { paymentMethods = {} } = this.props;
    const fingerprints = Object.keys(paymentMethods);
    if (fingerprints.length === 0) return <div>No cards found.</div>;
    return Object.keys(paymentMethods).map(fingerprint => {
      const { card, id } = paymentMethods[fingerprint];
      return <div key={fingerprint}>{this.renderMethod(card, id)}</div>;
    });
  };

  render() {
    return (
      <div>
        <div>
          {this.props.isLoadingPaymentMethods
            ? "Finding your past payment methods..."
            : this.renderPaymentMethods()}
        </div>
        <br />
      </div>
    );
  }
}

StoredPaymentMethods.propTypes = {
  isCardEditable: PropTypes.bool,
  isLoadingPaymentMethods: PropTypes.bool,
  onRemovePaymentMethod: PropTypes.func,
  onSelect: PropTypes.func,
  paymentMethods: PropTypes.object,
  selectedPaymentMethodFingerprint: PropTypes.string
};

StoredPaymentMethods.defaultProps = {
  isCardEditable: false,
  isLoadingPaymentMethods: false,
  onRemovePaymentMethod: () => {},
  paymentMethods: {},
  selectedPaymentMethodFingerprint: ""
};

export default StoredPaymentMethods;
