// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Icons
import { ArrowIcon, CloseIcon } from "assets/Icons";

class ResponsiveModalHeader extends React.Component {
  render() {
    const { classNames = {}, headerProps = {} } = this.props;
    const {
      customHeader,
      header = "",
      showHeader = true,
      show_back_button = false,
      show_close_button = true,
    } = headerProps;
    if (!showHeader) return null;
    else if (customHeader)
      return (
        <header
          className={cx(Style.headerContainer, classNames.headerContainer)}
        >
          {customHeader}
        </header>
      );
    return (
      <header className={cx(Style.headerContainer, classNames.headerContainer)}>
        <div className={Style.header_content}>
          {show_back_button && (
            <Button
              className={Style.back_button}
              hasShadow
              name="close modal button"
              onClick={this.props.onCloseModal}
            >
              <ArrowIcon className={Style.arrow_icon} />
            </Button>
          )}
          {header && <h1 className={Style.header}>{header}</h1>}
          <Button
            className={cx(
              Style.close_button,
              !show_close_button && Style.invisible,
              !header & !show_back_button && Style.no_margin_left
            )}
            hasShadow
            name="close modal button"
            onClick={this.props.onCloseModal}
          >
            <CloseIcon className={Style.cross_icon} />
          </Button>
        </div>
      </header>
    );
  }
}

ResponsiveModalHeader.propTypes = {
  classNames: PropTypes.shape({
    headerContainer: PropTypes.string,
  }),
  headerProps: PropTypes.shape({
    customHeader: PropTypes.any,
    header: PropTypes.string,
    showHeader: PropTypes.bool,
    show_back_button: PropTypes.bool,
    show_close_button: PropTypes.bool,
  }),
  onCloseModal: PropTypes.func.isRequired,
};

export default ResponsiveModalHeader;
