// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Input } from "fields";

// Components
import { CheckBoxGroup } from "components";

class GetStartedForm extends React.Component {
  onInputChange = ({ fieldId, fieldValue }) =>
    this.props.onChangeForm({ fieldId, fieldValue });

  renderCheckBoxGroup = ({ fieldId, field, selectedOptionIds = [] }) => {
    const { label, options } = field;
    return (
      <CheckBoxGroup
        heading={label}
        onClick={(optionId) =>
          this.onInputChange({
            fieldId,
            fieldValue: !selectedOptionIds.includes(optionId)
              ? selectedOptionIds.concat(optionId)
              : selectedOptionIds.filter((id) => id !== optionId),
          })
        }
        optionClassName={Style.checkBoxOption}
        options={options}
        selectedOptionIds={selectedOptionIds}
      />
    );
  };

  renderInputFields = () => {
    const { fields = {}, fieldValues = {} } = this.props;
    return Object.keys(fields).map((fieldId) => {
      const field = fields[fieldId];
      const { type } = field;
      if (type === "checkbox") {
        return (
          <div className={Style.inputField} key={fieldId}>
            {this.renderCheckBoxGroup({
              fieldId,
              field,
              selectedOptionIds: fieldValues[fieldId],
            })}
          </div>
        );
      }
      return (
        <div className={Style.inputField} key={fieldId}>
          <Input.TextInput
            {...fields[fieldId]}
            name={fieldId}
            onChange={(value) =>
              this.onInputChange({ fieldId, fieldValue: value })
            }
            value={fieldValues[fieldId] || ""}
          />
        </div>
      );
    });
  };

  render() {
    return <div>{this.renderInputFields()}</div>;
  }
}

GetStartedForm.propTypes = {
  fields: PropTypes.object.isRequired,
  fieldValues: PropTypes.object,
  onChangeForm: PropTypes.func.isRequired,
};

GetStartedForm.defaultProps = {
  fieldValues: {},
};

export default GetStartedForm;
