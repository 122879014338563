// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { ResponsiveModal } from "fields";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

// Lib
import { US_STATES } from "lib/_constants";

class USStateSeletor extends React.Component {
  state = { show_us_states: false };

  on_hide_us_states = () => this.setState({ show_us_states: false });

  on_show_us_states = () => this.setState({ show_us_states: true });

  on_select_state = (abbreviation) => {
    const { on_select_state = () => {} } = this.props;
    on_select_state(abbreviation);
    this.on_hide_us_states();
  };

  on_validate_selected_state = () => {
    const { required, selected_state = "" } = this.props;
    const state_name = US_STATES[selected_state];
    if (required && !state_name) return false;
    return true;
  };

  render_input_field = () => {
    const { placeholder = "State", selected_state = "" } = this.props;
    const state_name = US_STATES[selected_state];
    return (
      <div className={!state_name ? Style.reduce_opacity : ""}>
        {state_name ? selected_state : placeholder}
      </div>
    );
  };

  render_us_states = () =>
    Object.keys(US_STATES).map((abbreviation) => (
      <div
        className={Style.us_state}
        key={abbreviation}
        onClick={() => this.on_select_state(abbreviation)}
      >
        {US_STATES[abbreviation]}
      </div>
    ));

  render() {
    const { label, read_only } = this.props;
    const is_selected_state_valid = this.on_validate_selected_state();
    return (
      <React.Fragment>
        {this.state.show_us_states && !read_only && (
          <ResponsiveModal
            contentLabel="Select a US state"
            headerProps={{
              header: "Select US State",
              showHeader: true,
            }}
            onCloseModal={this.on_hide_us_states}
          >
            <div>{this.render_us_states()}</div>
          </ResponsiveModal>
        )}
        <div onClick={this.on_show_us_states}>
          {label && <div className={Style.label}>{label}</div>}
          <div
            className={cx(
              Style.input_field_container,
              read_only && Style.read_only,
              !is_selected_state_valid && Style.required
            )}
          >
            {this.render_input_field()}
            {!read_only && (
              <div className={Style.arrow_icon}>
                <ChevronArrowIcon />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

USStateSeletor.propTypes = {
  label: PropTypes.string,
  on_select_state: PropTypes.func,
  placeholder: PropTypes.string,
  read_only: PropTypes.bool,
  required: PropTypes.bool,
  selected_state: PropTypes.string,
};

USStateSeletor.defaultProps = {
  on_select_state: () => {},
  placeholder: "State",
  read_only: false,
  required: false,
  selected_state: "",
};

export default USStateSeletor;
