// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Image
import { CheckIcon } from "assets/Icons";

class LandingPageIntroduction extends React.Component {
  state = {};

  renderButtons = () => (
    <div className={Style.buttons}>
      <Button
        className={Style.startFreeStoreBtn}
        name="Create Free Store"
        onClick={() => this.props.history.push("/get-started")}
      >
        Create Your Store
      </Button>
      <Button
        className={Style.learnMoreBtn}
        name="Why Use Skipli"
        onClick={() => this.props.context.onScrollTo("whySkipli")}
      >
        Why use Skipli
      </Button>
    </div>
  );

  renderPoints = () => (
    <div className={Style.pointsContainer}>
      <div className={Style.point}>
        <div className={Style.circleCheck}>
          <CheckIcon />
        </div>
        Sell Your Food Online
      </div>
      <div className={Style.point}>
        <div className={Style.circleCheck}>
          <CheckIcon />
        </div>
        Keep 100% of Your Sales
      </div>
      <div className={Style.point}>
        <div className={Style.circleCheck}>
          <CheckIcon />
        </div>
        No hardware needed
      </div>
    </div>
  );

  renderIntroduction = () => (
    <div className={Style.introductionBox}>
      <h1 className={Style.mainMessage}>
        Create your own
        <br />
        virtual restaurant
      </h1>
      <p className={Style.description}>
        Whether you cook from home or own a restaurant, we help you get more
        take out, pick up, catering
        <br />
        and delivery orders — all in one place
      </p>
      {this.renderPoints()}
      {this.renderButtons()}
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <div className={Style.introductionBackground}>
          <div className={CommonStyle.container}>
            <div className={Style.contentContainer}>
              {this.renderIntroduction()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

LandingPageIntroduction.propTypes = {
  context: PropTypes.shape({
    onScrollTo: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(LandingPageIntroduction)
);
