// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Lib
import { TIME_RANGE_OPTIONS } from "lib/_constants";
import { BUTI } from "lib/services";

class TimeRangePicker extends React.Component {
  componentDidMount = () => this.onGetTimeRange(this.props.selectedTimeRangeID);

  componentDidUpdate = (prevProps) => {
    const { selectedTimeRangeID } = this.props;
    if (prevProps.selectedTimeRangeID !== selectedTimeRangeID) {
      this.onGetTimeRange(selectedTimeRangeID);
    }
  };

  onGetTimeRange = async (selectedTimeRangeID) => {
    const { GetTimeRange } = BUTI.GetRequests;
    const { endAt = "", startAt = "" } = await GetTimeRange({
      timeRange: selectedTimeRangeID,
      timeZone: this.props.timeZone,
    });
    this.props.onChange({ endAt, selectedTimeRangeID, startAt });
  };

  renderOptions = () => {
    return Object.entries(TIME_RANGE_OPTIONS).map((entry) => {
      const { label } = entry[1];
      return (
        <button
          className={cx(
            Style.option,
            entry[0] === this.props.selectedTimeRangeID && Style.selected
          )}
          key={entry[0]}
          onClick={() => this.onGetTimeRange(entry[0])}
        >
          {label}
        </button>
      );
    });
  };

  render() {
    return <div className={Style.container}>{this.renderOptions()}</div>;
  }
}

TimeRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedTimeRangeID: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default TimeRangePicker;
