// BUTI DINERS, INC. All right Reserved ©

import React from "react";
// import { del } from "object-path-immutable";

// Style
import Style from "./style.module.scss";

// Icons
import { MoreIcon } from "assets/Icons";

// Lib
import { FOOD_PREFERENCES } from "./FoodPreferences";
import REGEX from "./RegEx";

const COUPON_INFO_SECTION = [
  {
    id: "couponInfo",
    name: "Coupon Information",
    fields: [
      {
        fieldKind: "text",
        id: "name",
        label: "Name",
        required: true,
      },
      {
        fieldKind: "text",
        id: "description",
        label: "Description",
        required: false,
      },
    ],
  },
];

const CUSTOMER_TIPPING_OPTIONS = ({
  minSubtotalForDeliveryRequiredTip = 40,
  orderDeliveryTypeID = "pickUp",
  subTotal = 0,
}) => {
  const options = {
    option1: { label: "30%", multiplier: 0.3 },
    option2: { label: "20%", multiplier: 0.2 },
    option3: { label: "15%", multiplier: 0.15 },
    option4: { label: "10%", multiplier: 0.1 },
    other: { label: <MoreIcon className={Style.customTipOptionIcon} /> },
  };
  // if (
  //   orderDeliveryTypeID === "deliver" &&
  //   parseFloat(subTotal) >= minSubtotalForDeliveryRequiredTip
  // ) {
  //   return del(options, "other");
  // }
  return options;
  // return parseFloat(subTotal) < 50 ? options : del(options, "option5");
  // if (parseFloat(subTotal) > 50)
  //   return { ...options, option5: { label: "10%", multiplier: 0.1 } };
  // return { ...options, other: { label: "Other" } };
};

const AVERAGE_PREPARATION_TIMES = {
  timeRange1: {
    label: "10 - 20 mins",
  },
  timeRange2: {
    label: "20 - 40 mins",
  },
  timeRange3: {
    label: "At least 40 mins",
  },
};

const PICKUP_ORDER_PREPARATION_TIMES = {
  timeRange1: {
    label: "10 - 20 minutes",
  },
  timeRange2: {
    label: "20 - 40 minutes",
  },
  timeRange3: {
    label: "At least 40 minutes",
  },
};

const OPPORTUNITIES = [
  {
    id: "softwareEngineer",
    iconURL:
      "https://firebasestorage.googleapis.com/v0/b/buti-sheryl-angel.appspot.com/o/BUTI%2FSoftware%20Gray.svg?alt=media&token=2fe6a8ca-6649-435e-a6a0-d84f52067642",
    name: "Software Engineer",
    description:
      "Be the next rockstar developer and build amazing products used by billions",
    details: [
      {
        name: "Tech Stack",
        description: "React, Express.js, ElasticSearch and growing",
      },
      {
        name: "Responsibility",
        description: "You will involve in every engineering aspect",
      },
      {
        name: "Expectation",
        description: "Build something you want your family to use",
      },
    ],
  },
  {
    id: "influencer",
    iconURL:
      "https://firebasestorage.googleapis.com/v0/b/buti-sheryl-angel.appspot.com/o/BUTI%2FInfluencer%20Gray.svg?alt=media&token=e67bcf88-a0da-4c54-a220-fc8a494b7705",
    name: "Influencer",
    description:
      "Be the great influencer you meant to be and enlist people in our revolution",
    details: [
      {
        name: "Tools",
        description: "GTrends, Instagram, Facebook and growing",
      },
      {
        name: "Responsibility",
        description: "You will involve in every marketing aspect",
      },
      {
        name: "Expectation",
        description: "Spread the word for something you love",
      },
    ],
  },
];

// ---------------------------------------------------------------------
// Form fields for creating a new group
const NEW_GROUP_FIELDS = [
  {
    fieldKind: "text",
    id: "groupName",
    label: "Name",
    placeholder: "What to call the group",
    required: true,
  },
];

// ---------------------------------------------------------------------
// Form fields for creating new item

const GetAllergens = () => {
  const { options } = FOOD_PREFERENCES.find(({ id }) => id === "allergies");
  return options || {};
};

// ---------------------------------------------------------------------
// Form fields for creating new items

const NEW_ITEM_FIELDS = [
  {
    fieldKind: "text",
    id: "itemName",
    label: "Name",
    placeholder: "What to call the item",
    required: true,
  },
  {
    fieldKind: "radio",
    id: "itemIsOutOfStock",
    label: "Item out of stock?",
    options: {
      false: { label: "No" },
      true: { label: "Yes" },
    },
    required: true,
  },
  {
    fieldKind: "radio",
    id: "itemIsOnSale",
    label: "Item is on sale?",
    options: {
      false: { label: "No" },
      true: { label: "Yes" },
    },
  },
  {
    fieldKind: "text",
    id: "itemSaleRate",
    label: "Sale Rate",
    min: "0",
    max: "99",
    placeholder: "5",
    type: "number",
    required: false,
    unit: "%",
  },
  {
    fieldKind: "textarea",
    id: "itemDescription",
    label: "Description",
    placeholder: "Say something cool about the item",
    rows: 3,
  },
  {
    fieldKind: "text",
    id: "itemCalories",
    label: "Calories",
    placeholder: "100",
    type: "number",
  },
  {
    fieldKind: "checkboxes",
    id: "itemAllergens",
    label: "Allergens",
    options: GetAllergens(),
  },
  {
    fieldKind: "textarea",
    id: "itemNote",
    label: "Note For Customers",
    rows: 3,
  },
  {
    fieldKind: "text",
    id: "itemPrice",
    label: "Price ($)",
    placeholder: "00.00",
    type: "number",
  },
];

// ---------------------------------------------------------------------
// Form fields for creating new modifier
const NEW_MODIFIER_FIELDS = [
  {
    fieldKind: "text",
    id: "modifierName",
    label: "Name",
    placeholder: "What to call the modifier",
    required: true,
  },
  {
    fieldKind: "textarea",
    id: "modifierDescription",
    label: "Description",
    placeholder: "Anything you want the customer to know about this modifier",
    rows: 2,
  },
  {
    fieldKind: "text",
    id: "modifierPrice",
    label: "Price ($)",
    placeholder: "00.00",
    required: true,
    type: "number",
  },
];

// ---------------------------------------------------------------------
// Form fields for creating new modifier group

const NEW_MODIFIER_GROUP_FIELDS = [
  {
    fieldKind: "text",
    id: "modifierGroupName",
    label: "Name",
    placeholder: "What to call the modifier group",
    required: true,
  },
  {
    fieldKind: "text",
    id: "modifierGroupNote",
    label: "Note",
  },
  {
    fieldKind: "radio",
    id: "modifierGroupIsRequired",
    label: "Require customers to select an option?",
    options: {
      true: { label: "Yes" },
      false: { label: "No" },
    },
    required: true,
  },
  {
    fieldKind: "radio",
    id: "modifierGroupAllowMultipleChoices",
    label: "Customer can select more than one option?",
    options: {
      true: { label: "Yes" },
      false: { label: "No" },
    },
    required: true,
  },
  {
    fieldKind: "text",
    id: "modifierGroupMaxChoices",
    label: "Maximum Choices",
    type: "number",
  },
  {
    fieldKind: "text",
    id: "modifierGroupMinChoices",
    label: "Minimum Choices",
    min: 0,
    type: "number",
  },
];

// ---------------------------------------------------------------------
// Form fields for creating new debit card for diners

const NEW_PAYMENT_CARD_FIELDS = {
  email: {
    fieldKind: "text",
    placeholder: "Email",
    regEx: REGEX.EMAIL,
    type: "email",
    required: true,
  },
  // country: {
  //   fieldKind: "text",
  //   value: "United States",
  //   readOnly: true
  // },
  // line1: {
  //   fieldKind: "text",
  //   placeholder: "Address Line 1",
  //   required: true
  // },
  // line2: {
  //   fieldKind: "text",
  //   placeholder: "Address Line 2"
  // },
  // city: {
  //   fieldKind: "text",
  //   placeholder: "City",
  //   required: true
  // },
  // state: {
  //   fieldKind: "text",
  //   placeholder: "State (GA)",
  //   regEx: REGEX.US_STATES,
  //   required: true
  // },
  // postal_code: {
  //   fieldKind: "text",
  //   placeholder: "Zip",
  //   regEx: REGEX.POSTAL_CODE,
  //   required: true
  // }
};

const SALES_REPORT_FILTER_OPTIONS = {
  pastWeek: { label: "1w" },
  pastFourWeeks: { label: "4w" },
  pastYear: { label: "1y" },
  monthToDate: { label: "Mtd" },
  quarterToDate: { label: "Qtd" },
  yearToDate: { label: "Ytd" },
  all: { label: "All" },
};

const SERVICES_PRICING = {
  free: {
    label: "Free",
    price: "0",
    description:
      "Everything you need to start selling online and instantly increase your revenue.",
    features: [
      "Sell unlimited products for takeout, pickup, catering, and delivery",
      "Instagram and Facebook integration",
      "Accept payments online. Funds are transferred daily",
      "New order alerts via phone, tablet or text message",
      "Free basic website",
    ],
  },
  professional: {
    label: "Professional",
    price: "99",
    description:
      "Elevate your brand with a professionally designed website and custom domain.",
    features: [
      "All Free features, plus:",
      "Beautiful, mobile responsive website with your personal brand",
      "Our SEO makes your website appear in top 3 of search result",
      "No Skipli branding and ads",
      "Free custom domain",
    ],
  },
  premium: {
    label: "Premium",
    price: "149",
    description:
      "Increase your business by 10x with all our features plus social media management.",
    features: [
      "All Professional features, plus:",
      "Flyer Design for events and specials",
      "Posts, tweets & emails",
      "Up to 3 Instagram Posts & 3 Facebook Posts / Week",
      "Respond to messages, brand mentions, and comments across your social networks",
    ],
  },
};

const SHOP_INFO_SECTIONS = [
  {
    id: "basicInfo",
    name: "Basic Information",
    fields: [
      {
        fieldKind: "text",
        id: "address",
        label: "Address",
        placeholder: "Address of your shop",
        required: true,
      },
      {
        fieldKind: "text",
        id: "name",
        label: "Shop Name",
        placeholder: "Name of your shop",
        required: true,
      },
      {
        fieldKind: "text",
        id: "salesTax",
        label: "Sales Tax",
        placeholder: "Enter the sales tax of your shop",
        required: true,
        unit: "tax/100",
        type: "number",
      },
      {
        fieldKind: "text",
        id: "timeZone",
        label: "Time Zone",
        readOnly: true,
      },
    ],
  },
  {
    id: "mobilePayment",
    name: "Customer Payment",
    fields: [
      {
        fieldKind: "switch",
        id: "enableMobilePay",
        label: "Enable Mobile Pay",
      },
    ],
  },
];

// ---------------------------------------------------------------------
// Form fields for displaying the shop's bank account details

const SHOP_BANK_ACCOUNT_FIELDS = {
  legalName: {
    label: "Legal Business Name",
    readOnly: true,
  },
  employerID: {
    label: "Employer ID (EIN)",
    readOnly: true,
  },
  accountNumber: {
    label: "Account Number",
    readOnly: true,
  },
  routingNumber: {
    label: "Routing Number",
    type: "number",
    readOnly: true,
  },
};

export {
  AVERAGE_PREPARATION_TIMES,
  COUPON_INFO_SECTION,
  CUSTOMER_TIPPING_OPTIONS,
  NEW_GROUP_FIELDS,
  NEW_ITEM_FIELDS,
  NEW_MODIFIER_FIELDS,
  NEW_MODIFIER_GROUP_FIELDS,
  NEW_PAYMENT_CARD_FIELDS,
  OPPORTUNITIES,
  PICKUP_ORDER_PREPARATION_TIMES,
  SALES_REPORT_FILTER_OPTIONS,
  SERVICES_PRICING,
  SHOP_INFO_SECTIONS,
  SHOP_BANK_ACCOUNT_FIELDS,
};
