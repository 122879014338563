// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { Link } from "fields";

// Assets
import { DeliveryTruckIcon } from "assets/Icons";

class Partners extends React.Component {
  onGetPartnerIcon = (partnerId) => {
    const { WELL_KNOWN_ORDERING_CHANNELS = {} } = this.props;
    const { logo } = WELL_KNOWN_ORDERING_CHANNELS[partnerId] || {};
    return logo;
  };

  renderPartners = (partners = {}) =>
    Object.keys(partners).map((partnerId) => {
      const { name, url } = partners[partnerId];
      const partnerLogo = this.onGetPartnerIcon(partnerId);
      if (!url) return null;
      else if (!partnerLogo)
        return (
          name && (
            <React.Fragment key={partnerId}>
              <Link
                className={cx(
                  Style.partnerUrl,
                  !partnerLogo ? Style.unknownPartner : ""
                )}
                href={url}
              >
                <div className={Style.genericLogo}>
                  <DeliveryTruckIcon />
                </div>
                <div className={Style.unknownPartnerName}>{name}</div>
              </Link>
            </React.Fragment>
          )
        );
      return (
        <React.Fragment key={partnerId}>
          <Link className={cx(Style.partnerUrl, Style[partnerId])} href={url}>
            <div className={Style.partnerLogo}>{partnerLogo}</div>
          </Link>
        </React.Fragment>
      );
    });

  render() {
    const { partners = {} } = this.props;
    return (
      <div className={Style.deliveryPartners}>
        {this.renderPartners(partners)}
      </div>
    );
  }
}

Partners.propTypes = {
  partners: PropTypes.shape({
    skipli: PropTypes.shape({ url: PropTypes.string }),
    doordash: PropTypes.shape({ url: PropTypes.string }),
    postmates: PropTypes.shape({ url: PropTypes.string }),
    grubhub: PropTypes.shape({ url: PropTypes.string }),
    ubereats: PropTypes.shape({ url: PropTypes.string }),
  }),
  WELL_KNOWN_ORDERING_CHANNELS: PropTypes.object.isRequired,
};

export default Partners;
