// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import DateTimePicker from "react-datetime-picker";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Lib
import { DEFAULT_TIMEZONE } from "lib/_constants";

const DATETIME_PICKER_FORMAT = {
  dateFormat: "YYYY-MM-DD",
  timeFormat: "HH:mm",
};

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

class DateTimePickerWrapper extends React.Component {
  state = {};

  onChange = (dateTime) => {
    const value = this.onConvertDateTimeToTimeZone(dateTime);
    this.setState({ value }, () => this.props.onChange(value));
  };

  componentDidMount = () => {
    const {
      maxDateTime = "",
      minDateTime = "",
      startingDateTime = "",
    } = this.props;
    if (maxDateTime)
      this.setState({
        localizedMaxDateTime: this.onConvertDateTimeToTimeZone(maxDateTime),
      });
    if (minDateTime)
      this.setState({
        localizedMinDateTime: this.onConvertDateTimeToTimeZone(minDateTime),
      });
    if (startingDateTime) {
      const value = this.onConvertDateTimeToTimeZone(startingDateTime);
      this.setState({ value }, () => this.props.onChange(value));
    }
  };

  componentDidUpdate = (prevProps) => {
    const { maxDateTime, minDateTime, startingDateTime } = this.props;
    if (prevProps.maxDateTime !== maxDateTime)
      this.setState({
        localizedMaxDateTime: this.onConvertDateTimeToTimeZone(maxDateTime),
      });
    if (prevProps.minDateTime !== minDateTime)
      this.setState({
        localizedMinDateTime: this.onConvertDateTimeToTimeZone(minDateTime),
      });
    if (prevProps.startingDateTime !== startingDateTime) {
      const value = this.onConvertDateTimeToTimeZone(startingDateTime);
      this.setState({ value }, () => this.props.onChange(value));
    }
  };

  onConvertDateTimeToTimeZone = (value = "") => {
    const { dateFormat, timeFormat } = DATETIME_PICKER_FORMAT;
    return dayjs(value)
      .tz(this.props.timeZone || DEFAULT_TIMEZONE)
      .format(`${dateFormat}T${timeFormat}`);
  };

  render() {
    const {
      localizedMaxDateTime = "",
      localizedMinDateTime = "",
      value = "",
    } = this.state;
    const { disableCalendar, disableClock } = this.props;
    if (!this.props.timeZone) return null;
    return (
      <DateTimePicker
        className={cx(
          Style.datePicker,
          disableCalendar && Style.hideDateInput,
          disableClock && Style.hideTimeInput
        )}
        calendarClassName={Style.calendar}
        clockClassName={Style.clock}
        disableClock={this.props.disableClock}
        disableCalendar={disableCalendar}
        maxDate={localizedMaxDateTime ? new Date(localizedMaxDateTime) : null}
        minDate={localizedMinDateTime ? new Date(localizedMinDateTime) : null}
        name={this.props.name}
        onChange={this.onChange}
        value={value ? new Date(value) : null}
      />
    );
  }
}

DateTimePickerWrapper.propTypes = {
  disableCalendar: PropTypes.bool,
  disableClock: PropTypes.bool,
  maxDateTime: PropTypes.string, // UTC date time
  minDateTime: PropTypes.string, // UTC date time
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  timeZone: PropTypes.string.isRequired, // i.e "America/New_York"
  startingDateTime: PropTypes.string, // UTC datetime
};

DateTimePickerWrapper.defaultProps = {
  disableCalendar: false,
  disableClock: false,
  onChange: () => {},
  value: "",
};

export default DateTimePickerWrapper;
