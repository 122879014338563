// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const Link = (props) => {
  const { target } = props;
  const rel = target === "_blank" ? "noopener noreferrer" : props.rel;
  return (
    <a
      className={cx(props.className, Style.link)}
      href={props.href}
      rel={rel}
      target={target}
      style={{ cursor: "pointer" }}
    >
      {props.children}
    </a>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.oneOf(["_blank", "_parent", "_self", "_top"]),
};

Link.defaultProps = {
  rel: "external",
  target: "_blank",
};

export default Link;
