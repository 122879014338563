// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, StripeBrand } from "fields";

// Icons
import { PlusIcon, ShieldIcon } from "assets/Icons";

// Lib
import { Math } from "lib/functions";

const SubmitButtons = (props) => {
  const { totalAmount } = props;

  const renderAddPaymentMethodButton = () => (
    <div>
      <Button
        className={Style.addCardButton}
        onClick={props.onAddPaymentMethod}
        name="Add card button"
        status="active"
      >
        <div className={Style.payNow}>
          <PlusIcon className={Style.plusIcon} />
          Add New Card
        </div>
      </Button>
    </div>
  );

  const renderSubmitButton = () => {
    const button = (
      <React.Fragment>
        <Button
          className={Style.useStoredMethodButton}
          hasShadow
          onClick={props.onSubmit}
          name="Submit Order Button"
          status={props.useStoredPaymentButtonStatus}
        >
          <div className={Style.payNow}>
            <ShieldIcon className={Style.shieldIcon} />
            Pay Now
          </div>
          <div>${Math._roundNumber(totalAmount)}</div>
        </Button>
      </React.Fragment>
    );
    if (props.showUseStoredPaymentMethodButton) return button;
    return null;
  };

  if (!props.stripeAccountID) {
    return (
      <p className={Style.message}>
        Checkout is not available at the moment since the merchant is yet
        connected to Skipli Payout.
      </p>
    );
  }
  return (
    <div className={Style.dialogButtonsContainer}>
      {renderSubmitButton()}
      {renderAddPaymentMethodButton()}
      <StripeBrand />
    </div>
  );
};

SubmitButtons.propTypes = {
  onAddPaymentMethod: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showUseStoredPaymentMethodButton: PropTypes.bool,
  stripeAccountID: PropTypes.string,
  totalAmount: PropTypes.any.isRequired,
  useStoredPaymentButtonStatus: PropTypes.string,
};

SubmitButtons.defaultProps = {
  useStoredPaymentButtonStatus: "inactive",
  showUseStoredPaymentMethodButton: false,
  stripeAccountID: "",
};

export default SubmitButtons;
