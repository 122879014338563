// Skipli, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

const _createTerms = ({ history }) => [
  {
    title: "General",
    content: (
      <React.Fragment>
        The Skipli Checkout service ("Skipli Checkout") is technology that makes
        it easier for merchants on the Internet (“Merchants”) to collect payment
        from individuals like you. Skipli Checkout also makes it easy for you to
        store a credit card or debit card (“Payment Credentials”) with Skipli
        for use across the websites of Merchants who’ve chosen to enable it.
      </React.Fragment>
    ),
  },
  {
    title: "Skipli Role",
    content: (
      <React.Fragment>
        Skipli Checkout is a way of storing your Payment Credentials, but it
        doesn’t change anything else about your relationship with the Merchant
        you’re paying or your bank or credit card company. You are ultimately
        responsible for the purchases you make using Skipli Checkout. Also, the
        Merchant is the one responsible for providing you the goods or services
        that you purchase using Skipli Checkout, not Skipli. Skipli will use
        reasonable efforts to keep your Payment Credentials secure.
      </React.Fragment>
    ),
  },
  {
    title: "Using Skipli Checkout",
    content: (
      <React.Fragment>
        When you check out on the website of a Merchant that has Skipli Checkout
        enabled, we will ask you if you’d like us to remember you. When you
        allow us to remember you, Skipli will store certain identifying
        information, such as a password, your email address, or your mobile
        phone number ("Skipli Credentials"), and your Payment Credentials. The
        advantage of remembering you is that it will make your checkout quicker
        and easier if you come back to the same website, or to any of the other
        websites that use Skipli (a “Checkout Enabled Site”) – this can be
        especially handy when you’re on a mobile device or don’t have your
        credit card in front of you.
        <br />
        <br />
        If you elect to allow us to remember you, Skipli will use cookies to
        link your web browser to your Skipli Credentials and recognize when you
        return to a Checkout Enabled Site. If you come to a Checkout Enabled
        Site and we don’t recognize you (for example, because you’ve cleared
        your cookies, logged out, or you’re using a different device), we will
        provide a way for you to identify yourself and login via your Skipli
        Credentials (for example, by sending you a verification code via SMS
        text message). While you are logged in, Skipli will give you the ability
        to make purchases using your stored Payment Credentials. Skipli may also
        allow you to make a purchase with your Payment Credentials by sending a
        message directly from the email address or phone number stored as your
        Skipli Credentials (for example, to authorize a purchase via SMS).
        <br />​<br /> If you send us text messages, or have us send you one,
        don’t forget that your carrier might charge you for that.
      </React.Fragment>
    ),
  },
  {
    title: "Purchase Currency & Payments",
    content: (
      <React.Fragment>
        Purchases made on Skipli online ordering or Skipli pay are based on USD
        (US dollars). Skipli service is currently only available to US
        businesses so other currencies are not accepted and must be converted to
        USD before transactions.
        <br />
        <br />
        Skipli accepts most major credit/debit card brands:{" "}
        <strong>
          Visa, Mastercard, American Express, Diners Club, Discover, JCB and
          other
        </strong>
        . Skipli also accepts payments via{" "}
        <strong>Apple Pay and Google Pay</strong> if those services are made
        available on the customer's device and have sufficient fund.
      </React.Fragment>
    ),
  },
  {
    title: "Fulfillment & Refund Policy",
    content: (
      <React.Fragment>
        Skipli helps Merchants easily accept pre-paid online pickup, takeout,
        delivery, and catering orders from their customers and have funds
        transferred directly into their bank accounts. Merchants directly manage
        the presentation of their items on their Skipli store and the delivery
        of purchased items to the customer via pickup, takeout, curbside pickup,
        or in-house delivery. If Merchant has another form of items delivery
        beside those listed, it is the responsibility of the Merchant to ensure
        successful delivery to the customers and that the sold items remain
        according to the agreed terms between Skipli and Merchant.
        <br />
        <br />
        Skipli will issue refund to the customers the amount according to the
        request by the Merchant. Refund request can be made by the Merchant via
        the refund portal available to the Merchant in the Skipli Merchant
        Dashboard. Once a refund request is made by the Merchant, Skipli
        Customer Representative team will start reviewing the request and issue
        refund within one business day. In the event that Skipli needs more
        information about a refund request, Skipli will reach out to the
        owner/designted manager of the Merchant to confirm the request.
      </React.Fragment>
    ),
  },
  {
    title: "Making Changes",
    content: (
      <React.Fragment>
        ​If you want to delete your Payment Credentials, stop storing
        information using Skipli Checkout, or change your settings, please
        contact us at info@skiplinow.com
      </React.Fragment>
    ),
  },
  {
    title: "Representations and Warranties",
    content: (
      <React.Fragment>
        ​ ​By using Skipli Checkout you represent and warrant that you are at
        least 18 years of age and that you will not use Skipli Checkout for any
        fraudulent, unlawful or abusive purpose.
      </React.Fragment>
    ),
  },
  {
    title: "Disclaimers",
    content: (
      <React.Fragment>
        ​ Skipli Checkout, including all content, software, functions,
        materials, and information made available on, provided in connection
        with or accessible through Skipli Checkout, are provided “as is.” To the
        fullest extent permissible by law, Skipli, its affiliates, and their
        agents, merchants or independent contractors (the “Disclaiming
        Entities”), make no representation or warranty of any kind whatsoever
        for the services or the content, materials, information and functions
        made accessible by Skipli Checkout, or for any breach of security
        associated with the transmission of sensitive information through Skipli
        Checkout. Each Disclaiming Entity disclaims without limitation, any
        warranty of any kind with respect to the services, noninfringement,
        merchantability, or fitness for a particular purpose. The Disclaiming
        Entities do not warrant that the functions contained in the services
        will be uninterrupted or error free. The Disclaiming Entities shall not
        be responsible for any service interruptions, including, but not limited
        to, system failures or other interruptions that may affect the receipt,
        processing, acceptance, completion or settlement of payment
        transactions. The Disclaiming Entities are not responsible for the
        accuracy of any payment instrument, offer, or reward program item
        information, including, without limitation, whether such information is
        accurate.
      </React.Fragment>
    ),
  },
  {
    title: "Limitations of Liability; Force Majeure",
    content: (
      <React.Fragment>
        ​In no event shall any Disclaiming Entity be responsible or liable to
        you or any third party under any circumstances for any indirect,
        consequential, special, punitive or exemplary, damages or losses,
        including but not limited to damages for loss of profits, goodwill, use,
        data, or other intangible losses which may be incurred in connection
        with any Disclaiming Entity or the services, or any goods, services, or
        information purchased, received, sold, or paid for by way of the
        services, regardless of the type of claim or the nature of the cause of
        action, even if the Disclaiming Entity has been advised of the
        possibility of such damage or loss. In no event shall the Disclaiming
        Entities’ total cumulative liability arising from or relating to these
        Terms of Service exceed $10 US dollars.
        <br />
        <br />
        Each party acknowledges that the other party has entered into these
        Terms of Service relying on the limitations of liability stated herein
        and that those limitations are an essential basis of the bargain between
        the parties. In addition to and without limiting any of the foregoing,
        no Disclaiming Entity shall have any liability for any failure or delay
        resulting from any condition beyond the reasonable control of such
        party, including but not limited to governmental action or acts of
        terrorism, earthquake, fire, flood or other acts of God, labor
        conditions, power failures and Internet disturbances.
      </React.Fragment>
    ),
  },
  {
    title: "Governing Law",
    content: (
      <React.Fragment>
        These Terms of Service will be governed by the laws of Georgia, except
        for Georgia’s choice of law rules, and by applicable federal United
        States laws. Each party agrees to submit to personal and exclusive
        jurisdiction of the courts located in Atlanta, Georgia.
      </React.Fragment>
    ),
  },
  {
    title: "Modification of Terms of Service; Notices",
    content: (
      <React.Fragment>
        We have the right to change or add to these Terms of Service at any
        time, solely with prospective effect, and to change, delete,
        discontinue, or impose conditions on use of Skipli Checkout by posting
        such changes on our website or any other website we maintain or own. We
        may provide you with notice via email, postings on our website, or
        through other reasonable means. If you are an existing Skipli Checkout
        user, the changes will come into effect 10 days after we post the
        changes to our website, and your use of Skipli Checkout more than 10
        days after we publish any such changes on our website, constitutes your
        acceptance of the modified Terms of Service. In the event that you do
        not agree with any such modification, your sole and exclusive remedy is
        to terminate your use of Skipli Checkout. You can access a copy of the
        current terms of these Terms of Service on our website at any time. You
        can find out when these Terms of Service were last changed by checking
        the “Last updated” date at the top of these Terms of Service.
      </React.Fragment>
    ),
  },
  {
    title: "Assignment",
    content: (
      <React.Fragment>
        You may not assign these Terms of Service or any rights or obligations
        hereunder, by operation of law or otherwise, without our prior written
        approval and any such attempted assignment shall be void. We reserve the
        right to freely assign these Terms of Service and the rights and
        obligations hereunder, to any third party without notice or consent.
        Subject to the foregoing, these Terms of Service shall be binding upon
        and inure to the benefit of the parties hereto, their successors and
        permitted assigns.
      </React.Fragment>
    ),
  },
  {
    title: "Privacy",
    content: (
      <React.Fragment>
        By using Skipli Checkout, you agree to the{" "}
        <button
          className={Style.privacyBtn}
          onClick={() => {
            history && history.push("/privacy");
          }}
        >
          Skipli Privacy Policy
        </button>
        , which is incorporated into and forms part of these Terms of Service.
        You should be aware that your data may be transferred, processed and
        stored outside of your country (including, if you are located in the
        European Union, outside of the European Union), and that your data may
        be subject to disclosure as required by applicable law. Skipli has
        implemented reCAPTCHA on Skipli Checkout. Use of reCAPTCHA is subject to
        the Google Privacy Policy and Terms of Service.
      </React.Fragment>
    ),
  },
  {
    title: "Survival",
    content: (
      <React.Fragment>
        Upon termination of your use of Skipli Checkout or termination of these
        Terms of Service for any reason, in addition to this section, the
        following sections shall survive termination: Sections 5 through 14.
      </React.Fragment>
    ),
  },
  {
    title: "Miscellaneous",
    content: (
      <React.Fragment>
        Skipli’s failure to exercise or enforce any right or provision of the
        Terms of Service will not be considered a waiver of that right or
        provision. If any provision of these Terms of Service shall be adjudged
        by any court of competent jurisdiction to be unenforceable or invalid,
        that provision shall be limited or eliminated to the minimum extent
        necessary so that these Terms of Service shall otherwise remain in full
        force and effect and remain enforceable between the parties. Headings
        are for reference purposes only and in no way define, limit, construe or
        describe the scope or extent of such section. these Terms of Service,
        including Skipli's policies governing Skipli Checkout referenced herein,
        constitutes the entire agreement between you and Skipli with respect to
        the use of Skipli Checkout. These Terms of Service are not intended and
        shall not be construed to create any rights or remedies in any parties
        other than you and Skipli, and no other person will have the ability to
        assert any rights as a third party beneficiary under these Terms of
        Service. These Terms of Service do not limit any rights that Skipli may
        have under trade secret, copyright, patent or other laws.
      </React.Fragment>
    ),
  },
];

export { _createTerms };
