// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const PayMethodButton = ({ icon, isMethodSelected, label, onSelectMethod }) => {
  return (
    <button
      onClick={onSelectMethod}
      className={cx(
        Style.payMethodContainer,
        isMethodSelected && Style.selected
      )}
    >
      {icon && (
        <div className={Style.iconContainer}>
          <div className={Style.methodIcon}>{icon}</div>
        </div>
      )}
      <p>{label}</p>
    </button>
  );
};

PayMethodButton.propTypes = {
  icon: PropTypes.any,
  isMethodSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onSelectMethod: PropTypes.func.isRequired
};

PayMethodButton.defaultProps = {
  isMethodSelected: false
};

export default PayMethodButton;
