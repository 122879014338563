// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Components
import { SplitOverlayGroup } from "components";

// Image
import SkipliDispatch from "assets/Images/skipliDispatch.jpg";
import TrustedCouriers from "assets/Images/trustedCouriers.jpg";

const BENEFITS = [
  {
    action_button: { text: "Increase online revenue" },
    description:
      "Get your own ordering system for pickup, delivery, curbside, or dine-in. All done by us so you can keep pace with the big brands and quickly grow your customer base",
    image: SkipliDispatch,
    label: "Upgrade your ordering",
    list: [
      {
        item_name: "Website Ordering",
        item_description:
          "Let hungry customers order directly from your website—on desktop or mobile",
      },
      {
        item_name: "Pickup, Curbside, & Delivery",
        item_description:
          "Easily offer in-store pickup and curbside, and use our nationwide network of commission-free delivery partners",
      },
      {
        item_name: "Contactless Dine-In",
        item_description:
          "We’re here to help make on-premise dining seamless and safe with flexible contact-free solutions",
      },
    ],
  },
  {
    action_button: { text: "Turn your online traffic into revenue" },
    description:
      "Connect directly with new customers to receive orders from an expanding list of the world’s most popular sites and apps",
    image: TrustedCouriers,
    label: "Attract new customers",
    list: [
      {
        item_name: "Instagram Posts & Stories",
        item_description:
          "Turn followers into customers by taking orders straight from your Instagram profile and Stories",
      },
      {
        item_name: "Google search & maps",
        item_description:
          "Expand your reach by letting customers order via Google Search, Maps, and Assistant",
      },
      {
        item_name: "Yelp pages",
        item_description:
          "Increase visibility and orders with our Yelp integration",
      },
    ],
  },
  {
    action_button: { text: "Book a free demo" },
    description:
      "Turn occasional diners into repeat customers with valuable data, marketing expertise, and a large library of marketing tools",
    label: "Create lasting customer connections",
    image: "https://i.imgur.com/uBcJJDr.jpg",
    list: [
      {
        item_name: "Valuable Customer Data",
        item_description:
          "Easily identify and reward loyal guests by using customer data—something other platforms keep for themselves",
      },
      {
        item_name: "Digital Marketing",
        item_description:
          "Keep customers close with email marketing, push notifications, and other promotional tools created by our in-house studio",
      },
      {
        item_name: "Print Marketing Library",
        item_description:
          "Combine online outreach with a library of the best print marketing tools for reaching local audiences",
      },
    ],
  },
];

class ContactlessOrderingBenefits extends React.Component {
  render() {
    return <SplitOverlayGroup items={BENEFITS} />;
  }
}

export default ContactlessOrderingBenefits;
