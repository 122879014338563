// BUTI DINERS, INC. All right Reserved ©

// Assets
import Rating_1 from "assets/Images/Rating-1.png";
import Rating_2 from "assets/Images/Rating-2.png";
import Rating_3 from "assets/Images/Rating-3.png";
import Rating_4 from "assets/Images/Rating-4.png";
import Rating_5 from "assets/Images/Rating-5.png";

const RATINGS = {
  rating_1: {
    feedback_card_header: "Oh no! Could you tell us about it?",
    img_src: Rating_1,
    name: "rating 1",
  },
  rating_2: {
    feedback_card_header: "Oh no! Could you tell us about it?",
    img_src: Rating_2,
    name: "rating 2",
  },
  rating_3: {
    feedback_card_header: "What could we do to improve?",
    img_src: Rating_3,
    name: "rating 3",
  },
  rating_4: {
    feedback_card_header: "Thanks! How can we improve?",
    img_src: Rating_4,
    name: "rating 4",
  },
  rating_5: {
    feedback_card_header: "Fantastic! Can we get even better?",
    img_src: Rating_5,
    name: "rating 5",
  },
};

export { RATINGS };
