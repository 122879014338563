// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

//Styles
import Style from "./style.module.scss";

// Fields
import { Button, ResponsiveModal } from "fields";

// Assets
import {
  ArrowIcon,
  EasyTechIcon,
  FastOrderingIcon,
  NoHighFeesIcon,
} from "assets/Icons";

const BENEFITS = {
  fastOrdering: {
    icon: <FastOrderingIcon />,
    name: "Fast online ordering",
  },
  easyToUse: {
    icon: <EasyTechIcon />,
    name: "Easy-to-use tech",
  },
  noHighFees: {
    icon: <NoHighFeesIcon />,
    name: "No high fees for both of us",
  },
};

class BenefitsModal extends React.Component {
  renderBenefits = () =>
    Object.keys(BENEFITS).map((benefitId) => {
      const { icon, name } = BENEFITS[benefitId];
      return (
        <div className={Style.benefitContainer} key={benefitId}>
          <div className={Style.benefitIcon}>{icon}</div>
          <p>{name}</p>
        </div>
      );
    });

  render() {
    return (
      <ResponsiveModal
        contentLabel="Benefits Information"
        onCloseModal={this.props.onCloseModal}
        classNames={{ modal: Style.modalBox }}
      >
        <h2 className={Style.heading}>SKIPLI Benefits</h2>
        <p className={Style.paragraph}>
          SKIPLI gives our restaurant the tech big chains use and customers
          love. Plus, they help us keep costs down and food quality up for you.
        </p>
        <p className={Style.paragraph}>
          When you order from us through Skipli, you're supporting us and the
          community.
        </p>
        <p className={Style.paragraph}>
          Other perks to partnering with SKIPLI include:
        </p>
        <div className={Style.centerWrapper}>
          <div>{this.renderBenefits()}</div>
        </div>
        <Button
          className={Style.learn_more_button}
          name="learn more about SKIPLI"
          onClick={() => window.open("https://skiplinow.com", "_blank")}
        >
          Learn More <ArrowIcon />
        </Button>
      </ResponsiveModal>
    );
  }
}

BenefitsModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default BenefitsModal;
