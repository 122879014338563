// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

class Modifiers extends React.Component {
  renderModifiers = (modifiers = {}) => {
    const keys = Object.keys(modifiers);
    return keys.reduce((result, modifierID, index) => {
      const { modifierName = "" } = modifiers[modifierID];
      return result + `${modifierName}${index < keys.length - 1 ? ", " : ""}`;
    }, "");
  };

  renderModifierGroups = () => {
    const { modifierGroups } = this.props;
    const keys = Object.keys(modifierGroups);
    return keys.reduce((result, modifierGroupID, index) => {
      const { modifiers = {} } = modifierGroups[modifierGroupID];
      return (
        result +
        `${this.renderModifiers(modifiers)}${
          index < keys.length - 1 ? ", " : ""
        }`
      );
    }, "");
  };

  render() {
    return <div>{this.renderModifierGroups()}</div>;
  }
}

Modifiers.propTypes = {
  modifierGroups: PropTypes.object
};

Modifiers.defaultProps = {
  modifierGroups: {}
};

export default Modifiers;
