// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

const HOW_WE_CAN_HELP = [
  {
    title: "Affordable flat rate",
    description: "No hidden fees. Period.",
  },
  {
    title: "Pace orders",
    description: "Keep your operations running smoothly across service.",
  },
  {
    title: "Drive incremental revenue",
    description:
      "Manage reservations, To Go, events, and retail—all in one place.",
  },
  {
    title: "Forecast demand",
    description: "Take orders today, next week, and the rest of the year.",
  },
  {
    title: "Two-way text messaging",
    description: "Customize text messages for contactless delivery and pickup.",
  },
  {
    title: "World-class support",
    description: "Our hospitality team is here to help around the clock.",
  },
];

const REFERENCES = [
  {
    image: <img alt="" src="https://i.imgur.com/gozwAwn.png" />,
    description:
      "Skipli has been an an invaluable marketing platform and has given me a lot of visibility and new clientele. My numbers unexpectedly exploded once I became visible on Skipli. You guys are very much saving restaurants.",
    restaurantName: "Blue India",
    location: "Atlanta, GA",
    menuLink: "https://www.skiplinow.com/shop/-M174CQ-sg5MAWvXO49V",
    textStyle: {
      marginLeft: "-4rem",
      maxWidth: "26rem",
      minWidth: "26rem",
      transform: "translateY(-18%)",
      zIndex: "10",
    },
  },
  {
    image: <img alt="" src="https://i.imgur.com/JzBZulh.jpg" />,
    description:
      "We love it so much... we feel in control with Skipli, whereas we don’t always with the other systems. We sometimes end up having to shut them off.",
    restaurantName: "Pure Pho and Grill",
    location: "Cumming, GA",
    menuLink: "https://www.skiplinow.com/shop/-MESo98DLvRAmzXGgpXj",
    referenceClassName: Style.secondRef,
    textStyle: {
      marginRight: "-6rem",
      maxWidth: "26rem",
      minWidth: "26rem",
      transform: "translateY(18%)",
      zIndex: "10",
    },
  },
  {
    image: (
      <img
        alt=""
        src="https://skipli.s3.amazonaws.com/shops/taAjpT6Uw/thumbnails/DPxnuu-GQ.jpeg"
      />
    ),
    description:
      "Skipli has allowed us to gracefully navigate the process of re-opening our dining room. Now that we're open again we're able to manage our website, pickup & delivery orders in one unified system which streamlines our entire operation.",
    restaurantName: "Pho 4 U",
    location: "Sheridan, CO",
    referenceClassName: Style.thirdRef,
    textStyle: {
      marginLeft: "-6rem",
      maxWidth: "26rem",
      minWidth: "26rem",
      transform: "translateY(12%)",
      zIndex: "10",
    },
    menuLink: "https://www.skiplinow.com/shop/XuYRDkjcr",
  },
  {
    image: <img alt="" src="https://i.imgur.com/tSBYrgQ.jpg" />,
    description:
      "We love the product it’s been an godsend to us. I mean really amazing…I am absolutely amazed at how easy it is is to use the system and how fast it's become such an essential part of our business.",
    restaurantName: "Poppin Pita Grill",
    referenceClassName: Style.fourthRef,
    location: "Atlanta, GA",
    menuLink: "https://www.skiplinow.com/shop/-Lx6vjV1QKs_0taTHDvh",
    textStyle: {
      marginRight: "-4rem",
      maxWidth: "26rem",
      minWidth: "26rem",
      transform: "translateY(10%)",
      zIndex: "10",
    },
  },
  {
    image: <img alt="" src="https://i.imgur.com/yvbnrnK.jpg" />,
    description:
      "Many people look at restaurant menus online. Skipli helps us capture those customers by providing a great ordering experience — without spending a penny on advertising or commission.",
    restaurantName: "Gypsy Kitchen DC",
    referenceClassName: Style.fifthRef,
    location: "Washington, DC",
    menuLink: "https://www.skiplinow.com/shop/ZpdAUBVGd",
    textStyle: {
      marginRight: "-4rem",
      maxWidth: "26rem",
      minWidth: "26rem",
      transform: "translate(-8%, 10%)",
      zIndex: "10",
    },
  },
];

export { HOW_WE_CAN_HELP, REFERENCES };
