// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Image
import { ChevronArrowIcon } from "assets/Icons";
import Google_Logo from "assets/Images/Google-Logo.png";

// Assets
import { CheckIcon } from "assets/Icons";

const BULLET_POINTS = [
  "Deliver thousands of potential customers to your door",
  "Make more money from online traffic",
  "Get it done for less with a knowlegdable team",
];

class GoogleFrontPageIntro extends React.Component {
  renderBulletPoints = () =>
    BULLET_POINTS.map((text) => (
      <div className={Style.point}>
        <div className={Style.circleCheck}>
          <CheckIcon />
        </div>
        <p className={Style.pointText}>{text}</p>
      </div>
    ));

  renderButtons = () => (
    <div className={Style.buttons}>
      <Button
        className={Style.startNowBtn}
        hasShadow
        name="get started"
        onClick={() => this.props.history.push("/get-started")}
      >
        Start Now <ChevronArrowIcon className={Style.arrowIcon} />
      </Button>
      <Button
        className={Style.learnMoreBtn}
        color="white"
        name="what skipli website design offers"
        onClick={() =>
          this.props.context.onScrollTo("google_front_page_sixty_day_guarantee")
        }
      >
        Learn More <ChevronArrowIcon className={Style.arrowIcon} />
      </Button>
    </div>
  );

  render() {
    return (
      <div className={Style.container}>
        <div className={Style.text_column}>
          <div className={Style.text_content}>
            <img alt="" className={Style.google_logo} src={Google_Logo} />
            <h1 className={Style.heading}>Front Page Placement</h1>
            <p className={Style.paragraph}>
              Stand above your competitors online. Get your restaurant on the
              Front Page of Google in 60 days.
            </p>
            <div>{this.renderBulletPoints()}</div>
            {this.renderButtons()}
          </div>
        </div>
        <div className={Style.image_column}>
          <img
            alt=""
            className={Style.hero_image}
            src="https://i.imgur.com/LW6AyuD.png"
          />
        </div>
      </div>
    );
  }
}

GoogleFrontPageIntro.propTypes = {
  context: PropTypes.shape({
    onScrollTo: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(GoogleFrontPageIntro)
);
