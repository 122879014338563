// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Components
import { PricingModules } from "components";

// Fields
import { Tabs } from "fields";

const PRODUCTS = {
  onlineOrdering: {
    label: (
      <div className={Style.tabLabel}>
        <h2>Online Ordering</h2>
        <p>Delivery & takeout orders</p>
      </div>
    ),
  },
  website: {
    label: (
      <div className={Style.tabLabel}>
        <h2>Website</h2>
        <p>Increase online sales</p>
      </div>
    ),
  },
};

class Pricing extends React.Component {
  state = { selectedProductId: "onlineOrdering" };

  componentDidMount() {
    document.title = "Pricing | Skipli";
  }

  renderContent = () => {
    switch (this.state.selectedProductId) {
      case "onlineOrdering":
        return <PricingModules.OnlineOrdering />;
      case "website":
        return <PricingModules.Website />;
      default:
        return null;
    }
  };

  renderHeader = () => (
    <div className={Style.header}>
      <div className={Style.headerText}>
        <h1 className={Style.heading}>Pricing & Plans</h1>
        <Tabs
          classNames={{
            container: Style.tabs,
            selected: Style.selectedTab,
            tab: Style.tab,
          }}
          onClick={(id) => this.setState({ selectedProductId: id })}
          options={PRODUCTS}
          selectedOptionId={this.state.selectedProductId}
        />
      </div>
    </div>
  );

  render() {
    return (
      <div className={MainWebsiteStyle.pageContainer}>
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

export default Pricing;
