// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import cx from "classnames";

import { _lightOrDark } from "./HelperFunctions";

import SideMenu from "./SideMenu";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Icons
import { MenuIcon } from "assets/Icons";

const NAVIGATION_OPTIONS = {
  home: { label: "home" },
  menu: { label: "menu" },
  about: { label: "About us" },
  orderDelivery: { label: "Order Delivery" },
  orderPickUp: { label: "Order Pickup" },
  contactUs: { label: "Contact Us" },
};

class ShopHeader extends React.Component {
  state = {};

  onClickOption = (optionId = "") => {
    switch (optionId) {
      case "orderDelivery":
      case "orderPickUp": {
        this.props.onRedirectToOrderOnline();
        return;
      }
      default:
        this.setState({ showSideMenu: false }, () =>
          this.props.onScrollTo(optionId)
        );
    }
  };

  renderNavigationOptions = () => {
    const { header = {}, themeColor = "#3aafa9" } = this.props;
    const { textColor, textHoverColor } = header;

    const NavOption = styled(Button)`
      color: ${textColor};
      &:hover {
        border-bottom-color: ${textHoverColor || themeColor || "#3aafa9"};
        color: ${textHoverColor || themeColor || "#3aafa9"};
      }
    `;

    return Object.keys(NAVIGATION_OPTIONS).map((optionId) => {
      const { label } = NAVIGATION_OPTIONS[optionId];
      return (
        <div key={optionId}>
          <NavOption
            className={Style.navOption}
            name="label"
            onClick={() => this.onClickOption(optionId)}
          >
            {label}
          </NavOption>
        </div>
      );
    });
  };

  renderShopName = () => {
    const { header = {}, shopBasicInfo = {} } = this.props;
    const { logoURL = "", name = "" } = shopBasicInfo;
    const { backgroundColor = "#fff" } = header;
    return (
      <div className={Style.shopNameContainer}>
        {logoURL && <img alt="" className={Style.shopLogo} src={logoURL} />}
        <h1
          className={cx(
            Style.shopName,
            _lightOrDark(backgroundColor) === "light" ? Style.dark : Style.light
          )}
        >
          {name}
        </h1>
      </div>
    );
  };

  renderSideMenu = () => (
    <SideMenu
      navigationOptions={NAVIGATION_OPTIONS}
      onClickOption={this.onClickOption}
    />
  );

  render() {
    const { header = {} } = this.props;
    const { backgroundColor = "#fff" } = header;
    return (
      <React.Fragment>
        {this.state.showSideMenu && this.renderSideMenu()}
        <div
          className={Style.shopHeader}
          style={{ background: backgroundColor }}
        >
          <div className={Style.content}>
            <div onClick={() => this.props.onScrollTo("home")}>
              {this.renderShopName()}
            </div>
            <div
              className={Style.menuIconContainer}
              onClick={() =>
                this.setState({ showSideMenu: !this.state.showSideMenu })
              }
            >
              <MenuIcon
                className={cx(
                  Style.menuIcon,
                  _lightOrDark(backgroundColor) === "light"
                    ? Style.dark
                    : Style.light
                )}
              />
            </div>
            <div className={Style.navigation}>
              {this.renderNavigationOptions()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ShopHeader.propTypes = {
  header: PropTypes.exact({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    textHoverColor: PropTypes.string,
  }),
  onRedirectToOrderOnline: PropTypes.func.isRequired,
  onScrollTo: PropTypes.func.isRequired,
  shopBasicInfo: PropTypes.shape({
    logoURL: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  themeColor: PropTypes.string,
};

ShopHeader.defaultProps = {
  themeColor: "#3aafa9",
};

export default ShopHeader;
