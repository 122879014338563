// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// ModuleStyle
import ModuleStyle from "../style.module.scss";

const BASIC_PLAN_FEATURES = [
  "onlineOrdering",
  "contactless",
  "curbsidePickup",
  "webApp",
  "orderNotification",
  "acceptOrders",
  "unlimitedOrders",
  "guestReceipt",
  "guestSMS",
  "menuMananagement",
  "refundOrder",
];

const UNLIMITED_PLAN_FEATURES = BASIC_PLAN_FEATURES.concat([
  "printOrder",
  "salesReport",
  "flyers",
  "guestData",
  "consultation",
]);

const VIP_PLAN_FEATURES = UNLIMITED_PLAN_FEATURES.concat([
  "professionalPhoto",
  "basicEventDesign",
  "selfCheckIn",
  "priorityResponse",
  "giftCard",
  "coupon",
  "emailMarketing",
  "ordersHub",
]);

const FEATURE_GROUPS = {
  core: {
    label: "Core Features",
    features: {
      onlineOrdering: { label: "Online Ordering (Delivery + Take Out)" },
      acceptOrders: {
        label:
          "Auto-accept paid orders from Google, Website, Facebook, & Instagram",
      },
      unlimitedOrders: {
        label: "Unlimited number of orders",
      },
      orderNotification: {
        label: "New order notification via SMS, Tablet, and more",
      },
      contactless: { label: "Contactless Menu" },
      curbsidePickup: { label: "Curbside Pick Up" },
      webApp: { label: "Web Mobile App" },
    },
  },
  productivity: {
    label: "Productivity Features",
    features: {
      guestReceipt: { label: "Automatically send receipt to guest" },
      guestSMS: { label: "Communicate with guest via SMS" },
      menuMananagement: {
        label:
          "Automatic menu update, price change, put items on sale or sold out",
      },
      refundOrder: {
        label: "Refund orders on-demand",
      },
      printOrder: {
        label: "Print order to Bluetooth + Wifi printers",
      },
    },
  },
  marketing: {
    label: "Marketing Features",
    features: {
      salesReport: { label: "Advance Sales Report" },
      flyers: {
        label:
          "1,000 flyers for direct marketing (convert third-party guests to your own)",
      },
      guestData: { label: "Collect guest data" },
      consultation: {
        label: "Expert consultation for successful online strategy",
      },
      professionalPhoto: { label: "Professional Photoshoot" },
      basicEventDesign: {
        label: "Basic designs for events & special occasions",
      },
    },
  },
  premiumSupport: {
    label: "Premium Features",
    features: {
      selfCheckIn: { label: "Self check in for guests" },
      priorityResponse: { label: "Priority response + VIP support" },
      giftCard: { label: "Sell gift cards" },
      coupon: { label: "Create coupon for guests" },
      emailMarketing: {
        label: "Automatic email marketing",
      },
      ordersHub: {
        label: "Combine all third-party orders into one manageable tablet",
      },
    },
  },
};

const PAYMENT_PLANS = {
  yearly: {
    label: (
      <div>
        <div>Yearly</div>
        <div className={ModuleStyle.saving}>Save 20%</div>
      </div>
    ),
  },
  monthly: {
    label: "Monthly",
  },
};

const PLANS = {
  plus: {
    label: "Plus",
    payoutSchedule: "Weekly Payout",
    price: {
      monthly: { rate: 99, setup: 250 },
      yearly: { rate: 79, setup: 0 },
    },
    features: BASIC_PLAN_FEATURES,
  },
  premium: {
    label: "Premium",
    payoutSchedule: "Daily Payout",
    price: {
      monthly: { rate: 149, setup: 250 },
      yearly: { rate: 129, setup: 0 },
    },
    features: UNLIMITED_PLAN_FEATURES,
  },
  unlimited: {
    label: "Unlimited",
    payoutSchedule: "Daily Payout",
    price: {
      monthly: { rate: 299, setup: 250 },
      yearly: { rate: 249, setup: 0 },
    },
    features: VIP_PLAN_FEATURES,
  },
};

export { FEATURE_GROUPS, PAYMENT_PLANS, PLANS };
