// BUTI DINERS, INC. All right Reserved ©

import { RegEx } from "lib/_constants";

// const REQUIRED_ADDRESS_FIELDS = ["line1", "city", "state", "postal_code"];
const REQUIRED_ADDRESS_FIELDS = ["postal_code"];

const IsPaymentMethodFormValid = (billingDetails = {}) => {
  const { address, email = "" } = billingDetails;

  // Verify name and email
  if (!email || !RegEx.EMAIL.test(email)) return false;

  // Verify if required address values exist
  const isAddressFilled = REQUIRED_ADDRESS_FIELDS.reduce((result, fieldID) => {
    if (!address[fieldID]) result = false;
    return result;
  }, true);
  if (!isAddressFilled) return false;

  // Verify postal code and state
  if (!RegEx.POSTAL_CODE.test(address.postal_code)) return false;
  // if (!REGEX.US_STATES.test(address.state)) return false;

  // Return true after all info is verified
  return true;
};

export { IsPaymentMethodFormValid };
