// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Components
import { OrderingPageShopIntroduction } from "components";

class Brand extends React.Component {
  state = {};

  renderThumbnailImg = () => {
    const { shopBasicInfo = {} } = this.props.context;
    const { logoURL = "", thumbnailImg = "" } = shopBasicInfo;
    return (
      !this.state.thumbnail_img_not_found && (
        <div className={Style.thumbnailImgContainer}>
          <img
            alt=""
            className={Style.thumbnailImg}
            onError={() => this.setState({ thumbnail_img_not_found: true })}
            src={thumbnailImg}
          />
          {!this.state.shop_logo_not_found && (
            <React.Fragment>
              <div className={Style.thumbnailImgBottomBorder} />
              <div className={Style.shop_logo_container}>
                <img
                  alt=""
                  className={Style.shop_logo}
                  onError={() => this.setState({ shop_logo_not_found: true })}
                  src={logoURL}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      )
    );
  };

  render() {
    const { shopBasicInfo = {}, todayHours = {} } = this.props.context;
    return (
      <div>
        {this.renderThumbnailImg()}
        <div className={Style.shop_introduction_container}>
          <OrderingPageShopIntroduction
            on_shop_logo_not_found={() =>
              this.setState({ shop_logo_not_found: true })
            }
            shop_basic_info={shopBasicInfo}
            shop_logo_not_found={this.state.shop_logo_not_found}
            thumbnail_img_not_found={this.state.thumbnail_img_not_found}
            today_hours={todayHours}
          />
        </div>
      </div>
    );
  }
}

Brand.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      address: PropTypes.string.isRequired,
      logoURL: PropTypes.string,
      name: PropTypes.string.isRequired,
      thumbnailImg: PropTypes.string,
    }).isRequired,
    todayHours: PropTypes.object,
  }),
  onClickShopName: PropTypes.func.isRequired,
};

Brand.defaultProps = {
  onClickShopName: () => {},
};

export default withContext(CustomerInterfaceConsumer)(Brand);
