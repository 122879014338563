// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import cx from "classnames";

// Components
import { CartItem } from "components";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
// import { ViewReceiptButton } from "fields";

// Icons
import { ArrowIcon } from "assets/Icons";

// Lib
import {
  DATE_FORMAT,
  DEFAULT_TIMEZONE,
  ORDER_DELIVERY_TYPES,
  TIME_FORMAT,
} from "lib/_constants";
import { BUTI } from "lib/services";
import { Math } from "lib/functions";

const { GetOrderDetails } = BUTI.GetRequests;

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

class PastOrder extends React.Component {
  state = {};

  onExpandInfo = () =>
    this.setState(
      { showMoreInfo: !this.state.showMoreInfo },
      () => this.state.showMoreInfo && this.onGetMoreInfo()
    );

  onGetMoreInfo = async () => {
    this.setState({ isLoading: true });
    const { shopID } = this.props.context;
    const { orderDetails, success } = await GetOrderDetails({
      orderID: this.props.orderID,
      shopID,
    });
    this.setState({
      isLoading: false,
      orderDetails: success ? orderDetails : {},
    });
  };

  renderMoreInfo = () => {
    const { orderDetails = {} } = this.state;
    if (this.state.isLoading) return <p>Finding details of this order...</p>;
    else if (Object.keys(orderDetails).length === 0)
      return <p>No information found for this order</p>;
    return this.renderOrderDetails(orderDetails);
  };

  renderOrderDetails = (orderDetails = {}) => {
    const { pickUpTime } = orderDetails;
    // const { paymentIntentID = "", pickUpTime } = orderDetails;
    // const { address, name, timeZone } = this.props.context.shopBasicInfo;
    return (
      <div>
        <div className={Style.firstRow}>
          <div>Status: {orderDetails.status}</div>
          {pickUpTime && <div>Pickup Time: {pickUpTime}</div>}
        </div>
        <div>
          Order ID: <strong>{this.props.orderID}</strong>
        </div>
        {this.renderOrderItems(orderDetails.orderItems)}
        {/* <ViewReceiptButton
          paymentIntentID={paymentIntentID}
          orderID={this.props.orderID}
          orderDetails={orderDetails}
          shopAddress={address}
          shopName={name}
          timeZone={timeZone}
        /> */}
      </div>
    );
  };

  renderOrderItems = (orderItems = {}) =>
    Object.entries(orderItems).map((entry) => (
      <div key={entry[0]}>
        <CartItem
          allowShowingItemDetailsModal={false}
          detailsOfItemInCart={entry[1]}
          idOfItemInCart={entry[0]}
          showRemoveIcon={false}
          showPrice={false}
        />
      </div>
    ));

  render() {
    const { orderInfo } = this.props;
    const { showMoreInfo } = this.state;
    const { label = "" } = ORDER_DELIVERY_TYPES[orderInfo.orderDeliveryTypeID];
    const { timeZone } = this.props.context.shopBasicInfo;
    const received_date = dayjs(orderInfo.timeStamp)
      .tz(timeZone || DEFAULT_TIMEZONE)
      .format(`${DATE_FORMAT} ${TIME_FORMAT}`);

    return (
      <div className={Style.order}>
        <div onClick={this.onExpandInfo} style={{ cursor: "pointer" }}>
          <div className={Style.firstRow}>
            <div style={{ fontWeight: "bold" }}>{label}</div>
            {orderInfo.tableNumber && <div>Table: {orderInfo.tableNumber}</div>}
            <div>{received_date}</div>
          </div>
          <div className={Style.secondRow}>
            <div>Total: ${Math._roundNumber(orderInfo.totalPriceAfterTax)}</div>
            <div className={Style.expandButton}>
              {showMoreInfo ? "Hide" : "Show"} Details
              <ArrowIcon
                className={cx(Style.arrowIcon, showMoreInfo && Style.collapse)}
              />
            </div>
          </div>
        </div>
        {showMoreInfo && (
          <div className={Style.moreInfo}>{this.renderMoreInfo()}</div>
        )}
      </div>
    );
  }
}

PastOrder.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
  }),
  orderID: PropTypes.string.isRequired,
  orderInfo: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(PastOrder);
