// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { set } from "object-path-immutable";

import { HOME_PAGE_NAV_OPTIONS } from "./constants";

// Context
import { CustomerInterfaceProvider } from "context";

// Views
import {
  AutoPhone,
  Blog,
  BlogPost,
  ContactlessOrdering,
  GetStarted,
  GoogleFrontPage,
  HomePage,
  MatchingShops,
  // Pricing,
  Privacy,
  Terms,
  Testimonials,
  VirtualRestaurant,
  WebsiteDesign,
  WebsiteExamples,
} from "views";

// Style
import Style from "./style.module.scss";

// Components
import { GlobalFooter, LiveChatLoader, Navbars } from "components";

// Lib
import { ScrollToComponent } from "lib/functions";

// Assets
import { ArrowIcon } from "assets/Icons";

class Platform extends React.Component {
  constructor(props) {
    super(props);
    this.refs = {};
    this.state = { show_nav_options_menu: false };
  }

  onScrollTo = (refID) => {
    const ref = this.refs[refID];
    ref && ScrollToComponent({ ref });
  };

  onUpdateRefs = ({ refID = "", ref }) => {
    if (refID) {
      this.refs = set(this.refs, refID, ref);
    }
  };

  onClickNavbarItem = ({ optionID, pathname = "" }) => {
    switch (optionID) {
      case "howItWorks":
        this.props.history.push("/");
        this.onScrollTo(optionID);
        return;
      default:
        pathname && this.props.history.push(pathname);
    }
  };

  renderNavOptions = (options = {}) => {
    const { pathname: currentPathname } = this.props.location;
    return Object.keys(options).map((optionID) => {
      const { label, pathname = "", isNew = false } = options[optionID];
      if (currentPathname === "/order-food" && pathname === "/order-food")
        return null;
      return (
        <button
          className={cx(
            Style.navOption,
            optionID === "getStarted" && Style.getStarted,
            optionID === "orderingPlatform" && Style.orderingPlatform
          )}
          onClick={() =>
            this.setState({ show_nav_options_menu: false }, () =>
              this.onClickNavbarItem({ optionID, pathname })
            )
          }
          key={optionID}
        >
          {label} {isNew && <span className={Style.isNew}>NEW</span>}{" "}
          {optionID === "orderingPlatform" && <ArrowIcon />}
        </button>
      );
    });
  };

  renderView = () => {
    const { match = {} } = this.props;
    const { path = "" } = match;

    switch (path) {
      case "/auto-phone":
        return (
          <CustomerInterfaceProvider
            value={{
              onScrollTo: this.onScrollTo,
              onUpdateRefs: this.onUpdateRefs,
            }}
          >
            <AutoPhone />
          </CustomerInterfaceProvider>
        );
      case "/blog":
        return <Blog />;
      case "/blog/:title":
        return <BlogPost {...this.props} />;
      case "/browseFood":
      case "/order-food":
        return <MatchingShops />;
      case "/contactless":
        return (
          <CustomerInterfaceProvider
            value={{
              onScrollTo: this.onScrollTo,
              onUpdateRefs: this.onUpdateRefs,
            }}
          >
            <ContactlessOrdering />
          </CustomerInterfaceProvider>
        );
      case "/google-front-page":
        return (
          <CustomerInterfaceProvider
            value={{
              onScrollTo: this.onScrollTo,
              onUpdateRefs: this.onUpdateRefs,
            }}
          >
            <GoogleFrontPage />
          </CustomerInterfaceProvider>
        );
      case "/get-started":
      case "/getstarted":
      case "/pricing":
        return <GetStarted />;
      case "/privacy":
        return <Privacy />;
      case "/terms":
        return <Terms />;
      case "/testimonials":
        return <Testimonials />;
      case "/website-design":
        return (
          <CustomerInterfaceProvider
            value={{
              onScrollTo: this.onScrollTo,
              onUpdateRefs: this.onUpdateRefs,
            }}
          >
            <WebsiteDesign />
          </CustomerInterfaceProvider>
        );
      case "/website-examples":
        return <WebsiteExamples />;
      case "/virtual-restaurant":
        return (
          <CustomerInterfaceProvider
            value={{
              onScrollTo: this.onScrollTo,
              onUpdateRefs: this.onUpdateRefs,
            }}
          >
            <VirtualRestaurant />
          </CustomerInterfaceProvider>
        );
      case "/delivery":
      default:
        return (
          <CustomerInterfaceProvider
            value={{
              onScrollTo: this.onScrollTo,
              onUpdateRefs: this.onUpdateRefs,
            }}
          >
            <HomePage />
          </CustomerInterfaceProvider>
        );
    }
  };

  render() {
    return (
      <div>
        <Navbars.LandingPageNavbar
          navOptions={this.renderNavOptions(HOME_PAGE_NAV_OPTIONS)}
          onScrollTo={this.onScrollTo}
          onHideNavOptionsMenu={() =>
            this.setState({ show_nav_options_menu: false })
          }
          onShowNavOptionsMenu={() =>
            this.setState({ show_nav_options_menu: true })
          }
          show_nav_options_menu={this.state.show_nav_options_menu}
        />
        {this.renderView()}
        <LiveChatLoader
          app_id="2686831628122523"
          provider="messenger"
          provider_key="106961164970070"
        />
        <CustomerInterfaceProvider value={{ onScrollTo: this.onScrollTo }}>
          <GlobalFooter className={Style.footer} />
        </CustomerInterfaceProvider>
      </div>
    );
  }
}

Platform.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default withRouter(Platform);
