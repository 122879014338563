// BUTI DINERS, INC. All right Reserved ©

const BENEFITS = {
  save_on_fees: {
    title: "Affordable Fees",
    list: [
      {
        label: "0% Commission Delivery",
        description: "Offer delivery and pick-up to your customers without a mountain of fees",
      },
      {
        label: "Direct Sale",
        description: "Increase SEO & trafic when guests order directly from your branded website",
      },
      {
        label: "Built-in Rewards",
        description: "Guests love rewards. Make them loyal to your brand",
      },
    ],
    replaces: ["GrubHub", "Wix", "BentoBox"],
    video_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Home+Page/Save+On+Fees.mp4",
  },
  increase_sales: {
    title: "More Sales",
    list: [
      {
        label: "Email Marketing",
        description: "Frequent automated emails to stay on your guests' mind",
      },
      {
        label: "Text Marketing",
        description: "Send out direct promotion texts to promote specials & deals",
      },
      {
        label: "Beat Your Competition",
        description: "Top result online when guests search for your cuisine",
      },
    ],
    replaces: ["Google Ads", "Mailchimp", "Yelp"],
    video_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Home+Page/2+World+Class+Online+Ordering.mp4",
  },
  recruit: {
    title: "Staff Recruit",
    list: [
      {
        label: "Recruiter",
        description: "Show potential candidates the benefits of working at your restaurant",
      },
      {
        label: "Jobs",
        description: "Create your own job board to find competent applicants",
      },
      {
        label: "Awesome Branding",
        description: "Immediately impress candidates with a modern & sleek online brand",
      },
    ],
    replaces: ["ZipRecruiter", "Indeed", "CraigList"],
    video_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Home+Page/2+World+Class+Online+Ordering.mp4",
  },
  // delight_customers: {
  //   title: "Impress Customers",
  //   list: [
  //     {
  //       label: "Cool Experience",
  //       description:
  //         "A smooth online experience branded to your restaurant quickly impresses customers",
  //     },
  //     {
  //       label: "Loyalty Program",
  //       description:
  //         "Turn guests into loyal fans & promotors of your restaurant with awesome built-in rewards",
  //     },
  //     {
  //       label: "One Click Accounts",
  //       description: "Quick checkout with our sleek one click login and checkout",
  //     },
  //   ],
  //   replaces: ["BentoBox", "ChowNow",],
  //   video_url:
  //     "https://skipli.s3.amazonaws.com/Skipli+Website/Home+Page/Delight+Customers.mp4",
  // },
};

export { BENEFITS };
