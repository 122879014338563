// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const Slashy = (props) => {
  const { classNames = {} } = props;
  return (
    <div className={cx(Style.container, classNames.container)}>
      <div className={cx(Style.slashy, classNames.slashy)}></div>
      <div className={cx(Style.content, classNames.content)}>
        <div className={cx(Style.leftColumn, classNames.leftColumn)}>
          {props.leftColumnContent}
        </div>
        <div className={cx(Style.rightColumn, classNames.rightColumn)}>
          {props.rightColumnContent}
        </div>
      </div>
    </div>
  );
};

Slashy.propTypes = {
  classNames: PropTypes.shape({
    container: PropTypes.string,
    content: PropTypes.string,
    slashy: PropTypes.string,
    leftColumn: PropTypes.string,
    rightColumn: PropTypes.string,
  }),
  leftColumnContent: PropTypes.any,
  rightColumnContent: PropTypes.any,
};

export default Slashy;
