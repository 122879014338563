// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import Introduction from "./Introduction";
// import SimplePricing from "views/Delivery/SimplePricing";
import TurnWhatYouLove from "./TurnWhatYouLove";
import WhySkipli from "./WhySkipli";
import NotableFeatures from "./NotableFeatures";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

class VirtualRestaurant extends React.Component {
  componentDidMount() {
    document.title = "Virtual Restaurant | Skipli";
  }

  render() {
    const { onUpdateRefs } = this.props.context;
    return (
      <div>
        <Introduction />
        <div ref={(ref) => onUpdateRefs({ refID: "howItWorks", ref })}>
          <TurnWhatYouLove />
        </div>
        <div ref={(ref) => onUpdateRefs({ refID: "whySkipli", ref })}>
          <WhySkipli />
        </div>
        <NotableFeatures />
        {/* <SimplePricing /> */}
      </div>
    );
  }
}

VirtualRestaurant.propTypes = {
  context: PropTypes.shape({
    onUpdateRefs: PropTypes.func.isRequired,
  }).isRequired,
};

export default withContext(CustomerInterfaceConsumer)(VirtualRestaurant);
