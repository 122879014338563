// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Icons
import {
  ArrowIcon,
  EcommerceIcon,
  LocationIcon,
  RevenueIcon,
} from "assets/Icons";

// Images
import HomeCook from "assets/Images/homeCook.jpg";
import RestaurantOwner from "assets/Images/restaurantOwner.png";

const SELLING_POINTS = {
  yourStore: {
    icon: <EcommerceIcon />,
    label: "Your store, your way",
    description:
      "Build an online store as unique as your products with no coding or design skills with easy-to-use menu builder and management tools.",
  },
  sellEverywhere: {
    icon: <LocationIcon />,
    label: "Sell everywhere",
    description:
      "Be wherever your customers are. Sell your products on your online store, social media, marketplaces, and in-person.",
  },
  getPaidQuickly: {
    icon: <RevenueIcon />,
    label: "No upfront cost",
    description:
      "We run on any device. Turn your own tablet, smartphone, or desktop into a virtual restaurant that quickly generates profits.",
  },
};

const TARGET_AUDIENCES = {
  homeCook: {
    label: "Cook From Home",
    description:
      "Start selling your delicious food with Skipli easy-to-setup online store. Turn your passion into income.",
    image: HomeCook,
  },
  restaurantOwner: {
    label: "Own a restaurant",
    description:
      "Expand or start your online business and put your menu in front of millions of hungry customers.",
    image: RestaurantOwner,
  },
};

class WhySkipli extends React.Component {
  renderSellingPoints = () =>
    Object.keys(SELLING_POINTS).map((id) => {
      const { label, description, icon } = SELLING_POINTS[id];
      return (
        <div className={Style.point} key={id}>
          {icon}
          <h3>{label}</h3>
          <p>{description}</p>
        </div>
      );
    });

  renderTestimonial = () => (
    <div className={Style.testimonial}>
      <h3>
        "Skipli is better than other platforms we have used, and we have tried
        them all."
      </h3>
      <h4>KHOA VUONG, CEO OF PHO KING CHAIN</h4>
      <Button
        className={Style.startFreeTrialBtn}
        hasShadow
        name="start your free trial"
        onClick={() => this.props.history.push("/get-started")}
      >
        Start your free trial
      </Button>
    </div>
  );

  renderTargetAudience = () => (
    <div className={Style.targetAudience}>
      <h2>Bring your food beyond your neighborhood</h2>
      <div className={Style.audienceImages}>
        {Object.keys(TARGET_AUDIENCES).map((audienceID) => {
          const { label, description, image } = TARGET_AUDIENCES[audienceID];
          return (
            <div
              className={Style.imageContainer}
              key={label}
              onClick={() => this.props.history.push("/get-started")}
            >
              <img alt="" className={Style.image} src={image} />
              <div className={Style.imageInfo}>
                <h3>{label}</h3>
                <p>{description}</p>
                <button className={Style.getStartedBtn}>
                  Get Started <ArrowIcon />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  render() {
    return (
      <div className={CommonStyle.container}>
        {this.renderTargetAudience()}
        <div className={Style.sellingPoints}>{this.renderSellingPoints()}</div>
        {this.renderTestimonial()}
      </div>
    );
  }
}

WhySkipli.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(WhySkipli);
