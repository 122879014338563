// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { LANDING_PAGE_INTRO_IMAGE_URL } from "./constants";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Image
import Delivery from "assets/Images/delivery.jpg";
import { ChevronArrowIcon } from "assets/Icons";

class HomePageIntro extends React.Component {
  state = { showVideo: false };

  renderButtons = () => (
    <div className={Style.buttons}>
      <Button
        className={Style.startNowBtn}
        hasShadow
        name="get started"
        onClick={() => this.props.history.push("/get-started")}
      >
        Get a Free Demo <ChevronArrowIcon className={Style.arrowIcon} />
      </Button>
      {/* <Button
        className={Style.learnMoreBtn}
        color="white"
        name="what skipli website design offers"
        onClick={() => this.props.context.onScrollTo("auto_phone_how_it_works")}
      >
        How It Works? <ChevronArrowIcon className={Style.arrowIcon} />
      </Button> */}
    </div>
  );

  renderDesktopView = () => (
    <React.Fragment>
      <div className={Style.background}>
        <div className={CommonStyle.sectionContainer}>
          {this.renderMainContent()}
        </div>
      </div>
    </React.Fragment>
  );

  renderMobileView = () => (
    <React.Fragment>
      <div>
        <div className={CommonStyle.sectionContainer}>
          {this.renderMainContent()}
        </div>
        <img alt="" className={Style.image} src={Delivery} />
      </div>
    </React.Fragment>
  );

  render() {
    return (
      <div className={Style.container}>
        <div className={Style.text_column}>
          <div className={Style.text_content}>
            <h1 className={Style.heading}>
              Own Your Restaurant <span>Online.</span>
            </h1>
            <p className={Style.description}>
              Thousands of restaurant owners use the SKIPLI platform to create
              stunning websites, increase direct sales, save money on fees, and
              manage their online presence & marketing all-in-one.{" "}
              <span>0% Commission Fee</span>
            </p>
            {this.renderButtons()}
          </div>
        </div>
        <div className={Style.image_column}>
          <img
            alt=""
            className={Style.hero_image}
            src={LANDING_PAGE_INTRO_IMAGE_URL}
          />
        </div>
      </div>
    );
  }
}

HomePageIntro.propTypes = {
  context: PropTypes.shape({
    onScrollTo: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(HomePageIntro)
);
