// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { del, set } from "object-path-immutable";
import shortid from "shortid";

// Lib
import { SHOPS_NOT_ALLOW_DINE_IN, STRIPE } from "lib/_constants";
import { CartFuncs, Math } from "lib/functions";

const { STRIPE_TRANSACTION_FEES } = STRIPE;
const { CONV_FEE } = STRIPE_TRANSACTION_FEES;
const { CalculateTechnologyFee, CalculateServiceFee } = CartFuncs;

const _calculateFee = ({ fee = 0, rate = "percentage", subTotal = 0 }) => {
  switch (rate) {
    case "percentage":
      return subTotal * (parseFloat(fee) / 100);
    default:
      return 0;
  }
};

const calculateTipTaxAmount = (subTotal, salesTax, tipAmount) => {
  const tip_tax = subTotal * parseFloat(salesTax) + parseFloat(tipAmount);
  return Math._roundNumber(tip_tax);
};

const calculateBaseAmount = (id, subTotal, tipTaxAmount, totalDeliveryFee) => {
  return id === "online_payment_processing"
    ? subTotal + parseFloat(tipTaxAmount) + totalDeliveryFee
    : subTotal;
};

const calculateSingleFee = ({ fee, rate, base_amount }) => {
  const sanitized_fee = isNaN(parseFloat(fee)) ? 0 : parseFloat(fee);
  const feeAmount = _calculateFee({
    fee: sanitized_fee,
    rate,
    subTotal: base_amount,
  });
  return parseFloat(Math._roundNumber(feeAmount));
};

const _calculateFees = ({
  fees = {},
  subTotal = 0,
  salesTax = 0,
  tipAmount = 0,
  totalDeliveryFee = 0,
}) => {
  const tip_tax_amount = calculateTipTaxAmount(subTotal, salesTax, tipAmount);

  return Object.keys(fees).reduce(
    (result, id) => {
      const { fee = "0", rate = "percentage" } = fees[id];
      const base_amount = calculateBaseAmount(
        id,
        subTotal,
        tip_tax_amount,
        totalDeliveryFee
      );
      const new_fee = calculateSingleFee({ fee, rate, base_amount });

      return {
        fees_list: set(result.fees_list, `${id}.monetaryAmount`, new_fee),
        fees_details: [...result.fees_details, { id, new_fee, fee }],
        total_fee: result.total_fee + new_fee,
      };
    },
    { fees_list: fees, fees_details: [], total_fee: 0 }
  );
};

const _calculateMerchantFees = ({ merchantFees = {}, subTotal }) =>
  Object.keys(merchantFees).reduce(
    (result, id) => {
      const { fee = "0", rate = "percentage" } = merchantFees[id];
      const sanitizedFee = isNaN(parseFloat(fee)) ? 0 : parseFloat(fee);
      const newFee = parseFloat(
        Math._roundNumber(_calculateFee({ fee: sanitizedFee, rate, subTotal }))
      );
      return {
        feesList: set(result.feesList, `${id}.monetaryAmount`, newFee),
        totalFee: result.totalFee + newFee,
      };
    },
    { feesList: merchantFees, totalFee: 0 }
  );

const _findCustomerDefaultPhoneNumber = ({ phoneList }) => {
  const phoneNumbers = Object.keys(phoneList);
  if (phoneNumbers.length === 0) return "";
  const defaultNumber = phoneNumbers.find((number) => {
    const { isDefault = false } = phoneList[number];
    return isDefault;
  });
  return defaultNumber || phoneNumbers[0];
};

const _generateInitialState = ({ shopID, showCateringMenu }) => {
  const SHOPS_NOT_ALLOW_DELIVERY = [
    "-M2iWhFO9MjlKqYxLx9P",
    "-M4zoTWV6nhV8iRf7pRy",
    "-LmBOe1_TzdTppnl2lq1",
    "lW6LJYENb",
    "-Lx6vjV1QKs_0taTHDvh",
    "927797p",
    "454098k",
    "123917b",
    "133361v",
    "632113r",
    "431723q",
    "589516f",
    "148187n",
    "985793y",
    "694195y",
    "169366y",
    "398428e",
    "366260i",
    "dRz40ZobS",
    "730783y",
    "644150l",
  ];

  return {
    allowDelivery: !SHOPS_NOT_ALLOW_DELIVERY.includes(shopID),
    allowDineIn: !SHOPS_NOT_ALLOW_DINE_IN.includes(shopID),
    customerName: "",
    // deliveryAddress: {
    //   city: "Atlanta",
    //   state: "GA",
    //   streetAddress1: "1003 Center Street NW",
    //   streetAddress2: "A",
    //   zip: "30318",
    // },
    deliveryAddress: {},
    dropoff_option: "unattended_delivery",
    orderDeliveryTypeID: showCateringMenu ? "catering" : "pickUp",
    paymentMethods: {},
    phoneNumber: "",
    selectedCourier: "doordash",
    selectedPaymentWalletOption: "card",
    tableNumber: "",
    tipAmount: 0,
  };
};

const _getTotalDeliveryFee = ({ courierQuotes, selectedCourier }) => {
  const quote = courierQuotes[selectedCourier] || {};
  const { totalDeliveryFee = 0 } = quote;
  return totalDeliveryFee;
};

const CreateOutOfStockItemsWarning = ({
  cart = {},
  outOfStockItemIDs = [],
}) => {
  const msg = Object.keys(cart).reduce((result, key) => {
    const { itemID = "", itemSimpleDescription = {} } = cart[key];
    return outOfStockItemIDs.includes(itemID)
      ? result.concat(`• ${itemSimpleDescription.itemName}\n`)
      : result;
  }, "These items are sold out and removed from cart.\n");
  return <div style={{ whiteSpace: "pre-wrap" }}>{msg}</div>;
};

const CompareAddress = ({ address1 = {}, address2 = {} }) => {
  return Object.keys(address2).reduce((result, fieldID) => {
    const val1 = (address1[fieldID] || "").replace(/\s/g, "");
    const val2 = (address2[fieldID] || "").replace(/\s/g, "");
    if (val1 !== val2) result = false;
    return result;
  }, true);
};

const CreateOrderDetails = (data) => {
  const {
    courierQuotes = {},
    curbsideInfo,
    customerName,
    deliveryAddress,
    deliveryNotes = "",
    dropoff_option = "unattended_delivery",
    store_front,
    merchantFees = {},
    orderDeliveryTypeID = "inStore",
    paymentIntentID,
    phoneNumber,
    pickUpTime,
    selectedCourier = "doordash",
    skipliShareOfTips,
    shop_id,
    subTotal,
    tableNumber,
    totalAmount,
    total_fee,
    timeStamp,
    tipAmount,
  } = data;

  const serviceFee = CalculateServiceFee({
    orderDeliveryTypeID,
    shop_id,
    subTotal,
  });
  const technologyFee = CalculateTechnologyFee({
    orderDeliveryTypeID,
    skipliShareOfTips,
    tipAmount,
  });

  const orderDetails = {
    customerName,
    feesForBUTI: { convenience: CONV_FEE, serviceFee, technologyFee },
    store_front,
    merchantFees,
    orderDeliveryTypeID,
    paymentIntentID,
    phoneNumber,
    timeStamp,
    tipAmount: parseFloat(Math._roundNumber(tipAmount - technologyFee)),
    totalPriceAfterTax: parseFloat(Math._roundNumber(totalAmount - total_fee)),
  };

  if (orderDeliveryTypeID === "inStore") {
    return { ...orderDetails, tableNumber };
  } else if (orderDeliveryTypeID === "pickUp") {
    return { ...orderDetails, curbsideInfo, pickUpTime };
  } else if (orderDeliveryTypeID === "deliver") {
    const { feesForBUTI } = orderDetails;
    const result = {
      ...orderDetails,
      deliveryDetails: {
        courier: selectedCourier,
        courierQuote: courierQuotes[selectedCourier] || {},
        deliveryAddress,
        deliveryNotes,
        dropoff_option,
      },
      feesForBUTI: set(
        feesForBUTI,
        "deliveryFee",
        _getTotalDeliveryFee({ courierQuotes, selectedCourier })
      ),
    };
    return result;
  } else return orderDetails;
};

const GetUpdatedCustomerProfile = ({ customerProfile, newData }) => {
  const { address = {}, email = "", name } = newData;
  let result = customerProfile;

  // Add new address if it doesn't exist
  if (Object.keys(address).length > 0) {
    const { addressList = {} } = customerProfile;
    const isAddressAdded = Object.values(addressList).find((addr) =>
      CompareAddress({ address1: addr, address2: address })
    );
    if (!isAddressAdded)
      result = set(result, `addressList.${shortid.generate()}`, address);
  }
  // Add new email if it doesn't exist
  if (email) {
    const { emailList = {} } = customerProfile;
    if (!Object.values(emailList).includes(email))
      result = set(result, `emailList.${shortid.generate()}`, email);
  }
  // Add customer's name if it doesn't exist
  if (!result.name) result = set(result, "name", name || "");
  return result;
};

const RemoveOutOfStockItems = ({ cart = {}, outOfStockItemIDs = [] }) =>
  Object.keys(cart).reduce((result, key) => {
    const { itemID = "" } = cart[key];
    return outOfStockItemIDs.includes(itemID) ? del(result, key) : result;
  }, cart);

export {
  _calculateFees,
  _calculateMerchantFees,
  _findCustomerDefaultPhoneNumber,
  _generateInitialState,
  _getTotalDeliveryFee,
  CreateOrderDetails,
  CreateOutOfStockItemsWarning,
  GetUpdatedCustomerProfile,
  RemoveOutOfStockItems,
};
