// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icons
import { MinusIcon, PlusIcon } from "assets/Icons";

const QuantityCalculator = (props) => {
  const { incrementAmount } = props;
  const increment = isNaN(incrementAmount) ? 1 : parseFloat(incrementAmount);
  return (
    <div className={cx(Style.calculatorContainer, props.className)}>
      <button
        className={cx(
          Style.iconContainer,
          props.disableMinusButton && Style.disabled,
          props.minusButtonClassName
        )}
        onClick={() => props.onButtonClick(-increment)}
      >
        <MinusIcon className={Style.icon} />
      </button>
      <div className={cx(Style.quantityNumber, props.quantityNumberClassName)}>
        {props.currentQuantity}
      </div>
      <button
        className={cx(
          Style.iconContainer,
          props.disablePlusButton && Style.disabled,
          props.plusButtonClassName
        )}
        onClick={() => props.onButtonClick(increment)}
      >
        <PlusIcon className={Style.icon} />
      </button>
    </div>
  );
};

QuantityCalculator.propTypes = {
  className: PropTypes.any,
  currentQuantity: PropTypes.any,
  disableMinusButton: PropTypes.bool,
  disablePlusButton: PropTypes.bool,
  incrementAmount: PropTypes.number,
  onButtonClick: PropTypes.func.isRequired,
};

QuantityCalculator.defaultProps = {
  currentQuantity: 0,
  disableMinusButton: false,
  disablePlusButton: false,
  incrementAmount: 1,
};

export default QuantityCalculator;
