// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

const LabelOption = ({ icon, label, onSelect, style }) => {
  const { circle, container, labelText } = style;
  return (
    <button
      className={Style.labelOptionContainer}
      style={container}
      onClick={onSelect}
    >
      <div className={Style.labelOptionIcon} style={circle}>
        {icon}
      </div>
      <p style={labelText}>{label}</p>
    </button>
  );
};

LabelOption.propTypes = {
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  style: PropTypes.shape({
    container: PropTypes.object,
    circle: PropTypes.object,
    icon: PropTypes.object
  }).isRequired
};

export default LabelOption;
