// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Input } from "fields";

class ConfirmOrderModalCustomerInfo extends React.Component {
  onChangeCustomerName = (value) => {
    // Remove spaces before the customer's name
    let customerName = value.replace(/^\s+/, "");

    // Remove all non-alphanumeric/non-space characters from the customer's name,
    // but allow diacritical marks (accents)
    customerName = customerName.replace(/[^a-zA-Z0-9À-ž\-\s]/g, "");

    this.props.onChangeCustomerName(customerName);
  };

  onChangePhoneNumber = (value) => {
    let phoneNumber = value.replace(/[^0-9]/g, "");
    if (phoneNumber.length <= 11) this.props.onChangePhoneNumber(phoneNumber);
  };

  onChangeTableNumber = (value) => this.props.onChangeTableNumber(value);

  render() {
    return (
      <div>
        {this.props.orderDeliveryTypeID === "inStore" && (
          <div className={Style.inputFieldContainer}>
            <Input.TextInput
              onChange={this.onChangeTableNumber}
              label="Table Number / Area / To Go"
              name="Table Number"
              value={this.props.tableNumber}
              required
            />
          </div>
        )}
        <div className={Style.inputFieldContainer}>
          <Input.TextInput
            onChange={this.onChangeCustomerName}
            label="Your Name"
            name="Customer Name"
            placeholder="Your Name"
            required={true}
            value={this.props.customerName}
          />
        </div>
        <div className={Style.inputFieldContainer}>
          <Input.TextInput
            onChange={this.onChangePhoneNumber}
            label="Mobile Phone Number"
            name="Mobile Phone Number"
            placeholder="Mobile Phone Number"
            required={true}
            value={this.props.phoneNumber}
            type="tel"
          />
        </div>
        <p className={Style.phoneNumberInst}>
          You will receive receipt & order status at this number
        </p>
      </div>
    );
  }
}

ConfirmOrderModalCustomerInfo.propTypes = {
  customerName: PropTypes.string,
  onChangeCustomerName: PropTypes.func.isRequired,
  onChangeTableNumber: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  orderDeliveryTypeID: PropTypes.oneOf([
    "inStore",
    "pickUp",
    "deliver",
    "catering",
  ]).isRequired,
  tableNumber: PropTypes.string,
};

ConfirmOrderModalCustomerInfo.defaultProps = {
  customerName: "",
  phoneNumber: "",
  tableNumber: "",
};

export default ConfirmOrderModalCustomerInfo;
