// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

class AutoPhoneGuestsServed extends React.Component {
  render() {
    return (
      <div className={Style.container}>
        <div className={Style.content}>
          <img
            alt=""
            className={Style.graphic}
            src="https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/Auto+Phone+Guests+Served+1.png"
          />
          <div className={Style.text_column}>
            <h2 className={Style.guests_count}>1,000,000+</h2>
            <h3 className={Style.guests_served_with}>guests served with</h3>
            <strong className={Style.skipli}>SKIPLI</strong>
            <p className={Style.description}>
              Unbearable hold times, low quality of audio, lack of sincerity
              make it difficult to order today. This is what SKIPLI solves using
              technology + humans working together.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoPhoneGuestsServed;
