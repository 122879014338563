// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del } from "object-path-immutable";

// Components
import { CartItem, Modals } from "components";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Lib
import { LocalStorage, ShowConfirmNotif } from "lib/functions";
import { Customers } from "lib/services";
const { ChangeCustomerCart } = Customers.PostRequests;

class ReviewOrder extends React.Component {
  state = { showConfirmDeleteModal: false };

  componentWillUnmount = () =>
    this.setState({ idOfItemToRemove: "", showConfirmDeleteModal: false });

  onHideConfirmDeleteModal = () =>
    this.setState({ showConfirmDeleteModal: false });

  onShowConfirmDeleteModal = (idOfItemToRemove) =>
    this.setState({ idOfItemToRemove, showConfirmDeleteModal: true });

  onRemoveItemFromCart = async () => {
    const { context } = this.props;
    const { shopID, showCateringMenu = false } = context;
    const newCart = del(context.cart, this.state.idOfItemToRemove);
    const { success } = await ChangeCustomerCart({
      applyToCateringCart: showCateringMenu,
      cart: newCart,
      shopID,
      uuid: LocalStorage.getItemFromStorage("uuid"),
    });
    if (!success) {
      this.confirmNotif = ShowConfirmNotif({
        message: "Failed to remove item",
        type: "error",
      });
    }
    this.setState(
      { idOfItemToRemove: "" },
      () => success && context.onChangeCustomerCart(newCart)
    );
  };

  renderConfirmModal = () => (
    <Modals.ConfirmDeleteItemFromCartModal
      onCloseModal={this.onHideConfirmDeleteModal}
      onRemoveItemFromCart={this.onRemoveItemFromCart}
    />
  );

  renderItemsInCart = (cart = {}) =>
    Object.entries(cart).map((entry) => (
      <div key={entry[0]}>
        <CartItem
          detailsOfItemInCart={entry[1]}
          idOfItemInCart={entry[0]}
          onRemoveItemFromCart={this.onShowConfirmDeleteModal}
        />
      </div>
    ));

  render() {
    const {
      context,
      // totalPriceBeforeTax,
      // totalOrderPriceBeforeTaxWithoutSale,
    } = this.props;
    const { cart } = context;
    // const customerSaved = parseFloat(
    //   totalOrderPriceBeforeTaxWithoutSale - totalPriceBeforeTax
    // );
    return (
      <div>
        {this.state.showConfirmDeleteModal && this.renderConfirmModal()}
        <div style={{ marginBottom: "20px" }}>
          {this.renderItemsInCart(cart)}
        </div>
        {/* {customerSaved > 0 && (
          <div className={Style.estimateTotal} style={{ marginBottom: "10px" }}>
            <div>You Saved:</div>
            <strong className={Style.savedAmount}>
              ${Math._roundNumber(customerSaved)}
            </strong>
          </div>
        )} */}
        {/* <div className={Style.estimateTotal}>
          <div>Sub Total (Before Tax):</div>
          <div>${Math._roundNumber(totalPriceBeforeTax)}</div>
        </div> */}
      </div>
    );
  }
}

ReviewOrder.propTypes = {
  context: PropTypes.shape({
    cart: PropTypes.object.isRequired,
    onChangeCustomerCart: PropTypes.func.isRequired,
    shopID: PropTypes.string.isRequired,
    showCateringMenu: PropTypes.bool,
  }).isRequired,
  totalPriceBeforeTax: PropTypes.number.isRequired,
  totalOrderPriceBeforeTaxWithoutSale: PropTypes.number,
};

export default withContext(CustomerInterfaceConsumer)(ReviewOrder);
