// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import _get from "lodash.get";

import {
  _calcTotalOrderPriceBeforeTax,
  _createDeliveryRequest,
  _createEmptyCartMessage,
  _createFailedOrderMessage,
  _createSubmittedOrderMessage,
  _generateOrderID,
  _sendTrackingLinkToGuest,
} from "./HelperFunctions";

// Style
import Style from "./style.module.scss";

// Components
import { ConfirmOrder, ReviewOrder } from "components";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Fields
import {
  Button,
  LoadingSpinner,
  PageMsg,
  ResponsiveModal,
  ScreenSize,
} from "fields";

// Icons
import {
  CloseSignIcon,
  LocationDarkIcon,
  OrdersIcon,
  PlusIcon,
} from "assets/Icons";

// Lib
import { STRIPE } from "lib/_constants";
import {
  FoodMenuFuncs,
  LocalStorage,
  SendSingleTextMessageToMultipleNumbers,
} from "lib/functions";
import { Customers } from "lib/services";

const { STRIPE_CREDENTIALS } = STRIPE;

const {
  AddOrderToCustomerPastOrders,
  AddOrderToShopActiveOrders,
  ClearCustomerCart,
  CreateStripeCustomerID,
  SaveCustomerPhoneNumbers,
} = Customers.PostRequests;

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: window.Stripe(STRIPE_CREDENTIALS.STRIPE_PUBLIC_KEY),
    };
  }

  componentDidMount() {
    this.onCheckCustomerStripeID();
    window.addEventListener("beforeunload", this._confirm);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
  }

  componentWillUnmount() {
    this.setState({
      isOrderSubmitted: false,
      isOrderFailed: false,
      isSendingOrderToShop: false,
      submittedOrderDeliveryTypeID: "",
    });
    window.removeEventListener("beforeunload", this._confirm);
    window.onpopstate = () => {};
  }

  _backConfirm = () => this.props.context.onChangeGlobalNavItem("foodMenu");

  _confirm = (e) => {
    var confirmationMessage = "o/";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  onAddOrderToCustomerPastOrders = async ({ orderDetails, orderID }) =>
    await AddOrderToCustomerPastOrders({
      orderID,
      orderPreviewDetails: {
        orderDeliveryTypeID: orderDetails.orderDeliveryTypeID,
        tableNumber: orderDetails.tableNumber,
        timeStamp: orderDetails.timeStamp,
        totalPriceAfterTax: orderDetails.totalPriceAfterTax,
      },
      shopID: this.props.context.shopID,
      uuid: LocalStorage.getItemFromStorage("uuid"),
    });

  onCheckCustomerStripeID = async () => {
    // If the customer doesn't have a Stripe Customer ID, create one for that uuid
    const { context } = this.props;
    const { customerProfile, onChangeCustomerProfile } = context;
    const stripeCustomerID = _get(customerProfile, "stripeCustomerID");
    if (!stripeCustomerID) {
      const { stripeCustomerID, success } = await CreateStripeCustomerID({
        uuid: LocalStorage.getItemFromStorage("uuid"),
      });
      if (success) {
        onChangeCustomerProfile &&
          onChangeCustomerProfile(
            set(customerProfile, "stripeCustomerID", stripeCustomerID)
          );
      }
    }
  };

  onHideConfirmOrder = () => this.setState({ showConfirmOrder: false });

  onShowConfirmOrder = () => this.setState({ showConfirmOrder: true });

  onCheckOut = async (orderDetails = {}) => {
    this.setState({ isSendingOrderToShop: true });
    if (!orderDetails.paymentIntentID) return;
    const { cart } = this.props.context;
    this.onSubmitOrderToShop({
      ...orderDetails,
      orderItems: cart,
      status: "active",
      uuid: LocalStorage.getItemFromStorage("uuid"),
    });
  };

  onSendOrderToShop = async (orderDetails) => {
    const { shopBasicInfo = {}, shopID } = this.props.context;
    const { phoneNumbersToReceiveOrders: phone_numbers = {} } = shopBasicInfo;
    if (Object.keys(phone_numbers).length === 0) return;
    return await SendSingleTextMessageToMultipleNumbers({
      body: FoodMenuFuncs.GetTextDescriptionForOrder({
        name: shopBasicInfo.name,
        orderDetails,
        shopID,
        timeZone: shopBasicInfo.timeZone,
      }),
      phoneNumbers: phone_numbers,
    });
  };

  onSubmitOrderToShop = async (orderDetails = {}) => {
    const { onChangeCustomerCart, shopBasicInfo, shopID } = this.props.context;
    const { orderDeliveryTypeID } = orderDetails;
    const { address, name: shop_name } = shopBasicInfo;
    const orderID = _generateOrderID();
    if (orderDeliveryTypeID === "deliver") {
      const updatedOrderDetails = await _createDeliveryRequest({
        orderDetails,
        orderID,
        shopBasicInfo,
        shopID,
      });
      orderDetails = updatedOrderDetails;
    }
    const { success } = await AddOrderToShopActiveOrders({
      orderDetails,
      orderID,
      shopID,
    });
    if (!success) {
      this.setState({ isOrderFailed: true });
      return;
    }
    const uuid = LocalStorage.getItemFromStorage("uuid");
    await this.onSendOrderToShop(orderDetails);
    await this.onAddOrderToCustomerPastOrders({ orderDetails, orderID });
    const { phoneNumber } = orderDetails;
    await SaveCustomerPhoneNumbers({
      phoneNumbers: [phoneNumber || ""],
      uuid,
    });
    await ClearCustomerCart({ shopID, uuid });
    await _sendTrackingLinkToGuest({
      order_id: orderID,
      phone_number: phoneNumber,
      shop_address: address,
      shop_id: shopID,
      shop_name,
    });
    this.setState(
      {
        isSendingOrderToShop: false,
        isOrderSubmitted: true,
        submittedOrderDeliveryTypeID: orderDeliveryTypeID,
      },
      () => onChangeCustomerCart({})
    );
  };

  renderCartContent = () => {
    const { cart = {}, shopBasicInfo = {} } = this.props.context;
    const { address, name: shop_name, salesTax = 0 } = shopBasicInfo;
    const {
      totalOrderPriceBeforeTax: sub_total,
      totalOrderPriceBeforeTaxWithoutSale,
    } = _calcTotalOrderPriceBeforeTax({ cart });
    if (this.state.showConfirmOrder)
      return (
        <ConfirmOrder
          onCloseModal={this.onHideConfirmOrder}
          onSubmitOrder={this.onCheckOut}
          salesTax={parseFloat(salesTax)}
          stripe={this.state.stripe}
          subTotal={sub_total}
        />
      );
    return (
      <div>
        <div>
          <h2 className={Style.shop_name}>{shop_name}</h2>
          <div className={Style.shop_address}>
            <LocationDarkIcon />
            {address}
          </div>
        </div>
        <Button
          className={Style.checkOutButton}
          name="check out"
          onClick={this.onShowConfirmOrder}
        >
          <div>Checkout</div>
          <div>${Number.parseFloat(sub_total).toFixed(2)}</div>
        </Button>
        <Button
          className={Style.add_more_items}
          name="add more items"
          onClick={() => this.props.context.onChangeGlobalNavItem("")}
        >
          <div className={Style.plus_icon}>
            <PlusIcon />
          </div>
          <div>Add more items</div>
        </Button>
        <ReviewOrder
          totalPriceBeforeTax={sub_total}
          totalOrderPriceBeforeTaxWithoutSale={
            totalOrderPriceBeforeTaxWithoutSale
          }
        />
        {/* <div style={{ marginTop: "2rem" }}>
          {this.renderCheckoutButton()}
          {this.renderViewPastOrdersButton()}
        </div> */}
      </div>
    );
  };

  renderConfirmOrder = (subTotal) => {
    const { context } = this.props;
    const { shopBasicInfo = {} } = context;
    const { salesTax = 0 } = shopBasicInfo;
    return (
      <ConfirmOrder
        onCloseModal={this.onHideConfirmOrder}
        onSubmitOrder={this.onCheckOut}
        salesTax={parseFloat(salesTax)}
        stripe={this.state.stripe}
        subTotal={subTotal}
      />
    );
  };

  renderPageContent = () => {
    const { context } = this.props;
    const { cart = {} } = context;
    if (this.state.isOrderSubmitted)
      return _createSubmittedOrderMessage({
        pastOrdersButton: this.renderViewPastOrdersButton(),
        postmatesTrackingURL: this.state.postmatesTrackingURL,
        submittedOrderDeliveryTypeID: this.state.submittedOrderDeliveryTypeID,
      });
    else if (this.state.isSendingOrderToShop)
      return (
        <PageMsg>
          <LoadingSpinner message="Sending your order to staff" />
        </PageMsg>
      );
    else if (this.state.isOrderFailed) return _createFailedOrderMessage();
    else if (Object.keys(cart).length === 0 || !cart)
      return _createEmptyCartMessage({
        pastOrdersButton: this.renderViewPastOrdersButton(),
        viewMenuButton: this.renderViewMenuButton(),
      });
    return this.renderCartContent();
  };

  renderViewMenuButton = () => {
    const button = (
      <Button
        className={Style.viewMenuButton}
        name="view menu"
        onClick={() => this.props.context.onChangeGlobalNavItem("foodMenu")}
      >
        Start Shopping
      </Button>
    );
    return (
      <React.Fragment>
        <ScreenSize deviceType="mobile">{button}</ScreenSize>
        <ScreenSize deviceType="tablet">{button}</ScreenSize>
      </React.Fragment>
    );
  };

  renderViewPastOrdersButton = () => (
    <Button
      className={Style.viewPastOrdersButton}
      color="white"
      name="view past orders"
      onClick={this.props.context.onShowUserProfileModal}
    >
      <div className={Style.orders_icon}>
        <OrdersIcon />
      </div>
      <div>View Past Orders</div>
    </Button>
  );

  render() {
    const { context = {} } = this.props;
    const { todayHours = {} } = context;
    const { canShopAcceptOrders = true, isShopClosed = false } = todayHours;
    let pageContent;
    if (isShopClosed || !canShopAcceptOrders) {
      const message = isShopClosed
        ? "Shop is currently closed"
        : "Shop not accepting orders";
      pageContent = (
        <PageMsg>
          <div className={Style.bagIcon}>
            <CloseSignIcon />
          </div>
          <p style={{ fontSize: "1.1rem" }}>
            <strong>Our apology!</strong>
            <br />
            {message}
          </p>
        </PageMsg>
      );
    } else {
      pageContent = this.renderPageContent();
    }
    const { showConfirmOrder } = this.state;
    return (
      <ResponsiveModal
        classNames={{ modal: Style.modal }}
        contentLabel="customer cart modal"
        headerProps={{
          header: showConfirmOrder ? "Express Check Out" : "Your Order",
          show_back_button: true,
          show_close_button: false,
        }}
        onCloseModal={() =>
          showConfirmOrder
            ? this.setState({ showConfirmOrder: false })
            : this.props.context.onChangeGlobalNavItem("")
        }
      >
        {pageContent}
      </ResponsiveModal>
    );
  }
}

Cart.propTypes = {
  context: PropTypes.shape({
    cart: PropTypes.object.isRequired,
    customerProfile: PropTypes.object.isRequired,
    onChangeCustomerCart: PropTypes.func.isRequired,
    onChangeCustomerProfile: PropTypes.func.isRequired,
    onChangeGlobalNavItem: PropTypes.func.isRequired,
    onShowUserProfileModal: PropTypes.func.isRequired,
    shopBasicInfo: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
    todayHours: PropTypes.shape({
      isShopClosed: PropTypes.bool,
      shopTodayOpenHours: PropTypes.object,
      today: PropTypes.oneOf([
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ]),
    }).isRequired,
  }).isRequired,
};

export default withContext(CustomerInterfaceConsumer)(Cart);
