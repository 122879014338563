// BUTI DINERS, INC. All right Reserved ©

import {
  CONTACT_INFO_INPUT_FIELDS,
  OPTIONAL_INFO_INPUT_FIELDS,
} from "./constants";

const _getInputFieldsForScreen = (screenId) => {
  switch (screenId) {
    case "contactInfo":
      return CONTACT_INFO_INPUT_FIELDS;
    default:
      return {};
  }
};

const _areInputValuesValid = ({ fieldValues = {}, screenId }) => {
  const inputFields = _getInputFieldsForScreen(screenId);
  return Object.keys(inputFields).reduce((result, fieldId) => {
    const value = fieldValues[fieldId];
    const { regEx, required = false } = inputFields[fieldId];
    if (!required) return result;
    else if (required && !value) return false;
    else if (regEx && !regEx.test(value)) return false;
    return result;
  }, true);
};

const _convertFieldValuesToEmail = ({
  companyName = "",
  inputFields,
  fieldValues = {},
}) => {
  return {
    email_body: `Great News!\r\n\r\nA merchant is interested in our service. See below for more details.\r\n\r\n${_convertValuesToText(
      { fieldValues, inputFields }
    )}`,
    email_subject: `${companyName} is interested in Skipli!`,
  };
};

const _convertValuesToText = ({ fieldValues = {}, inputFields = {} }) =>
  Object.keys(inputFields).reduce((result, fieldId) => {
    const fieldValue = fieldValues[fieldId] || "";
    const { label: fieldLabel, options, type } = inputFields[fieldId] || "";
    let value = "";
    if (type === "checkbox") {
      const arr = (fieldValue || []).map((optionId) => {
        const { label } = options[optionId];
        return label;
      });
      value = `${fieldLabel}: ${arr.join(", ")}\r\n\r\n`;
    } else if (type === "radio") {
      const { label: optionLabel } = options[fieldValue];
      value = `${fieldLabel}: ${optionLabel}\r\n\r\n`;
    } else {
      value = `${fieldLabel}: ${fieldValue}\r\n\r\n`;
    }
    return result.concat(value);
  }, "");

const _createEmailForGuest = () =>
  `Hello,\r\n\r\nThanks for contacting Skipli! A member of our team will be in touch with you shortly.\r\n\r\nAt Skipli, we empower restaurants to own their presence, profits, and relationships directly through their website. We connect restaurants with their guests and provide tools that drive high-margin revenue online such as online ordering, google front page placement, customer data collection and more.\r\n\r\nOur business hours are Monday - Friday 9:30 am - 6:30 pm EST. If you’re inquiring about our services over the weekend, hang tight — someone from our team will be in touch as soon as possible.\r\n\r\nMeanwhile, click on any links below to see how some of our notable clients are using our services to drive online traffic & earn thousands of dollar per month:\r\n\r\nwww.skiplinow.com/website-examples\r\n\r\nwww.sangosushica.com\r\nwww.phodaiviet.com\r\nwww.iluvphomallofga.com\r\nwww.nydelibagelcafe.com\r\nwww.pho-asia.com\r\nwww.eviekitchen.com\r\nwww.gongchatea.com\r\nwww.9milepho.com\r\n\r\nThanks!\r\n\r\nTeam Skipli\r\n\r\nYou're receiving this email because you filled out a contact form on the Skipli website.`;

export {
  CONTACT_INFO_INPUT_FIELDS,
  OPTIONAL_INFO_INPUT_FIELDS,
  _areInputValuesValid,
  _convertFieldValuesToEmail,
  _convertValuesToText,
  _createEmailForGuest,
};
