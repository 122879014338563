// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { set } from "object-path-immutable";

import Form from "./Form";
import {
  CONTACT_INFO_INPUT_FIELDS,
  OPTIONAL_INFO_INPUT_FIELDS,
} from "./constants";
import {
  _areInputValuesValid,
  _convertValuesToText,
  _createEmailForGuest,
} from "./HelperFunctions";

// Style
import Style from "./style.module.scss";

// Fields
import { Box, Button, Link } from "fields";

// Lib
import { ShowConfirmNotif } from "lib/functions";
import { BUTI } from "lib/services";
const { SendEmail } = BUTI.PostRequests;

class GetStartedForm extends React.Component {
  state = { form: {} };

  onChangeForm = ({ fieldId, fieldValue, screenId }) =>
    this.setState({
      form: set(this.state.form, `${screenId}.${fieldId}`, fieldValue),
    });

  onChangeScreenId = () => {
    switch (this.props.screenId) {
      case "contactInfo":
        this.props.onChangeScreenId("optionalInfo");
        return;
      case "optionalInfo":
        this.props.onChangeScreenId("final");
        return;
      default:
        return;
    }
  };

  onGetSubmitButtonStatus = () => {
    const { screenId } = this.props;
    const { isSubmittingInfo } = this.state;
    if (isSubmittingInfo) return "loading";
    return _areInputValuesValid({
      fieldValues: this.state.form[screenId],
      screenId,
    })
      ? "active"
      : "inactive";
  };

  onSubmitInfo = async () => {
    this.setState({ isSubmittingInfo: true });
    const companyName = _get(this.state.form, "contactInfo.company_name");
    const email = _get(this.state.form, "contactInfo.email");
    const contactInfoToText = _convertValuesToText({
      fieldValues: this.state.form["contactInfo"],
      inputFields: CONTACT_INFO_INPUT_FIELDS,
    });
    const optionalInfoToText = _convertValuesToText({
      fieldValues: this.state.form["optionalInfo"],
      inputFields: OPTIONAL_INFO_INPUT_FIELDS,
    });
    try {
      await SendEmail({
        addresses: ["sales@skiplinow.com", "john@skiplinow.com"],
        body: `Great News!\r\n\r\nA merchant is interested in our service. See below for more details.\r\n\r\n${contactInfoToText}${optionalInfoToText}`,
        subject: `${companyName} is interested in Skipli!`,
      });
      await SendEmail({
        addresses: [email],
        body: _createEmailForGuest(),
        subject: "Thanks for contacting Skipli!",
      });
      this.onChangeScreenId();
    } catch {
      this.confirmNotif = ShowConfirmNotif({
        message: "We could not send your request. Please try again!",
        type: "error",
      });
    } finally {
      this.setState({ isSubmittingInfo: false });
    }
  };

  renderForm = () => {
    const { screenId } = this.props;
    const { form = {} } = this.state;
    return (
      <Form
        fields={
          screenId === "contactInfo"
            ? CONTACT_INFO_INPUT_FIELDS
            : OPTIONAL_INFO_INPUT_FIELDS
        }
        fieldValues={form[screenId] || {}}
        onChangeForm={({ fieldId, fieldValue }) =>
          this.onChangeForm({
            fieldId,
            fieldValue,
            screenId,
          })
        }
      />
    );
  };

  render() {
    const { screenId } = this.props;
    return (
      <Box className={Style.box}>
        <div>{this.renderForm()}</div>
        <Button
          className={Style.submitButton}
          color="skipli_red"
          name="submit"
          onClick={this.onSubmitInfo}
          status={this.onGetSubmitButtonStatus()}
        >
          {screenId === "contactInfo" ? "Get my free demo" : "Submit"}
        </Button>
        {screenId === "contactInfo" && (
          <React.Fragment>
            <p className={Style.privacy_notice}>
              By completing the form and submitting your information above, you
              are agreeing to our <Link href="/privacy">Privacy Policy</Link>.
            </p>
            <p className={Style.call_us}>
              Or Call Us at{" "}
              <Link
                className={Style.phone_number}
                href="tel:4156109521"
                target="_self"
              >
                (415) 610-9521
              </Link>
            </p>
          </React.Fragment>
        )}
      </Box>
    );
  }
}

GetStartedForm.propTypes = {
  onChangeScreenId: PropTypes.func.isRequired,
  screenId: PropTypes.oneOf(["contactInfo", "optionalInfo", "final"])
    .isRequired,
};

GetStartedForm.defaultProps = {};

export default GetStartedForm;
