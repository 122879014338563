// BUTI DINERS, INC. All right Reserved ©

import { set } from "object-path-immutable";
import _orderBy from "lodash.orderby";
import Math from "./Math";
import dayjs from "dayjs";

// Lib
import { DEFAULT_TIMEZONE, TIME_FORMAT } from "lib/_constants";
import { Merchants, MERCHANT_DASHBOARD_URL } from "lib/services";

const { _roundNumber } = Math;

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

// -------------------------------------------------------------------
// Calculate the before tax total unit price for an item

const CalcTotalUnitPriceBeforeTaxForItem = ({ detailsOfItemInCart = {} }) => {
  const { itemIsOnSale = false, itemSaleRate = 0 } = detailsOfItemInCart;
  const k = CalcTotalPriceForModifierGroups(detailsOfItemInCart.modifierGroups);
  const { itemSimpleDescription } = detailsOfItemInCart;
  const itemPrice = parseFloat(itemSimpleDescription.itemPrice || 0);
  let totalUnitPriceBeforeTax = itemPrice + k;
  const totalUnitPriceBeforeTaxWithoutSale = totalUnitPriceBeforeTax;
  if (itemIsOnSale) {
    totalUnitPriceBeforeTax =
      totalUnitPriceBeforeTax * (1 - parseFloat(itemSaleRate) / 100);
  }
  return {
    totalUnitPriceBeforeTaxWithoutSale,
    totalUnitPriceBeforeTax: _roundNumber(totalUnitPriceBeforeTax),
  };
};

const CalcTotalPriceBeforeTaxForItem = ({ detailsOfItemInCart = {} }) => {
  const {
    totalUnitPriceBeforeTax,
    totalUnitPriceBeforeTaxWithoutSale,
  } = CalcTotalUnitPriceBeforeTaxForItem({
    detailsOfItemInCart,
  });
  return {
    totalPriceBeforeTaxWithoutSale: _roundNumber(
      totalUnitPriceBeforeTaxWithoutSale * detailsOfItemInCart.quantity
    ),
    totalPriceBeforeTax: _roundNumber(
      totalUnitPriceBeforeTax * detailsOfItemInCart.quantity
    ),
  };
};

const CalcTotalPriceForModifierGroups = (modifierGroups = {}) => {
  const total = Object.keys(modifierGroups).reduce((sum, modifierGroupID) => {
    const { modifiers } = modifierGroups[modifierGroupID];
    return sum + CalcTotalPriceForModifiers(modifiers);
  }, 0);
  return parseFloat(total);
};

const CalcTotalPriceForModifiers = (modifiers = {}) => {
  const total = Object.keys(modifiers).reduce((sum, modifierID) => {
    const { modifierPrice } = modifiers[modifierID];
    return sum + parseFloat(modifierPrice || 0);
  }, 0);
  return parseFloat(total);
};

// -------------------------------------------------------------------
// Get the information from the database for a list of groups using
// their IDs

const GetInformationForGroups = async ({ groups = {}, shopID = "" }) => {
  if (!shopID) return {};
  const { GetMenuGroupInformation } = Merchants.GetRequests;
  return await Object.keys(groups).reduce(async (previousPromise, groupID) => {
    const result = await previousPromise;
    const { groupInfo } = await GetMenuGroupInformation({ groupID, shopID });
    return groupInfo ? set(result, groupID, groupInfo) : result;
  }, Promise.resolve({}));
};

// -------------------------------------------------------------------
// Get the information from the database for a list of items using
// their IDs

const GetInformationForItems = async ({ items = {}, shopID = "" }) => {
  if (!shopID) return {};
  const { GetMenuItemInformation } = Merchants.GetRequests;
  return await Object.keys(items).reduce(async (previousPromise, itemID) => {
    const result = await previousPromise;
    const { itemInfo } = await GetMenuItemInformation({ itemID, shopID });
    return itemInfo ? set(result, itemID, itemInfo) : result;
  }, Promise.resolve({}));
};

// -------------------------------------------------------------------
// Get the information from the database for a list of modifiers using
// their IDs

const GetInformationForModifiers = async ({ modifiers = {}, shopID = "" }) => {
  if (!shopID) return {};
  const { GetMenuModifierInformation } = Merchants.GetRequests;
  return await Object.keys(modifiers).reduce(
    async (previousPromise, modifierID) => {
      const result = await previousPromise;
      const params = { modifierID, shopID };
      const { modifierInfo } = await GetMenuModifierInformation(params);
      return modifierInfo ? set(result, modifierID, modifierInfo) : result;
    },
    Promise.resolve({})
  );
};

// -------------------------------------------------------------------
// Get the information from the database for a list of modifier groups using
// their IDs

const GetInformationForModifierGroups = async ({
  modifierGroups = {},
  shopID = "",
}) => {
  if (!shopID) return {};
  const { GetMenuModifierGroupInformation } = Merchants.GetRequests;
  return await Object.keys(modifierGroups).reduce(
    async (previousPromise, modifierGroupID) => {
      const result = await previousPromise;
      const params = { modifierGroupID, shopID };
      const { modifierGroupInfo } = await GetMenuModifierGroupInformation(
        params
      );
      return modifierGroupInfo
        ? set(result, modifierGroupID, modifierGroupInfo)
        : result;
    },
    Promise.resolve({})
  );
};

// -------------------------------------------------------------------
// Get the text description for an item so we have a written record of
// the item in case it gets deleted by the merchants

const GetTextDescriptionForOrder = ({
  name,
  orderDetails = {},
  shopID,
  timeZone,
}) => {
  const {
    customerName = "",
    orderDeliveryTypeID = "",
    timeStamp,
  } = orderDetails;
  const received_time = dayjs(timeStamp)
    .tz(timeZone || DEFAULT_TIMEZONE)
    .format(TIME_FORMAT);
  // const itemsDescription = GetTextDescriptionForOrderItems({
  //   orderItems: orderDetails.orderItems
  // });
  return `${
    orderDeliveryTypeID === "deliver" ? "*" : ""
  }[${name}] A new SKIPLI order for ${customerName} at ${received_time}${
    !timeZone ? " EST" : ""
  }. Check the SKIPLI tablet. Or click the link below: ${MERCHANT_DASHBOARD_URL}/${shopID}`;
};

// const GetTextDescriptionForOrderItems = ({ orderItems = {} }) =>
//   Object.values(orderItems).reduce((result, item) => {
//     const { customerInstruction, itemSimpleDescription } = item;
//     const { itemName } = itemSimpleDescription;
//     result = result.concat(`\n${item.quantity} - ${itemName}`);
//     if (customerInstruction) result = result.concat(`\n${customerInstruction}`);
//     return result;
//   }, "");

// -------------------------------------------------------------------
// Item Details

// const getSpecialPrice = (itemSpecialPrices = [], currentTimestamp) => {
//   const specialPricesApplicable = itemSpecialPrices.map(
//     ({ endTime, measure, specialPrice, startTime, startDate, units }) => {
//       // Get today's UTC date and convert it to ISO 8601 format
//       const todayDateTime = currentTimestamp.split("T");
//       const todayDate = todayDateTime[0];
//       const currentTime = todayDateTime[1];

//       // Create a recurrence rule
//       const recur = everydate({
//         start: startDate,
//         units: units,
//         measure: measure
//       });
//       // Check if today falls in the recurrence rule
//       const isTodayMatched = recur.match(todayDate);
//       // If today is not matched, return false, else check if the time matches
//       if (!isTodayMatched) return false;
//       if (startTime && currentTime < startTime) return false;
//       if (endTime && currentTime > endTime) return false;
//       return specialPrice;
//     }
//   );
//   const resultArray = specialPricesApplicable.filter(price => Boolean(price));
//   return resultArray.length > 0 ? resultArray[0] : "";
// };

const rankSectionOptions = (options = [], rankById = "") => {
  if (options.length === 0 || !rankById) {
    return options;
  }
  options.sort((a, b) => {
    const val1 = parseFloat(a[rankById]) || 0;
    const val2 = parseFloat(b[rankById]) || 0;
    return val2 - val1;
  });
  return options;
};

// -------------------------------------------------------------------
// Sort categories by name in alphabetical order by default
// Then sort the categories by its index in list
// Return an array of sorted group IDs

const _sortMenuCategories = (categories = {}) =>
  _orderBy(
    Object.keys(categories),
    [
      (categoryId) => categories[categoryId]["index_in_list"] || -10000000,
      (categoryId) => categories[categoryId]["groupName"] || "",
    ],
    ["asc", "asc"]
  );

const FoodMenuFuncs = {
  CalcTotalPriceBeforeTaxForItem,
  CalcTotalUnitPriceBeforeTaxForItem,
  GetInformationForGroups,
  GetInformationForItems,
  GetInformationForModifiers,
  GetInformationForModifierGroups,
  GetTextDescriptionForOrder,
  rankSectionOptions,
  _sortMenuCategories,
};

export default FoodMenuFuncs;
