// BUTI DINERS, INC. All right Reserved ©

import API_PATHS from "../API_Paths";
import axios from "axios";
import { set } from "object-path-immutable";
import _isEmpty from "lodash.isempty";

// -----------------------------------------------------------------------
// Move an order to the customer's past orders

const AddOrderToCustomerPastOrders = (params) =>
  new Promise((resolve, reject) => {
    const { orderID, orderPreviewDetails, shopID, uuid } = params;
    if (!orderPreviewDetails || !orderID || !shopID || !uuid)
      return reject(
        "(AddOrderToCustomerPastOrders) Parameters are not sufficient."
      );
    axios
      .post(API_PATHS.ADD_ORDER_TO_CUSTOMER_PAST_ORDERS, params)
      .then(() => resolve({ success: true }))
      .catch(reject);
  });

// -----------------------------------------------------------------------
// Move an order from pending orders to active orders

const AddOrderToShopActiveOrders = (params) =>
  new Promise((resolve, reject) => {
    const { orderDetails, orderID, shopID } = params;
    if (!Object.keys(orderDetails).length === 0 || !orderID || !shopID)
      return reject(
        "(AddOrderToShopActiveOrders) Parameters are not sufficient."
      );
    axios
      .post(API_PATHS.ADD_ORDER_TO_SHOP_ACTIVE_ORDERS, params)
      .then(() => resolve({ success: true }))
      .catch(({ response = {} }) =>
        resolve({
          error: response.data || "Failed to add order to active orders.",
          success: false,
        })
      );
  });

// -----------------------------------------------------------------------
// Attach the payment method to the customer

const AttachPaymentMethodToCustomer = (params) =>
  new Promise((resolve, reject) => {
    if (!params.paymentMethodID || !params.stripeCustomerID)
      return reject(
        "(AttachPaymentMethodToCustomer) Parameters are not sufficient."
      );
    axios
      .post(API_PATHS.ATTACH_PAYMENT_METHOD_TO_CUSTOMER, params)
      .then(() => resolve({ success: true }))
      .catch(({ response = {} }) =>
        resolve({
          error: response.data || "Failed to add method.",
          success: false,
        })
      );
  });

// -----------------------------------------------------------------------
// Set the customer's exiting cart in the database with the new cart

const ChangeCustomerCart = (params) =>
  new Promise((resolve, reject) => {
    const { shopID, uuid } = params;
    if (!shopID || !uuid)
      return reject("(ChangeCustomerCart) Parameters are not sufficient.");
    axios
      .post(API_PATHS.CHANGE_CUSTOMER_CART, params)
      .then(() => resolve({ success: true }))
      .catch(() => resolve({ success: false }));
  });

// -----------------------------------------------------------------------
// Update the customer's profile

const ChangeCustomerProfile = (params) =>
  new Promise((resolve, reject) => {
    const { customerProfile, uuid } = params;
    if (Object.keys(customerProfile).length === 0 || !uuid)
      return reject("(ChangeCustomerProfile) Parameters are not sufficient.");
    axios
      .post(API_PATHS.CHANGE_CUSTOMER_PROFILE, params)
      .then(() => resolve({ success: true }))
      .catch((error) => resolve({ error, success: false }));
  });

// ----------------------------------------------------------------------
// Clear the customer's cart at a particular shop

const ClearCustomerCart = (params) =>
  new Promise((resolve, reject) => {
    const { shopID, uuid } = params;
    if (!shopID || !uuid)
      return reject("(ClearCustomerCart) Parameters are not sufficient.");
    axios
      .post(API_PATHS.CLEAR_CUSTOMER_CART, params)
      .then(resolve)
      .catch(reject);
  });

// ----------------------------------------------------------------------
// Create a Doordash delivery

const CreateDoordashDelivery = (params) =>
  new Promise((resolve, reject) => {
    if (
      _isEmpty(params.pickup_address) ||
      _isEmpty(params.dropoff_address) ||
      _isEmpty(params.customer) ||
      _isEmpty(params.items) ||
      isNaN(parseInt(params.order_value)) ||
      parseInt(params.order_value) <= 0 ||
      !params.pickup_phone_number ||
      !params.external_business_name ||
      !params.external_store_id ||
      !params.external_delivery_id ||
      !params.pickup_business_name
    )
      return reject("(CreateDoordashDelivery) Parameters are not sufficient.");
    axios
      .post(API_PATHS.CREATE_DOORDASH_DELIVERY, params)
      .then(({ data }) => resolve({ doordashDelivery: data }))
      .catch(reject);
  });

// ----------------------------------------------------------------------
// Create a Postmates delivery

const CreatePostmatesDelivery = (params) =>
  new Promise((resolve, reject) => {
    if (
      !params.dropoff_address ||
      !params.dropoff_name ||
      !params.dropoff_phone_number ||
      !params.manifest ||
      !params.manifest_items ||
      !params.pickup_address ||
      !params.pickup_name ||
      !params.pickup_phone_number ||
      !params.quote_id
    )
      return reject("(CreatePostmatesDelivery) Parameters are not sufficient.");
    axios
      .post(API_PATHS.CREATE_POSTMATES_DELIVERY, params)
      .then(({ data }) => resolve({ postmatesDelivery: data }))
      .catch(reject);
  });

// ----------------------------------------------------------------------
// Create a Postmates delivery quote

const CreatePostmatesQuote = (params) =>
  new Promise((resolve, reject) => {
    const { dropoffAddress, pickupAddress } = params;
    if (!dropoffAddress || !pickupAddress)
      return reject("(CreatePostmatesQuote) Parameters are not sufficient.");
    axios
      .post(API_PATHS.CREATE_POSTMATES_QUOTE, params)
      .then(({ data }) => resolve({ postmatesQuote: data }))
      .catch(reject);
  });

// ----------------------------------------------------------------------
// Create a stripe customer id for a BUTI Diners customer
const CreateStripeCustomerID = (params) =>
  new Promise((resolve, reject) => {
    if (!params.uuid)
      return reject("(CreateStripeCustomerID). Parameters are not sufficient.");
    axios
      .post(API_PATHS.CREATE_STRIPE_CUSTOMER_ID, params)
      .then(({ data }) =>
        resolve({
          stripeCustomerID: data.stripeCustomerID || "",
          success: true,
        })
      )
      .catch(() => resolve({ success: false }));
  });

// ----------------------------------------------------------------------
// Remove a payment method from the customer
const DetachPaymentMethodFromCustomer = (params) =>
  new Promise((resolve, reject) => {
    if (!params.paymentMethodID)
      return reject(
        "(DetachPaymentMethodFromCustomer). Parameters are not sufficient."
      );
    axios
      .post(API_PATHS.DETACH_PAYMENT_METHOD_FROM_CUSTOMER, params)
      .then(() => resolve({ success: true }))
      .catch(() => resolve({ success: false }));
  });

// ---------------------------------------------------------------
// Get the delivery estimate from Doordash
const DoordashDeliveryEstimate = (params = {}) =>
  new Promise((resolve, reject) => {
    if (
      _isEmpty(params.dropoff_address) ||
      !params.external_business_name ||
      !params.external_store_id ||
      _isEmpty(params.pickup_address) ||
      isNaN(parseInt(params.order_value)) ||
      parseInt(params.order_value) <= 0
    ) {
      return reject(
        "(DoordashDeliveryEstimate) Parameters are not sufficient."
      );
    }
    axios
      .post(API_PATHS.DOORDASH_DELIVERY_ESTIMATE, params)
      .then(({ data = {} }) => resolve(data))
      .catch(reject);
  });

// -----------------------------------------------------------------------
// Start the Stripe checkout process for the customer

const InitializeStripeCheckout = (params) =>
  new Promise((resolve, reject) => {
    const { cancel_url, line_items, payment_intent_data, success_url } = params;
    if (!cancel_url || !line_items || !payment_intent_data || !success_url)
      return reject(
        "(InitializeStripeCheckout) Parameters are not sufficient."
      );
    // Currently, card is the only supported payment method type; see https://stripe.com/docs/api/checkout/sessions/create
    const finalParams = set(params, "payment_method_types", ["card"]);
    axios
      .post(API_PATHS.INITIALIZE_STRIPE_CHECKOUT, finalParams)
      .then(({ data }) => {
        resolve({ stripeSessionID: data.stripeSessionID || "" });
      })
      .catch(reject);
  });

// -----------------------------------------------------------------------
// Save multiple phone numbers to customer's profile

const SaveCustomerPhoneNumbers = (params) =>
  new Promise((resolve, reject) => {
    const { phoneNumbers = [] } = params;
    if (!phoneNumbers.length === 0) {
      const msg = "(SaveCustomerPhoneNumbers) Parameters are not sufficient.";
      return reject(msg);
    }
    axios
      .post(API_PATHS.SAVE_CUSTOMER_PHONE_NUMBERS, params)
      .then(() => resolve({ success: true }))
      .catch(() => resolve({ success: false }));
  });

// -----------------------------------------------------------------------
// Save the customer's survey for a specific restaurant

const SaveCustomerSurvey = (params) =>
  new Promise((resolve, reject) => {
    if (
      !params.feedback_text ||
      !params.selected_rating_id ||
      !params.shop_id
    ) {
      const msg = "(SaveCustomerSurvey) Parameters are not sufficient.";
      return reject(msg);
    }
    axios
      .post(API_PATHS.SAVE_CUSTOMER_SURVEY, params)
      .then(() => resolve({ success: true }))
      .catch(() => resolve({ success: false }));
  });

const Customers_Post_Requests = {
  AddOrderToCustomerPastOrders,
  AddOrderToShopActiveOrders,
  AttachPaymentMethodToCustomer,
  ChangeCustomerCart,
  ChangeCustomerProfile,
  ClearCustomerCart,
  CreateDoordashDelivery,
  CreatePostmatesDelivery,
  CreatePostmatesQuote,
  CreateStripeCustomerID,
  DetachPaymentMethodFromCustomer,
  DoordashDeliveryEstimate,
  InitializeStripeCheckout,
  SaveCustomerPhoneNumbers,
  SaveCustomerSurvey,
};

export default Customers_Post_Requests;
