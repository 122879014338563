// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

import MerchantDashboard from "assets/Images/turnWhatYouLove.png";

class TurnWhatYouLove extends React.Component {
  render() {
    return (
      <div className={Style.container}>
        <div className={CommonStyle.container}>
          <div className={Style.contentContainer}>
            <h2>Turn what you love into what you sell</h2>
            <p>
              All the features you need to start, run, and grow your business.
            </p>
            <Button
              className={Style.startSellingBtn}
              name="start selling now"
              onClick={() => this.props.history.push("/get-started")}
            >
              Start selling now
            </Button>
            <div>
              <img
                alt=""
                className={Style.merchantDashboardImg}
                src={MerchantDashboard}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TurnWhatYouLove.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(TurnWhatYouLove);
