// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Components
import { Modals } from "components";

// Fields
import { ComingSoonButton } from "fields";

// Assets
import { CloseSignIcon, ClockIcon } from "assets/Icons";

class NoServiceMessage extends React.Component {
  state = {};

  renderContent = () => {
    const { shop_membership_status, todayHours = {} } = this.props;
    const { isShopClosed = false, notInOpenHours = false } = todayHours;
    if (isShopClosed) return this.renderShopClosed();
    else if (shop_membership_status === "comingSoon")
      return (
        <div className={Style.notAcceptingOrder}>
          <ComingSoonButton className={Style.comingSoonButton} />
        </div>
      );
    else if (notInOpenHours)
      return (
        <div className={Style.notAcceptingOrder}>
          <div className={Style.icon}>
            <ClockIcon />
          </div>
          <div className={Style.text}>
            <h3>Today hours</h3>
            {this.renderOpenHours(todayHours)}
          </div>
        </div>
      );
    return (
      <div className={Style.notAcceptingOrder}>
        <div className={Style.icon}>
          <CloseSignIcon />
        </div>
        <div className={Style.text}>
          <h3>Shop can't accept order at the moment</h3>
          <div>Please check again later</div>
        </div>
      </div>
    );
  };

  renderOpenHours = ({ shopTodayExtraHours = {}, shopTodayOpenHours = {} }) => {
    const { localStartAt12Hr, localEndAt12Hr } = shopTodayOpenHours;
    return (
      <div>
        <div>
          {localStartAt12Hr} - {localEndAt12Hr}
        </div>
        {Object.keys(shopTodayExtraHours).map((hoursId) => {
          const {
            localStartAt12Hr: extraHourStart,
            localEndAt12Hr: extraHourEnd,
          } = shopTodayExtraHours[hoursId];
          return (
            <div className={Style.extraHours} key={hoursId}>
              {extraHourStart} - {extraHourEnd}
            </div>
          );
        })}
        {this.renderSeeOpenHoursButton()}
      </div>
    );
  };

  renderSeeOpenHoursButton = () => (
    <div
      className={Style.seeOpenHours}
      onClick={() => this.setState({ showShopInfo: true })}
    >
      See open hours
    </div>
  );

  renderShopClosed = () => (
    <div className={Style.notAcceptingOrder}>
      <div className={Style.icon}>
        <CloseSignIcon />
      </div>
      <div className={Style.text}>
        <h3>Shop closed today</h3>
        <div>Please check again later</div>
        {this.renderSeeOpenHoursButton()}
      </div>
    </div>
  );

  render() {
    const { shopBasicInfo } = this.props.context;
    return (
      <React.Fragment>
        {this.state.showShopInfo && (
          <Modals.AboutShop
            onCloseModal={() => this.setState({ showShopInfo: false })}
            shop_basic_info={shopBasicInfo}
            today_hours={this.props.todayHours}
          />
        )}
        {this.renderContent()}
      </React.Fragment>
    );
  }
}

NoServiceMessage.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
  }).isRequired,
  shop_membership_status: PropTypes.oneOf(["active", "comingSoon", "inactive"]),
  todayHours: PropTypes.shape({
    canShopAcceptOrders: PropTypes.bool,
    isShopClosed: PropTypes.bool,
    notInOpenHours: PropTypes.bool,
    shopTodayExtraHours: PropTypes.object,
    shopTodayOpenHours: PropTypes.shape({
      localEndAt: PropTypes.string,
      localEndAt12Hr: PropTypes.string,
      localStartAt: PropTypes.string,
      localStartAt12Hr: PropTypes.string,
    }),
    today: PropTypes.oneOf([
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ]).isRequired,
  }).isRequired,
};

export default withContext(CustomerInterfaceConsumer)(NoServiceMessage);
