import React from "react";
import { ContentLoader, ScreenSize } from "fields";

const _createResponsiveArticleSkeleton = () => {
  const shops = Array(1).fill(1);
  return (
    <div>
      {shops.map((val, index) => (
        <div key={index} style={{ marginTop: "60px" }}>
          <ContentLoader.ResponsivePostDetail />
        </div>
      ))}
    </div>
  );
};
const _createArticleSkeleton = () => (
  <div>
    <ScreenSize deviceType="mobile">
      {_createResponsiveArticleSkeleton()}
    </ScreenSize>
    <ScreenSize deviceType="tablet">
      {_createResponsiveArticleSkeleton()}
    </ScreenSize>
    <ScreenSize deviceType="desktop">
      {_createResponsiveArticleSkeleton()}
    </ScreenSize>
  </div>
);

const _createSharePostEmailSubjectAndBody = ({ post_title, post_url }) => ({
  body: `I trust this message finds you well. Recently, I came across a captivating blog post by SKIPLI that I believe holds immense value, especially for individuals engaged in the Food & Beverage industry or those considering venturing into it.\n\nShould you be interested in enhancing your knowledge, strategies, and overall success within the realm of Food & Beverage, I wholeheartedly recommend giving this blog post a read. It has the potential to provide you with a fresh perspective and perhaps even spark innovative ideas for your business.\n\nFeel free to access the blog post here ${post_url}, and I genuinely hope you find it as informative and inspiring as I did.\n\nWishing you continued success and growth in your entrepreneurial journey.\n\nWarm regards`,
  subject: `Unlocking Success: A Must-Read Blog Post for Thriving in the Restaurant Industry - ${post_title}`,
});

export { _createArticleSkeleton, _createSharePostEmailSubjectAndBody };
