// BUTI Corp All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Assets
import { SkipliLogoCircleIcon } from "assets/Icons";

const ANIMATION_TYPES = ["spin", "zoom", "slide"];
const MESSAGE_POSITIONS = ["top", "right", "bottom", "left"];

const LoadingSpinner = (props) => (
  <div
    className={cx(Style.loadingSpinnerContainer, Style[props.messagePosition])}
  >
    <div className={cx(Style.message, props.messageClassname)}>
      {props.message}
    </div>
    <div className={Style.svgContainerWrapper}>
      <div className={cx(Style.svgContainer, Style[props.animationType])}>
        <SkipliLogoCircleIcon
          className={cx(Style.svgIcon, Style[props.animationType])}
        />
      </div>
    </div>
  </div>
);

LoadingSpinner.propTypes = {
  animationType: PropTypes.oneOf(ANIMATION_TYPES),
  message: PropTypes.string.isRequired,
  messagePosition: PropTypes.oneOf(MESSAGE_POSITIONS),
};

LoadingSpinner.defaultProps = {
  animationType: "zoom",
  messagePosition: "bottom",
};

export default LoadingSpinner;
