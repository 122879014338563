// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { ConfirmOrderDeliveryQuote } from "fields";

const DeliveryQuotes = (props) => {
  const { courierQuotes = {}, selectedCourier } = props;
  const quote = courierQuotes[selectedCourier] || {};
  if (props.isGettingDeliveryQuote)
    return (
      <div className={Style.loadingMsg}>Finding best delivery route...</div>
    );
  else if (Object.keys(quote).length === 0)
    return (
      <div className={Style.loadingMsg}>Please enter your address above</div>
    );
  return (
    <ConfirmOrderDeliveryQuote
      allowDelivery={props.allowDelivery}
      courier={props.selectedCourier}
      onChangeDeliveryType={props.onChangeDeliveryType}
      quote={quote}
    />
  );
};

DeliveryQuotes.propTypes = {
  allowDelivery: PropTypes.bool.isRequired,
  courierQuotes: PropTypes.shape({
    doordash: PropTypes.shape({
      currency: PropTypes.string,
      dropoffETA: PropTypes.string,
      id: PropTypes.number,
      localizedDropoffETA: PropTypes.string,
      localized_pickup_time: PropTypes.string,
      pickup_time: PropTypes.string,
      pickup_window_end_time: PropTypes.any,
      pickup_window_start_time: PropTypes.any,
      totalDeliveryFee: PropTypes.number,
    }),
    postmates: PropTypes.shape({
      errorMessage: PropTypes.string,
      localizedDropoffETA: PropTypes.string,
      totalDeliveryFee: PropTypes.number,
    }),
  }),
  isGettingDeliveryQuote: PropTypes.bool,
  onChangeDeliveryType: PropTypes.func,
  selectedCourier: PropTypes.oneOf(["doordash", "postmates"]),
};

DeliveryQuotes.defaultProps = {
  isGettingDeliveryQuote: false,
};

export default DeliveryQuotes;
