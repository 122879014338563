// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ContentLoader from "react-content-loader";

const DesktopShopLoader = (props) => (
  <ContentLoader
    {...props}
    height={300}
    width={1300}
    speed={2}
    backgroundColor="#E0E0E0"
    foregroundColor="#F2F2F2"
  >
    {/* First rect */}
    <rect x="0" y="0" rx="0" ry="0" width="400" height="200" />
    <rect x="0" y="215" rx="0" ry="0" width="400" height="30" />
    {/* Second rect */}
    <rect x="430" y="0" rx="0" ry="0" width="400" height="200" />
    <rect x="430" y="215" rx="0" ry="0" width="400" height="30" />
    {/* Second rect */}
    <rect x="860" y="0" rx="0" ry="0" width="400" height="200" />
    <rect x="860" y="215" rx="0" ry="0" width="400" height="30" />
  </ContentLoader>
);

export default DesktopShopLoader;
