// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

const Box = (props) => (
  <div className={cx(Style.container, props.className)}>{props.children}</div>
);

Box.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Box;
