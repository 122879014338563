// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

class SideMenu extends React.Component {
  renderNavigationOptions = () => {
    const { navigationOptions = {} } = this.props;
    return Object.keys(navigationOptions).map((optionId) => {
      const { label } = navigationOptions[optionId];
      return (
        <div
          className={Style.navOption}
          key={optionId}
          onClick={() => this.props.onClickOption(optionId)}
        >
          {label}
        </div>
      );
    });
  };

  render() {
    return (
      <div className={Style.container}>
        <div className={Style.content}>
          <div className={Style.navigationOptions}>
            {this.renderNavigationOptions()}
          </div>
        </div>
      </div>
    );
  }
}

SideMenu.propTypes = {
  navigationOptions: PropTypes.object.isRequired,
  onClickOption: PropTypes.func.isRequired,
};

export default SideMenu;
