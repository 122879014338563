// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Link } from "fields";

// Assets
import { ArrowIcon } from "assets/Icons";

const SkipliFooter = () => (
  <div className={Style.container}>
    <div className={Style.content_container}>
      <Link className={Style.skipli_link} href="https://skiplinow.com">
        © Built & Powered By SKIPLI{" "}
      </Link>
      <Button
        className={Style.learn_more_button}
        name="learn more about SKIPLI"
        onClick={() => window.open("https://skiplinow.com", "_blank")}
      >
        Get A Free Demo <ArrowIcon />
      </Button>
    </div>
  </div>
);

export default SkipliFooter;
