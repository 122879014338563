// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { set } from "object-path-immutable";

// Utils
import { _scrollTo, _updatePageTitleAndDescription } from "utils";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Components
import { GetStartedModules, TrustedByLogosGallery } from "components";

// Fields
import { Link } from "fields";

class GetStarted extends React.Component {
  state = { screenId: "contactInfo" };

  componentDidMount() {
    _updatePageTitleAndDescription({
      description:
        "Book a free demo today. Drive more revenue with tools to help grow your restaurant business online.",
      title: "Get Started | Skipli",
    });
  }

  onChangeScreenId = (screenId) =>
    this.setState({ screenId }, () =>
      _scrollTo({ ref: this.refs["topOfGetStarted"] })
    );

  onGetHeadingAndParagraph = () => {
    switch (this.state.screenId) {
      case "contactInfo":
        return {
          heading:
            "Drive more revenue with tools to help grow your restaurant business online",
          paragraph: (
            <div>
              Book a free demo today
              <br />
              or call us at{" "}
              <Link
                className={Style.phone_number}
                href="tel:4156109521"
                target="_self"
              >
                (415) 610-9521
              </Link>
            </div>
          ),
        };
      case "optionalInfo":
        return {
          heading: "Thanks! A SKIPLI specialist will be in touch",
          paragraph:
            "Please answer the optional questions below so that we can better tailor our follow up",
        };
      default:
        return {
          heading: "Great!",
          paragraph:
            "The additional information was sent to your SKIPLI specialist. We’re looking forward to speaking with you!",
        };
    }
  };

  render() {
    const { screenId } = this.state;
    const { heading, paragraph } = this.onGetHeadingAndParagraph();
    return (
      <div>
        <div
          className={MainWebsiteStyle.pageContainer}
          ref={(ref) => {
            this.refs = set(this.refs, "topOfGetStarted", ref);
          }}
        >
          <div className={Style.container}>
            <h1 className={Style.heading}>{heading}</h1>
            <p className={Style.paragraph}>{paragraph}</p>
            {screenId !== "final" && (
              <GetStartedModules.Form
                onChangeScreenId={this.onChangeScreenId}
                screenId={screenId}
              />
            )}
          </div>
        </div>
        <TrustedByLogosGallery show_get_started_button={false} />
      </div>
    );
  }
}

export default GetStarted;
