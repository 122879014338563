// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import shortid from "shortid";

import ItemDetailsForm from "./ItemDetailsForm";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Fields
import { ResponsiveModal } from "fields";

// Lib
import { LocalStorage, ShowConfirmNotif } from "lib/functions";
import { Customers } from "lib/services";

// Assets
import { CloseIcon } from "assets/Icons";

const { ChangeCustomerCart } = Customers.PostRequests;

class ItemDetail extends React.Component {
  confirmNotif = null;
  state = {};

  componentDidMount() {
    window.addEventListener("beforeunload", this._confirm);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
  }

  componentWillUnmount() {
    this.setState({ isLoading: false });
    window.removeEventListener("beforeunload", this._confirm);
    window.onpopstate = () => {};
  }

  _backConfirm = async () => this.props.onCloseModal();

  _confirm = (e) => {
    var confirmationMessage = "o/";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  onAddItemToCart = (detailsOfItemInCart) => {
    const { context } = this.props;
    this.onChangeCart(
      set(context.cart, shortid.generate(), detailsOfItemInCart)
    );
  };

  onChangeItemDetailsInCart = (detailsOfItemInCart = {}) => {
    const { idOfItemInCart } = this.props;
    if (idOfItemInCart) {
      const { context } = this.props;
      this.onChangeCart(set(context.cart, idOfItemInCart, detailsOfItemInCart));
    }
  };

  onChangeCart = (newCart) => {
    this.setState({ isLoading: true });
    const { context } = this.props;
    const { shopID, showCateringMenu = false } = context;
    const uuid = LocalStorage.getItemFromStorage("uuid");
    const notifMessages = this.onGetNotificationMessages();
    ChangeCustomerCart({
      applyToCateringCart: showCateringMenu,
      cart: newCart,
      shopID: shopID,
      uuid,
    })
      .then(() => {
        this.confirmNotif = ShowConfirmNotif({
          message: notifMessages.success,
          type: "success",
        });
        context.onChangeCustomerCart(newCart);
        this.setState({ isLoading: false }, this.props.onCloseModal);
      })
      .catch(() => {
        this.confirmNotif = ShowConfirmNotif({
          message: notifMessages.failure,
          type: "error",
        });
        this.setState({ isLoading: false });
      });
  };

  onGetNotificationMessages = () =>
    this.props.isItemInCart
      ? {
          failure: "Failed to update your cart. Please try again.",
          success: "Updated the item. Congrats!",
        }
      : {
          failure: "Failed to add item. Please try again.",
          success: "Added item to your cart. Yum!",
        };

  renderDesktopCloseModalButton = () => (
    <button
      className={Style.desktop_close_modal_icon}
      onClick={this.props.onCloseModal}
    >
      <CloseIcon />
    </button>
  );

  renderLoadingSpinner = () => (
    <Modals.LoadingModal
      message={`${this.props.isItemInCart ? "Updating" : "Adding"} item`}
    />
  );

  render() {
    const window_inner_width = window.innerWidth;

    return (
      <ResponsiveModal
        classNames={{
          contentContainer: Style.contentContainer,
          modal: Style.modal,
        }}
        contentLabel="item detail modal"
        headerProps={{ showHeader: window_inner_width < 1000 }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.state.isLoading && this.renderLoadingSpinner()}
        {this.renderDesktopCloseModalButton()}
        <ItemDetailsForm
          categoryModifierGroups={this.props.categoryModifierGroups}
          detailsOfItemInCart={this.props.detailsOfItemInCart}
          isItemInCart={this.props.isItemInCart}
          itemID={this.props.itemID}
          itemImage={this.props.itemImage}
          itemInfo={this.props.itemInfo}
          onAddItemToCart={this.onAddItemToCart}
          onChangeItemDetailsInCart={this.onChangeItemDetailsInCart}
          onCloseModal={this.props.onCloseModal}
        />
      </ResponsiveModal>
    );
  }
}

ItemDetail.propTypes = {
  categoryModifierGroups: PropTypes.object,
  context: PropTypes.shape({
    cart: PropTypes.object.isRequired,
    onChangeCustomerCart: PropTypes.func.isRequired,
    shopID: PropTypes.string.isRequired,
    showCateringMenu: PropTypes.bool,
  }).isRequired,
  detailsOfItemInCart: PropTypes.object,
  idOfItemInCart: PropTypes.string,
  isItemInCart: PropTypes.bool,
  itemID: PropTypes.string.isRequired,
  itemInfo: PropTypes.object.isRequired,
  itemImage: PropTypes.any,
  onCloseModal: PropTypes.func.isRequired,
};

ItemDetail.defaultProps = {
  categoryModifierGroups: {},
  detailsOfItemInCart: {},
  isItemInCart: false,
};

export default withContext(CustomerInterfaceConsumer)(ItemDetail);
