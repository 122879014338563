// BUTI DINERS, INC. All right Reserved ©

import _round from "lodash.round";
import _isempty from "lodash.isempty";
import dayjs from "dayjs";

// Lib
import { DEFAULT_TIMEZONE, TIME_FORMAT } from "lib/_constants";
const DEFAULT_TIME_FORMAT = TIME_FORMAT;

const _formatPhoneNumber = (phoneNumberString = "") => {
  const cleaned = ("" + String(phoneNumberString)).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
};

const _isTrue = (value = "") => {
  if (!value) return false;
  else if (typeof value === "boolean") return value;
  else if (typeof value === "string") return value === "true";
  return value;
};

const _generateRandomID = () =>
  String(Math.floor(100000 + Math.random() * 900000)) +
  String.fromCharCode(97 + Math.floor(Math.random() * 26));

const _localizeTime = (params = {}) => {
  const {
    time,
    time_format = DEFAULT_TIME_FORMAT,
    time_zone = DEFAULT_TIMEZONE,
  } = params;
  return dayjs(time).tz(time_zone).format(time_format);
};

// ------------------------------------------------------
// Return the value as a String, rounded by the number of decimals
// 11 -> 11.00
// 10.5 -> 10.50
const _roundNumber = (value, decimals = 2) =>
  _round(isNaN(value) ? 0 : value, decimals);

const _scrollTo = ({
  behavior = "instant",
  block = "start",
  position = "relative",
  ref = {},
  top = "-120px",
}) => {
  if (Object.keys(ref).length > 0) {
    let i_pos = ref.style.position;
    let i_top = ref.style.top;
    ref.style.position = position;
    ref.style.top = top;
    ref.scrollIntoView({ behavior, block });
    ref.style.top = i_top;
    ref.style.position = i_pos;
  }
};

const _updatePageTitleAndDescription = ({ description = "", title = "" }) => {
  if (!_isempty(description))
    document.getElementsByTagName("meta")["description"].content = description;
  if (!_isempty(title)) document.title = title;
};

export {
  _formatPhoneNumber,
  _isTrue,
  _generateRandomID,
  _localizeTime,
  _roundNumber,
  _scrollTo,
  _updatePageTitleAndDescription,
};
