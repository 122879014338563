// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { withRouter } from "react-router-dom";

import { TRUSTED_PARTNERS } from "./constants";

// Style
import Style from "./style.module.scss";

// Fields
import Button from "fields/Button";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class TrustedByLogosGallery extends React.Component {
  renderButtons = () => (
    <div className={Style.buttons_container}>
      {this.props.show_get_started_button && (
        <div style={{ padding: "0 20px" }}>
          <Button
            className={Style.startNowBtn}
            hasShadow
            name="get started button"
            onClick={() => this.props.history.push("/get-started")}
          >
            Get a Free Demo <ChevronArrowIcon className={Style.arrowIcon} />
          </Button>
        </div>
      )}
      {this.props.show_more_examples_button && (
        <div style={{ padding: "0 20px" }}>
          <Button
            className={Style.learnMoreBtn}
            color="white"
            name="see more examples button"
            onClick={() => this.props.history.push("/website-examples")}
          >
            More Examples <ChevronArrowIcon className={Style.arrowIcon} />
          </Button>
        </div>
      )}
    </div>
  );

  renderLogos = () =>
    TRUSTED_PARTNERS.map((partner) => {
      const { img_src } = partner;
      return (
        <div className={Style.logo_link} key={shortid.generate()}>
          <img alt="" className={Style.logo_img} src={img_src} />
        </div>
      );
    });

  render() {
    return (
      <div className={Style.container}>
        <h2 className={Style.title}>
          Trusted by over 2,000+ restaurants (and counting!)
        </h2>
        <div className={Style.logos_container}>{this.renderLogos()}</div>
        <div className={Style.gradient_overlay} />
        <div className={Style.gradient_overlay} />
        {this.renderButtons()}
      </div>
    );
  }
}

TrustedByLogosGallery.propTypes = {
  history: PropTypes.object.isRequired,
  show_get_started_button: PropTypes.bool,
  show_more_examples_button: PropTypes.bool,
};

TrustedByLogosGallery.defaultProps = {
  show_get_started_button: true,
  show_more_examples_button: true,
};

export default withRouter(TrustedByLogosGallery);
