// BUTI DINERS, INC. All right Reserved ©

const FEATURES = {
  seo: {
    label: "SEO and List Building",
    title: "Gain New Customers",
    list: [
      "Turn new guests into loyal fans",
      "Impress guests with a easy order-and-pay experience",
      "Top result on Google search engine",
    ],
    video_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Home+Page/1+SEO+and+List+Building.mp4",
  },
  online_ordering: {
    label: "Top Notch Online Ordering",
    title: "0% Commission, Deliver & Pick up",
    list: [
      "Connect with a network of millions of drivers",
      "Show your menu on hundreds of online platforms",
      "Lowest delivery fees for your restaurant",
    ],
    video_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Home+Page/2+World+Class+Online+Ordering.mp4",
  },
  social_media: {
    label: "Re-marketing & Social Sharing",
    title: "Turn customers into your promoters",
    list: [
      "Invite guests back more frequently with emails & texts",
      "Encourage online follows & likes for your restaurant",
      "Entice great reviews for your restaurant on Yelp, Google, Facebook & Instagram",
    ],
    video_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Home+Page/3+Social+Sharing+%26+Re-marketing.mp4",
  },
};

export { FEATURES };
