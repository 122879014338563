// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import Img from "react-cool-img";

// Style
import Style from "./style.module.scss";

// Components
import { ShopSurveyModules } from "components";

// Lib
import { LocalStorage, ShowConfirmNotif } from "lib/functions";
import { Customers } from "lib/services";

const { SaveCustomerSurvey } = Customers.PostRequests;

class ShopSurvey extends React.Component {
  state = {
    feedback_text: "",
    screen_id: "survey",
    selected_rating_id: "",
  };

  onChangeFeedbackText = ({ feedback_text }) =>
    this.setState({ feedback_text });

  onSelectRating = ({ rating_id }) =>
    this.setState({ feedback_text: "", selected_rating_id: rating_id });

  onSubmitFeedback = async () => {
    const { feedback_text, selected_rating_id } = this.state;
    try {
      await SaveCustomerSurvey({
        feedback_text,
        selected_rating_id,
        shop_id: this.props.shopID,
        uuid: LocalStorage.getItemFromStorage("uuid"),
      });
      this.setState({
        feedback_text: "",
        screen_id: "feedback_sent",
        selected_rating_id: "",
      });
    } catch {
      ShowConfirmNotif({
        message: "Failed to send your feedback! Please try again.",
        type: "warning",
      });
    }
  };

  renderScreen = () => {
    switch (this.state.screen_id) {
      case "feedback_sent":
        const { shopBasicInfo = {} } = this.props;
        const { social_media_profiles = {} } = shopBasicInfo;
        return (
          <ShopSurveyModules.FeedbackSent
            shop_id={this.props.shopID}
            social_media_profiles={social_media_profiles}
          />
        );
      case "survey":
      default:
        return (
          <ShopSurveyModules.Feedback
            feedback_text={this.state.feedback_text}
            onChangeFeedbackText={this.onChangeFeedbackText}
            onSelectRating={this.onSelectRating}
            onSubmitFeedback={this.onSubmitFeedback}
            selected_rating_id={this.state.selected_rating_id}
          />
        );
    }
  };

  renderShopBranding = () => {
    const { shopBasicInfo = {} } = this.props;
    const { logoURL, name: shop_name } = shopBasicInfo;
    return (
      <div>
        <div className={Style.shop_logo}>
          <Img alt="Shop logo" src={logoURL} />
        </div>
        <h1 className={Style.shop_name}>{shop_name}</h1>
      </div>
    );
  };

  render() {
    return (
      <div className={Style.container}>
        {this.renderShopBranding()}
        {this.renderScreen()}
      </div>
    );
  }
}

ShopSurvey.propTypes = {
  shopBasicInfo: PropTypes.shape({
    address: PropTypes.string.isRequired,
    logoURL: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  shopID: PropTypes.string.isRequired,
};

export default ShopSurvey;
