// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Introduction from "./Introduction";
import Benefits from "./Benefits";
// import SimplePricing from "views/Delivery/SimplePricing";

// Utils
import { _updatePageTitleAndDescription } from "utils";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Components
import { LetsChatBox } from "components";

// Fields
import { TestimonialBox } from "fields";

class ContactlessOrdering extends React.Component {
  componentDidMount() {
    _updatePageTitleAndDescription({
      description:
        "Provide an end-to-end contactless dining experience to your guests. Guests can view the complete menu, place orders, and pay at the table—without the need for any human contact.",
      title: "Digital Ordering & Wait-Free Payment | Skipli",
    });
  }

  render() {
    const { onUpdateRefs } = this.props.context;
    return (
      <div className={Style.pageContainer}>
        <Introduction />
        <div
          style={{ marginBottom: "50px" }}
          ref={(ref) =>
            onUpdateRefs({ refID: "contactlessOrderingBenefits", ref })
          }
        >
          <Benefits />
        </div>
        <div style={{ marginTop: "10rem" }}>
          <div className={MainWebsiteStyle.sectionContainer}>
            <TestimonialBox
              profileImage="https://i.imgur.com/PuiRUep.png"
              profileName="Athir Jassim | Manager | Poppin Pita Grill"
              text={`“I was concerned about keeping my staff and guests safe. I also want my guests to have the same dine-in experience as before. Skipli Contactless was exactly what I needed to increase dine-in and curbside orders while limiting physical contact.”`}
            />
          </div>
        </div>
        <div className={Style.sectionContainer}>
          <LetsChatBox
            description="We'll help you drive revenue, directly through your
            restaurant's website."
          />
        </div>
        {/* <SimplePricing /> */}
      </div>
    );
  }
}

ContactlessOrdering.propTypes = {
  context: PropTypes.shape({
    onUpdateRefs: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(ContactlessOrdering)
);
