// BUTI DINERS, INC. All right Reserved ©

const TRUSTED_PARTNERS = [
  {
    business_name: "Blue India",
    logo_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Blue+India+Logo.png",
    owner_name: "Jiggar",
    profile_pic_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant+Owners/Jiggar+Patel.jpg",
    testimony:
      "We were using GrubHub, DoorDash, UberEats and struggling due to their high fees. We'd like to thank SKIPLI for helping us save a lot of money and actually make profits with online orders!",
  },
  {
    business_name: "Lady Ha",
    logo_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Lady+Ha+Logo.png",
    owner_name: "Ha",
    profile_pic_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant+Owners/Lady+Ha.png",
    testimony:
      "SKIPLI all-in-one ordering & marketing system is exactly what we needed. Their team went above and beyond to market my new restaurant. I quickly gained a lot of traffic & online orders even in the first month of business.",
  },
  {
    business_name: "Vietnam Pho in NJ",
    logo_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Vietnam+Pho+in+NJ+Logo+1.webp",
    owner_name: "Duong",
    profile_pic_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant+Owners/Duong.jpg",
    testimony:
      "Their customer service is one of the best. They’ve been a big factor to our success. The website & ordering system they built gained me a lot of money and valuable data for gaining loyal customers. I’m excited for the future and highly recommend SKIPLI!",
  },
  {
    business_name: "LimeNBasil & TeaZona",
    logo_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/LimeNBasil+Logo.jpeg",
    owner_name: "Mindy",
    profile_pic_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant+Owners/LimeNBasil+%26+TeaZona.jpeg",
    testimony:
      "Sales went through the roof when we use SKIPLI auto phone answering and all-in-one ordering system. Their auto phone handles my incoming calls without me having to constantly pick up the phone. My customers are happy; they get answers right away and instantly place online orders.",
  },
  {
    business_name: "Pho Bac Duluth",
    logo_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+Bac+Duluth+Logo.png",
    owner_name: "Jenny",
    profile_pic_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant+Owners/Jenny.png",
    testimony:
      "I've made more in one month on SKIPLI than I made on the old website in an entire year! SKIPLI auto phone answering is a really cool and helpful technology. No more missing sales due to backup calls.",
  },
  {
    business_name: "Pho Viet Number One",
    logo_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+Viet+Number+One+Logo.png",
    owner_name: "Thang",
    profile_pic_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant+Owners/Thang.png",
    testimony:
      "If you own a restaurant, you need SKIPLI. Their marketing & ordering tech is phenomenal. They'll help generate more sales for you and their customer service is off the chain!",
  },
  {
    business_name: "Nam Phuong - Buford Hwy",
    logo_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Nam+Phuong+Buford+Logo.png",
    owner_name: "Hoa",
    profile_pic_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant+Owners/Hoa+-+Nam+Phuong+Buford+Hwy.png",
    testimony:
      "SKIPLI's marketing technology has been great at attracting customers and retaining customers to reorder online. Many of my guests started dining-in or placing online orders more frequently. SKIPLI rocks!",
  },
  {
    business_name: "Pho King Midtown",
    logo_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+King+Logo.png",
    owner_name: "Khoa",
    profile_pic_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant+Owners/Khoa+Vuong.jpg",
    testimony:
      "We love the product it’s been an godsend to us. I mean really amazing… I am absolutely amazed at how easy it is is to use the system and how fast it's become such an essential part of our business.",
  },
  {
    business_name: "Saigon Pho",
    logo_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Saigon+Pho+Logo.png",
    owner_name: "Hung",
    profile_pic_url:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Saigon+Pho+Website+Thumbnail.png",
    testimony:
      "SKIPLI has allowed us to gracefully navigate the process of re-opening our dining room. Now that we're open again we're able to manage our website, pickup & delivery orders in one unified system which streamlines our entire operation.",
  },
];

export { TRUSTED_PARTNERS };
