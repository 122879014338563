// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

const TestimonialBox = (props) => (
  <div className={Style.testimonialBox}>
    <div className={Style.textCol}>
      <img alt="" className={Style.profileImg} src={props.profileImage} />
      <p>{props.text}</p>
      <h3 className={Style.profileName}>{props.profileName}</h3>
      {props.salesStat && <div>{props.salesStat}</div>}
    </div>
    {props.heroImage && (
      <div className={Style.imageCol}>
        <img
          alt=""
          className={props.heroImageClassname}
          src={props.heroImage}
        />
      </div>
    )}
  </div>
);

TestimonialBox.propTypes = {
  heroImage: PropTypes.string.isRequired,
  heroImageClassname: PropTypes.string,
  profileImage: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  salesStat: PropTypes.any,
  text: PropTypes.string.isRequired,
};

export default TestimonialBox;
