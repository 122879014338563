// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { ExpansionPanel, Input, RadioButton } from "fields";

class PickupInfo extends React.Component {
  state = { isAsapSelected: true, isPickUpInside: true };

  componentDidMount = () => {
    this.state.isAsapSelected && this.props.onChangePickupTime("ASAP");
  };

  onChangeCurbsideInfo = (val) => {
    val.length < 20 && this.props.onChangeCurbsideInfo(val);
  };

  onChangePickupTime = (val) => {
    val.length < 10 && this.props.onChangePickupTime(val);
  };

  onDeselectAsap = () =>
    this.setState({ isAsapSelected: false }, () =>
      this.props.onChangePickupTime("")
    );

  onSelectAsap = () =>
    this.setState({ isAsapSelected: true }, () =>
      this.props.onChangePickupTime("ASAP")
    );

  onSelectPickUpInside = () =>
    this.setState({ isPickUpInside: true }, () =>
      this.props.onChangeCurbsideInfo("")
    );

  onDeselectPickUpInside = () =>
    this.setState({ isPickUpInside: false }, () =>
      this.props.onChangeCurbsideInfo("")
    );

  renderCurbsideLocation = () => (
    <Input.TextInput
      label="Parking lot # / Car brand + color"
      name="Curbside pickup info"
      onChange={this.onChangeCurbsideInfo}
      value={this.props.curbsideInfo}
      required
    />
  );

  renderExtraHours = (shopTodayExtraHours) =>
    Object.keys(shopTodayExtraHours).map((timeId, index) => {
      const {
        localEndAt12Hr = "",
        localStartAt12Hr = "",
      } = shopTodayExtraHours[timeId];
      return (
        (localStartAt12Hr || localEndAt12Hr) && (
          <div className={Style.value} key={index}>
            {localStartAt12Hr} - {localEndAt12Hr}
          </div>
        )
      );
    });

  renderPickUpAddress = () => {
    const { shopBasicInfo = {} } = this.props.context;
    const { address, name } = shopBasicInfo;
    return (
      <div className={Style.noticeBox}>
        <div style={{ marginBottom: "0.6rem" }}>You will pick up at:</div>
        <div className={Style.shop_name}>{name}</div>
        <div className={Style.shop_address}>{address}</div>
      </div>
    );
  };

  renderPickUpType = () => {
    const { isPickUpInside = true } = this.state;
    return (
      <div className={Style.inputFieldContainer}>
        <p className={Style.question}>How do you want to pick up?</p>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <div style={{ marginRight: "25px" }}>
            <RadioButton
              checked={isPickUpInside}
              id="inside"
              label="Inside"
              onClick={this.onSelectPickUpInside}
            />
          </div>
          <RadioButton
            checked={!isPickUpInside}
            id="curbside"
            label="Curbside / Drive Thru"
            onClick={this.onDeselectPickUpInside}
          />
        </div>
      </div>
    );
  };

  renderTimeQuestion = () => {
    const { isAsapSelected } = this.state;
    return (
      <div className={Style.inputFieldContainer}>
        <p className={Style.question}>When do you want to pick up?</p>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <div style={{ marginRight: "25px" }}>
            <RadioButton
              checked={isAsapSelected}
              id="ASAP"
              label="ASAP"
              onClick={this.onSelectAsap}
            />
          </div>
          <RadioButton
            checked={!isAsapSelected}
            id="otherTime"
            label="Other Time"
            onClick={this.onDeselectAsap}
          />
        </div>
      </div>
    );
  };

  renderTimePicker = () => (
    <div style={{ marginBottom: "15px" }}>
      <Input.TextInput
        name="Pickup time"
        onChange={this.onChangePickupTime}
        placeholder="Pickup Time"
        value={this.props.pickUpTime}
        required
      />
    </div>
  );

  renderTodayPickUpHours = () => {
    const { todayHours = {} } = this.props.context;
    const { shopTodayExtraHours = {}, shopTodayOpenHours = {} } = todayHours;
    const { localEndAt12Hr = "", localStartAt12Hr = "" } = shopTodayOpenHours;
    if (!localStartAt12Hr && !localEndAt12Hr) return;
    return (
      <div style={{ marginBottom: "1rem" }}>
        <div className={Style.label}>Accept pickup orders between</div>
        <div className={Style.value}>
          {localStartAt12Hr} - {localEndAt12Hr}
        </div>
        {Object.keys(shopTodayExtraHours).length > 0 &&
          this.renderExtraHours(shopTodayExtraHours)}
      </div>
    );
  };

  render() {
    return (
      <ExpansionPanel
        moduleTitle="Pickup Info"
        showExpandableContent
        titleClassName={CommonStyle.expansionPanelTitle}
      >
        {this.renderPickUpAddress()}
        {this.renderTodayPickUpHours()}
        {this.renderTimeQuestion()}
        {!this.state.isAsapSelected && this.renderTimePicker()}
        {/* {this.renderPickUpType()} */}
        {/* {!this.state.isPickUpInside && this.renderCurbsideLocation()} */}
      </ExpansionPanel>
    );
  }
}

PickupInfo.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      address: PropTypes.string.isRequired,
    }).isRequired,
    todayHours: PropTypes.shape({
      isShopClosed: PropTypes.bool,
      shopTodayOpenHours: PropTypes.object,
    }).isRequired,
  }).isRequired,
  curbsideInfo: PropTypes.string,
  onChangeCurbsideInfo: PropTypes.func.isRequired,
  onChangePickupTime: PropTypes.func.isRequired,
  pickUpTime: PropTypes.string,
};

PickupInfo.defaultProps = {
  curbsideInfo: "",
  pickUpTime: "",
};

export default withContext(CustomerInterfaceConsumer)(PickupInfo);
