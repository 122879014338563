// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Components
import { SplitOverlayGroup } from "components";

// Image
// import DesignedByExperts from "assets/Images/designedByExperts.jpg";
// import MoreCustomers from "assets/Images/Google-Front-Page-More-Customers.xml";

// "We maximize the appearance of your profile while optimizing it for the keywords that are relevant to your business."

const WEB_DESIGN_FEATURES = [
  {
    action_button: { text: "Bring in more business" },
    label: "More customers today",
    description:
      "98% of people end up choosing a business that is on the 1st page of Google",
    image: "https://i.imgur.com/IW1v68b.png",
    list: [
      {
        item_name: "Drive website visits",
        item_description:
          "We help you grow online sales, bookings, or mailing list signups with online ads that direct people to your website.",
      },
      {
        item_name: "Increase store visits",
        item_description:
          "We get you more customers in the door by making it easier for people to find your restaurant on the map.",
      },
      {
        item_name: "Get more phone calls",
        item_description:
          "We help increase customer calls with ads that feature your phone number and a click-to-call button.",
      },
    ],
  },
  {
    action_button: { text: "Book a free demo" },
    label: "Unleash your business potential",
    description:
      "Approximately 65,000 searches are performed on Google every second",
    image: "https://i.imgur.com/QBYNal0.png",
    list: [
      {
        item_name: "Get you in front of new customers",
        item_description:
          "We help expand your reach by letting customers order via Google Search, Maps, and Assistant.",
      },
      {
        item_name: "Turn customers into loyal fans",
        item_description:
          "We help you easily identify and reward loyal guests by using customer data & insights from Google — something other platforms keep for themselves.",
      },
      {
        item_name: "Build your brand reputation",
        item_description:
          "We help you repair inconsistent listing data and manage online reviews to protect your online reputation and get good placement in search engine results.",
      },
    ],
  },
  // {
  //   label: "Bad Content Suppression",
  //   description:
  //     "Our team suppresses or eliminates bad content while increasing and developing industry specific positive content.",
  //   image: FourJapaneseNoodles,
  //   list: [
  //     {
  //       item_name: "Suppress or eliminate bad content",
  //       item_description: "N/A",
  //     },
  //     {
  //       item_name: "Increase positive content",
  //       item_description: "N/A",
  //     },
  //     {
  //       item_name: "Increase customer satisfaction",
  //       item_description: "N/A",
  //     },
  //   ],
  // },
  // {
  //   label: "Get more customers & online revenue",
  //   description:
  //     "Our innovative online marketing gets your restaurant in front of thousands of hungry customers. Our integrated online ordering quickly get you paid without hiring extra staff.",
  //   image: FourJapaneseNoodles,
  //   list: [
  //     {
  //       item_name: "Place your menu in front of more customers",
  //       item_description: "N/A",
  //     },
  //     {
  //       item_name: "Get paid more without hiring more staff",
  //       item_description: "N/A",
  //     },
  //     {
  //       item_name: "Turn new and return customers into loyal fans",
  //       item_description: "N/A",
  //     },
  //   ],
  // },
];

class WebsiteDesignFeatures extends React.Component {
  render() {
    return <SplitOverlayGroup items={WEB_DESIGN_FEATURES} />;
  }
}

export default WebsiteDesignFeatures;
