// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Components
import { Modals } from "components";

// Fields
import { ScreenSize } from "fields";

class CustomerGlobalNavbar extends React.Component {
  state = { show_main_menu: false };

  onHideMainMenu = () => this.setState({ show_main_menu: false });

  onShowMainMenu = () => this.setState({ show_main_menu: true });

  onSelectOption = (option_id) => {
    switch (option_id) {
      case "about_shop":
        this.setState({ show_about_shop: true });
        return;
      case "customer_profile":
        this.setState({ show_customer_profile: true });
        return;
      default:
        return;
    }
  };

  renderAboutShop = () => {
    const { shopBasicInfo, todayHours } = this.props.context;
    return (
      <Modals.AboutShop
        onCloseModal={() => this.setState({ show_about_shop: false })}
        shop_basic_info={shopBasicInfo}
        today_hours={todayHours}
      />
    );
  };

  renderCustomerProfile = () => {
    return (
      <Modals.CustomerProfileModal
        onCloseModal={() => this.setState({ show_customer_profile: false })}
      />
    );
  };

  renderOrderingPageMainMenu = () => (
    <Modals.OrderingPageMainMenu
      onCloseModal={this.onHideMainMenu}
      onSelectOption={this.onSelectOption}
    />
  );

  render() {
    const { activeNavbarItemId } = this.props;
    return (
      <React.Fragment>
        {this.state.show_main_menu && this.renderOrderingPageMainMenu()}
        {this.state.show_about_shop && this.renderAboutShop()}
        {this.state.show_customer_profile && this.renderCustomerProfile()}
        <ScreenSize deviceType="mobile">
          <MobileNavbar
            activeNavbarItemId={activeNavbarItemId}
            onShowMainMenu={this.onShowMainMenu}
          />
        </ScreenSize>
        <ScreenSize deviceType="tablet">
          <MobileNavbar
            activeNavbarItemId={activeNavbarItemId}
            onShowMainMenu={this.onShowMainMenu}
          />
        </ScreenSize>
        <ScreenSize deviceType="desktop">
          <DesktopNavbar onShowMainMenu={this.onShowMainMenu} />
        </ScreenSize>
      </React.Fragment>
    );
  }
}

CustomerGlobalNavbar.propTypes = {
  activeNavbarItemId: PropTypes.string.isRequired,
  context: PropTypes.shape({
    cart: PropTypes.object,
    onChangeGlobalNavItem: PropTypes.func,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  logoURL: PropTypes.string,
  shopName: PropTypes.string,
};

CustomerGlobalNavbar.defaultProps = {
  logoURL: "",
};

export default withContext(CustomerInterfaceConsumer)(CustomerGlobalNavbar);
