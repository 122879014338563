// BUTI DINERS, INC. All right Reserved ©

import dayjs from "dayjs";

const _sortSpecialHours = ({ special_hours }) =>
  Object.keys(special_hours).sort((a, b) => {
    const { date: date_a = "" } = special_hours[a];
    const { date: date_b = "" } = special_hours[b];
    return dayjs(date_a).diff(dayjs(date_b));
  });

export { _sortSpecialHours };
