// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import _get from "lodash.get";

// Fields
import { LoadingSpinner, PageMsg } from "fields";

// Lib
import { BUTI } from "lib/services";
const { GetCustomerStripeReceipt } = BUTI.GetRequests;

class ReceiptRedirectPage extends React.Component {
  state = { showNotFoundURL: false };

  componentDidMount = () => this.onGetStripeReceiptURL();

  onGetStripeReceiptURL = async () => {
    const paymentIntentID =
      _get(this.props, "match.params.paymentIntentID") || "";
    const { receiptURL } = await GetCustomerStripeReceipt({
      paymentIntentID,
    });
    if (receiptURL) window.location.href = receiptURL;
    else this.setState({ showNotFoundURL: true });
  };

  render() {
    return (
      <PageMsg>
        {this.state.showNotFoundURL ? (
          "Our apology! We could not find your receipt."
        ) : (
          <LoadingSpinner message="Generating your receipt..." />
        )}
      </PageMsg>
    );
  }
}

export default ReceiptRedirectPage;
