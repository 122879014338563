// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Assets
import {
  FacebookIcon,
  HyperlinkIcon,
  LinkedInIcon,
  SendEmailIcon,
} from "assets/Icons";

const SHARE_POST_CHANNELS = [
  {
    icon: <FacebookIcon height={18} width={18} />,
    id: "facebook",
    name: "share on facebook",
  },
  {
    icon: <LinkedInIcon height={18} width={18} />,
    id: "linkedIn",
    name: "share on linkedin",
  },
  {
    icon: <SendEmailIcon height={18} width={18} />,
    id: "email",
    name: "share through email",
  },
  {
    icon: <HyperlinkIcon height={18} width={18} />,
    id: "copy_link",
    name: "copy link",
  },
];

export { SHARE_POST_CHANNELS };
