// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import _orderBy from "lodash.orderby";
import { set, del } from "object-path-immutable";

// Fields
import { ContentLoader } from "fields";

const _createModifiersSkeleton = () => (
  <div>
    {Array(2)
      .fill(2)
      .map((val, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          <ContentLoader.ModifierLoader />
        </div>
      ))}
  </div>
);

// const _findPriciestModifiers = ({
//   modifiers = {},
//   modifierGroupMinChoices = 0,
// }) => {
//   const sortedModifiers = _orderBy(Object.keys(modifiers), (modifierID) => {
//     const { modifierPrice } = modifiers[modifierID];
//     return -modifierPrice;
//   });
//   return sortedModifiers.map(
//     (modifierID, index) => index < modifierGroupMinChoices && modifierID
//   );
// };

const _findCheapestModifiers = ({
  modifiers = {},
  modifierGroupMinChoices = 0,
}) => {
  const sortedModifiers = _orderBy(Object.keys(modifiers), (modifierID) => {
    const { modifierPrice } = modifiers[modifierID];
    return modifierPrice;
  });
  return sortedModifiers.map(
    (modifierID, index) => index < modifierGroupMinChoices && modifierID
  );
};

const _isModifierGroupRequired = ({ modifierGroupMinChoices }) =>
  parseInt(modifierGroupMinChoices) > 0;

const _isModififerSelectedAsDefault = ({
  modifiers = {},
  modifierGroupMinChoices = 0,
  modifierID = "",
  selectedModifiers,
}) => {
  if (Object.keys(selectedModifiers).length > 0) return false;
  const cheapest_modifiers = _findCheapestModifiers({
    modifiers,
    modifierGroupMinChoices,
  });
  return cheapest_modifiers.includes(modifierID);
};

const _constructModifiersAfterSelectModifiers = ({
  modifierGroupMaxChoices,
  modifierGroupMinChoices,
  selectedModifierID = "",
  selectedModifierInfo = {},
  selectedModifiers,
  shouldAllowMultipleSelect,
}) => {
  if (!shouldAllowMultipleSelect)
    return { [selectedModifierID]: selectedModifierInfo };

  // If the selectedModifierID doesn't exist in selectedModifiers
  if (!selectedModifiers[selectedModifierID]) {
    const newSelectedModifiers = set(
      selectedModifiers,
      `${selectedModifierID}`,
      selectedModifierInfo
    );
    return Object.keys(newSelectedModifiers).length > modifierGroupMaxChoices
      ? selectedModifiers
      : newSelectedModifiers;
  }

  // If the selectedModifierID already exists in selectedModifiers
  const newSelectedModifiers = del(selectedModifiers, `${selectedModifierID}`);
  return Object.keys(newSelectedModifiers).length < modifierGroupMinChoices
    ? selectedModifiers
    : newSelectedModifiers;
};

const _getModifierGroupDescription = ({ modifierGroupInfo = {} }) => {
  const maxChoices = parseInt(modifierGroupInfo.modifierGroupMaxChoices);
  const minChoices = parseInt(modifierGroupInfo.modifierGroupMinChoices);
  if (maxChoices === 1) return minChoices === 1 ? "Choose one option" : "";
  else if (maxChoices && minChoices)
    return `Choose between ${minChoices} and ${maxChoices} options`;
  else if (maxChoices) return `Choose at most ${maxChoices} options`;
  else if (minChoices) return `Choose at least ${minChoices} options`;
  else if (maxChoices === minChoices)
    return `Choose only ${maxChoices} options`;
};

const _sortModifierGroups = ({ loadedModifierGroups }) =>
  _orderBy(
    Object.keys(loadedModifierGroups),
    (modifier_group_id) => {
      const { modifierGroupMinChoices } = loadedModifierGroups[
        modifier_group_id
      ];
      return _isModifierGroupRequired({ modifierGroupMinChoices });
    },
    ["desc"]
  );

export {
  _constructModifiersAfterSelectModifiers,
  _createModifiersSkeleton,
  _getModifierGroupDescription,
  _isModifierGroupRequired,
  _isModififerSelectedAsDefault,
  _sortModifierGroups,
};
