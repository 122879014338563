// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import ModifierGroup from "./ModifierGroup";
import { _sortModifierGroups } from "./functions";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Lib
import { FoodMenuFuncs } from "lib/functions";

const { GetInformationForModifierGroups } = FoodMenuFuncs;

class ItemModifierGroups extends React.Component {
  state = { loadedModifierGroups: {} };

  componentDidMount = () => this.onGetModifierGroupsInfo();

  onGetModifierGroupsInfo = async () => {
    const { onChangeLoadingModifierGroupsStatus } = this.props;
    const modifierGroups = {
      ...this.props.categoryModifierGroups,
      ...this.props.itemModifierGroups,
    };
    if (Object.keys(modifierGroups || {}).length > 0) {
      onChangeLoadingModifierGroupsStatus("loading");
      const params = { modifierGroups, shopID: this.props.context.shopID };
      const loadedModifierGroups = await GetInformationForModifierGroups(
        params
      );
      this.setState({ loadedModifierGroups }, () =>
        onChangeLoadingModifierGroupsStatus("done")
      );
    } else {
      onChangeLoadingModifierGroupsStatus("done");
    }
  };

  onGetSelectedModifiers = (modifierGroupID) => {
    const { modifiers } =
      this.props.selectedModifierGroups[modifierGroupID] || {};
    return modifiers || {};
  };

  renderModifierGroups = () => {
    const { loadedModifierGroups } = this.state;
    return _sortModifierGroups({ loadedModifierGroups }).map(
      (modifierGroupID) => (
        <div key={modifierGroupID}>
          <ModifierGroup
            modifierGroupInfo={loadedModifierGroups[modifierGroupID]}
            onSelectModifier={(modifiers) =>
              this.props.onSelectModifier({ modifierGroupID, modifiers })
            }
            selectedModifiers={this.onGetSelectedModifiers(modifierGroupID)}
          />
        </div>
      )
    );
  };

  render() {
    return <div>{this.renderModifierGroups()}</div>;
  }
}

ItemModifierGroups.propTypes = {
  context: PropTypes.shape({ shopID: PropTypes.string.isRequired }).isRequired,
  categoryModifierGroups: PropTypes.object,
  itemModifierGroups: PropTypes.object,
  onChangeLoadingModifierGroupsStatus: PropTypes.func.isRequired,
  onSelectModifier: PropTypes.func.isRequired,
  selectedModifierGroups: PropTypes.object,
};

ItemModifierGroups.defaultProps = {
  categoryModifierGroups: {},
  itemModifierGroups: {},
  selectedModifierGroups: {},
};

export default withContext(CustomerInterfaceConsumer)(ItemModifierGroups);
