// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { set } from "object-path-immutable";

// Fields
import { ContentLoader, ScreenSize } from "fields";

const _organizeShops = (shops = {}) =>
  Object.keys(shops).reduce(
    (result, shopID) => {
      const { basicInfo } = shops[shopID];
      const { membership = {} } = basicInfo;
      const { status = "active" } = membership;
      return set(
        result,
        `${status === "active" ? "activeShops" : "comingSoonShops"}.${shopID}`,
        shops[shopID]
      );
    },
    { activeShops: {}, comingSoonShops: {} }
  );

const _createDesktopShopsSkeleton = () => {
  const shops = Array(2).fill(2);
  return (
    <div>
      {shops.map((val, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <ContentLoader.DesktopShopLoader />
        </div>
      ))}
    </div>
  );
};

const _createMobileShopsSkeleton = () => {
  const groups = Array(3).fill(3);
  return (
    <div>
      {groups.map((val, index) => (
        <div key={index}>
          <ContentLoader.MobileShopLoader />
        </div>
      ))}
    </div>
  );
};

const _createShopsSkeleton = () => (
  <div>
    <ScreenSize deviceType="mobile">{_createMobileShopsSkeleton()}</ScreenSize>
    <ScreenSize deviceType="tablet">{_createMobileShopsSkeleton()}</ScreenSize>
    <ScreenSize deviceType="desktop">
      {_createDesktopShopsSkeleton()}
    </ScreenSize>
  </div>
);

export { _createShopsSkeleton, _organizeShops };
