// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

class Tabs extends React.Component {
  renderOptions = () => {
    const { classNames = {}, options = {}, selectedOptionId } = this.props;
    return Object.keys(options).map((optionId) => {
      const { label, description = "" } = options[optionId];
      return (
        <button
          className={cx(
            Style.tab,
            classNames.tab,
            optionId === selectedOptionId &&
              cx(Style.selected, classNames.selected)
          )}
          onClick={() => this.props.onClick(optionId)}
          key={optionId}
        >
          {label}
          {description && <p>{description}</p>}
        </button>
      );
    });
  };

  render() {
    const { classNames = {} } = this.props;
    return (
      <div className={cx(Style.container, classNames.container)}>
        {this.renderOptions()}
      </div>
    );
  }
}

Tabs.propTypes = {
  classNames: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  selectedOptionId: PropTypes.string,
};

Tabs.defaultProps = {
  classNames: {},
};

export default Tabs;
