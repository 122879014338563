// BUTI DINERS, INC. All right Reserved ©

import { set } from "object-path-immutable";
import dayjs from "dayjs";

// Lib
import { BUTI } from "lib/services";

// ----------------------------------------------------------------
// Add 24 hours to time24
const _add24HoursToTime = (time24 = "") => {
  let timeArray = time24.split(":");
  timeArray[0] = parseInt(timeArray[0]) + 24;
  return timeArray.join(":");
};

// ----------------------------------------------------------------
// Check a time24 is between a time range
const _isBetween = ({ range = { startAt: "", endAt: "" }, time24 = "" }) => {
  const { startAt = "", endAt = "" } = range;
  if (!startAt && !endAt) return true;
  else if (startAt === endAt) return true;
  else if (!startAt && endAt) return time24 <= endAt;
  else if (startAt && !endAt) return time24 >= startAt;
  else {
    if (startAt < endAt) return time24 >= startAt && time24 <= endAt;
    const convertedEndAt = _add24HoursToTime(endAt);
    return (
      time24 >= startAt &&
      time24 <= (convertedEndAt === "" ? "" : convertedEndAt)
    );
  }
};

// ----------------------------------------------------------------
// Convert a set of extra hours into local time
const _convertExtraHours = async ({ extraHours, timeZone }) => {
  const result = await Object.keys(extraHours).reduce(
    async (previousPromise, timeId) => {
      const previousResult = await previousPromise;
      const convertedTime = await _convertHoursToLocal({
        hours: extraHours[timeId],
        timeZone,
      });
      return set(previousResult, `${timeId}`, convertedTime);
    },
    Promise.resolve({})
  );
  return result;
};

// ----------------------------------------------------------------
// Convert 24 hour time to am/pm
const _convertTime24to12 = (time24 = "") => {
  if (!time24) return "";
  let tmpArr = time24.split(":"),
    result = "";
  if (+tmpArr[0] === 12) result = tmpArr[0] + ":" + tmpArr[1] + " PM";
  else if (+tmpArr[0] === 0) result = "12:" + tmpArr[1] + " AM";
  else if (+tmpArr[0] > 12) result = +tmpArr[0] - 12 + ":" + tmpArr[1] + " PM";
  else result = +tmpArr[0] + ":" + tmpArr[1] + " AM";
  return String(result) === "undefined" ? "" : result;
};

// ----------------------------------------------------------------
// Returns one of "monday", "tuesday", "wednesday", "thursday",
// "friday", "saturday", "sunday"
const GetTodayDay = async ({ date_format = "" } = {}) => {
  const { GetCurrentUTCTimestamp } = BUTI.GetRequests;
  const { currentTimestamp } = await GetCurrentUTCTimestamp();
  const dayjs_time_stamp = dayjs(currentTimestamp);
  return {
    date: dayjs_time_stamp.format(date_format || "MM-DD-YYYY"),
    name_of_the_day: dayjs_time_stamp.format("dddd").toLowerCase(),
    time: dayjs_time_stamp.format("HH:MM"),
  };
};
// ----------------------------------------------------------------
// Returns one of "monday", "tuesday", "wednesday", "thursday",
// "friday", "saturday", "sunday"
const GetCurrentTime = async ({ date_format = "" ,timeZone = "America/New_York"} = {}) => {
  const { GetCurrentUTCTimestamp } = BUTI.GetRequests;
  const { currentTimestamp } = await GetCurrentUTCTimestamp();
  const dayjs_time_stamp = dayjs(currentTimestamp).tz(timeZone);
  return {
    date: dayjs_time_stamp.format(date_format || "MM-DD-YYYY"),
    name_of_the_day: dayjs_time_stamp.format("dddd").toLowerCase(),
    time: dayjs_time_stamp.format("HH:mm"),
  };
};

// ----------------------------------------------------------------
// Convert UTC hours to local 24 Hr and 12 Hr
const _convertHoursToLocal = async ({ hours, timeZone }) => {
  const { endAt = "", startAt = "" } = hours;
  const { ConvertUTCToLocalTime } = BUTI.GetRequests;
  let localEndAt = "",
    localStartAt = "";
  if (endAt) {
    const { localTime = "" } = await ConvertUTCToLocalTime({
      utc_time: endAt,
      timeZone,
    });
    localEndAt = localTime;
  }
  if (startAt) {
    const { localTime = "" } = await ConvertUTCToLocalTime({
      utc_time: startAt,
      timeZone,
    });
    localStartAt = localTime;
  }
  return {
    localEndAt,
    localEndAt12Hr: _convertTime24to12(localEndAt),
    localStartAt,
    localStartAt12Hr: _convertTime24to12(localStartAt),
  };
};

const DateTime = {
  _isBetween,
  _convertExtraHours,
  _convertTime24to12,
  _convertHoursToLocal,
  GetTodayDay,
  GetCurrentTime
};

export default DateTime;
