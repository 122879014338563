// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import cx from "classnames";
import _get from "lodash.get";

import {
  _initializeDetailsOfItemInCart,
  _renderButtons,
} from "./helperFunctions";
import CustomerInstruction from "./CustomerInstruction";
import ItemInfo from "./ItemInfo";
import ItemModifierGroups from "./ItemModifierGroups";
import { SubmitButton } from "./ItemButtons";
import ItemQuantityCalculator from "./ItemQuantityCalculator";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Lib
import { FoodMenuFuncs } from "lib/functions";
const { CalcTotalPriceBeforeTaxForItem } = FoodMenuFuncs;

class ItemDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    const { itemID, itemInfo = {} } = props;
    this.state = {
      detailsOfItemInCart: _initializeDetailsOfItemInCart({ itemID, itemInfo }),
    };
  }

  componentDidMount() {
    if (this.props.isItemInCart)
      this.setState({ detailsOfItemInCart: this.props.detailsOfItemInCart });
  }

  onChangeInstruction = (val) =>
    this.setState({
      detailsOfItemInCart: set(
        this.state.detailsOfItemInCart,
        "customerInstruction",
        val
      ),
    });

  onChangeItemQuantity = (increment = 0) => {
    const { detailsOfItemInCart } = this.state;
    const newQuantity = detailsOfItemInCart.quantity + increment;
    this.setState({
      detailsOfItemInCart: set(
        detailsOfItemInCart,
        "quantity",
        newQuantity < 1 ? 1 : newQuantity
      ),
    });
  };

  onSelectModifier = ({ modifierGroupID, modifiers }) =>
    this.setState({
      detailsOfItemInCart: set(
        this.state.detailsOfItemInCart,
        `modifierGroups.${modifierGroupID}.modifiers`,
        modifiers
      ),
    });

  onSubmit = () => {
    const { detailsOfItemInCart } = this.state;
    if (!this.props.isItemInCart)
      this.props.onAddItemToCart(detailsOfItemInCart);
    else this.props.onChangeItemDetailsInCart(detailsOfItemInCart);
  };

  renderButtons = () => {
    const { context = {} } = this.props;
    const { shopBasicInfo = {}, todayHours = {} } = context;
    return (
      <div className={Style.footerContainer}>
        {_renderButtons({
          quantityCalculator: this.renderQuantityCalculator(),
          shop_membership_status: _get(shopBasicInfo, "membership.status"),
          submitButton: this.renderSubmitButton(),
          todayHours,
        })}
      </div>
    );
  };

  renderCustomerInstructionField = () => (
    <div className={Style.sectionContainer}>
      <CustomerInstruction
        onInputChange={this.onChangeInstruction}
        text={this.state.detailsOfItemInCart.customerInstruction}
      />
    </div>
  );

  renderItemInfo = () => {
    const { itemImage } = this.props;
    return (
      <div className={Style.column_1}>
        {itemImage}
        {itemImage && (
          <div className={Style.imageDisclaimer}>
            * Image shown is for illustration purposes only
          </div>
        )}
        <div className={Style.item_info_container}>
          <ItemInfo itemInfo={this.props.itemInfo} />
        </div>
      </div>
    );
  };

  renderItemModifierGroups = () => {
    const { itemInfo = {} } = this.props;
    const { detailsOfItemInCart = {} } = this.state;
    const { modifierGroups = {} } = detailsOfItemInCart;
    return (
      <ItemModifierGroups
        categoryModifierGroups={this.props.categoryModifierGroups}
        itemModifierGroups={itemInfo.modifierGroups}
        onChangeLoadingModifierGroupsStatus={(status) =>
          this.setState({ isLoadingModifierGroups: status === "loading" })
        }
        onSelectModifier={this.onSelectModifier}
        selectedModifierGroups={modifierGroups}
      />
    );
  };

  renderQuantityCalculator = () => (
    <div style={{ marginRight: "15px" }}>
      <ItemQuantityCalculator
        currentQuantity={this.state.detailsOfItemInCart.quantity}
        onClick={this.onChangeItemQuantity}
      />
    </div>
  );

  renderSubmitButton = () => {
    const { itemIsOnSale = {}, itemPrice = 0 } = this.props.itemInfo;
    const { detailsOfItemInCart = {} } = this.state;
    const {
      totalPriceBeforeTaxWithoutSale,
      totalPriceBeforeTax,
    } = CalcTotalPriceBeforeTaxForItem({ detailsOfItemInCart });
    return (
      <div>
        <SubmitButton
          isItemInCart={this.props.isItemInCart}
          isLoadingModifierGroups={this.state.isLoadingModifierGroups}
          itemIsOnSale={Boolean(itemIsOnSale.true)}
          itemPrice={itemPrice}
          onClick={this.onSubmit}
          totalPriceBeforeTax={totalPriceBeforeTax}
          totalPriceBeforeTaxWithoutSale={totalPriceBeforeTaxWithoutSale}
        />
      </div>
    );
  };

  render() {
    return (
      <div
        className={cx(
          Style.container,
          !Boolean(this.props.itemImage) && Style.extraPaddingTop
        )}
      >
        {this.renderItemInfo()}
        <div className={Style.column_2}>
          <div className={Style.column_2_content}>
            {this.renderItemModifierGroups()}
            {this.renderCustomerInstructionField()}
            {this.renderButtons()}
          </div>
        </div>
      </div>
    );
  }
}

ItemDetailsForm.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      membership: PropTypes.shape({
        status: PropTypes.oneOf(["active", "comingSoon", "inactive"]),
      }).isRequired,
    }).isRequired,
    todayHours: PropTypes.shape({
      isShopClosed: PropTypes.bool,
      shopTodayOpenHours: PropTypes.object,
      today: PropTypes.oneOf([
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ]),
    }).isRequired,
  }).isRequired,
  categoryModifierGroups: PropTypes.object,
  detailsOfItemInCart: PropTypes.object,
  itemID: PropTypes.string.isRequired,
  itemImage: PropTypes.string,
  itemInfo: PropTypes.object.isRequired,
  isItemInCart: PropTypes.bool,
  onAddItemToCart: PropTypes.func,
  onChangeItemDetailsInCart: PropTypes.func,
  onCloseModal: PropTypes.func.isRequired,
};

ItemDetailsForm.defaultProps = {
  categoryModifierGroups: {},
  detailsOfItemInCart: {},
  isItemInCart: false,
  onAddItemToCart: () => {},
  onChangeItemDetailsInCart: () => {},
};

export default withContext(CustomerInterfaceConsumer)(ItemDetailsForm);
