// BUTI DINERS, INC. All right Reserved ©

import humanparser from "humanparser";

// Utils
import { _localizeTime } from "utils";

// Lib
import { Math } from "lib/functions";
import { Customers } from "lib/services";

const DELIVERY_FEE_DISCOUNT = 1; // $1;

const DROPOFF_OPTIONS = {
  attended_delivery: { label: "Hand it to me" },
  unattended_delivery: { label: "Leave it at my door" },
};

const _calculateDoordashQuote = async ({
  allow_delivery,
  dropoff_address,
  external_business_name,
  external_store_id,
  order_value,
  shop_adress,
  shop_time_zone,
}) => {
  const { DoordashDeliveryEstimate } = Customers.PostRequests;
  try {
    const pickup_address = _reformatShopAddress(shop_adress);
    const doordashQuote = await DoordashDeliveryEstimate({
      dropoff_address,
      external_business_name,
      external_store_id,
      order_value,
      pickup_address,
    });
    const {
      currency,
      delivery_time,
      fee = 0,
      id,
      pickup_time,
      pickup_window_end_time,
      pickup_window_start_time,
    } = doordashQuote;
    let feeInDollars = fee / 100 - DELIVERY_FEE_DISCOUNT;
    if (feeInDollars < 0) feeInDollars = 0;
    return {
      currency,
      dropoff_address,
      dropoffETA: delivery_time,
      id,
      localizedDropoffETA: _localizeTime({
        time: delivery_time,
        time_zone: shop_time_zone,
      }),
      localized_pickup_time: _localizeTime({
        time: pickup_time,
        time_zone: shop_time_zone,
      }),
      order_value,
      pickup_address,
      pickup_time,
      pickup_window_end_time,
      pickup_window_start_time,
      totalDeliveryFee: allow_delivery
        ? parseFloat(Math._roundNumber(feeInDollars))
        : 0,
    };
  } catch (error) {
    return "Failed to get a quote from Doordash. Please try again.";
  }
};

const _calculatePostmatesQuote = async ({
  allowDelivery,
  dropoffAddress,
  pickupAddress,
}) => {
  const { CreatePostmatesQuote } = Customers.PostRequests;
  try {
    const { postmatesQuote } = await CreatePostmatesQuote({
      dropoffAddress,
      pickupAddress,
    });
    const { dropoff_eta, fee, id } = postmatesQuote;
    const stripeProcessingFee = 1.029;
    let feeInDollars = fee / 100 - DELIVERY_FEE_DISCOUNT;
    if (feeInDollars < 0) feeInDollars = 0;
    return {
      dropoffETA: dropoff_eta,
      id,
      totalDeliveryFee: allowDelivery
        ? parseFloat(Math._roundNumber(feeInDollars * stripeProcessingFee))
        : 0,
    };
  } catch (error) {
    return error.message;
  }
};

const _reformatShopAddress = (shop_adress = "") => {
  const {
    address: street,
    city,
    state,
    zip: zip_code,
  } = humanparser.parseAddress(shop_adress);
  return { city, state, street, zip_code };
};

// const GOOGLE_MAPS_API = "https://maps.googleapis.com/maps/api/js";

// const GOOGLE_MAPS_KEYS = {
//   development: "AIzaSyC0_GYYtpyK39rEbZIv80IVJ_plrKsvp4U",
//   production: "AIzaSyDZYJzHKsOjoQlAXIKHY8TpQZd_srM5hU8",
// };

// onCalculateDistance = () => {
//   if (!this.state.isOrderAmountValid) return;
//   const origin = this.onGetShopAddress();
//   const destination = this.props.deliverAddress;
//   if (destination && origin) {
//     this.setState({ isCalculatingFee: true });
//     const { google } = window;
//     if (google) {
//       var service = new google.maps.DistanceMatrixService();
//       service.getDistanceMatrix(
//         {
//           origins: [origin],
//           destinations: [destination],
//           travelMode: "DRIVING",
//           unitSystem: google.maps.UnitSystem.IMPERIAL,
//         },
//         this.onHandleGoogleMapsResponse
//       );
//     }
//   } else {
//     this.setState({ isCalculatingFee: false });
//   }
// };

export { DROPOFF_OPTIONS, _calculateDoordashQuote, _calculatePostmatesQuote };
