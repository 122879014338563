// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { withRouter } from "react-router-dom";
import { set } from "object-path-immutable";

// Lib
import { ScrollToComponent } from "lib/functions";

class ScrollIntoView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.refs = {};
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      ScrollToComponent({ behavior: "auto", ref: this.refs["topOfPage"] });
    }
  };

  render = () => (
    <div
      ref={(ref) => {
        this.refs = set(this.refs, "topOfPage", ref);
      }}
    >
      {this.props.children}
    </div>
  );
}

export default withRouter(ScrollIntoView);
