// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Button, Input } from "fields";

// Icons
import { CloseIcon, MagnifyingGlassIcon } from "assets/Icons";

class MenuFilterModal extends React.Component {
  componentDidMount() {
    window.addEventListener("beforeunload", this._confirm);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this._confirm);
    window.onpopstate = () => {};
  }

  _backConfirm = () => this.props.onCloseModal();

  _confirm = (e) => {
    var confirmationMessage = "o/";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  onSubmitForm = (e) => {
    e && e.preventDefault();
    this.props.onApplyFilters();
  };

  renderButtons = () => (
    <div className={Style.buttonsContainer}>
      <Button
        className={Style.applyButton}
        name="apply filters"
        onClick={this.onSubmitForm}
      >
        <MagnifyingGlassIcon /> Search
      </Button>

      <Button
        className={Style.clearButton}
        name="clear filters"
        onClick={this.props.onClearFilters}
      >
        <CloseIcon /> Clear
      </Button>
    </div>
  );

  render() {
    const { filters = {} } = this.props;
    return (
      <Modals.ConfirmModal
        classNames={{
          modalBox: Style.modalBox,
        }}
        color="info"
        contentLabel="Search"
        heading="Search In Menu"
        onCloseModal={this.props.onCloseModal}
      >
        <form onSubmit={this.onSubmitForm}>
          <Input.TextInput
            label="Item Name"
            name="search item name"
            onChange={(value) =>
              this.props.onChangeFilters({ key: "itemName", value })
            }
            placeholder="Ramen"
            value={filters["itemName"] || ""}
          />
          {this.renderButtons()}
        </form>
      </Modals.ConfirmModal>
    );
  }
}

MenuFilterModal.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    itemName: PropTypes.string,
  }),
};

MenuFilterModal.defaultProps = {
  filters: {},
};

export default MenuFilterModal;
