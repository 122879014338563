// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

// Fields
import { SkipliLogoCircleIcon } from "assets/Icons";

class MatchingShopsNavbar extends React.Component {
  render() {
    return (
      <div className={Style.customerHeaderContainer}>
        <div className={Style.desktopNavbar}>
          <SkipliLogoCircleIcon className={Style.skipliLogoIcon} />
        </div>
      </div>
    );
  }
}

export default MatchingShopsNavbar;
