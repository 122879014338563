// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icons
import { CheckIcon } from "assets/Icons";

// Lib
import { Math } from "lib/functions";

class Modifier extends React.Component {
  componentDidMount = () =>
    this.props.isSelectedAsDefault && this.onSelectModifier();

  onSelectModifier = () => {
    const { modifierInfo } = this.props;
    const {
      modifierKitchenChitName = "",
      modifierName,
      modifierPrice = "0.00",
    } = modifierInfo;
    const result = { modifierName, modifierPrice };
    this.props.onSelectModifier(
      !modifierKitchenChitName ? result : { ...result, modifierKitchenChitName }
    );
  };

  renderCheckBox = () => (
    <div className={cx(Style.checkbox, this.props.isSelected && Style.checked)}>
      <CheckIcon className={Style.checkIcon} />
    </div>
  );

  renderModifierInfo = () => {
    const { modifierInfo } = this.props;
    const {
      modifierDescription = "",
      modifierName,
      modifierPrice = 0,
    } = modifierInfo;
    const price = parseFloat(modifierPrice) > 0 ? modifierPrice : 0;
    return (
      <div className={Style.modifierInfoColumn}>
        <div style={{ paddingRight: "10px" }}>
          <div className={Style.modifierName}>{modifierName}</div>
          {modifierDescription && (
            <p className={Style.modifierDescription}>{modifierDescription}</p>
          )}
        </div>
        <div className={Style.modifierPrice}>
          + ${price > 0 ? Math._roundNumber(price) : 0}
        </div>
      </div>
    );
  };

  renderRadioButton = () => (
    <div
      className={cx(Style.radioButton, this.props.isSelected && Style.checked)}
    >
      <div className={Style.circle} />
    </div>
  );

  render() {
    return (
      <div>
        <button className={Style.modifier} onClick={this.onSelectModifier}>
          <div className={Style.selectBoxColumn}>
            {this.props.allowMultipleSelect
              ? this.renderCheckBox()
              : this.renderRadioButton()}
          </div>
          {this.renderModifierInfo()}
        </button>
      </div>
    );
  }
}

Modifier.propTypes = {
  allowMultipleSelect: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSelectedAsDefault: PropTypes.bool,
  modifierInfo: PropTypes.shape({
    modifierDescription: PropTypes.string,
    modifierName: PropTypes.string.isRequired,
    modifierPrice: PropTypes.any,
  }).isRequired,
  onSelectModifier: PropTypes.func.isRequired,
};

Modifier.defaultProps = {
  allowMultipleSelect: true,
  isSelectedAsDefault: false,
  isSelected: false,
};

export default Modifier;
