// BUTI DINERS, INC. All right Reserved ©

const _getModalContent = ({ fee_id }) => {
  switch (fee_id) {
    case "convenience":
      return "Packaging & Handling Fee helps us cover the costs of takeout containers, bags, and other essential supplies, as well as the labor involved in carefully preparing and packing your order. This ensures that your food is securely packaged, stays fresh, and arrives safely, so you can enjoy it at its best.";
    case "online_payment_processing":
      return "The online payment processing fee helps cover the costs of securely handling and processing your payment. This fee supports the technology and systems that ensure your transaction is safe, fast, and seamless.";
    case "sub_total":
      return "Subtotal is the total cost of all items before taxes and additional charges are applied.";
    default:
      return "";
  }
};

export { _getModalContent };
