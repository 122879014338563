// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Assets
import { CreditCardIcon, OrdersIcon, UserIcon } from "assets/Icons";

const CUSTOMER_PROFILE_MODULES = {
  view_profile: { icon: <UserIcon />, label: "Profile" },
  past_orders: { icon: <OrdersIcon />, label: "Orders" },
  payment_methods: { icon: <CreditCardIcon />, label: "Payment" },
};

export { CUSTOMER_PROFILE_MODULES };
