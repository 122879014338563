// BUTI DINERS, INC. All right Reserved ©

import Math from "./Math";
import { STRIPE } from "lib/_constants";

const { STRIPE_TRANSACTION_FEES } = STRIPE;

const DEFAULT_SKIPLI_SHARE_OF_TIPS = {
  pickUp: { amount: "70", rate: "percentage" },
  deliver: { amount: "100", rate: "percentage" },
  catering: { amount: "70", rate: "percentage" },
  inStore: { amount: "50", rate: "percentage" },
};

// const WAIVE_GUEST_FEE_FOR_STORES = [
//   "602404g",
//   "276401p",
//   "631221g",
//   "633793q",
//   "585016m",
//   "276401p",
//   "142622u",
//   "265027s",
//   "927448u",
//   "232751y",
//   "466094k",
//   "499987k",
//   "396352g",
//   "398428e",
//   "169366y",
//   "818841c",
//   "398428e",
//   "310085j",
//   "170191m",
//   "439273v",
//   "408974j",
//   "127685r",
//   "730783y",
// ];

const CalculateServiceFee = ({
  orderDeliveryTypeID = "pickUp",
  shop_id = "",
  subTotal = 0,
}) => {
  return 0;
  // if (WAIVE_GUEST_FEE_FOR_STORES.includes(shop_id)) {
  //   return 0;
  // }
  // return parseFloat(
  //   Math._roundNumber(
  //     subTotal * parseFloat(_getServiceFeeMultiplier({ orderDeliveryTypeID }))
  //   )
  // );
};

// const _getServiceFeeMultiplier = ({ orderDeliveryTypeID }) => {
//   switch (orderDeliveryTypeID) {
//     case "inStore":
//       return 0;
//     default:
//       return 0.05;
//   }
// };

const CalculateTechnologyFee = ({
  orderDeliveryTypeID = "inStore",
  skipliShareOfTips = {},
  tipAmount = 0,
}) => {
  const shareOfTips =
    Object.keys(skipliShareOfTips).length === 0
      ? DEFAULT_SKIPLI_SHARE_OF_TIPS
      : skipliShareOfTips;
  const { amount = "0", rate = "percentage" } =
    shareOfTips[orderDeliveryTypeID] || {};

  if (rate === "percentage") {
    const result = parseFloat(tipAmount) * (parseFloat(amount) / 100);
    return parseFloat(Math._roundNumber(result));
  }
  return 0;
};

const CalculateApplicationFeeAmount = ({
  merchantFeesMonetaryAmount = 0,
  orderDeliveryTypeID = "inStore",
  skipliShareOfTips,
  shop_id,
  stripeTotalAmount,
  subTotal,
  tipAmount = 0,
  totalDeliveryFee = 0,
}) => {
  const { BASE_FEE, CARD_FEE, CONV_FEE } = STRIPE_TRANSACTION_FEES;

  const creditCardFee = CARD_FEE;
  const serviceFee = CalculateServiceFee({
    orderDeliveryTypeID,
    shop_id,
    subTotal,
  });
  const technologyFee = CalculateTechnologyFee({
    orderDeliveryTypeID,
    skipliShareOfTips,
    tipAmount,
  });

  // Application Fee for Stripe
  const applicationFeeAmount =
    parseFloat(stripeTotalAmount) * creditCardFee +
    100 *
      (BASE_FEE +
        CONV_FEE +
        serviceFee +
        technologyFee +
        merchantFeesMonetaryAmount +
        totalDeliveryFee);
  return parseInt(Math._roundUp(applicationFeeAmount));
};

const CartFuncs = {
  CalculateApplicationFeeAmount,
  CalculateTechnologyFee,
  CalculateServiceFee,
};

export default CartFuncs;
