// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icons
import { CheckIcon, CloseIcon } from "assets/Icons";

// Fields
import { Button, ResponsiveModal } from "fields";

class CreditCard extends React.Component {
  state = { showEditOptions: false };

  componentDidMount = () =>
    this.setState({ showEditOptions: this.props.isCardEditable });

  onConfirmRemoval = () => this.setState({ showConfirmRemoveCardModal: true });

  onSelectCard = (fingerprint) => {
    const { onSelect } = this.props;
    if (onSelect) onSelect(fingerprint);
  };

  renderConfirmRemoveCardModal = () => (
    <ResponsiveModal
      contentDescriptionProps={{
        contentDescription:
          "We secured and encrypted your card so you can enjoy faster checkout at any stores powered by Skiplinow.com",
        showContentDescription: true,
      }}
      classNames={{ modal: Style.modal }}
      contentLabel="remove credit card modal"
      headerProps={{
        header: "Remove this card?",
        show_back_button: true,
        show_close_button: false,
      }}
      onCloseModal={() => this.setState({ showConfirmRemoveCardModal: false })}
    >
      <div className={Style.dialogButtonsContainer}>
        <button
          className={Style.keepItemButton}
          onClick={() => this.setState({ showConfirmRemoveCardModal: false })}
        >
          Keep Card
        </button>
        <button
          className={Style.removeItemButton}
          onClick={this.props.onRemoveCard}
        >
          Remove
        </button>
      </div>
    </ResponsiveModal>
  );

  renderCheckbox = () => {
    const { isCardSelected, onSelect } = this.props;
    return (
      onSelect && (
        <div className={cx(Style.checkIcon, isCardSelected && Style.selected)}>
          {isCardSelected && <CheckIcon />}
        </div>
      )
    );
  };

  renderMethodInfo = ({ brand, exp_month, exp_year, last4 }) => (
    <div className={Style.methodInfo}>
      <div className={Style.methodInfoMainText} style={{ marginRight: "10px" }}>
        •••• {last4}
      </div>
      <div className={Style.methodInfoMainText} style={{ marginRight: "10px" }}>
        {exp_month}/{exp_year}
      </div>
      <div className={Style.cardBrand}>{brand}</div>
    </div>
  );

  renderRemoveButton = () => (
    <div className={Style.removeButton} onClick={this.onConfirmRemoval}>
      <CloseIcon />
    </div>
  );

  render() {
    const { card = {}, isCardSelected } = this.props;
    const { showEditOptions } = this.state;
    const { fingerprint } = card;
    return (
      <div>
        {this.state.showConfirmRemoveCardModal &&
          this.renderConfirmRemoveCardModal()}
        <Button
          className={cx(Style.paymentMethod, isCardSelected && Style.selected)}
          name="Credit/Debit Card"
          onClick={() => this.onSelectCard(fingerprint)}
        >
          {this.renderCheckbox()}
          {showEditOptions && this.renderRemoveButton()}
          {this.renderMethodInfo(card)}
        </Button>
      </div>
    );
  }
}

CreditCard.propTypes = {
  card: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    exp_month: PropTypes.number.isRequired,
    exp_year: PropTypes.number.isRequired,
    fingerprint: PropTypes.string.isRequired,
    last4: PropTypes.string.isRequired,
  }).isRequired,
  isCardEditable: PropTypes.bool,
  isCardSelected: PropTypes.bool,
  onRemoveCard: PropTypes.func,
  onSelect: PropTypes.func,
};

CreditCard.defaultProps = {
  isCardEditable: false,
  onRemoveCard: () => {},
  onSelect: null,
};

export default CreditCard;
