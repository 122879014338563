// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";
import { set } from "object-path-immutable";

// Utils
import { _scrollTo, _updatePageTitleAndDescription } from "utils";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Components
import { TestimonialsModules } from "components";

class Testimonials extends React.Component {
  componentDidMount = () => {
    _updatePageTitleAndDescription({
      description:
        "Skipli is a marketing, website, and ecommerce platform for restaurants worldwide. Here are some of our favorite restaurant success stories.",
      title: "Testimonials | Skipli",
    });
  };

  renderPageIntro = () => (
    <div className={Style.pageIntro}>
      <h1 className={Style.heading}>Success Stories</h1>
      <div>
        <p className={Style.paragraph}>
          Skipli is a website, ecommerce and marketing platform for restaurants
          worldwide. Here are some of our favorite restaurant success stories.
        </p>
        <button
          className={Style.callToActionButton}
          onClick={() => _scrollTo({ ref: this.refs["references"] })}
        >
          See what our partners say
        </button>
      </div>
    </div>
  );

  render() {
    return (
      <div className={cx(MainWebsiteStyle.pageContainer, Style.container)}>
        <img
          alt=""
          className={Style.image}
          src="https://i.imgur.com/0VqOpS9.png"
        />
        {this.renderPageIntro()}
        <div
          ref={(ref) => {
            this.refs = set(this.refs, "references", ref);
          }}
        >
          <TestimonialsModules.References />
        </div>
      </div>
    );
  }
}

export default Testimonials;
