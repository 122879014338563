// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ReactDOM from "react-dom";
import { StripeProvider } from "react-stripe-elements";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ReactModal from "react-modal";
import { ErrorPage, Oppties } from "views";

import { ScrollIntoView } from "fields";

// Style
import "./index.css";

// Constants
import { ROUTES, STRIPE } from "lib/_constants";
const { STRIPE_CREDENTIALS } = STRIPE;

ReactModal.setAppElement("#root");

const Routing = () => {
  if (window.location.protocol !== "https:") {
    const secure = document.URL.replace(/^http:/, "https:");
    window.location.replace(secure);
  } else {
    return (
      <StripeProvider apiKey={STRIPE_CREDENTIALS.STRIPE_PUBLIC_KEY}>
        <Router>
          <div style={{ height: "auto" }}>
            <ScrollIntoView>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => ROUTES("home_page", props)}
                />
                <Route
                  exact
                  path="/browseFood"
                  render={(props) => ROUTES("browseFood", props)}
                />
                <Route
                  exact
                  path="/auto-phone"
                  render={(props) => ROUTES("autoPhone", props)}
                />
                <Route
                  exact
                  path="/contactless"
                  render={(props) => ROUTES("contactless", props)}
                />
                <Route exact path="/delivery">
                  <Redirect to="/" />
                </Route>
                <Route
                  exact
                  path="/getstarted"
                  render={(props) => ROUTES("getstarted", props)}
                />
                <Route
                  exact
                  path="/get-started"
                  render={(props) => ROUTES("getstarted", props)}
                />
                <Route
                  exact
                  path="/google-front-page"
                  render={(props) => ROUTES("googleFrontPage", props)}
                />
                <Route
                  exact
                  path="/order-food"
                  render={(props) => ROUTES("browseFood", props)}
                />
                <Route exact path="/pricing">
                  <Redirect to="/get-started" />
                </Route>
                <Route
                  exact
                  path="/privacy"
                  render={(props) => ROUTES("privacy", props)}
                />
                <Route
                  exact
                  path="/terms"
                  render={(props) => ROUTES("terms", props)}
                />
                <Route
                  exact
                  path="/testimonials"
                  render={(props) => ROUTES("testimonials", props)}
                />
                <Route
                  exact
                  path="/virtual-restaurant"
                  render={(props) => ROUTES("virtualRestaurant", props)}
                />
                <Route exact path="/website-design">
                  <Redirect to="/" />
                </Route>
                <Route
                  exact
                  path="/website-examples"
                  render={(props) => ROUTES("websiteExamples", props)}
                />
                <Route
                  exact
                  path="/blog"
                  render={(props) => ROUTES("blog", props)}
                />
                <Route
                  exact
                  path="/blog/:title"
                  render={(props) => ROUTES("blog_post", props)}
                />
                <Route
                  exact
                  path="/receipts/:paymentIntentID"
                  render={(props) => ROUTES("receipts", props)}
                />
                <Route
                  exact
                  path="/shop/:shopID"
                  render={(props) => ROUTES("foodMenu", props)}
                />
                <Route
                  exact
                  path="/shop/:shopID/admin"
                  render={(props) => ROUTES("shopAdmin", props)}
                />
                <Route
                  exact
                  path="/shop/:shopID/catering"
                  render={(props) => ROUTES("catering", props)}
                />
                <Route
                  exact
                  path="/shop/:shopID/deals"
                  render={(props) => ROUTES("deals", props)}
                />
                <Route
                  exact
                  path="/shop/:shopID/cart"
                  render={(props) => ROUTES("cart", props)}
                />
                <Route
                  exact
                  path="/shop/:shopID/foodMenu"
                  render={(props) => ROUTES("foodMenu", props)}
                />
                <Route
                  exact
                  path="/shop/:shopID/service"
                  render={(props) => ROUTES("service", props)}
                />
                <Route
                  path="/shop/:shopID/site"
                  render={(props) => ROUTES("shopWebsite", props)}
                />
                <Route
                  exact
                  path="/shop/:shopID/review"
                  render={(props) => ROUTES("shop_survey", props)}
                />
                <Route
                  exact
                  path="/shop/:shopID/order/:order_id"
                  render={(props) => ROUTES("order_tracking", props)}
                />
                <Route
                  exact
                  path="/:shopID/:tableNumber"
                  render={() => ROUTES("businessCardShop")}
                />
                <Route path="/careers" component={Oppties} />
                <Route component={ErrorPage} />
              </Switch>
            </ScrollIntoView>
            <ToastContainer transition={Flip} />
          </div>
        </Router>
      </StripeProvider>
    );
  }
};

ReactDOM.render(Routing(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
