/* eslint-disable jsx-a11y/img-redundant-alt */
// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Assets
import placeholderImg from "assets/Images/image-placeholder.png";

const Img = (props) => (
  <img
    alt="Photo Not Found"
    onClick={props.onClick}
    onLoad={props.onLoad}
    className={cx(Style.image, props.className)}
    src={props.src || placeholderImg}
    style={props.style}
    onError={(e) => {
      e.target.src = placeholderImg;
      props.onError();
    }}
  />
);

Img.propTypes = {
  className: PropTypes.string,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  src: PropTypes.string,
};

Img.defaultProps = {
  className: "",
  onError: () => {},
  onLoad: () => {},
  src: "",
};

export default Img;
