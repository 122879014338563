// BUTI DINERS, INC. All right Reserved ©

import ListOfItems from "./ListOfItems";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import MenuItemDetail from "./MenuItemDetail";

const CustomerMenuElements = {
  ListOfItems,
  Menu,
  MenuItem,
  MenuItemDetail,
};

export default CustomerMenuElements;
