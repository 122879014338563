// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { del } from "object-path-immutable";

import StoredPaymentMethods from "components/ConfirmOrderModules/StoredPaymentMethods";
import { GetUpdatedCustomerProfile } from "components/ConfirmOrder/helperFunctions";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Lib
import { LocalStorage, ShowConfirmNotif } from "lib/functions";
import { Customers } from "lib/services";

const { GetCustomerPaymentMethods } = Customers.GetRequests;
const {
  ChangeCustomerProfile,
  DetachPaymentMethodFromCustomer,
} = Customers.PostRequests;

class PaymentMethodsModule extends React.Component {
  confirmNotif = null;
  state = { paymentMethods: {} };

  componentDidMount = () => this.onGetCustomerPaymentMethods();

  onAddPaymentMethod = () => this.setState({ showAddPaymentMethodModal: true });

  onCloseAddPaymentMethodModal = () => {
    this.setState(
      { showAddPaymentMethodModal: false },
      this.onGetCustomerPaymentMethods
    );
  };

  onGetCustomerPaymentMethods = async () => {
    this.setState({ isLoadingPaymentMethods: true });
    const { stripeCustomerID } = this.props.context.customerProfile;
    if (stripeCustomerID) {
      const { paymentMethods = {} } = await GetCustomerPaymentMethods({
        stripeCustomerID,
      });
      this.setState({ paymentMethods });
    }
    this.setState({ isLoadingPaymentMethods: false });
  };

  onRemovePaymentMethod = async (paymentMethodID) => {
    this.setState({ isLoading: true });
    const { success } = await DetachPaymentMethodFromCustomer({
      paymentMethodID,
    });
    this.setState({ isLoading: false });
    this.confirmNotif = ShowConfirmNotif({
      message: success ? "Removed the card" : "Please try again",
      type: success ? "success" : "error",
    });
    if (success) {
      const { paymentMethods } = this.state;
      const fingerprint = Object.keys(paymentMethods).find((fingerprint) => {
        const { id } = paymentMethods[fingerprint];
        return id === paymentMethodID;
      });
      if (fingerprint)
        this.setState({
          paymentMethods: del(paymentMethods, fingerprint),
        });
    }
  };

  onUpdateCustomerProfileAfterAdd = async ({ billingDetails = {} }) => {
    const updatedCustomerProfile = GetUpdatedCustomerProfile({
      customerProfile: this.props.context.customerProfile,
      newData: billingDetails,
    });
    const { success } = await ChangeCustomerProfile({
      customerProfile: updatedCustomerProfile,
      uuid: LocalStorage.getItemFromStorage("uuid"),
    });
    if (success) {
      const { onChangeCustomerProfile } = this.props.context;
      onChangeCustomerProfile &&
        onChangeCustomerProfile(updatedCustomerProfile);
    }
  };

  renderAddPaymentMethodModal = () => (
    <Modals.AddNewPaymentMethodModal
      onCloseModal={this.onCloseAddPaymentMethodModal}
      onUpdateCustomerProfileAfterAdd={this.onUpdateCustomerProfileAfterAdd}
      pastPaymentMethodFingerprints={Object.keys(
        this.state.paymentMethods || {}
      )}
    />
  );

  render() {
    return (
      <div>
        {this.state.isLoading && <Modals.LoadingModal />}
        {this.state.showAddPaymentMethodModal &&
          this.renderAddPaymentMethodModal()}
        <StoredPaymentMethods
          isCardEditable={true}
          isLoadingPaymentMethods={this.state.isLoadingPaymentMethods}
          onRemovePaymentMethod={this.onRemovePaymentMethod}
          paymentMethods={this.state.paymentMethods}
        />
        <button
          className={Style.addCardButton}
          onClick={this.onAddPaymentMethod}
        >
          Add New Card
        </button>
      </div>
    );
  }
}

export default withContext(CustomerInterfaceConsumer)(PaymentMethodsModule);
