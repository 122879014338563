// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Introduction from "./Introduction";
import Guarantee from "./Guarantee";
import Features from "./Features";

// Utils
import { _updatePageTitleAndDescription } from "utils";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Components
import { LetsChatBox } from "components";

// Fields
import { TestimonialBox } from "fields";

class GoogleFrontPage extends React.Component {
  componentDidMount = () => {
    _updatePageTitleAndDescription({
      description:
        "Get your restaurant on the Front Page of Google in 60 days. Quickly increase website traffic. Be seen by thousands of potential customers. Earn more revenue from online orders. Reduce marketing cost.",
      title:
        "Google Front Page | Social Media Management Presence Service | Skipli",
    });
  };

  render() {
    const { onUpdateRefs } = this.props.context;
    return (
      <div className={Style.pageContainer}>
        <Introduction />
        <div
          ref={(ref) =>
            onUpdateRefs({
              refID: "google_front_page_sixty_day_guarantee",
              ref,
            })
          }
        >
          <Guarantee />
        </div>
        <div
          ref={(ref) =>
            onUpdateRefs({ refID: "google_front_page_features", ref })
          }
        >
          <Features />
        </div>
        <div style={{ marginTop: "6rem" }}>
          <div className={MainWebsiteStyle.sectionContainer}>
            <TestimonialBox
              profileImage="https://i.imgur.com/ymA7dHg.png"
              profileName="Huong Pham | Owner | Pho 4 U"
              text={`“Google is the first place our guests are finding us. We need to keep our information accurate, but we're also extremely busy. Skipli not only does it for us but also go out of their way to understand our company and delivers a brand that is unique to us. Our online orders have tripled!”`}
            />
          </div>
        </div>
        <div style={{ marginTop: "4rem" }}>
          <div className={MainWebsiteStyle.sectionContainer}>
            <LetsChatBox
              heading="We can put your business on the map"
              description="Our specialists will make your restaurant successful online for an unbeatable price - no prior knowledge required."
            />
          </div>
        </div>
      </div>
    );
  }
}

GoogleFrontPage.propTypes = {
  context: PropTypes.shape({
    onUpdateRefs: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(GoogleFrontPage)
);
