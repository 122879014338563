// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Assets
import { StoreIcon, UserIcon } from "assets/Icons";

const SKIPLI_INFO_OPTIONS = {
  aboutUs: {
    label: "About Us",
    url: "https://skiplinow.com",
  },
  careers: {
    label: "Careers",
    url: "https://www.skiplinow.com/careers",
  },
  newsroom: {
    label: "SKIPLI Newsroom",
    url: "https://www.facebook.com/skiplirestaurants",
  },
  beAPartner: {
    label: "Be a Partner Restaurant",
    url: "https://www.skiplinow.com/get-started",
  },
  terms: {
    label: "Terms of Service",
    url: "https://www.skiplinow.com/terms",
  },
  privacy: {
    label: "Privacy",
    url: "https://www.skiplinow.com/privacy",
  },
};

const _createMainMenuOptions = ({ shop_name }) => ({
  about_shop: {
    icon: <StoreIcon />,
    label: `About ${shop_name}`,
  },
  customer_profile: {
    icon: <UserIcon />,
    label: "Your Profile",
  },
});

export { SKIPLI_INFO_OPTIONS, _createMainMenuOptions };
