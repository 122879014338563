// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import Img from "react-cool-img";

import { TRUSTED_PARTNERS } from "./constants";

// Style
import Style from "./style.module.scss";

// Fields
import { Carousel } from "fields";

class TrustedByCarousel extends React.Component {
  renderDesktopTestimonials = () =>
    TRUSTED_PARTNERS.map(
      ({ business_name, logo_url, owner_name, profile_pic_url, testimony }) => (
        <div className={Style.testimony_container}>
          <div className={Style.profile_pic_column}>
            <div className={Style.profile_pic_container}>
              <Img alt="" src={profile_pic_url} />
            </div>
          </div>
          <div className={Style.text_column}>
            <Img alt="" className={Style.logo} src={logo_url} />
            <h3 className={Style.testimony}>"{testimony}"</h3>
            <h4 className={Style.owner_name}>{owner_name}</h4>
            <div className={Style.owner_of}>
              Owner of <u>{business_name}</u>
            </div>
          </div>
        </div>
      )
    );

  renderMobileTestimonials = () =>
    TRUSTED_PARTNERS.map(
      ({ business_name, logo_url, owner_name, profile_pic_url, testimony }) => (
        <div className={Style.mobile_testimony_container}>
          <div className={Style.mobile_profile_pic_container}>
            <Img alt="" src={profile_pic_url} />
          </div>
          <div className={Style.mobile_text_column}>
            <Img alt="" className={Style.mobile_logo} src={logo_url} />
            <h3 className={Style.mobile_testimony}>"{testimony}"</h3>
            <h4 className={Style.mobile_owner_name}>{owner_name}</h4>
            <div className={Style.mobile_owner_of}>
              Owner of <u>{business_name}</u>
            </div>
          </div>
        </div>
      )
    );

  render() {
    return (
      <div className={Style.container}>
        <h2 className={Style.title}>
          Trusted by thousands of restaurant owners.
        </h2>
        <div className={Style.desktop_carousel}>
          <Carousel
            class_names={{
              slides_container: Style.slides_container,
              slide: Style.slide,
            }}
            slides={this.renderDesktopTestimonials()}
          />
        </div>
        <div className={Style.mobile_carousel}>
          <Carousel
            class_names={{
              slides_container: Style.mobile_slides_container,
              slide: Style.mobile_slide,
            }}
            slides={this.renderMobileTestimonials()}
          />
        </div>
      </div>
    );
  }
}

export default TrustedByCarousel;
