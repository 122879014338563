// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Img from "react-cool-img";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icons
import { SkipliLogoWithTextWhiteIcon } from "assets/Icons";

const ErrorPage = ({
  back_button,
  class_names = {},
  custom_content,
  description,
  error_code,
  header,
  history,
  image_src,
  show_skipli_logo,
}) => {
  const renderContent = () => (
    <div className={Style.text}>
      <h1 className={cx(class_names.header, Style.header)}>{header}</h1>
      <p className={cx(class_names.description, Style.description)}>
        {description}
      </p>
      {error_code && (
        <p className={Style.error_code}>Error Code: {error_code}</p>
      )}
    </div>
  );

  return (
    <div className={Style.page_container}>
      {show_skipli_logo && (
        <div
          className={cx(class_names.skipli_logo, Style.skipli_logo)}
          onClick={() => history.push("")}
        >
          <SkipliLogoWithTextWhiteIcon />
        </div>
      )}
      <div
        className={cx(class_names.content_container, Style.content_container)}
      >
        <Img alt="error image" className={Style.image} src={image_src} />
        {custom_content ? custom_content : renderContent()}
        <button
          className={Style.back_button}
          onClick={() => history.push(back_button.url)}
        >
          {back_button.label}
        </button>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  back_button: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  class_names: PropTypes.shape({
    content_container: PropTypes.string,
    description: PropTypes.string,
    header: PropTypes.string,
    skipli_logo: PropTypes.string,
  }),
  custom_content: PropTypes.object,
  description: PropTypes.string,
  error_code: PropTypes.number,
  header: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  image_src: PropTypes.string,
  show_skipli_logo: PropTypes.bool,
};

ErrorPage.defaultProps = {
  back_button: { label: "Back home", url: "/" },
  customContent: null,
  description: "What you're looking for must be somewhere else...",
  header: "Nothing found!",
  image_src:
    "https://skipli.s3.amazonaws.com/Skipli+Website/Error+Page/page-not-found-4.png",
  show_skipli_logo: true,
};

export default withRouter(ErrorPage);
