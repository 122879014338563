// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import shortid from "shortid";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";
import { _initializeDetailsOfItemInCart } from "components/CustomerMenuElements/MenuItemDetail/ItemDetailsForm/helperFunctions";

// Fields
import { Button, QuantityCalculator } from "fields";

// Assets
import { CheckIcon, CloseIcon } from "assets/Icons";

// Lib
import { LocalStorage, ShowConfirmNotif } from "lib/functions";
import { Customers } from "lib/services";

const { ChangeCustomerCart } = Customers.PostRequests;

class QuantitySelector extends React.Component {
  constructor(props) {
    super(props);
    const { itemID, itemInfo = {} } = props;
    this.state = {
      detailsOfItemInCart: _initializeDetailsOfItemInCart({ itemID, itemInfo }),
      loadedModifierGroups: {},
    };
  }

  onAddItemToCart = async () => {
    this.setState({ isAddingItemToCart: true });
    const { context } = this.props;
    const {
      cart = {},
      onChangeCustomerCart = () => {},
      shopID = "",
      showCateringMenu = false,
    } = context;
    const newCart = set(
      cart,
      shortid.generate(),
      this.state.detailsOfItemInCart
    );
    try {
      await ChangeCustomerCart({
        applyToCateringCart: showCateringMenu,
        cart: newCart,
        shopID,
        uuid: LocalStorage.getItemFromStorage("uuid"),
      });
      ShowConfirmNotif({
        message: "Added item to your cart. Yum!",
        type: "success",
      });
      onChangeCustomerCart(newCart);
      this.props.onHideQuantitySelector();
    } catch {
      ShowConfirmNotif({
        message: "Failed to add item. Please try again.",
        type: "error",
      });
      this.setState({ isAddingItemToCart: false });
    }
  };

  onChangeQuantity = (increment = 0) => {
    const { detailsOfItemInCart } = this.state;
    const { quantity = 1 } = detailsOfItemInCart;
    const newQuantity = quantity + increment;
    this.setState({
      detailsOfItemInCart: set(
        detailsOfItemInCart,
        "quantity",
        newQuantity < 1 ? 1 : newQuantity
      ),
    });
  };

  renderQuantitySelector = () => {
    const { detailsOfItemInCart = {} } = this.state;
    const { quantity: currentQuantity = 1 } = detailsOfItemInCart;
    return (
      <QuantityCalculator
        className={Style.quantityCalculator}
        currentQuantity={currentQuantity}
        disableMinusButton={currentQuantity === 1}
        quantityNumberClassName={Style.quantityNumber}
        minusButtonClassName={Style.minusButton}
        onButtonClick={this.onChangeQuantity}
        plusButtonClassName={Style.plusButton}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isAddingItemToCart && (
          <Modals.LoadingModal message="Adding item" />
        )}
        <div className={Style.container}>
          <div className={Style.content}>
            {this.renderQuantitySelector()}
            <Button
              className={Style.confirmButton}
              name="add item to cart"
              onClick={this.onAddItemToCart}
            >
              <CheckIcon />
            </Button>
            <Button
              className={Style.cancelButton}
              name="hide quantity selector"
              onClick={this.props.onHideQuantitySelector}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

QuantitySelector.propTypes = {
  categoryModifierGroups: PropTypes.object,
  context: PropTypes.shape({
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  itemID: PropTypes.string.isRequired,
  itemInfo: PropTypes.object.isRequired,
  onHideQuantitySelector: PropTypes.func.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(QuantitySelector);
