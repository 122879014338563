// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import CheckboxAndRadioButton from "./index";

class Radio extends React.Component {
  render() {
    return <CheckboxAndRadioButton {...this.props} kind="radio" />;
  }
}

Radio.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  description: PropTypes.any,
  id: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func,
  radioButtonClassName: PropTypes.string,
  readOnly: PropTypes.bool,
};

Radio.defaultProps = {
  className: "",
  checked: false,
  labelClassName: "",
  onClick: () => {},
  radioButtonClassName: "",
  readOnly: false,
};

export default Radio;
