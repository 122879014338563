// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash.isempty";
import Img from "react-cool-img";

// Style
import Style from "./style.module.scss";

// Fields
import { Link } from "fields";

// Assets
import { SendEmailIcon } from "assets/Icons";
import Facebook_Logo from "assets/Images/facebook.png";
import Google_Logo from "assets/Images/google.png";
import Instagram_Logo from "assets/Images/instagram.png";
import TripAdvisor_Logo from "assets/Images/tripadvisor.png";
import Yelp_Logo from "assets/Images/yelp.png";
import Rating5 from "assets/Images/Rating-5.png";

class ShopSurveyFeedbackSent extends React.Component {
  _getSocialMediaLogo = (social_media_id) => {
    switch (social_media_id) {
      case "facebook":
        return Facebook_Logo;
      case "google":
        return Google_Logo;
      case "instagram":
        return Instagram_Logo;
      case "tripadvisor":
        return TripAdvisor_Logo;
      case "yelp":
        return Yelp_Logo;
      default:
        return;
    }
  };

  renderSocialMediaProfiles = (social_media_profiles = {}) =>
    Object.keys(social_media_profiles).map((social_media_id) => {
      const { name, profile_href = "" } = social_media_profiles[
        social_media_id
      ];
      return (
        !_isEmpty(profile_href) && (
          <React.Fragment key={social_media_id}>
            <Link className={Style.link} href={profile_href}>
              <div className={Style.social_media_button}>
                <Img
                  alt={social_media_id}
                  className={Style.social_media_logo}
                  src={this._getSocialMediaLogo(social_media_id)}
                />
                <div className={Style.social_media_name}>{name}</div>
              </div>
            </Link>
          </React.Fragment>
        )
      );
    });

  renderThankYouMsg = () => (
    <div className={Style.thank_you_msg}>
      <SendEmailIcon />
      <strong>Thank you!</strong> Your feedback was sent to management for
      review.
    </div>
  );

  renderVisitOnlineCard = () => {
    const { social_media_profiles = {} } = this.props;
    return (
      <div className={Style.feedback_card}>
        <div className={Style.card_header}>Visit us Online</div>
        <div className={Style.card_body}>
          {this.renderSocialMediaProfiles(social_media_profiles)}
          <Link
            className={Style.link}
            href={`https://www.skiplinow.com/shop/${this.props.shop_id}`}
          >
            <div className={Style.social_media_button}>
              <Img
                alt="rating 5"
                className={Style.social_media_logo}
                src={Rating5}
              />
              <div className={Style.social_media_name}>
                Place an online order
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderThankYouMsg()}
        {this.renderVisitOnlineCard()}
      </div>
    );
  }
}

ShopSurveyFeedbackSent.propTypes = {
  shop_id: PropTypes.string.isRequired,
  social_media_profiles: PropTypes.shape({
    facebook: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
    google: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
    instagram: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
    trip_adviser: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
    yelp: PropTypes.shape({
      name: PropTypes.string,
      profile_href: PropTypes.string,
    }),
  }),
};

ShopSurveyFeedbackSent.defaultProps = {};

export default ShopSurveyFeedbackSent;
