// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Fields
import { ContentLoader } from "fields";
import styled from "styled-components";

// Convert Raw HTML TO REACT
const RawHTML = ({ children, tag = "div", nl2br = true, ...rest }) =>
  React.createElement(tag, {
    dangerouslySetInnerHTML: {
      __html: nl2br ? children && children.replace(/\n/g, "<br />") : children,
    },
    ...rest,
  });

RawHTML.propTypes = {
  children: PropTypes.string,
  nl2br: PropTypes.bool,
  tag: PropTypes.string,
};

// Format Date for post
const _formatDate = (pubDate) =>
  new Date(pubDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

// Extract the longest text content within <p> tags

const _getShortParagraph = (description) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = description;

  const paragraphs = tempElement.querySelectorAll("p");

  let longestText = "";
  let maxLength = 0;

  paragraphs.forEach((paragraph) => {
    const text = paragraph.textContent;
    if (text.length > maxLength) {
      maxLength = text.length;
      longestText = text;
    }
  });

  return longestText;
};

const _readTime = (content) => {
  const WPS = 275 / 60;

  var images = 0;
  const regex = /\w/;

  let words = content.split(" ").filter((word) => {
    if (word.includes("<img")) {
      images += 1;
    }
    return regex.test(word);
  }).length;

  var imageAdjust = images * 4;
  var imageSecs = 0;
  var imageFactor = 12;

  while (images) {
    imageSecs += imageFactor;
    if (imageFactor > 3) {
      imageFactor -= 1;
    }
    images -= 1;
  }

  const minutes = Math.ceil(((words - imageAdjust) / WPS + imageSecs) / 60);

  return minutes;
};

const ScreenLoader = styled.div`
  @media screen and (min-width: 700px) {
    .mobile {
      display: none;
    }
  }
  @media screen and (max-width: 700px) {
    .desktop,
    .tablet {
      display: none;
    }
  }
`;

const _createDesktopBlogSkeleton = () => (
  <div>
    {Array(4)
      .fill(4)
      .map((val, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <ContentLoader.DesktopBlogLoader />
        </div>
      ))}
  </div>
);

const _createMobileBlogSkeleton = () => (
  <div>
    {Array(4)
      .fill(4)
      .map((val, index) => (
        <div key={index}>
          <ContentLoader.MobileBlogLoader />
        </div>
      ))}
  </div>
);

const _createBlogSkeleton = () => (
  <div>
    <ScreenLoader>
      <div className="mobile">{_createMobileBlogSkeleton()}</div>
    </ScreenLoader>
    <ScreenLoader>
      <div className="tablet">{_createDesktopBlogSkeleton()}</div>
    </ScreenLoader>
    <ScreenLoader>
      <div className="desktop">{_createDesktopBlogSkeleton()}</div>
    </ScreenLoader>
  </div>
);

export {
  _createBlogSkeleton,
  _getShortParagraph,
  _formatDate,
  RawHTML,
  _readTime,
};
