// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Slashy } from "fields";

const TABLE = [
  { amount: "Free", label: "account opening" },
  { amount: "Unlimited", label: "orders" },
  { amount: "Free", label: "24/7 support" },
  { amount: "5 - 10%", label: "commission fee" },
];

class SimplePricing extends React.Component {
  renderWordsCol = () => (
    <React.Fragment>
      <h2 className={Style.heading}>30-day free trial</h2>
      <p className={Style.description}>
        Run your business without worrying about service charges, minimum
        balances, or hidden fees.
      </p>
    </React.Fragment>
  );

  renderTable = () =>
    TABLE.map(({ amount, label }) => (
      <div className={Style.tableCell} key={label}>
        <div className={Style.cellLabel}>{label}</div>
        <div className={Style.cellAmount}>{amount}</div>
      </div>
    ));

  render() {
    return (
      <Slashy
        leftColumnContent={this.renderWordsCol()}
        rightColumnContent={
          <React.Fragment>
            {this.renderTable()}
            <Button
              className={Style.openFreeAccountBtn}
              name="get started button"
              onClick={() => this.props.history.push("/get-started")}
            >
              get free trial
            </Button>
          </React.Fragment>
        }
      />
    );
  }
}

export default withRouter(SimplePricing);
