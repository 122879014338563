// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Components
import { SplitOverlayGroup } from "components";

// Image
import ContactlessPay from "assets/Images/contactlessPay.jpg";
import DineInOnlineOrdering from "assets/Images/dineInOnlineOrdering.jpg";
import FriendlyMenu from "assets/Images/friendlyMenu.jpg";

const CONTACTLESS_BENEFITS = [
  {
    label: "Branded, Mobile-Friendly Menus",
    image: FriendlyMenu,
    list: [
      {
        item_name: "Turn Smart Phones Into Ordering Devices ",
        item_description:
          "Show guests your up-to-date menu via a QR code — and take orders and payment — right at guests' seats",
      },
      {
        item_name: "Straightforward for guests",
        item_description:
          "Guests know exactly what to do when they sit down, saving your staff time.",
      },
      {
        item_name: "Expanded Info for Your Guests",
        item_description:
          "Include pictures of menu items and  crucial information like ingredients and dietary restrictions",
      },
    ],
  },
  {
    action_button: { text: "Reduce cost & increase revenue" },
    image: DineInOnlineOrdering,
    label: "More Efficient Dine-In Ordering",
    list: [
      {
        item_name: "Streamline operations",
        item_description:
          "Focus on existing operations and delivering the same great experience as we take care of setup for you",
      },
      {
        item_name: "Serve more tables, see more revenue",
        item_description:
          "Increase speed to order, turning tables in less time and removing idle time",
      },
      {
        item_name: "Keep overhead low",
        item_description:
          "Reduce labor costs and deliver safer, faster service with your existing staff",
      },
    ],
  },
  {
    action_button: { text: "Scale my business" },
    image: ContactlessPay,
    label: "Step Into a Future That Scales",
    list: [
      {
        item_name: "Paperless ordering & payment",
        item_description:
          "Cut your costs with replacing paper menus and bills with digital menus and secure checkout for every order",
      },
      {
        item_name: "Keep guests happy",
        item_description: "Drop the check and take payment at the same time",
      },
      {
        item_name: "Grow your marketing reach",
        item_description:
          "Collect guest email addresses and phone numbers from every table automatically at checkout",
      },
    ],
  },
];

class ContactlessOrderingBenefits extends React.Component {
  render() {
    return <SplitOverlayGroup items={CONTACTLESS_BENEFITS} />;
  }
}

export default ContactlessOrderingBenefits;
