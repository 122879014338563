// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Components
import { OrderingPageShopIntroduction } from "components";

// Lib
import { ORDER_DELIVERY_TYPES, SHOPS_NOT_ALLOW_DINE_IN } from "lib/_constants";

class ShopInfo extends React.Component {
  state = {};

  renderDesktopCoverImage = () => {
    const { shopBasicInfo = {} } = this.props;
    const { thumbnail_img_not_found = false } = this.state;
    const { thumbnailImg = "" } = shopBasicInfo;
    if (thumbnail_img_not_found || !thumbnailImg) return null;
    return (
      thumbnailImg && (
        <img
          alt=""
          className={Style.desktop_cover_image}
          onError={() => this.setState({ thumbnail_img_not_found: true })}
          src={thumbnailImg}
        />
      )
    );
  };

  renderFulfillmentMethods = () =>
    Object.keys(ORDER_DELIVERY_TYPES).map((id) => {
      const { label, icon } = ORDER_DELIVERY_TYPES[id];
      if (id === "catering") return null;
      else if (
        id === "inStore" &&
        SHOPS_NOT_ALLOW_DINE_IN.includes(this.props.shop_id)
      )
        return null;
      return (
        <div
          className={cx(
            Style.fulfillment_method,
            this.props.order_fulfillment_method === id && Style.selected
          )}
          key={id}
          onClick={() => this.props.onChangeOrderFulfillmentMethod(id)}
        >
          <div className={Style.fulfillment_method_icon}>{icon}</div>
          <p className={Style.fulfillment_method_label}>{label}</p>
        </div>
      );
    });

  renderMobileContent = () => {};

  renderMobileCoverImage = () => {
    const { shopBasicInfo = {} } = this.props;
    const { logoURL = "", thumbnailImg = "" } = shopBasicInfo;
    return (
      !this.state.thumbnail_img_not_found && (
        <div className={Style.mobile_cover_image_container}>
          <img
            alt=""
            className={Style.mobile_cover_image}
            onError={() => this.setState({ thumbnail_img_not_found: true })}
            src={thumbnailImg}
          />
          {!this.state.shop_logo_not_found && (
            <React.Fragment>
              <div className={Style.mobile_cover_image_bottom_border} />
              <div className={Style.mobile_shop_logo_container}>
                <img
                  alt=""
                  className={Style.mobile_shop_logo}
                  onError={() => this.setState({ shop_logo_not_found: true })}
                  src={logoURL}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      )
    );
  };

  renderShopInfo = () => {
    const { shopBasicInfo = {}, todayHours } = this.props;
    return (
      <div className={Style.shop_introduction_container}>
        <OrderingPageShopIntroduction
          on_shop_logo_not_found={() =>
            this.setState({ shop_logo_not_found: true })
          }
          shop_basic_info={shopBasicInfo}
          shop_logo_not_found={this.state.shop_logo_not_found}
          thumbnail_img_not_found={this.state.thumbnail_img_not_found}
          today_hours={todayHours}
        />
        <div className={Style.fulfillment_methods_row}>
          {this.renderFulfillmentMethods()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={Style.container}>
        {this.renderMobileCoverImage()}
        {this.renderShopInfo()}
        {this.renderDesktopCoverImage()}
      </div>
    );
  }
}

ShopInfo.propTypes = {
  onChangeOrderFulfillmentMethod: PropTypes.func.isRequired,
  order_fulfillment_method: PropTypes.oneOf([
    "catering",
    "inStore",
    "pickUp",
    "deliver",
  ]).isRequired,
  shopBasicInfo: PropTypes.shape({
    address: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pickUpInfo: PropTypes.object,
    thumbnailImg: PropTypes.string,
  }).isRequired,
  shop_id: PropTypes.string.isRequired,
  todayHours: PropTypes.object,
};

export default ShopInfo;
