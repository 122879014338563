// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Button, ScreenSize } from "fields";

// Image
import { ArrowIcon } from "assets/Icons";
import WebsiteDesign from "assets/Images/websiteDesign.png";

class WebsiteDesignIntro extends React.Component {
  renderMainContent = () => (
    <div className={Style.titleText}>
      <h1>Website Design for Your Business</h1>
      <p className={Style.paragraph}>
        Design matters. Get a website that converts online visitors into
        customers.
      </p>
      <div className={Style.buttons}>
        <Button
          className={Style.learnMoreBtn}
          color="white"
          name="what skipli website design offers"
          onClick={() =>
            this.props.context.onScrollTo("websiteDesignStatistics")
          }
        >
          What We Offer
        </Button>
        <Button
          className={Style.seeActionBtn}
          hasShadow
          name="get started"
          onClick={() => this.props.history.push("/get-started")}
        >
          Get Started <ArrowIcon className={Style.arrowIcon} />
        </Button>
      </div>
      <img alt="" className={Style.heroImage} src={WebsiteDesign} />
    </div>
  );

  renderDesktopView = () => (
    <React.Fragment>
      <div className={Style.background}>
        <div className={CommonStyle.sectionContainer}>
          {this.renderMainContent()}
        </div>
      </div>
    </React.Fragment>
  );

  renderMobileView = () => (
    <React.Fragment>
      <div>
        <div className={CommonStyle.sectionContainer}>
          {this.renderMainContent()}
        </div>
      </div>
    </React.Fragment>
  );

  render() {
    return (
      <React.Fragment>
        <ScreenSize deviceType="mobile">{this.renderMobileView()}</ScreenSize>
        <ScreenSize deviceType="tablet">{this.renderDesktopView()}</ScreenSize>
        <ScreenSize deviceType="desktop">{this.renderDesktopView()}</ScreenSize>
      </React.Fragment>
    );
  }
}

WebsiteDesignIntro.propTypes = {
  context: PropTypes.shape({
    onScrollTo: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(WebsiteDesignIntro)
);
