// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import { CUSTOMER_PROFILE_MODULES } from "./constants";

// Style
import OrderingPageMainMenuStyle from "components/Modals/OrderingPageMainMenu/style.module.scss";

// Components
import { CustomerProfileModules } from "components";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Fields
import { ResponsiveModal } from "fields";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class CustomerProfileModal extends React.Component {
  state = { selected_module_id: "" };

  onCloseModal = () => {
    if (this.state.selected_module_id)
      this.setState({ selected_module_id: "" });
    else this.props.onCloseModal();
  };

  onGetModalHeader = () => {
    const { selected_module_id = "" } = this.state;
    if (selected_module_id) {
      const { label } = CUSTOMER_PROFILE_MODULES[selected_module_id];
      return label;
    }
    return "Your Profile";
  };

  renderModuleContent = () => {
    switch (this.state.selected_module_id) {
      case "view_profile":
        return <CustomerProfileModules.ProfileModule />;
      case "past_orders":
        return <CustomerProfileModules.PastOrdersModule />;
      case "payment_methods":
        return <CustomerProfileModules.PaymentMethodsModule />;
      default:
        return;
    }
  };

  renderModules = () =>
    Object.entries(CUSTOMER_PROFILE_MODULES).map((entry) => {
      const module_id = entry[0];
      const { icon, label } = entry[1];
      return (
        <div
          className={OrderingPageMainMenuStyle.option}
          key={module_id}
          onClick={() => this.setState({ selected_module_id: module_id })}
        >
          <div className={OrderingPageMainMenuStyle.option_label_container}>
            <div className={OrderingPageMainMenuStyle.option_icon}>{icon}</div>
            <div className={OrderingPageMainMenuStyle.option_label}>
              {label}
            </div>
          </div>
          <div className={OrderingPageMainMenuStyle.arrowIcon}>
            <ChevronArrowIcon />
          </div>
        </div>
      );
    });

  render() {
    return (
      <ResponsiveModal
        classNames={{
          modal: OrderingPageMainMenuStyle.modal,
        }}
        contentLabel="customer profile modal"
        headerProps={{
          header: this.onGetModalHeader(),
          show_back_button: true,
          show_close_button: false,
        }}
        onCloseModal={this.onCloseModal}
      >
        {this.state.selected_module_id
          ? this.renderModuleContent()
          : this.renderModules()}
      </ResponsiveModal>
    );
  }
}

CustomerProfileModal.propTypes = {
  context: PropTypes.shape({
    customerProfile: PropTypes.object,
    shopID: PropTypes.string.isRequired,
  }),
  onCloseModal: PropTypes.func.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(CustomerProfileModal);
