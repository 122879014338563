import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { StripeBrand } from "fields";

import {
  injectStripe,
  PaymentRequestButtonElement,
} from "react-stripe-elements";

class PaymentRequestButtonStripe extends React.Component {
  constructor(props) {
    super(props);
    const paymentRequest = props.stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        amount: parseInt(props.totalAmount * 100),
        label: "Order total",
      },
    });
    paymentRequest.on("paymentmethod", async (event) =>
      this.onAcceptPayment({ event })
    );
    paymentRequest.canMakePayment().then((result) => {
      this.setState({ canMakePayment: !!result });
    });
    this.state = { canMakePayment: false, paymentRequest };
  }

  componentDidUpdate(prevProps) {
    if (this.props.totalAmount !== prevProps.totalAmount) {
      const paymentRequest = this.props.stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Order total",
          amount: parseInt(this.props.totalAmount * 100),
        },
      });
      paymentRequest.on("paymentmethod", async (event) =>
        this.onAcceptPayment({ event })
      );
      paymentRequest.canMakePayment().then((result) => {
        this.setState({ canMakePayment: !!result });
      });
      this.setState({ canMakePayment: false, paymentRequest });
    }
  }

  onAcceptPayment = ({ event }) => {
    this.props.onClick({ event, totalAmount: this.props.totalAmount });
  };

  render() {
    const { buttonStatus } = this.props;
    const { canMakePayment } = this.state;
    if (buttonStatus === "inactive")
      return (
        <p className={Style.message}>
          Complete details above to proceed with this payment method
        </p>
      );
    else if (!canMakePayment)
      return (
        <p className={Style.message}>
          This payment method is not found on your device.
        </p>
      );
    return canMakePayment && buttonStatus === "active" ? (
      <div>
        <h4>Total: ${this.props.totalAmount}</h4>
        <PaymentRequestButtonElement
          paymentRequest={this.state.paymentRequest}
          className="PaymentRequestButton"
          style={{
            // For more details on how to style the Payment Request Button, see:
            // https://stripe.com/docs/elements/payment-request-button#styling-the-element
            paymentRequestButton: {
              theme: "dark",
              height: "50px",
            },
          }}
        />
        <StripeBrand />
      </div>
    ) : null;
  }
}

PaymentRequestButtonStripe.propTypes = {
  buttonStatus: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  totalAmount: PropTypes.number.isRequired,
};

PaymentRequestButtonStripe.defaultProps = {
  buttonStatus: "inactive",
};

export default injectStripe(PaymentRequestButtonStripe);
