// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ContentLoader from "react-content-loader";

const MobileMenuItemsLoader = (props) => (
  <ContentLoader
    height={300}
    width={600}
    speed={2}
    backgroundColor="#E0E0E0"
    foregroundColor="#F2F2F2"
    {...props}
  >
    {/* First rect */}
    <rect x="0" y="0" rx="0" ry="0" width="150" height="15" />
    <rect x="0" y="30" rx="0" ry="0" width="200" height="15" />
    <rect x="0" y="80" rx="0" ry="0" width="100" height="15" />
    <rect x="230" y="0" rx="0" ry="0" width="110" height="110" />
    {/* Second rect */}
    <rect x="0" y="150" rx="0" ry="0" width="150" height="15" />
    <rect x="0" y="180" rx="0" ry="0" width="200" height="15" />
    <rect x="0" y="230" rx="0" ry="0" width="100" height="15" />
    <rect x="230" y="150" rx="0" ry="0" width="110" height="110" />
  </ContentLoader>
);

export default MobileMenuItemsLoader;
