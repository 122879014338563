// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ContentLoader from "react-content-loader";

const DesktopMenuItemsLoader = (props) => (
  <ContentLoader
    {...props}
    height={120}
    width={1300}
    speed={2}
    backgroundColor="#E0E0E0"
    foregroundColor="#F2F2F2"
  >
    {/* First rect */}
    <rect x="0" y="0" rx="0" ry="0" width="150" height="15" />
    <rect x="0" y="30" rx="0" ry="0" width="200" height="15" />
    <rect x="0" y="50" rx="0" ry="0" width="200" height="15" />
    <rect x="0" y="100" rx="0" ry="0" width="100" height="15" />
    <rect x="230" y="0" rx="0" ry="0" width="120" height="120" />
    {/* Second rect */}
    <rect x="400" y="0" rx="0" ry="0" width="150" height="15" />
    <rect x="400" y="30" rx="0" ry="0" width="200" height="15" />
    <rect x="400" y="50" rx="0" ry="0" width="200" height="15" />
    <rect x="400" y="100" rx="0" ry="0" width="100" height="15" />
    <rect x="630" y="0" rx="0" ry="0" width="120" height="120" />
    {/* Third rect */}
    <rect x="800" y="0" rx="0" ry="0" width="150" height="15" />
    <rect x="800" y="30" rx="0" ry="0" width="200" height="15" />
    <rect x="800" y="50" rx="0" ry="0" width="200" height="15" />
    <rect x="800" y="100" rx="0" ry="0" width="100" height="15" />
    <rect x="1030" y="0" rx="0" ry="0" width="120" height="120" />
  </ContentLoader>
);

export default DesktopMenuItemsLoader;
