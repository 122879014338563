// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Utils
import { _updatePageTitleAndDescription } from "utils";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Components
import {
  HomePageModules,
  TrustedByCarousel,
  TrustedByLogosGallery,
  TwentyFourSevenSupport,
} from "components";

// Fields
import { TestimonialBox } from "fields";

// Images
import JiggarPatel from "assets/Images/jiggarPatel.jpg";

class Delivery extends React.Component {
  componentDidMount = () => {
    _updatePageTitleAndDescription({
      description:
        "Skipli is the leading All-in-1 Marketing & Online Revenue Platform for Restaurants. Get everything from websites to social media, Google ads, email marketing, online ordering, reservations & more for the lowest cost & lowest effort.",
      title:
        "Leading All-in-1 Marketing & Online Revenue Platform for Restaurants | Skipli",
    });
  };

  renderTestimonial = () => (
    <div className={MainWebsiteStyle.sectionContainer}>
      <div style={{ paddingTop: "4rem" }}>
        <TestimonialBox
          heroImage="https://i.imgur.com/ol9ZfTs.jpg"
          heroImageClassname={Style.heroImage}
          profileImage={JiggarPatel}
          profileName="Jiggar Patel | Owner | Blue India"
          text={`Using Skipli Ordering is like having another member of my staff that can pick up phone calls and answer questions. The best part is I pay much less in delivery fees while being able to rely on Skipli's nationwide network of commission-free delivery partners.”`}
        />
      </div>
    </div>
  );

  render() {
    return (
      <div className={Style.pageContainer}>
        <HomePageModules.Introduction />
        <TrustedByCarousel />
        <HomePageModules.TakeBackControl />
        <HomePageModules.AllInOnePlatform />
        <TwentyFourSevenSupport />
        <TrustedByLogosGallery />
        {/* <div className={MainWebsiteStyle.sectionContainer}>
          <HomePageModules.NationalCoverage />
        </div> */}
        {/* <div
          style={{ marginBottom: "50px" }}
          ref={(ref) => onUpdateRefs({ refID: "deliveryBenefits", ref })}
        >
          <HomePageModules.Benefits />
        </div> */}
        {/* {this.renderTestimonial()} */}
        {/* <div className={Style.sectionContainer}>
          <LetsChatBox
            heading="Reduce Commission Fee"
            description="We'll get you started quickly so you can start accepting pick up & delivery orders within a few days."
          />
        </div>
        <HomePageModules.SimplePricing /> */}
      </div>
    );
  }
}

Delivery.propTypes = {
  context: PropTypes.shape({
    onUpdateRefs: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(withRouter(Delivery));
