// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Icons
import { ArrowIcon, DeliverIcon } from "assets/Icons";

const NewDeliveryFeature = (props) => (
  <div>
    <Button
      className={Style.button}
      hasShadow
      name="Try delivery"
      onClick={() => props.onChangeDeliveryType("deliver")}
    >
      <div className={Style.iconContainer}>
        <DeliverIcon />
      </div>
      <div>
        <h2 className={Style.heading}>WANT YOUR FOOD DELIVERED?</h2>
        <div className={Style.callToAction}>
          SELECT DELIVERY <ArrowIcon />
        </div>
      </div>
    </Button>
  </div>
);

NewDeliveryFeature.propTypes = {
  onChangeDeliveryType: PropTypes.func.isRequired,
};

NewDeliveryFeature.defaultProps = {
  onChangeDeliveryType: () => {},
};

export default NewDeliveryFeature;
