// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import CommonStyle from "../style.module.scss";

// Components
import { CustomerMenuElements } from "components";

// Fields
import { PageMsg } from "fields";

class ListOfItems extends Component {
  renderItems = (items = {}) =>
    (this.props.sortedItemIDs || Object.keys(items)).map((itemID) => {
      const { itemIsArchived } = items[itemID];
      return (
        !itemIsArchived && (
          <div key={itemID}>
            <CustomerMenuElements.MenuItem
              categoryModifierGroups={this.props.categoryModifierGroups}
              itemID={itemID}
              itemInfo={items[itemID]}
            />
          </div>
        )
      );
    });

  render() {
    const { items } = this.props;
    if (Object.keys(items).length === 0)
      return <PageMsg>No items found.</PageMsg>;
    return (
      <div className={CommonStyle.desktopList}>{this.renderItems(items)}</div>
    );
  }
}

ListOfItems.propTypes = {
  categoryModifierGroups: PropTypes.object,
  items: PropTypes.object,
  listContainerClassname: PropTypes.string,
  sortedItemIDs: PropTypes.array,
  showAllItems: PropTypes.bool,
};

ListOfItems.defaultProps = {
  categoryModifierGroups: {},
  items: {},
  sortedItemIDs: null,
  showAllItems: false,
};

export default ListOfItems;
