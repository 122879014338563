// BUTI DINERS, INC. All right Reserved ©

import React from "react";

import { _createShopsSkeleton } from "./HelperFunctions";

// Components
import { ShopOnPlatform } from "components";

// Style
import Style from "./style.module.scss";

// Assets
import Image from "assets/Images/shopsPageImage.png";

// Lib
import { Customers } from "lib/services";
const { GetShopsForCustomer } = Customers.GetRequests;

class MatchingShops extends React.Component {
  state = {};

  componentDidMount = () => {
    document.title =
      "Food delivery and Takeout - Order online from restaurants near you | Skipli";
    this.onGetShopsForCustomer();
  };

  onGetShopsForCustomer = async () => {
    this.setState({ isFindingShops: true });
    const { shops = {} } = await GetShopsForCustomer();
    this.setState({ shops, isFindingShops: false });
  };

  renderActiveShops = () =>
    this.state.isFindingShops
      ? _createShopsSkeleton()
      : this.renderShops(this.state.shops);

  renderShops = (shops = {}) => (
    <div className={Style.shopsContainer}>
      {Object.keys(shops).map((shopID) => (
        <div className={Style.shop} key={shopID}>
          <ShopOnPlatform {...shops[shopID]} shopID={shopID} />
        </div>
      ))}
    </div>
  );

  renderSlogan = () => (
    <div className={Style.sloganContainer}>
      <div className={Style.sloganContent}>
        <div className={Style.slogan}>
          <h1>
            Order at your place.
            <br />
            Pick up at your time.
          </h1>
        </div>
        <div className={Style.imgColumn}>
          <img alt="" className={Style.image} src={Image} />
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <div>
        <div className={Style.pageContent}>
          {this.renderSlogan()}
          <div className={Style.mainContent}>{this.renderActiveShops()}</div>
        </div>
      </div>
    );
  }
}

export default MatchingShops;
