// BUTI DINERS, INC. All right Reserved ©

const TRUSTED_PARTNERS = [
  {
    href: "https://www.pho-asia.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+Asia+New+Logo.png",
  },
  {
    href: "https://www.gongchatea.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Gong+Cha+Logo.png",
  },
  {
    href: "https://www.ladyha.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Lady+Ha+Logo.png",
  },
  {
    href: "https://www.pearlofcanton.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pearl+Canton+Logo.png",
  },
  {
    href: "https://www.iluvphomallofga.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/I+Luv+Pho+Vietnamese+Cuisine+Logo.png",
  },
  {
    href: "https://www.bameetee.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Bamee+Tee+Logo.png",
  },
  {
    href: "https://www.bigmamavietnamkitchen.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Big+Mama+Vietnam+Kitchen+Logo.png",
  },
  {
    href: "https://www.kajunseafoodusa.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Kajun+Seafood+Logo.png",
  },
  {
    href: "https://www.nydelibagelcafe.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/NY+Bagels+Logo.png",
  },
  {
    href: "https://www.bobakul.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Boba+Kulture+Logo.png",
  },
  {
    href: "https://www.pholeebubbletea.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+Lee+Logo.png",
  },
  {
    href: "https://www.hiroramenteabar.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Hiro+Ramen+Logo.png",
  },
  {
    href: "https://www.louisianacrabshackusa.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Louisiana+Crab+Shack+Logo.png",
  },
  {
    href: "https://www.skiplinow.com/shop/877368c",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/King+Kong+Milk+Tea+Logo.png",
  },
  {
    href: "https://www.gypsykitchendc.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Gypsy+Kitchen+Logo.jpeg",
  },
  {
    href: "https://www.skiplinow.com/shop/-MBp1HQwV8Fu1JbkQNkQ",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Nom+Nom+Tea+Bites+Logo.jpeg",
  },
  {
    href: "https://www.skiplinow.com/shop/602404g",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/LimeNBasil+Logo.jpeg",
  },
  {
    href: "https://www.skiplinow.com/shop/-MBlKhyHjUbTrQ-T7XX-",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/New+Super+Pho+Logo.png",
  },
  {
    href: "https://www.blueindiaatlanta.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Blue+India+Logo.png",
  },
  {
    href: "https://www.skiplinow.com/shop/897765u",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+Cowboys+San+Angelo+Logo.png",
  },
  {
    href: "https://www.saigonexpresslongmont.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Saigon+Express+Logo.png",
  },
  {
    href: "https://www.vnnoodleatl.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Hu+Tieu+My+Tho.png",
  },
  {
    href: "https://www.island-pho.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Island+Pho+Logo.png",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+King+Logo.png",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/102+Banh+Mi+Logo.jpeg",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/IndoViet+Logo.png",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Mr+Pho+Logo.jpeg",
  },
  {
    href: "https://www.phodaiviet.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+Dai+Viet+Logo.png",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+126+Logo.png",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+Day+Logo.png",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+T+%26+N+Logo.jpeg",
  },
  {
    href: "https://www.saigonpho2.net/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Saigon+Pho+Logo.png",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+Viet+Number+One+Logo.png",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Pho+Yeah+Logo.jpeg",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Siuper+Pho+Logo.jpeg",
  },
  {
    href: "",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Taste+of+Viet+Logo.png",
  },
  {
    href: "https://www.sangosushica.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Sango+Sushi+New+Logo.png",
  },
  {
    href: "https://www.eviekitchen.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/Evie+Vietnamese+Kitchen+Logo.png",
  },
  {
    href: "https://www.9milepho.com/",
    img_src:
      "https://skipli.s3.amazonaws.com/Skipli+Website/Restaurant_Logos/9+Mile+Pho+Logo.png",
  },
];

export { TRUSTED_PARTNERS };
