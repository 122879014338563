// BUTI DINERS, INC. All right Reserved ©

import AllInOnePlatform from "./AllInOnePlatform";
import Benefits from "./Benefits";
import Introduction from "./Introduction";
import NationalCoverage from "./NationalCoverage";
import SimplePricing from "./SimplePricing";
import TakeBackControl from "./TakeBackControl";

const HomePageModules = {
  AllInOnePlatform,
  Benefits,
  Introduction,
  NationalCoverage,
  SimplePricing,
  TakeBackControl,
};

export default HomePageModules;
