// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "../style.module.scss";

// Fields
import { Input } from "fields";
const { TextInput } = Input;

// Constants
const MAX_CHARACTERS_COUNT = 100;

const CustomerInstruction = ({ onInputChange, text }) => {
  const onUpdateInstruction = (value) =>
    value.length < MAX_CHARACTERS_COUNT + 1 && onInputChange(value);

  return (
    <React.Fragment>
      <div className={Style.sectionNameContainer}>
        <h3 className={Style.sectionName}>Special Instruction</h3>
        <div className={Style.subText}>
          {text.length}/{MAX_CHARACTERS_COUNT}
        </div>
      </div>
      <TextInput
        hasMultipleLines={true}
        name="customerInstruction"
        onChange={onUpdateInstruction}
        placeholder="Add Instructions..."
        rows={3}
        value={text}
      />
      <p className={Style.specialInstrDisclaimer}>
        Special instruction is not guaranteed and will be fulfilled within the
        store's capability.
      </p>
    </React.Fragment>
  );
};

CustomerInstruction.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  text: PropTypes.string,
};

CustomerInstruction.defaultProps = {
  text: "",
};

export default CustomerInstruction;
