// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";
import cx from "classnames";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Tabs } from "fields";

// Lib
import { ORDER_DELIVERY_TYPES } from "lib/_constants";

class DeliveryTypeSelector extends React.Component {
  state = {};

  onGetOptions = () => {
    const { showCateringMenu = false } = this.props.context;
    return Object.keys(ORDER_DELIVERY_TYPES).reduce((result, typeId) => {
      if (showCateringMenu && typeId !== "catering") return result;
      else if (!showCateringMenu && typeId === "catering") return result;
      else if (!this.props.allowDineIn && typeId === "inStore") return result;
      const { label, icon } = ORDER_DELIVERY_TYPES[typeId];
      return set(result, typeId, {
        label: (
          <div className={Style.tabLabel}>
            <div
              className={cx(
                Style.icon,
                this.props.selectedTypeID === typeId && Style.selected
              )}
            >
              {icon}
            </div>
            {label}
          </div>
        ),
      });
    }, {});
  };

  render() {
    return (
      <div className={Style.tabsContainer}>
        <Tabs
          onClick={this.props.onSelect}
          options={this.onGetOptions()}
          selectedOptionId={this.props.selectedTypeID}
        />
      </div>
    );
  }
}

DeliveryTypeSelector.propTypes = {
  allowDineIn: PropTypes.bool,
  context: PropTypes.shape({
    showCateringMenu: PropTypes.bool,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedTypeID: PropTypes.string,
};

DeliveryTypeSelector.defaultProps = {
  allowDineIn: true,
};

export default withContext(CustomerInterfaceConsumer)(DeliveryTypeSelector);
