// Skipli, INC. All right Reserved ©

import React from "react";
import { withRouter } from "react-router-dom";

import { _createTerms } from "./HelperFunctions";

// Utils
import { _updatePageTitleAndDescription } from "utils";

// Style
import Style from "./style.module.scss";

class Terms extends React.Component {
  componentDidMount = () => {
    _updatePageTitleAndDescription({
      description:
        "Skipli is the leading All-in-1 Marketing & Online Revenue Platform for Restaurants. Get everything from websites to social media, Google ads, email marketing, online ordering, reservations & more for the lowest cost & lowest effort.",
      title: "Terms | Skipli",
    });
  };

  renderTerms = () => {
    const terms = _createTerms({ history: this.props.history });
    return terms.map((info, index) => {
      const { title, content } = info;
      return (
        <div className={Style.textBlock}>
          <h3>
            {index + 1}. {title}
          </h3>
          <p>{content}</p>
        </div>
      );
    });
  };

  render() {
    return (
      <div className={Style.pageContainer}>
        <div className={Style.sectionContainer}>
          <h1>User Terms of Service</h1>
          <h2 style={{ marginBottom: "30px" }}>
            Last update: 29 November 2021
          </h2>
          <div className={Style.textBlock}>
            <h3>Welcome to Skipli!</h3>
            <p>
              These SKIPLI Checkout User Terms of Service are a legal agreement
              between SKIPLI and its affiliates (collectively "SKIPLI", "we" and
              "us") and you, the user of the SKIPLI Checkout service ("you"). By
              using the Skipli Checkout service, you agree to be bound by these
              terms and conditions.
            </p>
          </div>
          {this.renderTerms()}
        </div>
      </div>
    );
  }
}

export default withRouter(Terms);
