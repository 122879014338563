// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import Style from "./style.module.scss";

// Fields
import { Link } from "fields";

// Lib
import RegEx from "lib/_constants/RegEx";

// Assets
import { ArrowIcon } from "assets/Icons";

const CONTACT_INFO_INPUT_FIELDS = {
  name: {
    label: "Your Name",
    required: true,
  },
  company_name: {
    label: "Company / Restaurant Name",
    required: true,
  },
  zip_code: {
    label: "Zip Code",
    regEx: RegEx.POSTAL_CODE,
    required: true,
    type: "number",
  },
  phone: {
    label: "Phone",
    regEx: RegEx.US_PHONE_NUMBER,
    required: true,
    type: "number",
  },
  email: {
    label: "Email",
    regEx: RegEx.EMAIL,
  },
};

const OPTIONAL_INFO_INPUT_FIELDS = {
  restaurants_count: {
    label: "How many restaurants do you have?",
    type: "number",
  },
  website: {
    label: "If you have one, what is your current website?",
    regEx: RegEx.WEBSITE,
  },
  services: {
    label: "How can SKIPLI help you?",
    options: {
      website: {
        description: (
          <Link
            className={Style.learnMoreLink}
            href="https://skiplinow.com/website-design"
          >
            Learn more <ArrowIcon />
          </Link>
        ),
        label: "New website or redesign existing website",
      },
      onlineOrdering: {
        description: (
          <Link className={Style.learnMoreLink} href="https://skiplinow.com">
            Learn more <ArrowIcon />
          </Link>
        ),
        label: "Online ordering (pickup & delivery)",
      },
      auto_phone_answering: {
        description: (
          <Link
            className={Style.learnMoreLink}
            href="https://skiplinow.com/auto-phone"
          >
            Learn more <ArrowIcon />
          </Link>
        ),
        label: "Auto Phone Answering",
      },
      googleFrontPage: {
        description: (
          <Link
            className={Style.learnMoreLink}
            href="https://skiplinow.com/google-front-page"
          >
            Learn more <ArrowIcon />
          </Link>
        ),
        label: "Google front page placement",
      },
      // contactlessDining: {
      //   description: (
      //     <Link
      //       className={Style.learnMoreLink}
      //       href="https://skiplinow.com/contactless"
      //     >
      //       Learn more <ArrowIcon />
      //     </Link>
      //   ),
      //   label: "Contactless dining features for reopening after Covid-19",
      // },
    },
    type: "checkbox",
  },
  comments: {
    hasMultipleLines: true,
    label: "Any additional comments or notes?",
  },
};

export { CONTACT_INFO_INPUT_FIELDS, OPTIONAL_INFO_INPUT_FIELDS };
