// BUTI DINERS, INC. All right Reserved ©

import API_PATHS from "../API_Paths";
import axios from "axios";

// ---------------------------------------------------------------
// GET a customer List of blog

const GetAllBlogForCustomer = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_POSTS_FOR_CUSTOMER)
      .then(({ data }) => {
        const { posts } = data;
        resolve({ posts });
      })
      .catch(reject);
  });
};

const GetSinglePostForCustomer = (params) => {
  return new Promise(async (resolve, reject) => {
    if (!params.title)
      return reject(
        "(GetSinglePostForCustomer) Parameters are not sufficient."
      );
    await axios
      .get(API_PATHS.GET_SINGLE_POST_FOR_CUSTOMER, { params })
      .then(({ data }) => resolve({ data }))
      .catch((err) => reject(err));
  });
};
// ---------------------------------------------------------------
// Create a unique id for the customer

const CreateUniqueIDForCustomer = () =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.CREATE_UNIQUE_ID_FOR_CUSTOMER)
      .then(({ data }) => {
        const { customerProfile, uuid } = data;
        resolve({ customerProfile, uuid });
      })
      .catch(reject);
  });

// ---------------------------------------------------------------
// Get the cart of the customer at a particular shop

const GetCustomerCart = (params) =>
  new Promise((resolve, reject) => {
    if (!params.shopID || !params.uuid)
      return reject("(GetCustomerCart) Parameters are not sufficient.");
    axios
      .get(API_PATHS.GET_CUSTOMER_CART, { params })
      .then(({ data }) =>
        resolve({ cart: typeof data === "string" ? JSON.parse(data) : data })
      )
      .catch(reject);
  });

// ---------------------------------------------------------------
// Get the customer's past orders at a particular shop

const GetCustomerPastOrdersAtShop = (params) =>
  new Promise((resolve, reject) => {
    if (!params.shopID || !params.uuid)
      return reject(
        "(GetCustomerPastOrdersAtShop) Parameters are not sufficient."
      );
    axios
      .get(API_PATHS.GET_CUSTOMER_PAST_ORDERS_AT_SHOP, { params })
      .then(({ data }) =>
        resolve({
          pastOrdersAtShop: typeof data === "string" ? JSON.parse(data) : data,
        })
      )
      .catch(reject);
  });

// ---------------------------------------------------------------
// Get the customer's stored payment methods (i.e., cards)

const GetCustomerPaymentMethods = (params) =>
  new Promise((resolve, reject) => {
    if (!params.stripeCustomerID)
      return reject(
        "(GetCustomerPaymentMethods) Parameters are not sufficient."
      );
    axios
      .get(API_PATHS.GET_CUSTOMER_PAYMENT_METHODS, { params })
      .then(({ data }) =>
        resolve({
          paymentMethods: typeof data === "string" ? JSON.parse(data) : data,
        })
      )
      .catch(reject);
  });

// ---------------------------------------------------------------
// Get the profile of customer

const GetCustomerProfile = (params) =>
  new Promise((resolve, reject) => {
    if (!params.uuid)
      return reject("(GetCustomerProfile) Parameters are not sufficient.");
    axios
      .get(API_PATHS.GET_CUSTOMER_PROFILE, { params })
      .then(({ data }) => {
        const { customerProfile, success } = data;
        resolve({ customerProfile, success });
      })
      .catch(() => resolve({ success: false }));
  });

// ---------------------------------------------------------------
// Get the details of a Doordash delivery

const GetDoordashDeliveryDetails = (params) =>
  new Promise((resolve, reject) => {
    if (!params.delivery_id)
      return reject(
        "(GetDoordashDeliveryDetails) Parameters are not sufficient."
      );
    axios
      .get(API_PATHS.GET_DOORDASH_DELIVERY_DETAILS, { params })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

// ---------------------------------------------------------------
// Get the full image of a menu item

const GetMenuItemFullImage = (params) =>
  new Promise((resolve, reject) => {
    if (!params.itemID || !params.shopID)
      return reject("(GetMenuItemFullImage) Parameters are not sufficient.");
    axios
      .get(API_PATHS.GET_MENU_ITEM_FULL_IMAGE, { params })
      .then(({ data }) => resolve({ imageURL: data.imageURL || "" }))
      .catch(reject);
  });

// ---------------------------------------------------------------
// Get the menus of a shop using its unique id
const GetMenusForCustomer = (params) =>
  new Promise((resolve, reject) => {
    if (!params.shopID)
      return reject("(GetMenusForCustomer) Parameters are not sufficient.");
    axios
      .get(API_PATHS.GET_MENUS_FOR_CUSTOMER, { params })
      .then(({ data }) => resolve({ customerMenus: data.customerMenus || {} }))
      .catch((e) => reject(e));
  });

// ---------------------------------------------------------------
// Get the matching shops for the customer
const GetShopsForCustomer = (params) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_SHOPS_FOR_CUSTOMER, { params })
      .then(({ data }) => resolve({ shops: data.shops || {} }))
      .catch((e) => reject(e));
  });

// ---------------------------------------------------------------
// Get the shop's website information
const GetWebsiteInfo = (params) =>
  new Promise((resolve, reject) => {
    if (!params.shopID)
      return reject("(GetWebsiteInfo) Parameters are not sufficient.");
    axios
      .get(API_PATHS.GET_SHOP_WEBSITE_INFO, { params })
      .then(({ data = {} }) => resolve({ website: data, success: true }))
      .catch(() => resolve({ success: false }));
  });

const Customers_Get_Requests = {
  GetAllBlogForCustomer,
  GetSinglePostForCustomer,
  CreateUniqueIDForCustomer,
  GetCustomerCart,
  GetCustomerPastOrdersAtShop,
  GetCustomerPaymentMethods,
  GetCustomerProfile,
  GetDoordashDeliveryDetails,
  GetMenuItemFullImage,
  GetMenusForCustomer,
  GetShopsForCustomer,
  GetWebsiteInfo,
};

export default Customers_Get_Requests;
