// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import ItemStyle from "../../style.module.scss";
import Style from "./style.module.scss";

// Icons
import { ArrowIcon } from "assets/Icons";

// Fields
import { Button } from "fields";

class ItemBackButton extends Component {
  render() {
    const buttonLabel = `${!this.props.isItemInCart ? "Menu" : "Cart"}`;
    return (
      <div className={ItemStyle.buttonContainer}>
        <Button
          className={Style.backToMenuBtn}
          name={`${buttonLabel} button`}
          onClick={this.props.onClick}
          status="active"
        >
          <ArrowIcon className={Style.arrowIcon} />
          {buttonLabel}
        </Button>
      </div>
    );
  }
}

ItemBackButton.propTypes = {
  isItemInCart: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ItemBackButton;
