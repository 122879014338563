// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// ModuleStyle
import ModuleStyle from "../style.module.scss";

const BASIC_PLAN_FEATURES = {
  design: {},
  connectDomain: {},
  freeDomain: {},
  maintainance: {},
  websiteUpdate: { note: "$15 / update" },
  additionalLocation: { note: "$10 / location" },
};

const PREMIUM_PLAN_FEATURES = {
  design: {},
  connectDomain: {},
  freeDomain: {},
  maintainance: {},
  seo: {},
  customerData: {},
  websiteUpdate: {},
  additionalLocation: { note: "$10 / location" },
};

const PREMIUM_PLUS_PLAN_FEATURES = {
  design: {},
  connectDomain: {},
  freeDomain: {},
  maintainance: {},
  seo: {},
  customerData: {},
  websiteUpdate: {},
  analytics: {},
  logo: {},
  additionalLocation: {},
  firstOnGoogle: {},
};

const FEATURES = {
  design: { label: "Design / Redesign Website" },
  connectDomain: { label: "Connect your domain" },
  freeDomain: { label: "1 free Domain" },
  maintainance: { label: "Website hosting & maintenance" },
  seo: { label: "SEO to improve Google Ranking & get found easier" },
  customerData: { label: "Grow email database via website email collection" },
  websiteUpdate: { label: "Update website information, specials, & events" },
  analytics: { label: "Visitor analytics" },
  logo: { label: "Professional Logo" },
  additionalLocation: { label: "Add more locations" },
  firstOnGoogle: { label: "First result on Google" },
};

const PAYMENT_PLANS = {
  yearly: {
    label: (
      <div>
        <div>Yearly</div>
        <div className={ModuleStyle.saving}>Save 20%</div>
      </div>
    ),
  },
  monthly: {
    label: "Monthly",
  },
};

const PLANS = {
  basic: {
    label: "Basic",
    payoutSchedule: "Simple website for single location",
    price: {
      monthly: { rate: 20, setup: 60 },
      yearly: { rate: 15, setup: 60 },
    },
    features: BASIC_PLAN_FEATURES,
  },
  premium: {
    label: "Premium",
    payoutSchedule: "Professional website to build your brand",
    price: {
      monthly: { rate: 69, setup: 299 },
      yearly: { rate: 49, setup: 299 },
    },
    features: PREMIUM_PLAN_FEATURES,
  },
  premiumPlus: {
    label: "Premium Plus",
    payoutSchedule: "Get the full suite for multiple locations",
    price: {
      monthly: { rate: 129, setup: 499 },
      yearly: { rate: 99, setup: 499 },
    },
    features: PREMIUM_PLUS_PLAN_FEATURES,
  },
};

export { FEATURES, PAYMENT_PLANS, PLANS };
