// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icons
import { CheckIcon } from "assets/Icons";

class CheckboxAndRadioButton extends React.Component {
  onClick = () => {
    const { id, onClick, readOnly = false } = this.props;
    !readOnly && onClick(id);
  };

  renderCheckbox = () => {
    const { checked = false, readOnly = false } = this.props;
    return (
      <div
        className={cx(
          Style.checkbox,
          this.props.checkboxClassName,
          this.props.checked && Style.checked,
          readOnly && Style.readOnly
        )}
        onClick={this.onClick}
      >
        {checked && (
          <CheckIcon
            className={cx(Style.checkIcon, this.props.checkIconClassName)}
          />
        )}
      </div>
    );
  };

  renderRadio = () => {
    const { checked = false, readOnly = false } = this.props;
    return (
      <div
        className={cx(
          Style.radioButton,
          this.props.radioButtonClassName,
          checked && Style.checked,
          readOnly && Style.readOnly
        )}
        onClick={this.onClick}
      >
        {checked && <div className={Style.circle} />}
      </div>
    );
  };

  render() {
    const { description = "" } = this.props;
    return (
      <button
        className={cx(
          Style.container,
          description && Style.alignStart,
          this.props.className
        )}
      >
        {this.props.kind === "radio"
          ? this.renderRadio()
          : this.renderCheckbox()}
        <div>
          <div
            className={cx(Style.label, this.props.labelClassName)}
            onClick={this.onClick}
          >
            {this.props.label}
          </div>
          {description && (
            <div
              className={cx(Style.description, this.props.descriptionClassName)}
            >
              {description}
            </div>
          )}
        </div>
      </button>
    );
  }
}

CheckboxAndRadioButton.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  description: PropTypes.any,
  id: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  label: PropTypes.any.isRequired,
  labelClassName: PropTypes.string,
  onClick: PropTypes.func,
  checkboxClassName: PropTypes.string,
  checkIconClassName: PropTypes.string,
  radioButtonClassName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CheckboxAndRadioButton.defaultProps = {
  className: "",
  checked: false,
  checkboxClassName: "",
  checkIconClassName: "",
  labelClassName: "",
  onClick: () => {},
  readOnly: false,
};

export default CheckboxAndRadioButton;
