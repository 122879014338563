// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { set } from "object-path-immutable";

import { EXAMPLE_WEBSITES, WEBSITE_EXAMPLES_HERO_IMG_URL } from "./constants";

// Utils
import { _scrollTo, _updatePageTitleAndDescription } from "utils";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Components
import { TrustedByLogosGallery } from "components";

// Fields
import { Link } from "fields";

// Icons
import { NewWindowIcon } from "assets/Icons";

class WebsiteExamples extends React.Component {
  componentDidMount() {
    _updatePageTitleAndDescription({
      description:
        "Skipli is trusted and loved by 2000+ restaurants worldwide. Here are some of the restaurants we work with.",
      title: "Our customer websites | Skipli",
    });
  }

  onRedirectToGetStarted = () => this.props.history.push("/get-started");

  renderPageIntro = () => (
    <div className={Style.pageIntro}>
      <h1 className={Style.heading}>SKIPLI Examples</h1>
      <div>
        <p className={Style.paragraph}>
          Skipli is trusted and loved by 2000+ restaurants. Here are some of the
          restaurants we work with.
        </p>
        <button
          className={Style.callToActionButton}
          onClick={() => _scrollTo({ ref: this.refs["websites_gallery"] })}
        >
          See examples
        </button>
      </div>
    </div>
  );

  renderWebsitesGallery = () =>
    EXAMPLE_WEBSITES.map(({ id, name, thumbnailImg, url }) => (
      <div className={Style.websiteContainer} key={id}>
        <Link href={url}>
          <div className={Style.websiteThumbnailImg}>
            <img alt="" src={thumbnailImg} />
          </div>
          <div className={Style.websiteNameContainer}>
            <h3 className={Style.websiteName}>{name}</h3>
            <NewWindowIcon className={Style.newWindowIcon} />
          </div>
        </Link>
      </div>
    ));

  render() {
    return (
      <div className={cx(MainWebsiteStyle.pageContainer, Style.container)}>
        <img
          alt=""
          className={Style.image}
          src={WEBSITE_EXAMPLES_HERO_IMG_URL}
        />
        {this.renderPageIntro()}
        <div
          ref={(ref) => {
            this.refs = set(this.refs, "websites_gallery", ref);
          }}
        >
          <div className={Style.websitesGalleryContainer}>
            <div className={Style.websitesGallery}>
              {this.renderWebsitesGallery()}
            </div>
          </div>
        </div>
        <TrustedByLogosGallery show_more_examples_button={false} />
      </div>
    );
  }
}

WebsiteExamples.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(WebsiteExamples);
