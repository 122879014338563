// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import cx from "classnames";

import ContentDescription from "./ContentDescription";
import Footer from "./Footer";
import Header from "./Header";

// Style
import Style from "./style.module.scss";

// Lib
import { ShowConfirmNotif } from "lib/functions";

class ResponsiveModal extends React.Component {
  state = { isSubmitting: false };

  componentDidMount() {
    window.addEventListener("beforeunload", this._confirm);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this._confirm);
    window.onpopstate = () => {};
  }

  _backConfirm = () => this.onCloseModal();

  _confirm = (e) => {
    var confirmationMessage = "o/";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  onCloseModal = () => !this.state.isSubmitting && this.props.onCloseModal();

  onSubmit = async () => {
    const { onSubmit = () => {} } = this.props;
    this.setState({ isSubmitting: true });
    try {
      await onSubmit();
      this.setState(
        { isSubmitting: false },
        () => this.props.closeModalAfterSuccessSubmit && this.onCloseModal()
      );
    } catch (e) {
      ShowConfirmNotif({
        message: "An error occured. Please try again.",
        type: "error",
      });
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const {
      classNames = {},
      contentDescriptionProps = {},
      footerProps = {},
      headerProps = {},
      shouldCloseOnOverlayClick = true,
    } = this.props;
    const { isSubmitting } = this.state;
    return (
      <Modal
        onCloseModal={this.onCloseModal}
        overlayClassName={cx(Style.overlay, classNames.overlay)}
        className={cx(classNames.modal, Style.modal)}
        contentLabel={this.props.contentLabel}
        isOpen
        onRequestClose={this.onCloseModal}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <Header
          classNames={classNames}
          headerProps={headerProps}
          onCloseModal={this.onCloseModal}
        />
        <ContentDescription
          classNames={classNames}
          contentDescriptionProps={contentDescriptionProps}
        />
        <div
          className={cx(
            Style.contentContainer,
            classNames.contentContainer,
            isSubmitting ? Style.disableContent : ""
          )}
        >
          {this.props.children}
        </div>
        <Footer
          classNames={classNames}
          footerProps={footerProps}
          isSubmitting={isSubmitting}
          onCloseModal={this.onCloseModal}
          onSubmit={this.onSubmit}
        />
      </Modal>
    );
  }
}

ResponsiveModal.propTypes = {
  children: PropTypes.any.isRequired,
  classNames: PropTypes.shape({
    contentContainer: PropTypes.string,
    contentDescriptionContainer: PropTypes.string,
    footerContainer: PropTypes.string,
    headerContainer: PropTypes.string,
    overlay: PropTypes.string,
    modal: PropTypes.string,
  }),
  closeModalAfterSuccessSubmit: PropTypes.bool,
  contentDescriptionProps: PropTypes.shape({
    contentDescription: PropTypes.any,
    showContentDescription: PropTypes.bool,
    title: PropTypes.string,
  }),
  contentLabel: PropTypes.string.isRequired,
  headerProps: PropTypes.shape({
    customHeader: PropTypes.any,
    header: PropTypes.string,
    showHeader: PropTypes.bool,
  }),
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

ResponsiveModal.defaultProps = {
  classNames: {},
  closeModalAfterSuccessSubmit: true,
  onSubmit: () => {},
  shouldCloseOnOverlayClick: true,
};

export default ResponsiveModal;
