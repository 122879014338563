// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import _get from "lodash.get";

// Style
import Style from "./style.module.scss";

// Fields
import { Input } from "fields";

// Lib
import { NEW_PAYMENT_CARD_FIELDS } from "lib/_constants";

class BillingSection extends React.Component {
  onGetFieldValue = (fieldID) => {
    const { billingDetails } = this.props;
    switch (fieldID) {
      case "country":
        return "United States";
      case "email":
      case "name":
        return billingDetails[fieldID] || "";
      default:
        return _get(billingDetails, `address.${fieldID}`) || "";
    }
  };

  onInputChange = (fieldID, fieldValue) =>
    this.props.onChangeBillingInfo(fieldID, fieldValue);

  renderFormFields = () =>
    Object.entries(NEW_PAYMENT_CARD_FIELDS).map((entry) => {
      const fieldID = entry[0];
      const fieldInfo = entry[1];
      return (
        <div className={cx(Style.field, Style[fieldID])} key={fieldID}>
          <Input.TextInput
            {...fieldInfo}
            fieldClassname={Style.zipCodeField}
            inputClassname={Style.inputField}
            name={fieldID}
            onChange={(val) => this.onInputChange(fieldID, val)}
            showError
            value={this.onGetFieldValue(fieldID)}
          />
        </div>
      );
    });

  render() {
    return this.renderFormFields();
  }
}

BillingSection.propTypes = {
  billingDetails: PropTypes.object,
  onChangeBillingInfo: PropTypes.func,
};

BillingSection.defaultProps = {
  billingDetails: {},
  onChangeBillingInfo: () => {},
};

export default BillingSection;
