// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Components
import { SplitOverlayGroup } from "components";

// Image
import DesignedByExperts from "assets/Images/designedByExperts.jpg";
import DesignedForMobile from "assets/Images/designedForMobile.png";
import FourJapaneseNoodles from "assets/Images/fourJapaneseNoodles.jpg";

const WEB_DESIGN_FEATURES = [
  {
    label: "Best-In-Class Websites for Restaurants",
    image: DesignedByExperts,
    list: [
      {
        item_name: "Best-In-Class Websites for Restaurants",
        item_description: "Custom designs that reflect your brand",
      },
      {
        item_name: "No DIY. Our experienced team works with you",
        item_description:
          "We handle the whole process of creating your website so you can focus on making great food",
      },
      {
        item_name: "Hospitality-first. Powering thousands of restaurants",
        item_description: "Get it done for less with a knowlegdable team",
      },
    ],
  },
  {
    label: "Your Customers are On-The-Go",
    image: DesignedForMobile,
    list: [
      {
        item_name: "Layouts that drive orders and results",
        item_description: "Responsive designs that scale to any device",
      },
      {
        item_name: "Easy. Efficient. Less Work for You",
        item_description: "Phone calls and location apps are a click away",
      },
      {
        item_name: "Easy to navigate. Get visitors what they need—fast",
        item_description:
          "Include pictures of menu items and  crucial information like ingredients and dietary restrictions",
      },
    ],
  },
  {
    label: "Best Practices, Built-In",
    image: FourJapaneseNoodles,
    list: [
      {
        item_name: "Experts in SEO and ADA accessibility",
        item_description:
          "Customers with special needs will be able to navigate your website",
      },
      {
        item_name: "Menus that drive sales. No PDFs",
        item_description:
          "Say goodbye to boring PDFs. Menus with beautiful pictures always drive up sales",
      },
    ],
  },
];

class WebsiteDesignFeatures extends React.Component {
  render() {
    return <SplitOverlayGroup items={WEB_DESIGN_FEATURES} />;
  }
}

export default WebsiteDesignFeatures;
