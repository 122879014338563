// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Styles
import Style from "./style.module.scss";

// Fields
import { Button, ResponsiveModal } from "fields";

// Lib
import { FoodMenuFuncs } from "lib/functions";

const { _sortMenuCategories = () => {} } = FoodMenuFuncs;

class CategoryNavigationModal extends React.Component {
  onSelectCategory = ({ categoryId }) => {
    this.props.onScrollToCategory({ categoryId });
    this.props.onCloseModal();
  };

  renderCategories = (categories = {}) =>
    _sortMenuCategories(categories).map((categoryId) => {
      const {
        groupIsArchived = false,
        groupName = "",
        items = {},
      } = categories[categoryId];
      const itemsCount = Object.keys(items).length;
      if (groupIsArchived || itemsCount === 0) return null;
      return (
        <div key={groupName}>
          <Button
            className={Style.category}
            name={groupName}
            onClick={() => this.onSelectCategory({ categoryId })}
          >
            <div className={Style.categoryName}>{groupName}</div>
            <div className={Style.itemsCount}>
              {itemsCount} item{itemsCount > 1 ? "s" : ""}
            </div>
          </Button>
        </div>
      );
    });

  render() {
    const { categories = {} } = this.props;
    return (
      Object.keys(categories).length > 0 && (
        <ResponsiveModal
          contentLabel="Navigate to a category"
          onCloseModal={this.props.onCloseModal}
        >
          <h1 className={Style.heading}>Select a category</h1>
          <div>{this.renderCategories(categories)}</div>
        </ResponsiveModal>
      )
    );
  }
}

CategoryNavigationModal.propTypes = {
  categories: PropTypes.object,
  onCloseModal: PropTypes.func.isRequired,
  onScrollToCategory: PropTypes.func.isRequired,
};

CategoryNavigationModal.defaultProps = {
  categories: {},
};

export default CategoryNavigationModal;
