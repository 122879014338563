// BUTI DINERS, INC. All right Reserved ©

import React from "react";
// import shortid from "shortid";

// Style
import Style from "./style.module.scss";

// Components
// import { OpptyBox } from "components";

// Constants
// const { OPPORTUNITIES } = Constants;

const Oppties = () => {
  // const renderOppties = () =>
  //   OPPORTUNITIES.map(oppty => (
  //     <div key={shortid.generate()}>
  //       <OpptyBox {...oppty} />
  //     </div>
  //   ));

  return (
    <div>
      <div className={Style.contentContainer}>
        <h1 className={Style.callToAction}>
          Do more than you ever thought possible. Create something amazing that
          you will use.
        </h1>
        <div className={Style.form}>
          <h2 className={Style.message}>
            We are looking for quick learners with a passion for learning new
            things and building products that will be used by billions. Whether
            you are a driven programmer or a curious individual, you will find a
            place at BUTI to explore and develop skills beneficial to{" "}
            <strong>your career path</strong>.
          </h2>
          <p className={Style.message}>
            Email your awesomeness to{" "}
            <a
              className={Style.jobContactEmail}
              href="mailto:careers@skiplinow.com"
            >
              careers@skiplinow.com
            </a>
          </p>
          {/* <div className={Style.opptyBoxesContainer}>{renderOppties()}</div> */}
        </div>
      </div>
    </div>
  );
};

export default Oppties;
