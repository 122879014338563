// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";

// Style
import Style from "./style.module.scss";

// Components
import { CheckBoxGroup } from "components";

// Fields
import { Button, Input } from "fields";

// Lib
import RegEx from "lib/_constants/RegEx";

const BUSINESS_INFO_FORM = [
  {
    id: "shopName",
    required: true,
  },
  {
    id: "shopAddress",
    required: true,
  },
  {
    id: "website",
    regEx: RegEx.WEBSITE,
  },
];

const PERSONAL_INFO_FORM = [
  {
    id: "name",
    placeholder: "Jane Doe",
    required: true,
  },
  {
    id: "email",
    placeholder: "jane.doe@example.com",
    regEx: RegEx.EMAIL,
    required: true,
    type: "email",
  },
  {
    id: "phoneNumber",
    placeholder: "678.453.6754",
    required: true,
    type: "number",
  },
];

const SERVICES = {
  virtualRestaurant: { label: "Virtual Restaurant" },
  contactless: { label: "Contactess Ordering" },
  onlineOrdering: { label: "Online Ordering" },
  websiteDesign: { label: "Website Design" },
  delivery: { label: "Delivery (Zero Commission)" },
};

class GetStartedForm extends React.Component {
  state = { form: {}, selectedOptionIds: {} };

  onClickCheckbox = (optionId) => {
    const { selectedOptionIds } = this.state;
    this.setState({
      selectedOptionIds: selectedOptionIds[optionId]
        ? del(selectedOptionIds, optionId)
        : set(selectedOptionIds, optionId, 1),
    });
  };

  onGetInputLabel = (fieldID) => {
    switch (fieldID) {
      case "email":
        return "email";
      case "locationsCount":
        return "# of locations";
      case "name":
        return "your name";
      case "phoneNumber":
        return "phone number";
      case "shopAddress":
        return "Restaurant Address";
      case "shopName":
        return "Restaurant Name";
      case "website":
        return "Website";
      default:
        return "";
    }
  };

  onGetSumbitButtonStatus = () => {
    const { form } = this.state;
    if (this.props.isSubmittingForm) return "loading";
    else if (
      !form.name ||
      !form.phoneNumber ||
      !form.shopName ||
      !form.shopAddress
    )
      return "inactive";
    else if (form.website) {
      if (!RegEx.WEBSITE.test(form.website)) return "inactive";
    } else if (!RegEx.EMAIL.test(form.email)) return "inactive";
    return "active";
  };

  onInputChange = (fieldID, fieldValue) => {
    const { form = {} } = this.state;
    let value = fieldValue;
    if (fieldID === "locationsCount") value = value < 1 ? 1 : value;
    this.setState({ form: set(form, fieldID, value) });
  };

  onSubmitMerchantInfo = () => {
    const { form, selectedOptionIds } = this.state;
    this.props.onSubmit({
      form: form,
      selectedServices: Object.keys(selectedOptionIds).map((id) => {
        const { label = "" } = SERVICES[id];
        return label;
      }),
    });
  };

  renderForm = (form) =>
    form.map((field) => {
      const { id } = field;
      return (
        <div className={Style.field} key={id}>
          <div className={Style.inputLabel}>
            <div style={{ paddingRight: "5px" }}>
              {this.onGetInputLabel(id)}
            </div>
          </div>
          <Input.TextInput
            {...field}
            fieldClassname={Style.inputField}
            name={id}
            onChange={(val) => this.onInputChange(id, val)}
            value={this.state.form[id] || ""}
          />
        </div>
      );
    });

  renderCheckboxGroup = () => (
    <CheckBoxGroup
      heading="Which of our services are you most interested in?"
      headingClassname={Style.checkboxGroupHeading}
      labelClassName={Style.checkBox}
      onClick={this.onClickCheckbox}
      options={SERVICES}
      selectedOptionIds={Object.keys(this.state.selectedOptionIds)}
    />
  );

  render() {
    return (
      <div className={Style.container}>
        <h1 className={Style.heading}>Contact Us</h1>
        <p className={Style.paragraph}>
          Increase your takeout & delivery sales. Feature your business on
          Skipli and reach new customers.
        </p>
        <div className={Style.form}>{this.renderForm(BUSINESS_INFO_FORM)}</div>
        <div className={Style.form}>{this.renderForm(PERSONAL_INFO_FORM)}</div>
        <div className={Style.form}>{this.renderCheckboxGroup()}</div>
        <Button
          className={Style.submitButton}
          hasShadow
          name="Contact us button"
          loadingText="Submitting"
          onClick={this.onSubmitMerchantInfo}
          status={this.onGetSumbitButtonStatus()}
        >
          Submit
        </Button>
      </div>
    );
  }
}

GetStartedForm.propTypes = {
  isSubmittingForm: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

GetStartedForm.defaultProps = {
  isSubmittingForm: false,
};

export default GetStartedForm;
