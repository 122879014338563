// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

// Style
import Style from "./style.module.scss";

// Fields
import { CenterModal } from "fields";

const LoadingModal = (props) => (
  <CenterModal
    contentLabel="Video Player Modal"
    onCloseModal={props.onCloseModal}
    modalBoxClassname={Style.modalBox}
    showCloseButton={false}
    shouldCloseOnOverlayClick
  >
    <ReactPlayer
      className={Style.videoPlayer}
      controls={props.controls}
      playing={props.playing}
      url={props.url}
    />
  </CenterModal>
);

LoadingModal.propTypes = {
  controls: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  playing: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

LoadingModal.defaultProps = {
  controls: true,
  playing: true,
};

export default LoadingModal;
