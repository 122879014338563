// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ContentLoader from "react-content-loader";

const ModifierLoader = (props) => (
  <ContentLoader
    {...props}
    height={30}
    width={260}
    speed={2}
    // primaryColor="#E0E0E0"
    // secondaryColor="#F2F2F2"
  >
    <rect x="0" y="0" rx="0" ry="0" width="260" height="30" />
  </ContentLoader>
);

export default ModifierLoader;
