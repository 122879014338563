// BUTI DINERS, INC. All right Reserved ©

const isLocal = () => {
  const { NODE_ENV } = process.env;
  return NODE_ENV === "development";
};

const DEV_BASE_URL = "http://localhost:8000";
const PROD_BASE_URL = "https://www.skipli-external-backend.com";
const BASE_URL = isLocal() ? DEV_BASE_URL : PROD_BASE_URL;

// -------------- BUTI DINERS METHODS --------------
// GET METHODS
const CONVERT_LOCAL_TIME_TO_UTC = `${BASE_URL}/convertLocalTimeToUTC`;
const CONVERT_UTC_TIME_TO_LOCAL_TIME = `${BASE_URL}/convertUTCToLocalTime`;
const GET_ALL_SHOPS = `${BASE_URL}/allShops`;
const GET_CURRENT_UTC_TIMESTAMP = `${BASE_URL}/currentUTCTimestamp`;
const GET_CUSTOMER_STRIPE_RECEIPT = `${BASE_URL}/customerStripeReceipt`;
const GET_ORDER_DETAILS = `${BASE_URL}/orderDetails`;
const GET_TIME_RANGE = `${BASE_URL}/timeRange`;

// POST METHODS
const ADD_EMAIL_TO_MAILING_LIST = `${BASE_URL}/addEmail`;
const CHANGE_MENU_GROUP_IMAGE = `${BASE_URL}/changeMenuGroupImage`;
const CHANGE_MENU_ITEM_IMAGE = `${BASE_URL}/changeMenuItemImage`;
const CHANGE_SHOP_LOGO = `${BASE_URL}/changeShopLogo`;
const GET_CUSTOMER_ITEMIZED_RECEIPT = `${BASE_URL}/customerItemizedReceipt`;
const SAVE_MERCHANT_STRIPE_ACCOUNT_ID = `${BASE_URL}/saveMerchantStripeAccountID`;
const SEND_TEXT_MESSAGE = `${BASE_URL}/sendTextMessage`;
const SEND_EMAIL = `${BASE_URL}/sendEmail`;

// -------------- CUSTOMER METHODS --------------
// GET METHODS
const GET_ALL_POSTS_FOR_CUSTOMER = `${BASE_URL}/allPosts`;
const GET_SINGLE_POST_FOR_CUSTOMER = `${BASE_URL}/singlePost`;
const CREATE_UNIQUE_ID_FOR_CUSTOMER = `${BASE_URL}/customerUniqueID`;
const GET_CUSTOMER_CART = `${BASE_URL}/customerCart`;
const GET_CUSTOMER_PAST_ORDERS_AT_SHOP = `${BASE_URL}/customerPastOrdersAtShop`;
const GET_CUSTOMER_PAYMENT_METHODS = `${BASE_URL}/customerPaymentMethods`;
const GET_CUSTOMER_PROFILE = `${BASE_URL}/customerProfile`;
const GET_DOORDASH_DELIVERY_DETAILS = `${BASE_URL}/doordashDeliveryDetails`;
const GET_MENU_ITEM_FULL_IMAGE = `${BASE_URL}/menuItemFullImage`;
const GET_SHOPS_FOR_CUSTOMER = `${BASE_URL}/shopsForCustomer`;
const GET_SHOP_WEBSITE_INFO = `${BASE_URL}/websiteInfo`;

// POST METHODS
const ADD_ORDER_TO_CUSTOMER_PAST_ORDERS = `${BASE_URL}/addOrderToCustomerPastOrders`;
const ADD_ORDER_TO_SHOP_ACTIVE_ORDERS = `${BASE_URL}/addOrderToShopActiveOrders`;
const ATTACH_PAYMENT_METHOD_TO_CUSTOMER = `${BASE_URL}/attachPaymentMethodToCustomer`;
const CHANGE_CUSTOMER_CART = `${BASE_URL}/changeCustomerCart`;
const CHANGE_CUSTOMER_PROFILE = `${BASE_URL}/changeCustomerProfile`;
const CLEAR_CUSTOMER_CART = `${BASE_URL}/clearCustomerCart`;
const CREATE_DOORDASH_DELIVERY = `${BASE_URL}/doordashDelivery`;
const CREATE_POSTMATES_DELIVERY = `${BASE_URL}/postmatesDelivery`;
const CREATE_POSTMATES_QUOTE = `${BASE_URL}/postmatesQuote`;
const CREATE_STRIPE_CUSTOMER_ID = `${BASE_URL}/newStripeCustomerID`;
const DETACH_PAYMENT_METHOD_FROM_CUSTOMER = `${BASE_URL}/detachPaymentMethodFromCustomer`;
const DOORDASH_DELIVERY_ESTIMATE = `${BASE_URL}/doordashDeliveryEstimate`;
const INITIALIZE_STRIPE_CHECKOUT = `${BASE_URL}/initializeStripeCheckout`;
const SAVE_CUSTOMER_PHONE_NUMBERS = `${BASE_URL}/saveCustomerPhoneNumbers`;
const SAVE_CUSTOMER_SURVEY = `${BASE_URL}/saveCustomerSurvey`;

// -------------- MERCHANT METHODS --------------
// GET METHODS
const CHECK_IF_ITEMS_ARE_IN_STOCK = `${BASE_URL}/checkIfItemsAreInStock`;
const CREATE_STRIPE_CONNECT_ACCOUNT = `${BASE_URL}/createStripeConnectAccount`;
const GET_GROUP_MODIFIER_INFORMATION = `${BASE_URL}/groupModifierInformation`;
const GET_MENU_GROUP_INFORMATION = `${BASE_URL}/menuGroupInformation`;
const GET_MENU_ITEM_INFORMATION = `${BASE_URL}/menuItemInformation`;
const GET_SALES_REPORT_DATA = `${BASE_URL}/salesReportData`;
const GET_MENU_MODIFIER_GROUP_INFORMATION = `${BASE_URL}/menuModifierGroupInformation`;
const GET_MENU_MODIFIER_INFORMATION = `${BASE_URL}/menuModifierInformation`;
const GET_SHOP_ACTIVE_COUPONS = `${BASE_URL}/activeCoupons`;
const GET_SHOP_ALL_GROUPS = `${BASE_URL}/allGroups`;
const GET_SHOP_ALL_ITEMS = `${BASE_URL}/allItems`;
const GET_SHOP_ALL_MENUS = `${BASE_URL}/allMenus`;
const GET_SHOP_ALL_MODIFIER_GROUPS = `${BASE_URL}/allModifierGroups`;
const GET_SHOP_ALL_MODIFIERS = `${BASE_URL}/allModifiers`;
const GET_SHOP_ALL_PERSONNELS = `${BASE_URL}/allPersonnels`;
const GET_SHOP_BASIC_INFO = `${BASE_URL}/shopBasicInfo`;
const GET_MENUS_FOR_CUSTOMER = `${BASE_URL}/menusForCustomer`;
const GET_SHOP_ORDERS = `${BASE_URL}/shopOrders`;
const GET_SHOP_PERSONNEL_INFORMATION = `${BASE_URL}/personnelInfo`;
const GET_SHOP_STOREFRONT = `${BASE_URL}/getShopStoreFront`;
const VERIFY_PERSONNEL_PIN = `${BASE_URL}/verifyPersonnelPin`;


// POST METHODS
const ADD_GROUP_TO_MENU = `${BASE_URL}/addGroupToMenu`;
const ADD_ITEM_TO_GROUP = `${BASE_URL}/addItemToGroup`;
const ADD_ITEM_TO_GROUP_MODIFIER = `${BASE_URL}/addItemToGroupModifier`;
const ADD_MODIFIER_TO_GROUP = `${BASE_URL}/addModifierToGroup`;
const CREATE_COUPON = `${BASE_URL}/createCoupon`;
const CREATE_NEW_MENU_GROUP = `${BASE_URL}/createNewMenuGroup`;
const CREATE_NEW_MENU = `${BASE_URL}/createNewMenu`;
const CREATE_NEW_MODIFIER = `${BASE_URL}/createNewModifier`;
const CREATE_NEW_MODIFIER_GROUP = `${BASE_URL}/createNewModifierGroup`;
const CREATE_NEW_SHOP_PERSONNEL = `${BASE_URL}/createNewShopPersonnel`;
const CREATE_STRIPE_PAYMENT = `${BASE_URL}/createStripePayment`;
const ARCHIVE_ITEM = `${BASE_URL}/archiveItem`;
const ARCHIVE_MENU_GROUP = `${BASE_URL}/archiveMenuGroup`;
const DELETE_MENU = `${BASE_URL}/deleteMenu`;
const DELETE_MODIFIER = `${BASE_URL}/deleteModifier`;
const ARCHIVE_MODIFIER_GROUP = `${BASE_URL}/archiveModifierGroup`;
const MOVE_ACTIVE_ORDER_TO_PAST_ORDERS = `${BASE_URL}/moveActiveOrderToPastOrders`;
const REMOVE_COUPON = `${BASE_URL}/removeCoupon`;
const REMOVE_GROUP_FROM_MENU = `${BASE_URL}/removeGroupFromMenu`;
const REMOVE_ITEM_FROM_GROUP = `${BASE_URL}/removeItemFromGroup`;
const REMOVE_ITEM_FROM_GROUP_MODIFIER = `${BASE_URL}/removeItemFromGroupModifier`;
const SAVE_CHANGED_COUPON = `${BASE_URL}/saveChangedCoupon`;
const REMOVE_SHOP_PERSONNEL = `${BASE_URL}/removeShopPersonnel`;
const SAVE_CHANGED_GROUP_INFO = `${BASE_URL}/saveChangedGroupInfo`;
const SAVE_CHANGED_MENU_INFO = `${BASE_URL}/saveChangedMenuInfo`;
const SAVE_CHANGED_MODIFIER_INFO = `${BASE_URL}/saveChangedModifierInfo`;
const SAVE_CHANGED_MODIFIER_GROUP_INFO = `${BASE_URL}/saveChangedModifierGroupInfo`;
const SAVE_CHANGED_PICKUP_INFO = `${BASE_URL}/saveChangedPickUpInfo`;
const SAVE_CHANGED_SHOP_INFO = `${BASE_URL}/saveChangedShopInfo`;
const SAVE_CHECK_IN_INFO = `${BASE_URL}/saveCheckInInfo`;
const SAVE_PERSONNEL_INFO = `${BASE_URL}/savePersonnelInfo`;

const API_Paths = {
  ADD_EMAIL_TO_MAILING_LIST,
  ADD_GROUP_TO_MENU,
  ADD_ITEM_TO_GROUP,
  ADD_ITEM_TO_GROUP_MODIFIER,
  ADD_MODIFIER_TO_GROUP,
  ADD_ORDER_TO_CUSTOMER_PAST_ORDERS,
  ADD_ORDER_TO_SHOP_ACTIVE_ORDERS,
  ATTACH_PAYMENT_METHOD_TO_CUSTOMER,
  CHANGE_CUSTOMER_CART,
  CHANGE_CUSTOMER_PROFILE,
  CHANGE_MENU_GROUP_IMAGE,
  CHANGE_MENU_ITEM_IMAGE,
  CHANGE_SHOP_LOGO,
  CHECK_IF_ITEMS_ARE_IN_STOCK,
  CLEAR_CUSTOMER_CART,
  CONVERT_LOCAL_TIME_TO_UTC,
  CONVERT_UTC_TIME_TO_LOCAL_TIME,
  CREATE_COUPON,
  CREATE_DOORDASH_DELIVERY,
  CREATE_NEW_MENU_GROUP,
  CREATE_NEW_MENU,
  CREATE_NEW_MODIFIER,
  CREATE_NEW_MODIFIER_GROUP,
  CREATE_NEW_SHOP_PERSONNEL,
  CREATE_POSTMATES_DELIVERY,
  CREATE_POSTMATES_QUOTE,
  CREATE_STRIPE_PAYMENT,
  CREATE_STRIPE_CONNECT_ACCOUNT,
  CREATE_STRIPE_CUSTOMER_ID,
  CREATE_UNIQUE_ID_FOR_CUSTOMER,
  ARCHIVE_ITEM,
  ARCHIVE_MENU_GROUP,
  ARCHIVE_MODIFIER_GROUP,
  DELETE_MENU,
  DELETE_MODIFIER,
  DETACH_PAYMENT_METHOD_FROM_CUSTOMER,
  DOORDASH_DELIVERY_ESTIMATE,
  GET_ALL_POSTS_FOR_CUSTOMER,
  GET_SINGLE_POST_FOR_CUSTOMER,
  GET_ALL_SHOPS,
  GET_CUSTOMER_ITEMIZED_RECEIPT,
  GET_CURRENT_UTC_TIMESTAMP,
  GET_CUSTOMER_CART,
  GET_CUSTOMER_PROFILE,
  GET_DOORDASH_DELIVERY_DETAILS,
  GET_CUSTOMER_PAST_ORDERS_AT_SHOP,
  GET_CUSTOMER_PAYMENT_METHODS,
  GET_GROUP_MODIFIER_INFORMATION,
  GET_MENU_GROUP_INFORMATION,
  GET_MENU_ITEM_FULL_IMAGE,
  GET_MENU_ITEM_INFORMATION,
  GET_MENU_MODIFIER_GROUP_INFORMATION,
  GET_MENU_MODIFIER_INFORMATION,
  GET_ORDER_DETAILS,
  GET_CUSTOMER_STRIPE_RECEIPT,
  GET_SALES_REPORT_DATA,
  GET_SHOP_ACTIVE_COUPONS,
  GET_SHOP_ALL_GROUPS,
  GET_SHOP_ALL_ITEMS,
  GET_SHOP_ALL_MENUS,
  GET_SHOP_ALL_MODIFIER_GROUPS,
  GET_SHOP_ALL_MODIFIERS,
  GET_SHOP_ALL_PERSONNELS,
  GET_SHOP_BASIC_INFO,
  GET_SHOPS_FOR_CUSTOMER,
  GET_MENUS_FOR_CUSTOMER,
  GET_SHOP_ORDERS,
  GET_SHOP_PERSONNEL_INFORMATION,
  GET_SHOP_STOREFRONT,
  GET_SHOP_WEBSITE_INFO,
  GET_TIME_RANGE,
  INITIALIZE_STRIPE_CHECKOUT,
  MOVE_ACTIVE_ORDER_TO_PAST_ORDERS,
  REMOVE_COUPON,
  REMOVE_GROUP_FROM_MENU,
  REMOVE_ITEM_FROM_GROUP,
  REMOVE_ITEM_FROM_GROUP_MODIFIER,
  SAVE_CHANGED_COUPON,
  REMOVE_SHOP_PERSONNEL,
  SAVE_CHANGED_GROUP_INFO,
  SAVE_CHANGED_MENU_INFO,
  SAVE_CHANGED_MODIFIER_INFO,
  SAVE_CHANGED_MODIFIER_GROUP_INFO,
  SAVE_CHANGED_SHOP_INFO,
  SAVE_CHANGED_PICKUP_INFO,
  SAVE_CUSTOMER_PHONE_NUMBERS,
  SAVE_CUSTOMER_SURVEY,
  SAVE_MERCHANT_STRIPE_ACCOUNT_ID,
  SAVE_CHECK_IN_INFO,
  SAVE_PERSONNEL_INFO,
  SEND_TEXT_MESSAGE,
  SEND_EMAIL,
  VERIFY_PERSONNEL_PIN,
};

export default API_Paths;
