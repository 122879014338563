// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Utils
import { _updatePageTitleAndDescription } from "utils";

// Style
import Style from "./style.module.scss";

class Privacy extends React.Component {
  componentDidMount = () => {
    _updatePageTitleAndDescription({
      description:
        "SKIPLI is the leading All-in-1 Marketing & Online Revenue Platform for Restaurants. Get everything from websites to social media, Google ads, email marketing, online ordering, reservations & more for the lowest cost & lowest effort.",
      title: "Privacy | SKIPLI",
    });
  };

  render() {
    return (
      <div className={Style.pageContainer}>
        <div className={Style.sectionContainer}>
          <h1>Global Privacy Policy</h1>
          <h2 style={{ marginBottom: "30px" }}>
            Last update: 27 September 2022
          </h2>
          <div className={Style.textBlock}>
            <h3>Welcome to SKIPLI!</h3>
            <p>
              SKIPLI and its affiliates (collectively "SKIPLI", "we" and "us")
              respect your privacy. We offer services that enable platforms and
              merchants to run businesses, and to safely conduct online payment
              transactions.
              <br />
              <br />
              ​This Global Privacy Policy describes the types of Personal Data
              we collect through our payments products and services ("Services")
              and via our online presence, which include our main website at
              SKIPLInow.com, as well as services and websites that we enable
              Internet users to access, such as SKIPLI Checkout and the SKIPLI
              Merchant Management (collectively, our “Sites”). This policy also
              describes how we use Personal Data, with whom we share it, your
              rights and choices, and how you can contact us about our privacy
              practices. This policy does not apply to third-party websites,
              products, or services, even if they link to our Services or Sites,
              and you should consider the privacy practices of those
              third-parties carefully.
            </p>
          </div>
          <div className={Style.textBlock}>
            <h3>1. Overview</h3>
            <p>
              SKIPLI obtains Personal Data about you from various sources to
              provide our Services and to manage our Sites. "You" may be a
              visitor to one of our websites, a user of one or more of our
              Services ("User" or "SKIPLI User"), or a customer of a User
              ("Customer"). If you are a Customer, SKIPLI will generally not
              collect your Personal Data directly from you. Your agreement with
              the relevant SKIPLI User should explain how the SKIPLI User shares
              your Personal Data with SKIPLI, and if you have questions about
              this sharing, then you should direct those questions to the SKIPLI
              User.
              <br />
              <br />
              <strong>a. Site visitors:</strong> If you visit or use our Sites,
              we may collect Personal Data. For example, we collect Personal
              Data that you submit to us via online forms and surveys, and when
              you contact us by email.
              <br />
              <br />
              <strong>b. Payment processing services:</strong> As a processor of
              payment transactions and provider of related services, we may
              collect, use and disclose Personal Data about Customers when we
              act as a SKIPLI User’s service provider. SKIPLI Users are
              responsible for making sure that the Customer’s privacy rights are
              respected, including ensuring appropriate disclosures about third
              party data collection and use. To the extent that we are acting as
              a SKIPLI User’s data processor, we will process Personal Data in
              accordance with the terms of our agreement with the SKIPLI User
              and the SKIPLI User’s lawful instructions. If you are a Customer
              and would like to obtain more information about how a SKIPLI User
              uses third party services like SKIPLI to process your Personal
              Data in the context of payment transactions, please contact the
              SKIPLI User directly or visit the SKIPLI User’s privacy policy.
              <br />
              <br />
              <strong>
                c. Fraud prevention activities and activities for offering a
                compliant and secure platform:
              </strong>{" "}
              The collection and use of Personal Data is critical in helping us
              to ensure that our platform and services are safe, secure and
              compliant. In the context of fraud monitoring, prevention and
              detection services, we may monitor insights and patterns of
              payment transactions and other online signals to reduce the risk
              of fraud, money laundering and other harmful activity for
              ourselves, our Users and their Customers. The SKIPLI entities
              responsible for the collection and use of Personal Data for fraud
              monitoring, detection and prevention activities are indicated
              below.
            </p>
          </div>
          <div className={Style.textBlock}>
            <h3>2. Data We Collect</h3>
            <p>
              <strong>a. Personal Data that we collect about you:</strong>{" "}
              Personal Data is any information that relates to an identified or
              identifiable individual. The Personal Data that you provide
              directly to us through our Sites will be apparent from the context
              in which you provide the data. In particular: (i) When you
              register for a SKIPLI account we collect your full name, email
              address, and account log-in credentials, (ii) When you fill-in our
              online form to contact our sales team, we collect your full name,
              work email, country, and anything else you tell us about your
              project, needs and timeline, (iii) When you use the “Remember Me”
              feature of SKIPLI Checkout, we collect your email address, payment
              card number, CVC code and expiration date.
              <br />
              <br />
              When you respond to SKIPLI emails or surveys we collect your email
              address, name and any other information you choose to include in
              the body of your email or responses. If you contact us by phone,
              we will collect the phone number you use to call SKIPLI. If you
              contact us by phone as a SKIPLI User, we may collect additional
              information in order to verify your identity.
              <br />
              <br />
              If you are a SKIPLI User, you will provide your contact details,
              such as name, postal address, telephone number, and email address.
              As part of your business relationship with us, we may also receive
              financial and personal information about you, such as your date of
              birth and government identifiers associated with you and your
              organization (such as your social security number, tax number, or
              Employer Identification Number).
              <br />
              <br />
              If you are a Customer, when you make payments or conduct
              transactions through a SKIPLI User’s website or application, we
              will receive your transaction information. Depending on how the
              SKIPLI User implements our Services, we may receive this
              information directly from you, or from the SKIPLI User or third
              parties. The information that we collect will include payment
              method information (such as credit or debit card number, or bank
              account information), purchase amount, date of purchase, and
              payment method. Different payment methods may require the
              collection of different categories of information. The SKIPLI User
              will determine the payment methods that it enables you to use, and
              the payment method information that we collect will depend upon
              the payment method that you choose to use from the list of
              available payment methods that are offered to you by the SKIPLI
              User. When you make a transaction, we may also receive your name,
              email, billing or shipping address and in some cases your
              transaction history to authenticate you.
              <br />
              <br />
              When we conduct fraud monitoring, prevention and detection
              activities, we may also receive Personal Data about you from our
              business partners, financial service providers, identity
              verification services, and publicly available sources (e.g., name,
              address, phone number, country), as necessary to confirm your
              identity and prevent fraud. Our fraud monitoring, detection and
              prevention services may use technology that helps us assess the
              risk associated with an attempted transaction that is enabled on
              the SKIPLI User’s website or the application that collects
              information.
              <br />
              <br />
              You may also choose to submit information to us via other methods,
              including: (i) in response to marketing or other communications,
              (ii) through social media or online forums, (iii) through
              participation in an offer, program or promotion, (iv) in
              connection with an actual or potential business relationship with
              us, or (v) by giving us your business card or contact details at
              trade shows or other events.
              <br />
              <br />
              <strong>b. Merchant Dashboard Mobile App:</strong> If you are a
              merchant using the Merchant Dashboard Mobile App on an Android
              device, we may collect MAC address of the bluetooth thermal
              receipt printers. We only collect the aforementioned information
              only when you add a bluetooth thermal receipt printer into the app
              in order to print receipts. MAC address is a unique identifer of
              the thermal printer and contains no sensitive personal information
              of the user. We collect MAC address of the printer for the app to
              automatically reconnect to the printer in the event of app
              crashing or loss of connectivity.
              <br />
              <br />
              <strong>
                c. Information that we collect automatically on our Sites:
              </strong>{" "}
              Our Sites use cookies and other technologies to function
              effectively. These technologies record information about your use
              of our Sites, including: (i) Browser and device data, such as IP
              address, device type, operating system and Internet browser type,
              screen resolution, operating system name and version, device
              manufacturer and model, language, plug-ins, add-ons and the
              language version of the Sites you are visiting, (ii) Usage data,
              such as time spent on the Sites, pages visited, links clicked,
              language preferences, and the pages that led or referred you to
              our Sites. (iii) We also may collect information about your online
              activities on websites and connected devices over time and across
              third-party websites, devices, apps and other online features and
              services. We use Google Analytics on our Sites to help us analyze
              Your use of our Sites and diagnose technical issues.
              <br />
              <br />
            </p>
          </div>
          <div className={Style.textBlock}>
            <h3>3. How We Use Personal Data</h3>
            <p>
              <strong>a. Our products and services:</strong> We rely upon a
              number of legal grounds to ensure that our use of your Personal
              Data is compliant with applicable law. We use Personal Data to
              facilitate the business relationships we have with our Users, to
              comply with our financial regulatory and other legal obligations,
              and to pursue our legitimate business interests. We also use
              Personal Data to complete payment transactions and to provide
              payment-related services to our Users.
              <br />
              <br />
              Contractual and pre-contractual business relationships. We use
              Personal Data for the purpose of entering into business
              relationships with prospective SKIPLI Users, and to perform the
              contractual obligations under the contracts that we have with
              SKIPLI Users. Activities that we conduct in this context include:
              (i) Creation and management of SKIPLI accounts and SKIPLI account
              credentials, including the evaluation of applications to commence
              or expand the use of our Services, (ii) Creation and management of
              SKIPLI Checkout accounts, (iii) Accounting, auditing, and billing
              activities, (iv) Processing of payments with SKIPLI Checkout,
              communications regarding such payments, and related customer
              service.
              <br />
              <br />
              Legal and regulatory compliance. We use Personal Data to verify
              the identity of our Users in order to comply with fraud
              monitoring, prevention and detection obligations, laws associated
              with the identification and reporting of illegal and illicit
              activity, such as AML (Anti-Money Laundering) and KYC
              (Know-Your-Customer) obligations, and financial reporting
              obligations. For example, we may be required to record and verify
              a User’s identity for the purpose of compliance with legislation
              intended to prevent money laundering and financial crimes. These
              obligations are imposed on us by the operation of law, industry
              standards, and by our financial partners, and may require us to
              report our compliance to third parties, and to submit to third
              party verification audits.
              <br />
              <br />
              Legitimate business interests. We rely on our legitimate business
              interests to process Personal Data about you. The following list
              sets out the business purposes that we have identified as
              legitimate. In determining the content of this list, we balanced
              our interests against the legitimate interests and rights of the
              individuals whose Personal Data we process.
              <br />
              <br />
              Payment transactions and related services. As a processor of
              payment transactions, we use Personal Data of our User’s Customers
              to process online payment transactions and authenticate Customers
              on behalf of our Users. All such use is pursuant to the terms of
              our business relationships with our Users. In addition, we may
              offer payment-related services to Users who have requested such
              services, and our delivery of such related services to our Users
              may involve the use of Personal Data. For example, a SKIPLI User
              may specify parameters for transactions submitted by its Customers
              that determine whether the transactions are blocked or allowed by
              our platform. The entity responsible for the collection and use of
              Customer’s Personal Data in the context of payment transactions
              and payment-related services is the User.
              <br />
              <br />
              <strong>
                b. Marketing and events-related communications:
              </strong>{" "}
              We may send you email marketing communications about SKIPLI
              products and services, invite you to participate in our events or
              surveys, or otherwise communicate with you for marketing purposes,
              provided that we do so in accordance with the consent requirements
              that are imposed by applicable law. When we collect your business
              contact details through our participation at trade shows or other
              events, we may use the information to follow-up with you regarding
              an event, send you information that you have requested on our
              products and services and, with your permission, include you on
              our marketing information campaigns.
              <br />
              <br />
              <strong>c. Interest-based advertising:</strong> When you visit our
              Sites or online services, both we and certain third parties
              collect information about your online activities over time and
              across different sites to provide you with advertising about
              products and services tailored to your individual interests (this
              type of advertising is called “interest-based advertising”). These
              third parties may place or recognize a unique cookie or other
              technology on your browser (including the use of pixel tags).
              Where required by applicable law, we will obtain your consent
              prior to processing of your information for the purpose of
              interest-based advertising. ​ You may see our ads on other
              websites or mobile apps because we participate in advertising
              networks. Ad networks allow us to target our messaging to users
              based on a range of factors, including demographic data, users’
              inferred interests and browsing context (for example, the time and
              date of your visit to our Sites, the pages that you viewed, and
              the links that you clicked on). This technology also helps us
              track the effectiveness of our marketing efforts and understand if
              you have seen one of our advertisements.
            </p>
          </div>
          <div className={Style.textBlock}>
            <h3>4. How We Disclose Personal Data</h3>
            <p>
              SKIPLI does not sell or rent Personal Data to marketers or
              unaffiliated third parties. We share your Personal Data with
              trusted entities, as outlined below.
              <br />
              <br />
              <strong>a. SKIPLI:</strong> We share Personal Data with other
              SKIPLI entities in order to provide our Services and for internal
              administration purposes.
              <br />
              <br />
              <strong>b. Service providers:</strong> We share Personal Data with
              a limited number of our service providers. We have service
              providers that provide services on our behalf, such as identity
              verification services, website hosting, data analysis, information
              technology and related infrastructure, customer service, email
              delivery, and auditing services. These service providers may need
              to access Personal Data to perform their services. We authorize
              such service providers to use or disclose the Personal Data only
              as necessary to perform services on our behalf or comply with
              legal requirements. We require such service providers to
              contractually commit to protect the security and confidentiality
              of Personal Data they process on our behalf. Our service providers
              are predominantly located in the United States of America.
              <br />
              <br />
              <strong>c. Business partners:</strong> We share Personal Data with
              third party business partners when this is necessary to provide
              our Services to our Users. Examples of third parties to whom we
              may disclose Personal Data for this purpose are banks and payment
              method providers (such as credit card networks) when we provide
              payment processing services.
              <br />
              <br />
              <strong>
                d. Our Users and third parties authorized by our Users:
              </strong>{" "}
              We share Personal Data with Users as necessary to maintain a User
              account and provide the Services. We share data with parties
              directly authorized by a User to receive Personal Data, such as
              when a User authorizes a third party application provider to
              access the User’s Stripe account using Stripe Connect. The use of
              Personal Data by an authorized third party is subject to the third
              party’s privacy policy.
              <br />
              <br />
              <strong>e. Corporate transactions:</strong> In the event that we
              enter into, or intend to enter into, a transaction that alters the
              structure of our business, such as a reorganization, merger, sale,
              joint venture, assignment, transfer, change of control, or other
              disposition of all or any portion of our business, assets or
              stock, we may share Personal Data with third parties for the
              purpose of facilitating and completing the transaction.
              <br />
              <br />
              <strong>f. Compliance and harm prevention:</strong> We share
              Personal Data as we believe necessary: (i) to comply with
              applicable law, or payment method rules; (ii) to enforce our
              contractual rights; (iii) to protect the rights, privacy, safety
              and property of SKIPLI, you or others; and (iv) to respond to
              requests from courts, law enforcement agencies, regulatory
              agencies, and other public and government authorities, which may
              include authorities outside your country of residence.
            </p>
          </div>
          <div className={Style.textBlock}>
            <h3>5. Security and Retention</h3>
            <p>
              We make reasonable efforts to ensure a level of security
              appropriate to the risk associated with the processing of Personal
              Data. We maintain organizational, technical and administrative
              measures designed to protect Personal Data within our organization
              against unauthorized access, destruction, loss, alteration or
              misuse. Your Personal Data is only accessible to a limited number
              of personnel who need access to the information to perform their
              duties. Unfortunately, no data transmission or storage system can
              be guaranteed to be 100% secure. If you have reason to believe
              that your interaction with us is no longer secure (for example, if
              you feel that the security of your account has been compromised),
              please contact us immediately.
              <br />
              <br />
              If you are a SKIPLI User, we retain your Personal Data as long as
              we are providing the Services to you. We retain Personal Data
              after we cease providing Services to you, even if you close your
              SKIPLI account, to the extent necessary to comply with our legal
              and regulatory obligations, and for the purpose of fraud
              monitoring, detection and prevention. We also retain Personal Data
              to comply with our tax, accounting, and financial reporting
              obligations, where we are required to retain the data by our
              contractual commitments to our financial partners, and where data
              retention is mandated by the payment methods that we support.
              Where we retain data, we do so in accordance with any limitation
              periods and records retention obligations that are imposed by
              applicable law.
            </p>
          </div>
          <div className={Style.textBlock}>
            <h3>6. Updates To this Privacy Policy and Notifications</h3>
            <p>
              We may change this Privacy Policy from time to time to reflect new
              services, changes in our Personal Data practices or relevant laws.
              The “Last updated” legend at the top of this Privacy Policy
              indicates when this Privacy Policy was last revised. Any changes
              are effective when we post the revised Privacy Policy on the
              Services. We may provide you with disclosures and alerts regarding
              the Privacy Policy or Personal Data collected by posting them on
              our website and, if you are a User, by contacting you through your
              SKIPLI Dashboard, email address and/or the physical address listed
              in your SKIPLI account.
            </p>
          </div>
          <div className={Style.textBlock}>
            <h3>7. Links To Other Websites</h3>
            <p>
              The Services may provide the ability to connect to other websites.
              These websites may operate independently from us and may have
              their own privacy notices or policies, which we strongly suggest
              you review. If any linked website is not owned or controlled by
              us, we are not responsible for its content, any use of the website
              or the privacy practices of the operator of the website.
            </p>
          </div>
          <div className={Style.textBlock}>
            <h3>8. Contact Us</h3>
            <p>
              If you have any questions or complaints about this Privacy Policy,
              please contact us electronically at support@skiplinow.com
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
