// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

const STATISTICS = [
  {
    label: "80%",
    description: "diners look at restaurant websites before visiting",
  },
  {
    label: "$2,579",
    description: "average monthly revenue",
  },
  {
    label: "100%",
    description: "Skipli websites are optimized and responsive",
  },
];

const OUR_APPROACH = [
  {
    label: "Designed by Experts",
    description: "Full-service design. Just for your business.",
  },
  {
    label: "Mobile-First Design",
    description: "Websites that work. On any device.",
  },
  {
    label: "Built-In Best Practices",
    description: "Templates proven to deliver results.",
  },
];

class WebsiteDesignStatistics extends React.Component {
  renderStatistics = () =>
    STATISTICS.map(({ label, description }) => (
      <div className={Style.statCard} key={label}>
        <h3>{label}</h3>
        <p>{description}</p>
      </div>
    ));

  renderOurApproach = () =>
    OUR_APPROACH.map(({ label, description }) => (
      <div className={Style.approachCard} key={label}>
        <h3>{label}</h3>
        <p>{description}</p>
      </div>
    ));

  render() {
    return (
      <div className={CommonStyle.sectionContainer}>
        <div className={Style.contentContainer}>
          <div className={Style.statistics}>{this.renderStatistics()}</div>
          {/* <h2>Our Approach</h2>
          <div className={Style.statistics}>{this.renderOurApproach()}</div> */}
        </div>
      </div>
    );
  }
}

export default WebsiteDesignStatistics;
