// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, ResponsiveModal } from "fields";

const RemoveItemFromCartModal = (props) => {
  const onConfirmRemoval = () => {
    props.onCloseModal();
    props.onRemoveItemFromCart();
  };

  return (
    <ResponsiveModal
      contentLabel="remove item from cart modal"
      headerProps={{
        header: "Remove Item?",
      }}
      onCloseModal={props.onCloseModal}
      classNames={{ modal: Style.modal }}
    >
      <p className={Style.dialogDescription}>
        Are you sure you want to remove this item from your cart?
      </p>
      <div className={Style.dialogButtonsContainer}>
        <Button
          className={Style.keepItemButton}
          hasShadow
          name="Keep Item"
          onClick={props.onCloseModal}
        >
          Keep Item
        </Button>
        <Button
          color="white"
          className={Style.removeItemButton}
          name="Keep Item from cart"
          onClick={onConfirmRemoval}
        >
          Remove
        </Button>
      </div>
    </ResponsiveModal>
  );
};

RemoveItemFromCartModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onRemoveItemFromCart: PropTypes.func.isRequired,
};

export default RemoveItemFromCartModal;
