// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import TimePicker from "react-time-picker";

// Style
import Style from "./style.module.scss";

// Lib
import { BUTI } from "lib/services";

class TimePickerWrapper extends React.Component {
  state = { localTime: "" };

  componentDidMount = () => {
    const { defaultTime } = this.props;
    if (defaultTime) this.onLocalizeTime({ utc_time: defaultTime });
  };

  componentDidUpdate = (prevProps) => {
    const { defaultTime } = this.props;
    if (defaultTime !== prevProps.defaultTime)
      this.onLocalizeTime({ utc_time: defaultTime });
  };

  onChange = async (localTime = "") => {
    if (!localTime) this.setState({ localTime }, () => this.props.onChange(""));
    else {
      const { ConvertLocalTimeToUTC } = BUTI.GetRequests;
      const { utc_time } = await ConvertLocalTimeToUTC({
        localTime,
        timeZone: this.props.timeZone,
      });
      this.setState({ localTime }, () => this.props.onChange(utc_time));
    }
  };

  onLocalizeTime = async ({ utc_time }) => {
    const { ConvertUTCToLocalTime } = BUTI.GetRequests;
    const { localTime } = await ConvertUTCToLocalTime({
      utc_time,
      timeZone: this.props.timeZone,
    });
    this.setState({ localTime });
  };

  render() {
    if (!this.props.timeZone) return null;
    return (
      <div>
        <TimePicker
          className={Style.timePicker}
          disableClock
          onChange={this.onChange}
          value={this.state.localTime}
        />
      </div>
    );
  }
}

TimePickerWrapper.propTypes = {
  defaultTime: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  startingTime: PropTypes.string, // MUST BE UTC
  timeZone: PropTypes.string.isRequired,
};

TimePickerWrapper.defaultProps = {
  defaultTime: "",
  onChange: () => {},
};

export default TimePickerWrapper;
