// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Fields
import { QuantityCalculator } from "fields";

class ItemQuantityCalculator extends Component {
  render() {
    const { currentQuantity, onClick } = this.props;
    return (
      <QuantityCalculator
        className={Style.quantityCalculator}
        currentQuantity={currentQuantity}
        disableMinusButton={currentQuantity === 1}
        quantityNumberClassName={Style.quantityNumber}
        minusButtonClassName={Style.minusButton}
        onButtonClick={onClick}
        plusButtonClassName={Style.plusButton}
      />
    );
  }
}

ItemQuantityCalculator.propTypes = {
  addToCartBtnStatus: PropTypes.oneOf(["active", "inactive", "loading"]),
  currentQuantity: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

ItemQuantityCalculator.defaultProps = {
  addToCartBtnStatus: "active",
  currentQuantity: 1,
};

export default ItemQuantityCalculator;
