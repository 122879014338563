// BUTI DINERS, INC. All right Reserved ©

import dayjs from "dayjs";

// Lib
import { DEFAULT_TIMEZONE } from "lib/_constants";
import { DateTime } from "lib/functions";
import { BUTI } from "lib/services";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const _checkIfStoreAcceptOrders = async ({ timeZone, todayHours }) => {
  const { GetCurrentUTCTimestamp } = BUTI.GetRequests;
  const { _isBetween } = DateTime;

  const { currentTimestamp } = await GetCurrentUTCTimestamp();
  const current_local_time = dayjs(currentTimestamp)
    .tz(timeZone || DEFAULT_TIMEZONE)
    .format("HH:mm");

  // Check open hours
  const { shopTodayExtraHours = {}, shopTodayOpenHours = {} } = todayHours;
  const {
    localEndAt: storeCloseAt = "",
    localStartAt: storeOpenAt = "",
  } = shopTodayOpenHours;

  const accept1 = _isBetween({
    range: { endAt: storeCloseAt, startAt: storeOpenAt },
    time24: current_local_time,
  });
  const accept2 = Object.keys(shopTodayExtraHours).reduce(
    (result, timeRangeId) => {
      const { localEndAt = "", localStartAt = "" } = shopTodayExtraHours[
        timeRangeId
      ];
      return (
        result ||
        _isBetween({
          range: { endAt: localEndAt, startAt: localStartAt },
          time24: current_local_time,
        })
      );
    },
    false
  );
  return { currentTimestamp, shouldAcceptOrder: accept1 || accept2 };
};

const OrdersManagement = { _checkIfStoreAcceptOrders };

export default OrdersManagement;
