// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Introduction from "./Introduction";
import Features from "./Features";
import Statistics from "./Statistics";
import SimplePricing from "./SimplePricing";

// Utils
import { _updatePageTitleAndDescription } from "utils";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Components
import { LetsChatBox } from "components";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Fields
import { TestimonialBox } from "fields";

// Images
import MaiDuong from "assets/Images/maiDuong.jpg";
import PhoKingExpressDesktop from "assets/Images/phoKingExpressDesktop.jpg";

class WebsiteDesign extends React.Component {
  componentDidMount = () => {
    _updatePageTitleAndDescription({
      description:
        "Design matters. Get a website that converts online visitors into customers.",
      title: "Website Design | Skipli",
    });
  };

  render() {
    const { onUpdateRefs } = this.props.context;
    return (
      <div className={Style.pageContainer}>
        <Introduction />
        <div
          ref={(ref) => onUpdateRefs({ refID: "websiteDesignStatistics", ref })}
        >
          <Statistics />
        </div>
        <Features />
        <div style={{ marginTop: "6rem" }}>
          <div className={MainWebsiteStyle.sectionContainer}>
            <TestimonialBox
              heroImage={PhoKingExpressDesktop}
              profileImage={MaiDuong}
              profileName="Mai Duong | Owner | Pho King Express"
              text={`“I tried several DIY website builders that claimed to be easy to
              set up. I quickly found that building a business website was
              time-consuming and required a lot of experience. Skipli experts
              helped double my online sales within the first month.”`}
            />
          </div>
        </div>
        <div className={MainWebsiteStyle.sectionContainer}>
          <LetsChatBox
            heading="See why 1,000+ businesses trust Skipli."
            description="We'll get you started quickly so you can start building your brand and online reputation."
          />
        </div>
        <SimplePricing />
      </div>
    );
  }
}

WebsiteDesign.propTypes = {
  context: PropTypes.shape({
    onUpdateRefs: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(WebsiteDesign)
);
