// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";
import _get from "lodash.get";
import _isempty from "lodash.isempty";
import { withRouter } from "react-router-dom";

import { SHARE_POST_CHANNELS } from "./constants";
import {
  _createArticleSkeleton,
  _createSharePostEmailSubjectAndBody,
} from "./functions";

// Utils
import { _updatePageTitleAndDescription } from "utils";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Views
import { ErrorPage } from "views";

// Components
import { RawHTML } from "components/BlogPostList/functions";

// Lib
import { ShowConfirmNotif } from "lib/functions";

// Service
import { Customers } from "lib/services";

const { GetSinglePostForCustomer } = Customers.GetRequests;

class PostDetail extends React.Component {
  state = { post_info: {} };

  componentDidMount() {
    const { location = {} } = this.props;
    const { state = {} } = location;
    const { post_info = {} } = state || this.state;

    // take the last string of URL
    const match = _get(window, "location.pathname") || "";
    const title = match.substring(match.lastIndexOf("/") + 1);

    if (_isempty(post_info)) this.onGetPostInfo(title);
    else {
      this.setState({ post_info }, () => {
        const { title = "" } = this.state.post_info;
        _updatePageTitleAndDescription({
          description:
            "Free resources, tips, and inspiration to help you run a successful restaurant.",
          title: `${title} | SKIPLI Blog`,
        });
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    const { post_info = {} } = this.state;
    const { title } = post_info;
    if (post_info && title !== prevProps.post_info?.title) {
      _updatePageTitleAndDescription({
        description:
          "Free resources, tips, and inspiration to help you run a successful restaurant.",
        title: `${title} | SKIPLI Blog`,
      });
    }
  };

  onGetPostInfo = async (title) => {
    this.setState({ is_fetching_post_info: true });
    try {
      const { data } = await GetSinglePostForCustomer({ title });
      this.setState({ post_info: data });
    } catch {
      this.setState({ post_info: {} });
    } finally {
      this.setState({ is_fetching_post_info: false });
    }
  };

  onCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      ShowConfirmNotif({ message: "Copied link!", type: "success" });
    } catch (error) {
      ShowConfirmNotif({ message: "Failed to copy link !", type: "error" });
    }
  };

  onMailTo = async () => {
    const { post_info = {} } = this.state;
    const { title = "" } = post_info;
    const email = "";
    const { body, subject } = _createSharePostEmailSubjectAndBody({
      post_title: title,
      post_url: window.location.href,
    });
    try {
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      await window.open(mailtoLink);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  onShareToFacebook = () =>
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href
      )}`,
      "_blank"
    );

  onShareToLinkedIn = () =>
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        window.location.href
      )}`,
      "_blank"
    );

  renderSharingChannels = () =>
    SHARE_POST_CHANNELS.map(({ icon, id, name }) => (
      <button
        className={Style.channel_button}
        key={id}
        name={name}
        onClick={this.onFindFunctionForShareButton(id)}
      >
        {icon}
      </button>
    ));

  onFindFunctionForShareButton = (channel_id) => {
    switch (channel_id) {
      case "facebook":
        return this.onShareToFacebook;
      case "linkedIn":
        return this.onShareToLinkedIn;
      case "email":
        return this.onMailTo;
      case "copy_link":
        return this.onCopyLink;
      default:
        return () => {};
    }
  };

  renderContent = () => {
    const { post_info = {} } = this.state;
    const { content, title } = post_info;
    if (_isempty(post_info))
      return (
        <ErrorPage
          back_button={{
            label: "See SKIPLI blog",
            url: "/blog",
          }}
          description="Sorry, the post was not found. Click the button below to see other awesome posts."
          header="Post not found!"
        />
      );
    return (
      <div className={cx(MainWebsiteStyle.pageContainer)}>
        <div className={Style.container}>
          <h1 className={Style.post_title}>{title}</h1>
          <div className={Style.share_post_channels}>
            {this.renderSharingChannels()}
          </div>
          <RawHTML className={Style.post_content} nl2br={false}>
            {content}
          </RawHTML>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.is_fetching_post_info
          ? _createArticleSkeleton()
          : this.renderContent()}
      </div>
    );
  }
}

export default withRouter(PostDetail);
