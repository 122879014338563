// BUTI DINERS, INC. All right Reserved ©

// Image
import FeedbackCollectImage from "assets/Images/feedbackCollect.png";
import PickUpAndDeliverImage from "assets/Images/pickUpAndDeliver.png";
import SellOnSocialMediaImage from "assets/Images/sellOnSocialMedia.png";
import SkipliPOSImage from "assets/Images/skipliPOS.png";

const NOTABLE_FEATURES = {
  pickUpAndDelivery: {
    label: "Pickup and deliver",
    description:
      "Make it easy for customers to place online orders and increase recurring sales.",
    img: PickUpAndDeliverImage,
  },
  sellOnSocialMedia: {
    label: "Sell on social media",
    description:
      "Turn your Instagram, Facebook, and Google listing into your sales tool.",
    img: SellOnSocialMediaImage,
  },
  receiveOrders: {
    label: "Never miss an order",
    description:
      "Auto accept and receive pre-paid orders on any device (phone, tablet, and laptop) and via text messages.",
    img: SkipliPOSImage,
  },
  happyCustomers: {
    label: "Happy customers, more orders",
    description:
      "We take care of your customers before and after sales. Automatically collect feedback and upsell via the web.",
    img: FeedbackCollectImage,
  },
};

export { NOTABLE_FEATURES };
