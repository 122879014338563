// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

class ShopOnPlatform extends React.Component {
  renderShopInfo = () => {
    const { basicInfo } = this.props;
    const { address, name } = basicInfo;
    return (
      <div className={Style.shopInfo}>
        <div className={Style.shopName}>{name}</div>
        <p className={Style.shopAddress}>{address}</p>
        {/* {membershipStatus === "active" && (
          <div className={Style.secondColumn}>
            <div className={Style.orderBtn}>
              Order Pickup
              <ArrowIcon />
            </div>
          </div>
        )} */}
      </div>
    );
  };

  renderShop = ({ membershipStatus = "active" }) => {
    const { basicInfo = {} } = this.props;
    return (
      <div className={Style.shopContainer}>
        <div className={Style.content}>
          <img
            alt=""
            className={Style.thumbnailImg}
            src={basicInfo.thumbnailImg || ""}
          />
          {this.renderShopInfo({ membershipStatus })}
        </div>
      </div>
    );
  };

  render() {
    const { basicInfo, shopID } = this.props;
    const { membership } = basicInfo;
    const { status = "active" } = membership;
    return status === "active" ? (
      <button
        className={Style.container}
        onClick={() => this.props.history.push(`/shop/${shopID}`)}
      >
        {this.renderShop({ membershipStatus: status })}
      </button>
    ) : (
      this.renderShop({ membershipStatus: status })
    );
  }
}

ShopOnPlatform.propTypes = {
  activeCoupons: PropTypes.object,
  basicInfo: PropTypes.shape({
    address: PropTypes.string.isRequired,
    membership: PropTypes.shape({ status: PropTypes.string }),
    name: PropTypes.string,
    thumbnailImg: PropTypes.string
  }).isRequired,
  history: PropTypes.object.isRequired,
  pastOrdersCount: PropTypes.number,
  itemsOnSale: PropTypes.object,
  shopID: PropTypes.string.isRequired
};

ShopOnPlatform.defaultProps = {
  activeCoupons: {},
  pastOrdersCount: 0
};

export default withRouter(ShopOnPlatform);
