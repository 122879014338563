// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { Elements } from "react-stripe-elements";

import {
  _calculateOrderTotalAmount,
  _getSubmitButtonStatus,
} from "./HelperFunctions";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Components
import { ConfirmOrderModules } from "components";

// Lib
import { Math } from "lib/functions";
import { _getTotalDeliveryFee } from "components/ConfirmOrder/helperFunctions";

class ConfirmOrderForm extends React.Component {
  state = { show_order_detail: false };

  onCalculateSubTotalWithTax = () => {
    const { salesTax = 0, subTotal = 0 } = this.props;
    return parseFloat(
      Math._roundNumber(subTotal + subTotal * parseFloat(salesTax))
    );
  };

  onCalculateTotalAmount = () => {
    const {
      context = {},
      courierQuotes = {},
      is_storefront_enabled,
      total_fee,
    } = this.props;

    const { doordash = {} } = courierQuotes;
    const { totalDeliveryFee = 0 } = doordash;
    const { shopID: shop_id } = context;
    return _calculateOrderTotalAmount({
      orderDeliveryTypeID: this.props.orderDeliveryTypeID,
      shop_id,
      subTotal: parseFloat(this.props.subTotal),
      subTotalWithTax: this.onCalculateSubTotalWithTax(),
      tipAmount: parseFloat(this.props.tipAmount),
      totalDeliveryFee: parseFloat(totalDeliveryFee),
      total_fee,
      is_storefront_enabled,
    });
  };

  renderCustomerInfo = () => (
    <ConfirmOrderModules.CustomerInfo
      customerName={this.props.customerName}
      phoneNumber={this.props.phoneNumber}
      onChangeCustomerName={this.props.onChangeCustomerName}
      onChangePhoneNumber={this.props.onChangePhoneNumber}
      onChangeTableNumber={this.props.onChangeTableNumber}
      orderDeliveryTypeID={this.props.orderDeliveryTypeID}
      tableNumber={this.props.tableNumber}
    />
  );

  renderDeliveryInfo = ({ allowDelivery }) => (
    <ConfirmOrderModules.DeliveryInfo
      allowDelivery={allowDelivery}
      deliveryAddress={this.props.deliveryAddress}
      deliveryNotes={this.props.deliveryNotes}
      dropoff_option={this.props.dropoff_option}
      onChangeDeliveryAddress={this.props.onChangeDeliveryAddress}
      onChangeDeliveryNotes={this.props.onChangeDeliveryNotes}
      onChangeCourierQuotes={this.props.onChangeCourierQuotes}
      onHideGettingQuoteLoader={this.props.onHideGettingQuoteLoader}
      onShowGettingQuoteLoader={this.props.onShowGettingQuoteLoader}
      on_update_dropoff_options={this.props.on_update_dropoff_options}
      subTotal={this.props.subTotal}
    />
  );

  renderDeliveryQuote = ({ allowDelivery }) => (
    <ConfirmOrderModules.DeliveryQuotes
      allowDelivery={allowDelivery}
      courierQuotes={this.props.courierQuotes}
      isGettingDeliveryQuote={this.props.isGettingDeliveryQuote}
      onChangeDeliveryType={this.props.onChangeDeliveryType}
      selectedCourier={this.props.selectedCourier}
    />
  );

  renderFeeSummary = (totalAmount) => {
    const {
      salesTax = 0,
      subTotal = 0,
      is_storefront_enabled = false,
      fees_details,
      tipAmount,
      guest_fees,
      courierQuotes = {},
      selectedCourier = "",
    } = this.props;

    return (
      <ConfirmOrderModules.FeeSummary
        salesTax={salesTax}
        subTotal={subTotal}
        is_storefront_enabled={is_storefront_enabled}
        fees_details={fees_details}
        total_amount={totalAmount}
        tipAmount={tipAmount}
        guest_fees={guest_fees}
        total_delivery_fee={_getTotalDeliveryFee({
          courierQuotes,
          selectedCourier,
        })}
      />
    );
  };

  renderPaymentMethod = (totalAmount) =>
    this.props.selectedPaymentWalletOption === "card" ? (
      <React.Fragment>
        {this.renderStoredPaymentMethods()}
        {this.renderSubmitButtons(totalAmount)}
      </React.Fragment>
    ) : (
      this.renderPaymentRequestButton(totalAmount)
    );

  renderPaymentRequestButton = (totalAmount) => (
    <Elements>
      <ConfirmOrderModules.PaymentRequestButtonStripe
        buttonStatus={_getSubmitButtonStatus(this.props)}
        onClick={this.props.onSubmitForm}
        totalAmount={totalAmount}
      />
    </Elements>
  );

  renderPaymentWalletSelector = () => (
    <ConfirmOrderModules.PaymentWalletSelector
      onSelectPaymentWalletOption={this.props.onSelectPaymentWalletOption}
      selectedPaymentWalletOption={this.props.selectedPaymentWalletOption}
    />
  );

  renderPickupInfo = () => (
    <ConfirmOrderModules.PickupInfo
      curbsideInfo={this.props.curbsideInfo}
      pickUpTime={this.props.pickUpTime}
      onChangeCurbsideInfo={this.props.onChangeCurbsideInfo}
      onChangePickupTime={this.props.onChangePickupTime}
    />
  );

  renderStoredPaymentMethods = () => (
    <ConfirmOrderModules.StoredPaymentMethods
      isLoadingPaymentMethods={this.props.isLoadingPaymentMethods}
      paymentMethods={this.props.paymentMethods}
      onSelect={this.props.onSelectPaymentMethod}
      selectedPaymentMethodFingerprint={
        this.props.selectedPaymentMethodFingerprint
      }
    />
  );

  renderSubmitButtons = (totalAmount) => {
    const { paymentMethods = {} } = this.props;
    const stripeAccountID = _get(
      this.props.context,
      "shopBasicInfo.stripeConnectInfo.stripeAccountID"
    );
    return (
      <ConfirmOrderModules.SubmitButtons
        onAddPaymentMethod={this.props.onAddPaymentMethod}
        onSubmit={() => this.props.onSubmitForm({ totalAmount })}
        showUseStoredPaymentMethodButton={
          Object.keys(paymentMethods).length > 0
        }
        stripeAccountID={stripeAccountID}
        totalAmount={totalAmount}
        useStoredPaymentButtonStatus={_getSubmitButtonStatus(this.props)}
      />
    );
  };

  renderTipAmountSelector = () => {
    const { context, orderDeliveryTypeID } = this.props;
    const { shopID } = context;
    return (
      <ConfirmOrderModules.TipAmountSelector
        onChangeTipAmount={this.props.onChangeTipAmount}
        orderDeliveryTypeID={this.props.orderDeliveryTypeID}
        shop_id={shopID}
        subTotal={this.props.subTotal}
        subTotalWithTax={this.onCalculateSubTotalWithTax()}
        tipAmount={this.props.tipAmount}
        title={
          orderDeliveryTypeID === "deliver" ? "Tip For Driver" : "Gratuity"
        }
      />
    );
  };

  render() {
    const { allowDelivery = true, orderDeliveryTypeID } = this.props;
    const totalAmount = this.onCalculateTotalAmount();
    return (
      <div>
        {this.renderCustomerInfo()}
        {orderDeliveryTypeID === "pickUp" && this.renderPickupInfo()}
        {orderDeliveryTypeID === "deliver" && (
          <React.Fragment>
            {this.renderDeliveryInfo({ allowDelivery })}
            {this.renderDeliveryQuote({ allowDelivery })}
          </React.Fragment>
        )}
        {this.renderTipAmountSelector()}
        {this.renderFeeSummary(totalAmount)}
        {this.renderPaymentWalletSelector()}
        {this.renderPaymentMethod(totalAmount)}
      </div>
    );
  }
}

ConfirmOrderForm.propTypes = {
  allowDelivery: PropTypes.bool.isRequired,
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      stripeConnectInfo: PropTypes.shape({
        stripeAccountID: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  courierQuotes: PropTypes.exact({
    doordash: PropTypes.shape({
      currency: PropTypes.string,
      dropoffETA: PropTypes.string,
      id: PropTypes.number,
      localizedDropoffETA: PropTypes.string,
      localized_pickup_time: PropTypes.string,
      pickup_time: PropTypes.string,
      pickup_window_end_time: PropTypes.any,
      pickup_window_start_time: PropTypes.any,
      totalDeliveryFee: PropTypes.number,
    }),
    postmates: PropTypes.shape({
      errorMessage: PropTypes.string,
      localizedDropoffETA: PropTypes.string,
      totalDeliveryFee: PropTypes.number,
    }),
  }),
  curbsideInfo: PropTypes.string,
  deliveryAddress: PropTypes.shape({
    streetAddress1: PropTypes.string,
    streetAddress2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.any,
    notes: PropTypes.string,
  }),
  deliveryNotes: PropTypes.string,
  dropoff_option: PropTypes.string,
  isGettingDeliveryQuote: PropTypes.bool,
  isLoadingPaymentMethods: PropTypes.bool,
  is_storefront_enabled: PropTypes.bool,
  onAddPaymentMethod: PropTypes.func,
  onChangeCourierQuotes: PropTypes.func,
  onChangeCurbsideInfo: PropTypes.func,
  onChangeCustomerName: PropTypes.func.isRequired,
  onChangeDeliveryAddress: PropTypes.func,
  onChangeDeliveryNotes: PropTypes.func,
  onChangeDeliveryType: PropTypes.func,
  onChangePhoneNumber: PropTypes.func.isRequired,
  onChangePickupTime: PropTypes.func,
  onChangeTableNumber: PropTypes.func,
  onChangeTipAmount: PropTypes.func.isRequired,
  onHideGettingQuoteLoader: PropTypes.func,
  onSelectPaymentMethod: PropTypes.func.isRequired,
  onSelectPaymentWalletOption: PropTypes.func.isRequired,
  onShowGettingQuoteLoader: PropTypes.func,
  onSubmitForm: PropTypes.func.isRequired,
  on_update_dropoff_options: PropTypes.func.isRequired,
  orderDeliveryTypeID: PropTypes.oneOf([
    "inStore",
    "pickUp",
    "deliver",
    "catering",
  ]).isRequired,
  paymentMethods: PropTypes.object,
  pickUpTime: PropTypes.string,
  salesTax: PropTypes.number.isRequired,
  selectedPaymentWalletOption: PropTypes.oneOf(["card", "other"]).isRequired,
  selectedCourier: PropTypes.oneOf(["doordash", "postmates"]),
  selectedPaymentMethodFingerprint: PropTypes.string,
  subTotal: PropTypes.number.isRequired,
  tipAmount: PropTypes.number.isRequired,
  total_fee: PropTypes.number.isRequired,
};

ConfirmOrderForm.defaultProps = {
  curbsideInfo: "",
  isGettingDeliveryQuote: false,
  isLoadingPaymentMethods: false,
  orderDeliveryTypeID: "pickUp",
  pickUpTime: "",
  selectedPaymentMethodFingerprint: "",
  tipAmount: 0,
  total_fee: 0,
};

export default withContext(CustomerInterfaceConsumer)(ConfirmOrderForm);
