// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

class ShopAbout extends React.Component {
  state = {};

  render() {
    const { aboutUs = {}, themeColor } = this.props;

    const { imageURL = "", text = "", textPosition = "right" } = aboutUs;
    const OrderNowBtn = styled(Button)`
      background: ${themeColor};
    `;

    return (
      <React.Fragment>
        <div
          className={cx(
            Style.container,
            textPosition === "left" && Style.textOnLeft
          )}
        >
          <img alt="" className={Style.image} src={imageURL} />
          <div className={Style.textContainer}>
            <h2 className={Style.heading}>Our Story</h2>
            <p className={Style.description}>{text}</p>
            <OrderNowBtn
              className={Style.orderNow}
              hasShadow
              name="order now"
              onClick={this.props.onRedirectToOrderOnline}
            >
              Order Now
            </OrderNowBtn>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ShopAbout.propTypes = {
  aboutUs: PropTypes.exact({
    imageURL: PropTypes.string,
    text: PropTypes.string,
    textPosition: PropTypes.oneOf(["left", "right"]),
  }),
  onRedirectToOrderOnline: PropTypes.func.isRequired,
  themeColor: PropTypes.string,
};

export default ShopAbout;
