// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

// ------------------------------------------------------
// Return the value rounded up into an integer
// 11.40 -> 12
// 10.5 -> 11
const _roundUp = (value) => Math.ceil(parseFloat(value));

// ------------------------------------------------------
// Return the value rounded up into an integer
// 10.4 -> 10
// 10.6 -> 11
const _round = (value) => Math.round(parseFloat(value));

// ------------------------------------------------------
// Return the value as a String, rounded by the number of decimals
// 11 -> 11.00
// 10.5 -> 10.50
const _roundNumber = (value, decimals = 2) =>
  Number.parseFloat(value).toFixed(decimals);

const _convertPriceToCents = (totalUnitPrice = "0.00", salesTax = 0) => {
  const taxedUnitPrice =
    parseFloat(totalUnitPrice) * (1 + parseFloat(salesTax));
  return (taxedUnitPrice * 100).toFixed(0);
};

const _Math = { _convertPriceToCents, _roundNumber, _roundUp, _round };

export default _Math;
