// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ContentLoader from "react-content-loader";

const MobileShopLoader = props => (
  <ContentLoader
    {...props}
    height={300}
    width={500}
    speed={2}
    primaryColor="#E0E0E0"
    secondaryColor="#F2F2F2"
  >
    <rect x="0" y="0" rx="0" ry="0" width="400" height="200" />
    <rect x="0" y="215" rx="0" ry="0" width="400" height="30" />
  </ContentLoader>
);

export default MobileShopLoader;
