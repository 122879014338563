// BUTI Corp All right Reserved ©

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

const ComingSoonButton = (props) => (
  <button className={cx(Style.comingSoonButton, props.className)}>
    coming soon
  </button>
);

ComingSoonButton.propTypes = {
  className: PropTypes.string,
};

ComingSoonButton.defaultProps = {
  className: "",
};

export default ComingSoonButton;
