// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, CenterModal } from "fields";

// Icons
import { CloseIcon } from "assets/Icons";

class ConfirmModal extends Component {
  renderContent = () => (
    <div className={Style.content}>{this.props.children}</div>
  );

  renderHeadingSection = () => {
    const { description } = this.props;
    return (
      <div className={cx(Style.headingContainer, Style[this.props.color])}>
        <Button
          className={Style.closeModalBtn}
          name="close modal button"
          onClick={this.props.onCloseModal}
        >
          <CloseIcon />
        </Button>
        <h1 className={Style.heading}>{this.props.heading}</h1>
        {description && <p className={Style.description}>{description}</p>}
      </div>
    );
  };

  render() {
    const { classNames = {} } = this.props;
    return (
      <CenterModal
        contentLabel={this.props.contentLabel}
        modalBoxClassname={cx(Style.modalBox, classNames.modalBox)}
        contentContainerClassname={cx(
          Style.contentContainer,
          classNames.contentContainer
        )}
        onCloseModal={this.props.onCloseModal}
        overlayClassname={cx(Style.overlay, classNames.overlay)}
        showCloseButton={false}
      >
        {this.renderHeadingSection()}
        {this.renderContent()}
      </CenterModal>
    );
  }
}

ConfirmModal.propTypes = {
  children: PropTypes.any,
  color: PropTypes.oneOf(["error", "info", "success", "warning"]),
  contentLabel: PropTypes.string.isRequired,
  description: PropTypes.string,
  heading: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  color: "",
};

export default ConfirmModal;
