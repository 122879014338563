// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, Slashy } from "fields";

// Icons
import { ArrowIcon } from "assets/Icons";

const TABLE = [
  { amount: "Free", label: "consultation" },
  { amount: "Free", label: "web hosting" },
  { amount: "Free", label: "24/7 support" },
  { amount: "1 Free", label: "custom domain" },
];

class SimplePricing extends React.Component {
  renderWordsCol = () => (
    <React.Fragment>
      <h2 className={Style.heading}>
        Make yourself <br /> stand out
      </h2>
      <p className={Style.description}>
        Get a branded website that represents you and get orders from millions
        of online customers.
      </p>
    </React.Fragment>
  );

  renderTable = () =>
    TABLE.map(({ amount, label }) => (
      <div className={Style.tableCell} key={label}>
        <div className={Style.cellLabel}>{label}</div>
        <div className={Style.cellAmount}>{amount}</div>
      </div>
    ));

  render() {
    return (
      <Slashy
        leftColumnContent={this.renderWordsCol()}
        rightColumnContent={
          <React.Fragment>
            {this.renderTable()}
            <Button
              className={Style.openFreeAccountBtn}
              name="get started"
              onClick={() => this.props.history.push("/get-started")}
            >
              get started <ArrowIcon />
            </Button>
            <Button
              className={Style.openFreeAccountBtn}
              name="see full pricing"
              onClick={() => this.props.history.push("/pricing")}
            >
              full pricing <ArrowIcon />
            </Button>
          </React.Fragment>
        }
      />
    );
  }
}

export default withRouter(SimplePricing);
