// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { FEATURES, PAYMENT_PLANS, PLANS } from "./HelperFunctions";

// Style
import ModuleStyle from "../style.module.scss";

// Fields
import { Button, Tabs } from "fields";

// Icon
import { CheckIcon, CloseIcon } from "assets/Icons";

class WebsitePricing extends React.Component {
  state = { selectedPaymentPlan: "yearly" };

  renderFeature = ({ featureId, feature = {} }) => {
    const { label: featureName } = feature;
    return (
      <React.Fragment>
        <div className={cx(ModuleStyle.featureColumn, ModuleStyle.borderRight)}>
          <div className={ModuleStyle.cell}>{featureName}</div>
        </div>
        {Object.keys(PLANS).map((planId) => {
          const { features = {} } = PLANS[planId];
          const { note = "" } = features[featureId] || {};
          return (
            <div className={ModuleStyle.planColumn} key={planId}>
              <div className={ModuleStyle.cell}>
                {Object.keys(features).includes(featureId) ? (
                  note ? (
                    note
                  ) : (
                    <CheckIcon className={ModuleStyle.checkIcon} />
                  )
                ) : (
                  <CloseIcon className={ModuleStyle.closeIcon} />
                )}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  renderFeatures = () =>
    Object.keys(FEATURES).map((featureId) => (
      <div className={ModuleStyle.tableRow} key={featureId}>
        {this.renderFeature({ featureId, feature: FEATURES[featureId] })}
      </div>
    ));

  renderPaymentPlans = () => (
    <Tabs
      classNames={{
        container: ModuleStyle.pricingTabs,
        tab: ModuleStyle.pricingTab,
      }}
      onClick={(optionId) => this.setState({ selectedPaymentPlan: optionId })}
      options={PAYMENT_PLANS}
      selectedOptionId={this.state.selectedPaymentPlan}
    />
  );

  renderRate = () =>
    Object.keys(PLANS).map((planId) => {
      const { price = {} } = PLANS[planId];
      const { rate } = price[this.state.selectedPaymentPlan];
      return (
        <React.Fragment key={planId}>
          <div className={ModuleStyle.planColumn}>
            <div className={cx(ModuleStyle.cell, ModuleStyle.rate)}>
              ${rate}
            </div>
          </div>
        </React.Fragment>
      );
    });

  renderSetupFee = () =>
    Object.keys(PLANS).map((planId) => {
      const { price = {} } = PLANS[planId];
      const { setup } = price[this.state.selectedPaymentPlan];
      return (
        <React.Fragment key={planId}>
          <div className={ModuleStyle.planColumn}>
            <div className={cx(ModuleStyle.cell, ModuleStyle.rate)}>
              ${setup} (one time)
            </div>
          </div>
        </React.Fragment>
      );
    });

  renderTableHeader = () => (
    <div className={ModuleStyle.tableHeader}>
      <div className={ModuleStyle.featureColumn}>
        {this.renderPaymentPlans()}
      </div>
      {Object.keys(PLANS).map((planId) => {
        const { label, payoutSchedule } = PLANS[planId];
        return (
          <div className={ModuleStyle.planColumn} key={planId}>
            <h2 className={ModuleStyle.planName}>{label}</h2>
            <p className={ModuleStyle.payoutSchedule}>{payoutSchedule}</p>
            <Button
              className={ModuleStyle.startTrialBtn}
              name="get started"
              onClick={() => this.props.history.push("/get-started")}
            >
              Get Started
            </Button>
          </div>
        );
      })}
    </div>
  );

  render() {
    return (
      <div className={ModuleStyle.container}>
        {this.renderTableHeader()}
        {this.renderFeatures()}
        <div className={ModuleStyle.tableRow}>
          <div
            className={cx(ModuleStyle.featureColumn, ModuleStyle.borderRight)}
          >
            <div className={cx(ModuleStyle.cell, ModuleStyle.rate)}>
              Design + Setup
            </div>
          </div>
          {this.renderSetupFee()}
        </div>
        <div className={ModuleStyle.tableRow}>
          <div
            className={cx(ModuleStyle.featureColumn, ModuleStyle.borderRight)}
          >
            <div className={cx(ModuleStyle.cell, ModuleStyle.rate)}>
              Rate / month
            </div>
          </div>
          {this.renderRate()}
        </div>
      </div>
    );
  }
}

WebsitePricing.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(WebsitePricing);
