// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Icon
import { CloseIcon, MenuIcon, SkipliLogoWithTextIcon } from "assets/Icons";

class LandingPageNavbar extends React.Component {
  onClickSkipliLogo = () => this.props.history.push("/");

  renderNavbar = () => {
    const { show_nav_options_menu } = this.props;
    return (
      <div className={Style.container}>
        <div className={Style.content}>
          <div
            className={Style.skipliLogoContainer}
            onClick={this.onClickSkipliLogo}
          >
            <SkipliLogoWithTextIcon />
          </div>
          <div className={Style.desktopNavbarOptions}>
            {this.props.navOptions}
          </div>
          <div
            className={Style.menuIcon}
            onClick={() => {
              show_nav_options_menu
                ? this.props.onHideNavOptionsMenu()
                : this.props.onShowNavOptionsMenu();
            }}
          >
            {show_nav_options_menu ? <CloseIcon /> : <MenuIcon />}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props.show_nav_options_menu && (
          <Modals.MobileNavOptions>
            <div className={Style.mobileNavbarOptions}>
              {this.props.navOptions}
            </div>
          </Modals.MobileNavOptions>
        )}
        {this.renderNavbar()}
      </React.Fragment>
    );
  }
}

LandingPageNavbar.propTypes = {
  history: PropTypes.object.isRequired,
  navOptions: PropTypes.any,
  onHideNavOptionsMenu: PropTypes.func.isRequired,
  onShowNavOptionsMenu: PropTypes.func.isRequired,
  show_nav_options_menu: PropTypes.bool,
};

LandingPageNavbar.defaultProps = {
  show_nav_options_menu: false,
};

export default withRouter(LandingPageNavbar);
