// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash.isempty";
import Img from "react-cool-img";
import cx from "classnames";

import DriverStatus from "../DriverStatus";

// Utils
import { _formatPhoneNumber } from "utils";

// Style
import Style from "../style.module.scss";

// Fields
import { Link, ResponsiveModal } from "fields";

// Assets
import { CarIcon, PhoneIcon, WorldWideWebIcon } from "assets/Icons";

class DriverInfo extends React.Component {
  state = {};

  renderDriverVehicle = (vehicle = {}) => {
    const { color, license_plate_number, make, model } = vehicle || {};
    let result = [];
    if (make) result = result.concat(make);
    if (model) result = result.concat(model);
    if (color) result = result.concat(color);
    if (license_plate_number)
      result = result.concat(`Plate #${license_plate_number}`);
    return result.join(" • ");
  };

  renderGoogleMap = ({ lat, lng }) => {
    const google_map_link = `https://maps.google.com/maps?q=${String(
      lat
    )},${String(lng)}&z=14&amp&output=embed`;
    return (
      <div className={Style.iframe_container}>
        <iframe
          className={Style.iframe}
          src={google_map_link}
          title="Driver real-time position"
        />
      </div>
    );
  };

  renderProfileImageModal = () => {
    const { dasher = {} } = this.props;
    const { profile_image_url = "" } = dasher;
    return (
      <ResponsiveModal
        contentLabel="Enlarge driver profile image"
        classNames={{
          contentContainer: Style.profile_image_modal_content_container,
          modal: Style.modal,
        }}
        headerProps={{
          header: "Driver Image",
          show_back_button: true,
          show_close_button: false,
        }}
        onCloseModal={() =>
          this.setState({ show_driver_profile_image_modal: false })
        }
      >
        <Img
          alt=""
          className={Style.large_profile_image}
          src={profile_image_url}
        />
      </ResponsiveModal>
    );
  };

  render() {
    const { dasher = {}, dasher_status, status } = this.props;
    if (dasher_status === "unassigned") {
      return (
        <div>
          <h2 className={Style.row_title}>Your Driver</h2>
          <div className={Style.gray_text}>
            Driver information will be available once they pick up the order
          </div>
        </div>
      );
    }
    if (!dasher || _isEmpty(dasher)) {
      const { id: doordash_order_id } = this.props;
      const doordash_drive_support_phone = "8552228111";
      const doordash_drive_support_url =
        "https://help.doordash.com/consumers/s/article/DoorDash-Drive-How-do-I-Contact-Support?language=en_US";
      return (
        <div>
          <h2 className={Style.row_title}>Your Driver</h2>
          <div className={Style.gray_text}>
            Doordash Order #{doordash_order_id}
          </div>
          <div className={cx(Style.delivery_status, Style[status])}>
            {status}
          </div>
          <div className={Style.gray_text}>
            Your order was cancelled by Doordash driver. Please contact Doordash
            support {_formatPhoneNumber(doordash_drive_support_phone)} for live
            order issues.
          </div>
          <Link href={`tel:${doordash_drive_support_phone}`} target="_self">
            <div className={Style.phone_number_button}>
              <PhoneIcon /> {_formatPhoneNumber(doordash_drive_support_phone)}
            </div>
          </Link>
          <Link href={doordash_drive_support_url} target="_blank">
            <div className={Style.phone_number_button}>
              <WorldWideWebIcon /> Doordash Drive Support Website
            </div>
          </Link>
          {/* https://help.doordash.com/consumers/s/article/DoorDash-Drive-How-do-I-Contact-Support?language=en_US */}
        </div>
      );
    }
    const {
      dasher_phone_number_for_customer,
      id,
      first_name,
      last_name,
      location = {},
      phone_number,
      profile_image_url = "",
      vehicle = {},
    } = dasher;
    const driver_phone_number =
      dasher_phone_number_for_customer || phone_number || "";
    const { lat = "", lng = "" } = location || {};

    return (
      <>
        {this.state.show_driver_profile_image_modal &&
          this.renderProfileImageModal()}
        <div>
          <h2 className={Style.row_title}>Your Driver</h2>
          {profile_image_url && (
            <Img
              alt="driver profile image"
              className={Style.driver_profile_image}
              onClick={() =>
                this.setState({ show_driver_profile_image_modal: true })
              }
              src={profile_image_url}
            />
          )}
          <div className={cx(Style.delivery_status, Style[status])}>
            {status}
          </div>
          <div className={Style.gray_text}>
            {first_name} {last_name}
          </div>
          <div className={Style.gray_text}>Driver #{id}</div>
          <div className={Style.gray_text}>
            <div className={Style.vehicle}>
              <div className={Style.car_icon}>
                <CarIcon />
              </div>
              {!_isEmpty(vehicle) && this.renderDriverVehicle(vehicle)}
            </div>
          </div>
          {driver_phone_number && (
            <Link href={`tel:${driver_phone_number}`} target="_self">
              <div className={Style.phone_number_button}>
                <PhoneIcon /> {_formatPhoneNumber(driver_phone_number)}
              </div>
            </Link>
          )}
          <DriverStatus driver_status={dasher_status} />
          {!_isEmpty(lat) &&
            !_isEmpty(lng) &&
            this.renderGoogleMap({ lat, lng })}
        </div>
      </>
    );
  }
}

DriverInfo.propTypes = {
  courier: PropTypes.string,
  dasher: PropTypes.shape({
    dasher_phone_number_for_customer: PropTypes.string,
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    location: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    phone_number: PropTypes.string,
    profile_image_url: PropTypes.string,
    vehicle: PropTypes.shape({
      color: PropTypes.string,
      license_plate_number: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
    }),
  }),
  dasher_status: PropTypes.oneOf([
    "unassigned",
    "accepted",
    "arrived_at_store",
    "picked_up",
    "arrived_at_consumer",
    "dropped_off",
  ]).isRequired,
  id: PropTypes.string,
  status: PropTypes.oneOf([
    "scheduled",
    "assigned",
    "picked_up",
    "delivered",
    "cancelled",
  ]).isRequired,
};

DriverInfo.defaultProps = {
  dasher: {},
};

export default DriverInfo;
