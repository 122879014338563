// BUTI DINERS, INC. All right Reserved ©

import React from "react";

// Icons
import {
  FacebookIcon,
  InstagramIcon,
  LocationIcon,
  PhoneIcon,
  SendEmailIcon,
  TripAdvisorIcon,
  TwitterIcon,
  YelpIcon,
  YoutubeIcon,
  GoogleLogoBlackIcon,
} from "assets/Icons";

const CONTACT_FIELDS = {
  address: { icon: <LocationIcon />, label: "address", target: "_blank" },
  email: { icon: <SendEmailIcon />, label: "email", target: "_self" },
  phoneNumber: { icon: <PhoneIcon />, label: "Phone Number", target: "_self" },
};

const SOCIAL_MEDIA = {
  facebook: { icon: <FacebookIcon />, label: "facebook" },
  instagram: { icon: <InstagramIcon />, label: "instagram" },
  twitter: { icon: <TwitterIcon />, label: "twitter" },
  yelp: { icon: <YelpIcon />, label: "yelp" },
  google: { icon: <GoogleLogoBlackIcon />, label: "google" },
  youtube: { icon: <YoutubeIcon />, label: "youtube" },
  tripadvisor: { icon: <TripAdvisorIcon />, label: "trip advisor" },
};

export { CONTACT_FIELDS, SOCIAL_MEDIA };
