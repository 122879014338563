// BUTI DINERS, INC. All right Reserved ©

import React from "react";

import { NOTABLE_FEATURES } from "./constants";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Carousel } from "fields";

class NotableFeatures extends React.Component {
  renderFeatures = () =>
    Object.keys(NOTABLE_FEATURES).map((feature_id) => {
      const { label, description, img } = NOTABLE_FEATURES[feature_id];
      return (
        <div className={Style.feature_container}>
          <div className={Style.feature_picture}>
            <img alt="" src={img} />
          </div>
          <div className={Style.feature_info}>
            <div className={Style.feature_label}>{label}</div>
            <p>{description}</p>
          </div>
        </div>
      );
    });

  renderTitle = () => (
    <div className={Style.title}>
      <h2>Need a website?</h2>
      <p>
        {/* Build your retail store online to start selling products fast. Create a
        business website, take online orders and offer curbside pickup or
        delivery. Provide automated check in to collect feedback from your
        customers. */}
        Don't spend thousands of dollars on a website that can't sell your
        products. We can build for you a personalized website online ordering to
        showcase your products and turn website visits into sales. Our SEO will
        make your website and products the top result on Google.
      </p>
      <Carousel slides={this.renderFeatures()} />
    </div>
  );
  render() {
    return <div className={CommonStyle.container}>{this.renderTitle()}</div>;
  }
}

export default NotableFeatures;
