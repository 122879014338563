// BUTI DINERS, INC. All right Reserved ©

// Assets
import FreeUpStaff from "assets/Images/Free-Up-Staff.png";
import GainInsights from "assets/Images/Gain-Insights.png";
import MarketingAutomation from "assets/Images/Marketing-Automation.png";
import NoMissingOrders from "assets/Images/No-Missing-Orders.png";

const AUTO_PHONE_BENEFITS = {
  noMissingOrders: {
    img_src: NoMissingOrders,
    name: "Stop Missing Orders",
    list: [
      {
        name: "Call Deflection",
        description: "Send customers to online actions. Never lose a sale",
      },
      {
        name: "Automated text messages",
        description:
          "For placing online orders, getting hours of operations or directions, or even sending promotions",
      },
    ],
  },
  freeUpStaff: {
    img_src: FreeUpStaff,
    name: "Free Up Staff",
    list: [
      {
        name: "No Waiting",
        description:
          "Customers won’t have to wait for a staff member to attend them on the phone",
      },
      // {
      //   name: "More Money",
      //   description: "Makes your restaurant run more efficiently",
      // },
      {
        name: "Happy Customers",
        description:
          "Allow your staff to focus on in-store customers, services, and quality",
      },
    ],
  },
  gain_insights: {
    img_src: GainInsights,
    name: "Gain Insights",
    list: [
      {
        name: "Maximize Profits",
        description: "Gauge peak traffic times with the power of analytics",
      },
      {
        name: "More with less",
        description: "Understand seasonality and peaks to staff accordingly",
      },
      {
        name: "Best practice",
        description:
          "Get analytics on all Call Deflection events to know what’s working best",
      },
    ],
  },
  marketing_automation: {
    img_src: MarketingAutomation,
    name: "Marketing Automation",
    list: [
      {
        name: "Social Media",
        description:
          "Send emails and text messages, publish to social media, update your Google My Business page",
      },
      {
        name: "Great Reviews",
        description: "Track reviews and create QR codes",
      },
    ],
  },
};

export { AUTO_PHONE_BENEFITS };
