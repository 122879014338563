// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import shortid from "shortid";

// Style
import Style from "./style.module.scss";

// Assets
import {
  CarIcon,
  HomeIcon,
  StoreIcon,
  SkipliLogoWhiteIcon,
} from "assets/Icons";

const STATUS_LIST = [
  { icon: <SkipliLogoWhiteIcon />, name: "Accepted" },
  { icon: <StoreIcon />, name: "Arrived at store" },
  { icon: <CarIcon />, name: "Driver on the way" },
  { icon: <HomeIcon />, name: "Arrived at your place" },
];

const DriverStatus = ({ driver_status }) => {
  const _findCurrentActiveIndex = () => {
    switch (driver_status) {
      case "arrived_at_store":
        return 1;
      case "picked_up":
        return 2;
      case "arrived_at_consumer":
      case "dropped_off":
        return 3;
      default:
        return 0;
    }
  };

  return (
    <div className={Style.driver_status_container}>
      {STATUS_LIST.map((item, index) => {
        const { icon, name } = item;
        const current_active_index = _findCurrentActiveIndex();
        return (
          <div className={Style.status_container} key={shortid.generate()}>
            <div className={Style.status}>
              <div
                className={cx(
                  Style.icon,
                  index <= current_active_index && Style.highlight
                )}
              >
                {icon}
              </div>
              {index < STATUS_LIST.length - 1 && (
                <div className={Style.line_container}>
                  <div
                    className={cx(
                      Style.line,
                      index < current_active_index && Style.highlight
                    )}
                  />
                </div>
              )}
            </div>
            {index === current_active_index && (
              <div className={Style.status_name}>{name}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

DriverStatus.propTypes = {
  driver_status: PropTypes.oneOf([
    "unassigned",
    "accepted",
    "arrived_at_store",
    "picked_up",
    "arrived_at_consumer",
    "dropped_off",
  ]).isRequired,
};

export default DriverStatus;
