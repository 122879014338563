// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import Img from "react-cool-img";
import cx from "classnames";

import { RATINGS } from "./constants";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

class ShopSurveyFeedback extends React.Component {
  state = { isSendingFeedback: false };

  componentWillUnmount = () => this.setState({ isSendingFeedback: false });

  onSubmitFeedback = () => {
    this.setState({ isSendingFeedback: true }, this.props.onSubmitFeedback);
  };

  renderFeedbackInput = () => {
    const { feedback_card_header } = RATINGS[this.props.selected_rating_id];
    const { feedback_text } = this.props;
    return (
      <div className={Style.feedback_card}>
        <div className={Style.card_header}>{feedback_card_header}</div>
        <div className={Style.card_body}>
          <textarea
            className={Style.feedback_input}
            onChange={(event) =>
              this.props.onChangeFeedbackText({
                feedback_text: event.target.value,
              })
            }
            placeholder="Leave your comments here..."
            rows={5}
            value={feedback_text}
          />
        </div>
        <div className={Style.card_footer}>
          <Button
            className={Style.submit_button}
            name="send feedback to management"
            loadingText="Sending"
            onClick={this.onSubmitFeedback}
            status={
              this.state.isSendingFeedback
                ? "loading"
                : String(feedback_text).length > 4
                ? "active"
                : "inactive"
            }
          >
            Send to management
          </Button>
        </div>
      </div>
    );
  };

  renderRatings = () => {
    const { selected_rating_id = "" } = this.props;
    return Object.keys(RATINGS).map((rating_id) => {
      const { img_src, name } = RATINGS[rating_id];
      return (
        <Img
          alt={name}
          className={cx(
            Style.rating,
            selected_rating_id &&
              rating_id !== selected_rating_id &&
              Style.inactive
          )}
          key={rating_id}
          onClick={() => this.props.onSelectRating({ rating_id })}
          src={img_src}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <h2 className={Style.title}>How was your experience?</h2>
        <div className={Style.ratings_container}>{this.renderRatings()}</div>
        {this.props.selected_rating_id && this.renderFeedbackInput()}
      </div>
    );
  }
}

ShopSurveyFeedback.propTypes = {
  feedback_text: PropTypes.string,
  onChangeFeedbackText: PropTypes.func.isRequired,
  onSelectRating: PropTypes.func.isRequired,
  onSubmitFeedback: PropTypes.func.isRequired,
  selected_rating_id: PropTypes.oneOf([...Object.keys(RATINGS), ""]),
};

ShopSurveyFeedback.defaultProps = {
  feedback_text: "",
  selected_rating_id: "",
};

export default ShopSurveyFeedback;
