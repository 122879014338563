// BUTI DINERS, INC. All right Reserved ©

const SUPPORT_FEATURES = [
  "Free Coaching",
  "Frequent Events",
  "Chat & Phone",
  "Meet & Greet",
  "New Features",
  "Best Practices",
];

export { SUPPORT_FEATURES };
