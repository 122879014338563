// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { withRouter } from "react-router-dom";

import { AUTO_PHONE_BENEFITS } from "./constants";

// Style
import Style from "./style.module.scss";

// Fields
import Button from "fields/Button";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class AutoPhoneWhyItWorks extends React.Component {
  renderBenefitDetailsList = (list = []) =>
    list.map((item) => {
      const { description, name } = item;
      return (
        <div key={shortid.generate()}>
          <div className={Style.list_item_name}>{name}</div>
          <p className={Style.list_item_description}>{description}</p>
        </div>
      );
    });

  renderBenefits = () =>
    Object.keys(AUTO_PHONE_BENEFITS).map((benefit_id) => {
      const { img_src, name, list } = AUTO_PHONE_BENEFITS[benefit_id];
      return (
        <div className={Style.benefit_card} key={benefit_id}>
          <div className={Style.benefit_name_container}>
            <img alt="" className={Style.icon} src={img_src} />
            <h3 className={Style.benefit_name}>{name}</h3>
          </div>
          <div className={Style.benefit_details_list}>
            {this.renderBenefitDetailsList(list)}
          </div>
          <Button
            className={Style.learn_more_button}
            name="Learn More Button"
            onClick={() => this.props.history.push("/get-started")}
          >
            Learn More <ChevronArrowIcon className={Style.arrow_icon} />
          </Button>
        </div>
      );
    });

  render() {
    return (
      <div className={Style.container}>
        <h2 className={Style.title}>Why It Works?</h2>
        <div className={Style.benefits_container}>{this.renderBenefits()}</div>
      </div>
    );
  }
}

AutoPhoneWhyItWorks.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AutoPhoneWhyItWorks);
