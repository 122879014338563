// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Fields
import { SplitOverlay } from "fields";

const SplitOverlayGroup = ({ items }) => (
  <div>
    {items.map((item, index) => (
      <div key={index}>
        <SplitOverlay {...item} reverse={index % 2 === 1} />
      </div>
    ))}
  </div>
);

SplitOverlayGroup.propTypes = {
  items: PropTypes.arrayOf({
    image: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    list: PropTypes.arrayOf({
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SplitOverlayGroup;
