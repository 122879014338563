// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { injectStripe } from "react-stripe-elements";
import { set } from "object-path-immutable";
import _get from "lodash.get";
import PropTypes from "prop-types";

import BillingSection from "./BillingSection";
import CardSection from "./CardSection";
import { IsPaymentMethodFormValid } from "./helperFunctions";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Icons
import { ShieldIcon } from "assets/Icons";

// Fields
import { Button, Checkbox, StripeBrand } from "fields";

// Lib
import { ShowConfirmNotif } from "lib/functions";
import { Customers } from "lib/services";
const { AttachPaymentMethodToCustomer } = Customers.PostRequests;

class PaymentMethodForm extends React.Component {
  confirmNotif = null;
  state = {
    billingDetails: { address: {}, email: "" },
    useMethodForNextCheckout: true,
  };

  componentWillUnmount = () =>
    this.setState({
      billingDetails: { address: {}, email: "" },
      isConfirming: false,
    });

  onChangeBillingInfo = (fieldID, fieldValue) => {
    const { billingDetails } = this.state;
    if (["email", "name"].includes(fieldID)) {
      this.setState({
        billingDetails: set(billingDetails, fieldID, fieldValue),
      });
    } else {
      this.setState({
        billingDetails: set(billingDetails, `address.${fieldID}`, fieldValue),
      });
    }
  };

  onDetermineButtonStatus = () => {
    if (this.state.isConfirming) return "loading";
    return IsPaymentMethodFormValid(this.state.billingDetails)
      ? "active"
      : "inactive";
  };

  onFailToConfirm = () => this.setState({ isConfirming: false });

  handleSubmit = async (e) => {
    e.preventDefault();
    // If confirming, don't do anything
    if (this.state.isConfirming) return;

    this.setState({ isConfirming: true });
    const stripeCustomerID = _get(
      this.props.context,
      "customerProfile.stripeCustomerID"
    );
    if (!stripeCustomerID) return this.onFailToConfirm();

    // Create the payment method
    const { paymentMethod = {} } = await this.props.stripe.createPaymentMethod(
      "card",
      {
        billing_details: this.state.billingDetails,
      }
    );
    const { id = "" } = paymentMethod;

    // If the payment method fails to be created or already exists
    const fingerprint = _get(paymentMethod, "card.fingerprint");
    if (this.props.pastPaymentMethodFingerprints.includes(fingerprint)) {
      this.confirmNotif = ShowConfirmNotif({
        message: "Payment method is already added.",
        type: "warning",
      });
      return this.onFailToConfirm();
    }
    if (!id) {
      this.confirmNotif = ShowConfirmNotif({
        message: "Please enter correct card info.",
        type: "warning",
      });
      return this.onFailToConfirm();
    }

    // Attach the payment method to customer
    const { error, success } = await AttachPaymentMethodToCustomer({
      paymentMethodID: id,
      stripeCustomerID,
    }).finally(() => this.setState({ isConfirming: false }));
    this.confirmNotif = ShowConfirmNotif({
      message: success ? "Card is added to your account." : error,
      type: success ? "success" : "error",
    });
    if (success) {
      this.props.onUpdateCustomerProfileAfterAdd({
        billingDetails: this.state.billingDetails,
      });
      this.props.onCloseModal();
    }
  };

  renderUseNextTime = (e) => {
    if (e) e.preventDefault();
    const { useMethodForNextCheckout } = this.state;
    return (
      <div className={Style.useForNextCheckout}>
        <Checkbox
          checked={useMethodForNextCheckout}
          id="Save for faster checkout"
          label="Save for faster checkout next time"
          onClick={() =>
            this.setState({
              useMethodForNextCheckout: !useMethodForNextCheckout,
            })
          }
        />
      </div>
    );
  };

  render() {
    const { postal_code = "" } = this.state.billingDetails.address;
    return (
      <form onSubmit={this.handleSubmit}>
        <CardSection
          onChangeBillingInfo={this.onChangeBillingInfo}
          postalCode={postal_code}
        />
        <BillingSection
          billingDetails={this.state.billingDetails}
          onChangeBillingInfo={this.onChangeBillingInfo}
        />
        {this.renderUseNextTime()}
        <Button
          className={Style.confirmCardButton}
          loadingText="Confirming"
          name="Confirm Card"
          status={this.onDetermineButtonStatus()}
        >
          <ShieldIcon className={Style.icon} />
          confirm card
        </Button>
        <StripeBrand />
      </form>
    );
  }
}

PaymentMethodForm.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onUpdateCustomerProfileAfterAdd: PropTypes.func,
  pastPaymentMethodFingerprints: PropTypes.array,
};

PaymentMethodForm.defaultProps = {
  onCloseModal: () => {},
  onUpdateCustomerProfileAfterAdd: () => {},
  pastPaymentMethodFingerprints: [],
};

export default withContext(CustomerInterfaceConsumer)(
  injectStripe(PaymentMethodForm)
);
