// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

const LetsChatBox = ({ description, heading, mainButton, secondaryButton }) => (
  <div className={Style.letsChatBox}>
    <div className={Style.letsChatText}>
      <h2>{heading}</h2>
      <p>{description}</p>
    </div>
    <div className={Style.buttons}>
      <div className={Style.mainButton}>{mainButton}</div>
      {secondaryButton && (
        <div className={Style.secondaryButton}>{secondaryButton}</div>
      )}
    </div>
  </div>
);

LetsChatBox.propTypes = {
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  mainButton: PropTypes.object.isRequired,
  secondaryButton: PropTypes.object,
};

LetsChatBox.defaultProps = {
  heading: "Let's Chat.",
};

export default LetsChatBox;
