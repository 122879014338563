// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import { _renderTopBanner } from "../helperFunctions";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Button, SkipliLogo } from "fields";

// Icons
import { BagFullIcon, MenuIcon } from "assets/Icons";

class CustomerGlobalNavbarDesktop extends React.Component {
  renderDesktopNavbarToolbar = () => {
    const { cart = {} } = this.props.context;
    const itemsCount = Object.keys(cart).reduce((result, cartItemID) => {
      const { quantity } = cart[cartItemID];
      return result + quantity;
    }, 0);
    return (
      <div className={Style.desktopNavbarToolbar}>
        {/* <Button
          className={Style.rewardsButton}
          name="navbar rewards button"
          onClick={() => this.props.onClickNavItem("deals")}
        >
          <DiamondIcon />
          Deals
        </Button> */}
        <Button
          className={Style.cartButton}
          name="navbar cart button"
          onClick={() => this.props.context.onChangeGlobalNavItem("cart")}
        >
          <BagFullIcon />
          {itemsCount}
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div className={Style.customerHeaderContainer}>
        {_renderTopBanner()}
        <div className={Style.desktopNavbar}>
          <div className={Style.menuIcon} onClick={this.props.onShowMainMenu}>
            <MenuIcon />
          </div>
          <SkipliLogo
            className={Style.skipliLogo}
            linkClassname={Style.skipliLogoLink}
          />
          {this.renderDesktopNavbarToolbar()}
        </div>
      </div>
    );
  }
}

CustomerGlobalNavbarDesktop.propTypes = {
  context: PropTypes.shape({
    cart: PropTypes.object,
  }).isRequired,
  onShowMainMenu: PropTypes.func.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  CustomerGlobalNavbarDesktop
);
