// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import { MAX_DELIVERY_FEE } from "components/ConfirmOrderForm/HelperFunctions";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Lib
import { Math } from "lib/functions";

// Icons
import {
  AlertIcon,
  ArrowIcon,
  SkipliLogoWithTextWhiteIcon,
} from "assets/Icons";

const { _roundNumber } = Math;

class ConfirmOrderDeliveryQuote extends React.Component {
  renderNoDeliveryMessage = () => (
    <div className={Style.quoteSuccess}>
      <div className={Style.unableToDeliver}>
        <h1>Unable to deliver</h1>
        <p>All drivers are busy at the moment</p>
        <Button
          className={Style.choosePickUpBtn}
          onClick={() => this.props.onChangeDeliveryType("pickUp")}
          name="Choose pickup"
        >
          <ArrowIcon /> Select Pick Up
        </Button>
      </div>
    </div>
  );

  renderSuccessfulQuote = () => {
    const { quote } = this.props;
    const { localizedDropoffETA, totalDeliveryFee } = quote;
    return (
      <div className={Style.quoteSuccess}>
        <div className={Style.skipliLogo}>
          <SkipliLogoWithTextWhiteIcon /> Delivery
        </div>
        <div className={Style.quoteEstimate}>
          <div className={Style.estimate}>
            <h2>${_roundNumber(totalDeliveryFee)}</h2>
            <div>Delivery Fee</div>
          </div>
          <div className={Style.estimate}>
            <h2>{localizedDropoffETA}</h2>
            <div>Earliest Time Arrival</div>
          </div>
        </div>
        {parseFloat(totalDeliveryFee) > 8 && (
          <p className={Style.farLocationMsg}>
            <AlertIcon /> Your location is quite far from the store.
          </p>
        )}
      </div>
    );
  };

  render() {
    const { allowDelivery = true, quote } = this.props;
    const { errorMessage, totalDeliveryFee = 0 } = quote;
    if (errorMessage)
      return (
        <div className={Style.quoteError}>
          Unable to find your location. Please check your address!
        </div>
      );
    else if (
      !allowDelivery ||
      parseFloat(totalDeliveryFee) <= 0 ||
      parseFloat(totalDeliveryFee) > MAX_DELIVERY_FEE
    )
      return this.renderNoDeliveryMessage();
    return this.renderSuccessfulQuote();
  }
}

ConfirmOrderDeliveryQuote.propTypes = {
  allowDelivery: PropTypes.bool.isRequired,
  courier: PropTypes.oneOf(["postmates", "doordash"]).isRequired,
  quote: PropTypes.shape({
    dropoffETA: PropTypes.string,
    errorMessage: PropTypes.string,
    totalDeliveryFee: PropTypes.number,
  }).isRequired,
  onChangeDeliveryType: PropTypes.func.isRequired,
};

export default ConfirmOrderDeliveryQuote;
