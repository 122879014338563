// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { Elements } from "react-stripe-elements";

import PaymentMethodForm from "./PaymentMethodForm";

// Fields
import { ResponsiveModal } from "fields";

// Style
import Style from "./style.module.scss";

class AddNewPaymentMethodModal extends React.Component {
  render() {
    return (
      <ResponsiveModal
        contentLabel="Add a payment method modal"
        onCloseModal={this.props.onCloseModal}
        modalBoxClassname={Style.modalBoxConfirmOrder}
        contentContainerClassname={Style.modalContainer}
      >
        <Elements>
          <PaymentMethodForm {...this.props} />
        </Elements>
      </ResponsiveModal>
    );
  }
}

AddNewPaymentMethodModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  pastPaymentMethodFingerprints: PropTypes.array,
};

AddNewPaymentMethodModal.defaultProps = {
  onCloseModal: () => {},
  pastPaymentMethodFingerprints: [],
};

export default AddNewPaymentMethodModal;
