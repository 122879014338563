// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Components
import { WebsiteBuilder } from "components";

// Lib
import { Customers } from "lib/services";
const { GetWebsiteInfo } = Customers.GetRequests;

class ShopWebsite extends React.Component {
  state = { isFindingWebsiteInfo: true, website: {} };

  componentDidMount = () => this.onGetWebsite();

  onGetWebsite = async () => {
    this.setState({ isFindingWebsiteInfo: true });
    const { shopID = "" } = this.props;
    try {
      const { website = {} } = await GetWebsiteInfo({ shopID });
      this.setState({ website });
    } catch {
      this.setState({ failedToFindWebsiteInfo: true });
    } finally {
      this.setState({ isFindingWebsiteInfo: false });
    }
  };

  render() {
    if (this.state.isFindingWebsiteInfo) return <div />;
    else {
      const { website = {} } = this.state;
      return (
        <WebsiteBuilder
          {...website}
          shopBasicInfo={this.props.shopBasicInfo}
          shopID={this.props.shopID}
        />
      );
    }
  }
}

ShopWebsite.propTypes = {
  shopBasicInfo: PropTypes.object.isRequired,
  shopID: PropTypes.string.isRequired,
};

export default ShopWebsite;
