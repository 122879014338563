// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Fields
import { Button, ScreenSize } from "fields";

// Image
import ContactlessDining from "assets/Images/contactlessDining.jpg";
import { PlayIcon } from "assets/Icons";

class ContactlessOrderingIntro extends React.Component {
  state = { showVideo: false };

  renderMainContent = () => (
    <div className={Style.titleText}>
      <h1>Digital Ordering & Wait-Free Payment</h1>
      <p className={Style.paragraph}>
        Provide an end-to-end contactless dining experience to your guests.
        Guests can view the complete menu, place orders, and pay at the
        table—without the need for any human contact.
      </p>
      <div className={Style.buttons}>
        <Button
          className={Style.learnMoreBtn}
          color="white"
          name="What Skipli Offers"
          onClick={() =>
            this.props.context.onScrollTo("contactlessOrderingBenefits")
          }
        >
          What We Offer
        </Button>
        <Button
          className={Style.seeActionBtn}
          hasShadow
          name="Play contactless dining video"
          onClick={() => this.setState({ showVideo: true })}
        >
          <PlayIcon className={Style.playIcon} /> See In Action
        </Button>
      </div>
    </div>
  );

  renderDesktopView = () => (
    <React.Fragment>
      <div className={Style.background}>
        <div className={CommonStyle.sectionContainer}>
          {this.renderMainContent()}
        </div>
      </div>
    </React.Fragment>
  );

  renderMobileView = () => (
    <React.Fragment>
      <div>
        <img alt="" className={Style.image} src={ContactlessDining} />
        <div className={CommonStyle.sectionContainer}>
          {this.renderMainContent()}
        </div>
      </div>
    </React.Fragment>
  );

  render() {
    return (
      <React.Fragment>
        {this.state.showVideo && (
          <Modals.VideoPlayerModal
            onCloseModal={() => this.setState({ showVideo: false })}
            playing
            url="https://youtu.be/bmcbc_7BZqk"
          />
        )}
        <ScreenSize deviceType="mobile">{this.renderMobileView()}</ScreenSize>
        <ScreenSize deviceType="tablet">{this.renderDesktopView()}</ScreenSize>
        <ScreenSize deviceType="desktop">{this.renderDesktopView()}</ScreenSize>
      </React.Fragment>
    );
  }
}

ContactlessOrderingIntro.propTypes = {
  context: PropTypes.shape({
    onScrollTo: PropTypes.func.isRequired,
  }).isRequired,
};

export default withContext(CustomerInterfaceConsumer)(ContactlessOrderingIntro);
