// BUTI DINERS, INC. All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import cx from "classnames";

import MenuItemDetail from "../MenuItemDetail";
import QuantitySelector from "./QuantitySelector";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Style
import { Button } from "fields";

// Assets
import { PlusIcon } from "assets/Icons";

// Lib
import { Math } from "lib/functions";

class MenuItem extends Component {
  state = { showQuantitySelector: false };

  _shouldRenderImage = () => {
    const itemImgURL = this.onGetImageURL();
    const { imageNotFound = false } = this.state;
    return itemImgURL && !imageNotFound;
  };

  onClickQuickAdd = () => {
    const { categoryModifierGroups = {}, itemInfo = {} } = this.props;
    const { itemPrice = 0, modifierGroups: itemModifierGroups = {} } = itemInfo;
    const modifierGroups = {
      ...categoryModifierGroups,
      ...itemModifierGroups,
    };
    const price = isNaN(parseFloat(itemPrice)) ? 0 : parseFloat(itemPrice);
    if (price === 0 || Object.keys(modifierGroups).length > 0)
      this.setState({ showItemDetailModal: true });
    else this.setState({ showQuantitySelector: true });
  };

  onCloseModal = () => this.setState({ showItemDetailModal: false });

  onGetImageURL = () =>
    _get(this.props.itemInfo, "itemImages.full.imageURL") || "";

  onGetImage = ({ imageClassname = "" } = {}) =>
    this._shouldRenderImage() && (
      <img
        alt=""
        className={imageClassname}
        onError={this.onImageNotFound}
        src={this.onGetImageURL()}
      />
    );

  onImageNotFound = () => this.setState({ imageNotFound: true });

  onShowItemDetails = () => this.setState({ showItemDetailModal: true });

  renderContent = () => {
    const { itemInfo = {} } = this.props;
    const {
      itemDescription = "",
      itemIsOutOfStock = {},
      itemIsOnSale = {},
      itemName = "",
      itemSaleRate = 0,
    } = itemInfo;
    return (
      <div style={{ position: "relative" }}>
        <button
          className={cx(
            Style.itemContainer,
            itemIsOutOfStock.true && Style.disabled
          )}
          onClick={() => {
            !itemIsOutOfStock.true && this.onShowItemDetails();
          }}
        >
          <div className={Style.itemPreview}>
            <div className={Style.itemPreviewInfo}>
              <h3 className={Style.itemName}>
                {(itemName || "").toLowerCase()}
              </h3>
              {itemDescription && (
                <p className={Style.itemDescription}>{itemDescription}</p>
              )}
            </div>
            <div className={Style.itemPreviewPrice}>
              {this.renderPrice()}
              {itemIsOnSale.true &&
                !itemIsOutOfStock.true &&
                itemSaleRate > 0 && (
                  <div className={Style.onSaleSign}>{itemSaleRate}% OFF</div>
                )}
            </div>
          </div>
          {this.onGetImage({ imageClassname: Style.itemThumbnailImg })}
        </button>
        {!this.state.showQuantitySelector ? (
          this.renderQuickAddButton()
        ) : (
          <QuantitySelector
            itemID={this.props.itemID}
            itemInfo={this.props.itemInfo}
            onHideQuantitySelector={() =>
              this.setState({ showQuantitySelector: false })
            }
          />
        )}
      </div>
    );
  };

  renderItemDetail = () => (
    <MenuItemDetail
      categoryModifierGroups={this.props.categoryModifierGroups}
      itemImage={this.onGetImage({ imageClassname: Style.itemDetailImg })}
      itemID={this.props.itemID}
      itemInfo={this.props.itemInfo}
      onCloseModal={this.onCloseModal}
    />
  );

  renderQuickAddButton = () => {
    const { todayHours = {} } = this.props.context;
    const { itemInfo = {} } = this.props;
    const { itemIsOutOfStock = {} } = itemInfo;
    if (itemIsOutOfStock.true) return;
    const { canShopAcceptOrders = true, isShopClosed = false } = todayHours;
    if (isShopClosed || !canShopAcceptOrders) return;
    return (
      <Button
        className={Style.quickAddButton}
        name="quick add"
        onClick={this.onClickQuickAdd}
      >
        <PlusIcon />
      </Button>
    );
  };

  renderPrice = () => {
    const { itemInfo = {} } = this.props;
    const {
      itemIsOutOfStock = {},
      itemPrice,
      itemIsOnSale = {},
      itemSaleRate,
    } = itemInfo;
    if (itemIsOutOfStock.true)
      return <div className={Style.soldOutSign}>SOLD OUT</div>;
    else if (!itemPrice || parseFloat(itemPrice) === 0) return;
    else if (itemIsOnSale.true && itemSaleRate > 0) {
      const priceAfterSaleApplied = Math._roundNumber(
        (1 - parseFloat(itemSaleRate) / 100) * itemPrice
      );
      return (
        <React.Fragment>
          <div className={Style.priceBeforeSale}>
            ${Math._roundNumber(itemPrice)}
          </div>
          <div className={Style.itemPrice}>${priceAfterSaleApplied}</div>
        </React.Fragment>
      );
    }
    return (
      <div className={Style.itemPrice}>${Math._roundNumber(itemPrice)}</div>
    );
  };

  render() {
    const { itemInfo = {} } = this.props;
    if (Object.keys(itemInfo).length === 0) return;
    return (
      <div>
        {this.state.showItemDetailModal && this.renderItemDetail()}
        {this.renderContent()}
      </div>
    );
  }
}

MenuItem.propTypes = {
  categoryModifierGroups: PropTypes.object,
  context: PropTypes.object.isRequired,
  itemID: PropTypes.string.isRequired,
  itemInfo: PropTypes.any,
};

MenuItem.defaultProps = {
  categoryModifierGroups: {},
  itemInfo: {},
};

export default withContext(CustomerInterfaceConsumer)(MenuItem);
