// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icon
import { SkipliLogoWithTextIcon } from "assets/Icons";

// Fields
import { Link } from "fields";

const SkipliLogo = ({ className, linkClassname }) => (
  <Link
    className={cx(Style.link, linkClassname)}
    href="https://www.skiplinow.com"
  >
    <SkipliLogoWithTextIcon className={cx(Style.skipliLogo, className)} />
  </Link>
);

SkipliLogo.propTypes = {
  className: PropTypes.string,
};

export default SkipliLogo;
