import React from "react";
import PropTypes from "prop-types";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "react-stripe-elements";

// Fields
import { ExpansionPanel, Input } from "fields";

// Style
import Style from "./style.module.scss";

class CardSection extends React.Component {
  onChangeCardInfo = ({ value }) => {
    const { postalCode = "" } = value;
    this.props.onChangeBillingInfo("postal_code", postalCode);
  };

  render() {
    return (
      <ExpansionPanel moduleTitle="Debit/Credit Card" showExpandableContent>
        <CardNumberElement
          classes={{
            base: Style.StripeElement,
            focus: Style.StripeElementFocus,
            invalid: Style.StripeElementInvalid,
          }}
          style={{ base: { fontSize: "16px" } }}
        />
        <div className={Style.otherCardInfoContainer}>
          <CardExpiryElement
            classes={{
              base: Style.otherCardInfo,
              focus: Style.StripeElementFocus,
              invalid: Style.StripeElementInvalid,
            }}
            style={{ base: { fontSize: "16px" } }}
          />
          <CardCvcElement
            classes={{
              base: Style.otherCardInfo,
              focus: Style.StripeElementFocus,
              invalid: Style.StripeElementInvalid,
            }}
            style={{ base: { fontSize: "16px" } }}
          />
          <div style={{ width: "30%" }}>
            <Input.TextInput
              fieldClassname={Style.zipCodeField}
              inputClassname={Style.inputField}
              placeholder="Zip"
              name="Credit Card postal code"
              onChange={(val) =>
                this.props.onChangeBillingInfo("postal_code", val)
              }
              required
              type="number"
              value={this.props.postalCode}
            />
          </div>
        </div>
      </ExpansionPanel>
    );
  }
}

CardSection.propTypes = {
  onChangeBillingInfo: PropTypes.func.isRequired,
  postalCode: PropTypes.string,
};

CardSection.defaultProps = {
  postalCode: "",
};

export default CardSection;
