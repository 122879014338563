// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

import DeliveryAddressForm from "./DeliveryAddressForm";
import { DROPOFF_OPTIONS, _calculateDoordashQuote } from "./HelperFunctions";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Components
import { RadioGroup } from "components";

// Fields
import { ExpansionPanel, Input } from "fields";

class DeliveryInfo extends React.Component {
  state = {};
  timeout = null;

  // onCalculatePostmatesQuote = async (dropoffAddress) => {
  //   const {
  //     allowDelivery = true,
  //     onChangeCourierQuotes,
  //   } = this.props;
  //   this.props.onShowGettingQuoteLoader();
  //   try {
  //     const postmatesQuote = await _calculatePostmatesQuote({
  //       allowDelivery,
  //       dropoffAddress,
  //       pickupAddress: this.onGetShopAddress(),
  //     });
  //     const { dropoffETA } = postmatesQuote;
  //     onChangeCourierQuotes({
  //       courier: "postmates",
  //       quote: {
  //         ...postmatesQuote,
  //         localizedDropoffETA: this.onLocalizeDropoffETA(dropoffETA),
  //       },
  //     });
  //   } catch (error) {
  //     onChangeCourierQuotes({
  //       courier: "postmates",
  //       quote: { errorMessage: error },
  //     });
  //   } finally {
  //     this.props.onHideGettingQuoteLoader();
  //   }
  // };

  onCalculateDoordashQuote = async (dropoff_address = {}) => {
    const {
      allowDelivery: allow_delivery = true,
      onChangeCourierQuotes,
    } = this.props;
    this.props.onShowGettingQuoteLoader();
    const { shopBasicInfo, shopID: shop_id } = this.props.context;
    const {
      address: shop_adress,
      name: shop_name,
      timeZone: shop_time_zone,
    } = shopBasicInfo;
    try {
      const doordashQuote = await _calculateDoordashQuote({
        allow_delivery,
        dropoff_address,
        external_business_name: shop_name,
        external_store_id: shop_id,
        order_value: parseInt(this.props.subTotal * 100),
        shop_adress,
        shop_time_zone,
      });
      onChangeCourierQuotes({ courier: "doordash", quote: doordashQuote });
    } catch (error) {
      onChangeCourierQuotes({
        courier: "doordash",
        quote: { errorMessage: error },
      });
    } finally {
      this.props.onHideGettingQuoteLoader();
    }
  };

  onGetShopAddress = () => _get(this.props, "context.shopBasicInfo.address");

  onKeyUpAddress = () => {
    const { deliveryAddress } = this.props;
    const {
      streetAddress1,
      streetAddress2,
      city,
      state,
      zip,
    } = deliveryAddress;
    if (streetAddress1 && city && state && zip) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () =>
          this.onCalculateDoordashQuote({
            city,
            state,
            street: streetAddress1,
            unit: streetAddress2,
            zip_code: zip,
          }),
        1000
      );
    }
  };

  render_dropoff_options = () => (
    <RadioGroup
      heading="Drop-off Options"
      onClick={(optionId) => {
        this.onKeyUpAddress();
        this.props.on_update_dropoff_options(optionId);
      }}
      options={DROPOFF_OPTIONS}
      required
      selectedOptionId={this.props.dropoff_option}
    />
  );

  render() {
    return (
      <ExpansionPanel
        moduleTitle="Delivery Info"
        showExpandableContent
        titleClassName={CommonStyle.expansionPanelTitle}
      >
        <div style={{ marginBottom: "1.4rem" }}>
          <Input.TextInput
            label="Restaurant Address"
            name="Shop Address"
            value={this.onGetShopAddress()}
            readOnly
          />
        </div>
        <DeliveryAddressForm
          deliveryAddress={this.props.deliveryAddress}
          deliveryNotes={this.props.deliveryNotes}
          onChangeDeliveryAddress={this.props.onChangeDeliveryAddress}
          onChangeDeliveryNotes={this.props.onChangeDeliveryNotes}
          onKeyUpAddress={this.onKeyUpAddress}
        />
        <div className={Style.dropoff_options}>
          {this.render_dropoff_options()}
        </div>
      </ExpansionPanel>
    );
  }
}

DeliveryInfo.propTypes = {
  allowDelivery: PropTypes.bool.isRequired,
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.shape({
      address: PropTypes.string.isRequired,
      deliverInfo: PropTypes.shape({
        deliverFee: PropTypes.object,
      }),
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  deliveryAddress: PropTypes.shape({
    streetAddress1: PropTypes.string,
    streetAddress2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.any,
  }),
  deliveryNotes: PropTypes.string,
  dropoff_option: PropTypes.string,
  onChangeDeliveryAddress: PropTypes.func.isRequired,
  onChangeDeliveryNotes: PropTypes.func.isRequired,
  onChangeCourierQuotes: PropTypes.func.isRequired,
  onHideGettingQuoteLoader: PropTypes.func.isRequired,
  onShowGettingQuoteLoader: PropTypes.func.isRequired,
  on_update_dropoff_options: PropTypes.func.isRequired,
  subTotal: PropTypes.number.isRequired,
};

DeliveryInfo.defaultProps = {
  deliveryAddress: {},
  deliveryNotes: "",
};

export default withContext(CustomerInterfaceConsumer)(DeliveryInfo);
