// BUTI Corp All right Reserved ©

import React, { Component } from "react";
import PropTypes from "prop-types";

import AllergensList from "./AllergensList";

// Style
import Style from "./style.module.scss";

class ItemInfo extends Component {
  renderAllergens = (itemAllergens = {}) =>
    Object.keys(itemAllergens).length > 0 && (
      <AllergensList allergensList={itemAllergens || {}} />
    );

  renderOnSaleSign = (itemSaleRate) => (
    <div className={Style.onSaleSign}>{itemSaleRate}% OFF</div>
  );

  render() {
    const { itemInfo } = this.props;
    const {
      itemAllergens = {},
      itemCalories,
      itemDescription,
      itemIsOnSale = {},
      itemNote,
      itemSaleRate = 0,
    } = itemInfo;
    return (
      <React.Fragment>
        <h2 className={Style.itemName}>{itemInfo.itemName}</h2>
        {itemIsOnSale.true &&
          itemSaleRate > 0 &&
          this.renderOnSaleSign(itemSaleRate)}
        {itemDescription && (
          <p className={Style.itemDescription}>
            {itemInfo.itemDescription}
            {parseInt(itemCalories) > 0 ? ` (${itemCalories} cal)` : ""}
          </p>
        )}
        {this.renderAllergens(itemAllergens)}
        {itemNote && <p className={Style.itemNote}>* {itemNote}</p>}
      </React.Fragment>
    );
  }
}

ItemInfo.propTypes = {
  itemInfo: PropTypes.shape({
    itemAllergens: PropTypes.any,
    itemCalories: PropTypes.string,
    itemDescription: PropTypes.string,
    itemName: PropTypes.string.isRequired,
    itemNote: PropTypes.string,
  }).isRequired,
};

ItemInfo.defaultProps = {
  itemInfo: {
    itemAllergens: {},
    itemNote: "",
  },
};

export default ItemInfo;
