// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

const BouncingDotsLoader = ({ class_names }) => (
  <div className={cx(Style.container, class_names.container)}>
    <div className={cx(Style.dot, class_names.dot)} />
    <div className={cx(Style.dot, class_names.dot)} />
    <div className={cx(Style.dot, class_names.dot)} />
  </div>
);

BouncingDotsLoader.propTypes = {
  class_names: PropTypes.shape({
    container: PropTypes.string,
    dot: PropTypes.string,
  }),
};

BouncingDotsLoader.defaultProps = {
  class_names: {},
};

export default BouncingDotsLoader;
