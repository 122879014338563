// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _orderBy from "lodash.orderby";

import PastOrder from "./PastOrder";

// Style
import ModuleStyle from "../style.module.scss";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Lib
import { LocalStorage } from "lib/functions";
import { Customers } from "lib/services";
const { GetCustomerPastOrdersAtShop } = Customers.GetRequests;

class PastOrdersModule extends React.Component {
  state = { pastOrdersAtShop: {} };

  componentDidMount = () => this.onGetPastOrdersAtShop();

  onGetPastOrdersAtShop = async () => {
    this.setState({ isLoading: true });
    const { pastOrdersAtShop } = await GetCustomerPastOrdersAtShop({
      uuid: LocalStorage.getItemFromStorage("uuid"),
      shopID: this.props.context.shopID,
    });
    this.setState({
      isLoading: false,
      pastOrdersAtShop,
    });
  };

  renderPastOrdersAtShop = () => {
    const { pastOrdersAtShop = {} } = this.state;
    const orders = Object.keys(pastOrdersAtShop);
    if (orders.length === 0) return <p>No orders found.</p>;
    const sortedOrders = _orderBy(
      orders,
      (orderID) => {
        const { timeStamp } = pastOrdersAtShop[orderID];
        return new Date(timeStamp);
      },
      "desc"
    );
    return sortedOrders.map((orderID) => (
      <div key={orderID}>
        <PastOrder orderID={orderID} orderInfo={pastOrdersAtShop[orderID]} />
      </div>
    ));
  };

  render() {
    const { shopBasicInfo } = this.props.context;
    return (
      <div>
        <h3 className={ModuleStyle.shopName}>
          Your orders at {shopBasicInfo.name}
        </h3>
        {this.state.isLoading ? (
          <p>Finding your past orders...</p>
        ) : (
          this.renderPastOrdersAtShop()
        )}
      </div>
    );
  }
}

PastOrdersModule.propTypes = {
  context: PropTypes.shape({
    shopBasicInfo: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
  }),
};

export default withContext(CustomerInterfaceConsumer)(PastOrdersModule);
