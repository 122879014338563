// BUTI DINERS, INC. All right Reserved ©

import React from "react";

const CustomerInterfaceContext = React.createContext({});
const MerchantInterfaceContext = React.createContext({});

export const CustomerInterfaceProvider = CustomerInterfaceContext.Provider;
export const CustomerInterfaceConsumer = CustomerInterfaceContext.Consumer;

export const MerchantInterfaceProvider = MerchantInterfaceContext.Provider;
export const MerchantInterfaceConsumer = MerchantInterfaceContext.Consumer;

export const withContext = Consumer => Component => props => (
  <Consumer>{context => <Component {...props} context={context} />}</Consumer>
);
