// BUTI DINERS, INC. All right Reserved ©

import CustomerGlobalNavbar from "./CustomerGlobalNavbar";
import LandingPageNavbar from "./LandingPageNavbar";
import MatchingShopsNavbar from "./MatchingShopsNavbar";
import SubNavbar from "./SubNavbar";

const Navbars = {
  CustomerGlobalNavbar,
  LandingPageNavbar,
  MatchingShopsNavbar,
  SubNavbar
};

export default Navbars;
