import React from "react";

// Style
import Style from "./style.module.scss";

//Components
import { Modals } from "components/";

// Assets
import watermark_black from "assets/Images/skipli_watermark_black.png";

class BenefitsPill extends React.Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        {this.state.openModal && (
          <Modals.BenefitsModal
            onCloseModal={() => this.setState({ openModal: false })}
          />
        )}
        <div className={Style.container}>
          <div
            className={Style.pill}
            onClick={() => this.setState({ openModal: true })}
          >
            <img
              alt="Skipli watermark"
              className={Style.watermark}
              src={watermark_black}
            />
            <div>
              <h2 className={Style.heading}>Powered by SKIPLI</h2>
              <p className={Style.generalText}>
                Our restaurant is a proud part of the SKIPLI family
              </p>
              <div className={Style.seeBenefits}>See the benefits</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BenefitsPill;
