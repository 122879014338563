// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Modifier from "./Modifier";
import {
  _constructModifiersAfterSelectModifiers,
  _createModifiersSkeleton,
  _getModifierGroupDescription,
  _isModifierGroupRequired,
  _isModififerSelectedAsDefault,
} from "./functions";

// Style
import ItemDetailStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Lib
import { FoodMenuFuncs } from "lib/functions";
const { GetInformationForModifiers } = FoodMenuFuncs;

const DEFAULT_VISIBLE_MODIFIERS_COUNT = 6;

class ModifierGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedModifiers: {},
      selectedModifiers: props.selectedModifiers,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.onGetModifiersInfo();
  }

  componentWillUnmount = () => (this._isMounted = false);

  isModififerSelected = (modifierID) => {
    const { selectedModifiers } = this.state;
    return (selectedModifiers || {})[modifierID] ? true : false;
  };

  isSelectedAsDefault = (modifierID) =>
    _isModififerSelectedAsDefault({
      modifiers: this.state.loadedModifiers,
      modifierGroupMinChoices: parseInt(
        this.props.modifierGroupInfo.modifierGroupMinChoices
      ),
      modifierID,
      selectedModifiers: this.state.selectedModifiers,
    });

  onGetModifiersInfo = async () => {
    this.setState({ isLoadingModifiers: true });
    const { modifierGroupInfo = {} } = this.props;
    const { modifiers = {} } = modifierGroupInfo;
    try {
      const loadedModifiers = await GetInformationForModifiers({
        modifiers,
        shopID: this.props.context.shopID,
      });
      if (this._isMounted)
        this.setState({ loadedModifiers: loadedModifiers || {} });
    } catch {
      this.setState({ loadedModifiers: {} });
    } finally {
      this.setState({ isLoadingModifiers: false });
    }
  };

  onSelectModifier = (modifierID, modifierSimpleDescription) => {
    const { modifierGroupInfo = {} } = this.props;
    const selectedModifiers = _constructModifiersAfterSelectModifiers({
      modifierGroupMaxChoices: parseInt(
        modifierGroupInfo.modifierGroupMaxChoices
      ),
      modifierGroupMinChoices: parseInt(
        modifierGroupInfo.modifierGroupMinChoices
      ),
      selectedModifierID: modifierID,
      selectedModifierInfo: modifierSimpleDescription,
      selectedModifiers: this.state.selectedModifiers || {},
      shouldAllowMultipleSelect: this.shouldAllowMultipleSelect(),
    });
    if (this._isMounted)
      this.setState({ selectedModifiers }, () =>
        this.props.onSelectModifier(selectedModifiers)
      );
  };

  onToggleModifiersVisibility = () =>
    this.setState({ showAllModifiers: !this.state.showAllModifiers });

  shouldAllowMultipleSelect = () => {
    if (Object.keys(this.state.loadedModifiers).length === 1) return true;
    return !(
      parseInt(this.props.modifierGroupInfo.modifierGroupMaxChoices) === 1
    );
  };

  renderModifiers = () =>
    Object.entries(this.state.loadedModifiers).map((entry, index) => {
      const modifier = (
        <div key={entry[0]}>
          <Modifier
            allowMultipleSelect={this.shouldAllowMultipleSelect()}
            isSelected={this.isModififerSelected(entry[0])}
            isSelectedAsDefault={this.isSelectedAsDefault(entry[0])}
            modifierInfo={entry[1]}
            onSelectModifier={(modifierSimpleDescription) =>
              this.onSelectModifier(entry[0], modifierSimpleDescription)
            }
          />
        </div>
      );
      if (this.state.showAllModifiers) return modifier;
      else if (index < DEFAULT_VISIBLE_MODIFIERS_COUNT) return modifier;
      return null;
    });

  renderRequireIncator = () => {
    const { modifierGroupMinChoices } = this.props.modifierGroupInfo;
    const isRequired = _isModifierGroupRequired({ modifierGroupMinChoices });
    return (
      isRequired && (
        <div className={cx(ItemDetailStyle.subText, Style.requiredLabel)}>
          Required
        </div>
      )
    );
  };

  renderShowMoreButton = () =>
    Object.keys(this.state.loadedModifiers).length >
      DEFAULT_VISIBLE_MODIFIERS_COUNT && (
      <div
        className={Style.showMoreButton}
        onClick={this.onToggleModifiersVisibility}
      >
        <button>Show {this.state.showAllModifiers ? "Less" : "More"}</button>
      </div>
    );

  render() {
    const { modifierGroupInfo } = this.props;
    return (
      <div className={ItemDetailStyle.sectionContainer}>
        <div className={ItemDetailStyle.sectionNameContainer}>
          <h3 className={ItemDetailStyle.sectionName}>
            {modifierGroupInfo.modifierGroupName}
          </h3>
          {this.renderRequireIncator()}
        </div>
        <p style={{ fontWeight: "600" }}>
          {_getModifierGroupDescription({ modifierGroupInfo })}
        </p>
        {this.state.isLoadingModifiers ? (
          _createModifiersSkeleton()
        ) : (
          <React.Fragment>
            {this.renderModifiers()}
            {this.renderShowMoreButton()}
          </React.Fragment>
        )}
      </div>
    );
  }
}

ModifierGroup.propTypes = {
  modifierGroupInfo: PropTypes.shape({
    modifierGroupIsRequired: PropTypes.object,
    modifierGroupMaxChoices: PropTypes.any,
    modifierGroupMinChoices: PropTypes.any,
    modifierGroupName: PropTypes.string,
    modifiers: PropTypes.object,
  }).isRequired,
  onSelectModifier: PropTypes.func.isRequired,
  selectedModifiers: PropTypes.object,
};

ModifierGroup.defaultProps = {
  selectedModifiers: {},
};

export default withContext(CustomerInterfaceConsumer)(ModifierGroup);
