// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Fields
import Button from "fields/Button";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

class AutoPhoneProvenResults extends React.Component {
  renderGraphic = () => (
    <img
      alt=""
      className={Style.graphic}
      src="https://skipli.s3.amazonaws.com/Skipli+Website/Auto+Phone+Answering+Landing+Page/Auto+Phone+Proven+Results+Graphic+1.png"
    />
  );

  renderTestimonial = () => (
    <div>
      <h3 className={Style.testimonial_headline}>
        How LimeNBasil & TeaZona redirected{" "}
        <span>90% of phone calls to online services</span>
      </h3>
      <p className={Style.testimonial_description}>
        LimeNBasil & TeaZona chose SKIPLI for its Call Deflection technology,
        providing a self-serve menu that allowed callers to get the help they
        needed in an innovative way. Call menu options converted calls to SMS
        text messages, providing their patrons one-click access to online
        ordering, directions, and promotions.
      </p>
      <Button
        className={Style.learn_more_button}
        name="Learn More Button"
        onClick={() => this.props.history.push("/get-started")}
      >
        Get a free demo <ChevronArrowIcon className={Style.arrow_icon} />
      </Button>
    </div>
  );

  render() {
    return (
      <div className={Style.container}>
        <h2 className={Style.title}>Proven Results</h2>
        <div className={Style.content_grid}>
          {this.renderTestimonial()}
          {this.renderGraphic()}
        </div>
      </div>
    );
  }
}

AutoPhoneProvenResults.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AutoPhoneProvenResults);
