// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import cx from "classnames";

// Utils
import { _updatePageTitleAndDescription } from "utils";
import { withRouter } from "react-router-dom";

// Style
import MainWebsiteStyle from "styles/_mainWebsite.module.scss";
import Style from "./style.module.scss";

// Components
import { BlogPostList } from "components";

class Blog extends React.Component {
  componentDidMount() {
    _updatePageTitleAndDescription({
      description:
        "Skipli is trusted and loved by 2000+ restaurants worldwide. Here are some of the restaurants we work with.",
      title: "Our Blog | Skipli",
    });
  }

  render() {
    return (
      <div className={cx(MainWebsiteStyle.pageContainer)}>
        <div className={Style.content_container}>
          <h1 className={Style.heading}>The SKIPLI Blog</h1>
          <p className={Style.description}>
            Free resources, tips, and inspiration to help you run a successful
            restaurant.
          </p>
          <BlogPostList />
        </div>
      </div>
    );
  }
}

export default withRouter(Blog);
