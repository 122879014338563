// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import ContentLoader from "react-content-loader";
import PropTypes from "prop-types";

const MobileMenuGroupLoader = (props) => (
  <ContentLoader
    backgroundColor="#E0E0E0"
    foregroundColor="#F2F2F2"
    height={props.height}
    width={props.width}
    speed={2}
  >
    <rect x="0" y="0" rx="0" ry="0" height={props.height} width={props.width} />
  </ContentLoader>
);

MobileMenuGroupLoader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

MobileMenuGroupLoader.defaultProps = {
  height: 20,
  width: 100,
};

export default MobileMenuGroupLoader;
