// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Icons
import { ArrowIcon, CloseIcon } from "assets/Icons";

const NUMBER_PAD_KEYS = [
  {
    id: "1",
    label: "1",
    value: "1"
  },
  {
    id: "2",
    label: "2",
    value: "2"
  },
  {
    id: "3",
    label: "3",
    value: "3"
  },
  {
    id: "4",
    label: "4",
    value: "4"
  },
  {
    id: "5",
    label: "5",
    value: "5"
  },
  {
    id: "6",
    label: "6",
    value: "6"
  },
  {
    id: "7",
    label: "7",
    value: "7"
  },
  {
    id: "8",
    label: "8",
    value: "8"
  },
  {
    id: "9",
    label: "9",
    value: "9"
  },
  {
    id: "clear",
    label: <CloseIcon className={Style.clearIcon} />,
    value: "clear"
  },
  {
    id: "0",
    label: "0",
    value: "0"
  },
  {
    id: "backspace",
    label: <ArrowIcon className={Style.backSpaceIcon} />,
    value: "backspace"
  }
];

class Numberpad extends React.Component {
  onClickKey = key => {
    const { onChange, value = "" } = this.props;
    switch (key) {
      case "backspace":
        onChange(value.substring(0, value.length - 1));
        return;
      case "clear":
        onChange("");
        return;
      default:
        onChange(value.concat(key));
    }
  };

  renderNumberPad = () =>
    NUMBER_PAD_KEYS.map(({ id, label, value }) => (
      <div className={Style.keyContainer} key={id}>
        <button
          className={cx(Style.key, this.props.keyClassname)}
          onClick={() => this.onClickKey(value)}
          key={id}
        >
          {label}
        </button>
      </div>
    ));

  render() {
    return (
      <div className={cx(Style.container, this.props.className)}>
        {this.renderNumberPad()}
      </div>
    );
  }
}

Numberpad.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default Numberpad;
