// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Assets
import { ArrowIcon } from "assets/Icons";

class Carousel extends React.Component {
  state = { currentIndex: 0 };

  onChangeCurrentIndex = (index) => this.setState({ currentIndex: index });

  onGetDisplaySlides = () => {
    const { slides = [] } = this.props;
    const { currentIndex } = this.state;
    const centerPic = slides[currentIndex];
    const leftPic = slides[this.onConvertIndex(currentIndex - 1)];
    const rightPic = slides[this.onConvertIndex(currentIndex + 1)];

    if (slides.length === 1) return [centerPic];
    else if (slides.length === 2) return [centerPic, rightPic];
    return [leftPic, centerPic, rightPic];
  };

  onConvertIndex = (index) => {
    const { slides = [] } = this.props;
    if (index >= slides.length) return 0;
    else if (index < 0) return slides.length - 1;
    return index;
  };

  renderCircles = () => {
    const { slides = [] } = this.props;
    return slides.map((slide, index) => (
      <button
        className={cx(
          Style.circle,
          this.state.currentIndex === index && Style.active
        )}
        key={index}
        onClick={() => this.onChangeCurrentIndex(index)}
      />
    ));
  };

  renderSlides = () => {
    const { class_names = {} } = this.props;
    const { currentIndex } = this.state;
    const display_slides = this.onGetDisplaySlides();
    return display_slides.map(
      (slide, index) =>
        slide && (
          <div
            className={cx(
              Style.slide,
              class_names.slide,
              index === 1 && Style.active
            )}
            onClick={() => {
              index !== 1 &&
                this.onChangeCurrentIndex(
                  this.onConvertIndex(currentIndex + (index - 1))
                );
            }}
            key={index}
          >
            {slide}
          </div>
        )
    );
  };

  render() {
    const { class_names = {}, slides = [] } = this.props;
    const { currentIndex } = this.state;
    return (
      slides.length >= 1 && (
        <div>
          <div
            className={cx(Style.slides_container, class_names.slides_container)}
          >
            {this.renderSlides()}
          </div>
          <div className={Style.slides_navigation}>
            <button
              className={Style.left_arrow}
              onClick={() =>
                this.onChangeCurrentIndex(this.onConvertIndex(currentIndex - 1))
              }
            >
              <ArrowIcon />
            </button>
            <div className={Style.circles_container}>
              {this.renderCircles()}
            </div>
            <button
              className={Style.right_arrow}
              onClick={() =>
                this.onChangeCurrentIndex(this.onConvertIndex(currentIndex + 1))
              }
            >
              <ArrowIcon />
            </button>
          </div>
        </div>
      )
    );
  }
}

Carousel.propTypes = {
  class_names: PropTypes.shape({
    slides_container: PropTypes.string,
    slide: PropTypes.string,
  }),
  slides: PropTypes.array,
};

export default Carousel;
