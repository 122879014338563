// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Style
import { Button } from "fields";

class GoogleFrontPageGuarantee extends React.Component {
  render() {
    return (
      <div className={Style.container}>
        <div className={Style.content}>
          <h2 className={Style.heading}>60 day guarantee</h2>
          <div>
            <div className={Style.paragraph}>
              We guarantee that your business will be found on the front page of
              Google search engine results within 60 days of your account
              verification for a minimum of 2 placements. Otherwise, upon
              request, your basic service will be absolutely free until your
              business can be found on the front page. Approximately 92% of our
              clients currently reside on the front page. The remaining are new
              clients, on their way to the front page.
            </div>
            <Button
              className={Style.seeBenefitsButton}
              onClick={() =>
                this.props.context.onScrollTo("google_front_page_features")
              }
            >
              See the benefits
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

GoogleFrontPageGuarantee.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withContext(CustomerInterfaceConsumer)(
  withRouter(GoogleFrontPageGuarantee)
);
