// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { withRouter } from "react-router-dom";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Images
import NationalCoverageImg from "assets/Images/nationalCoverage.png";

// Icons
import { ArrowIcon } from "assets/Icons";

class NationalCoverage extends React.Component {
  render() {
    return (
      <div className={Style.container}>
        <div className={Style.textCol}>
          <h2>NATIONWIDE COVERAGE</h2>
          <p>
            We partner with trusted couriers in over 700 cities across the U.S.
            Fulfill your delivery orders with 100,000+ local, on-demand drivers.
          </p>
          <Button
            className={Style.getStartedBtn}
            name="get started button"
            onClick={() => this.props.history.push("/get-started")}
          >
            Free 30-day trial <ArrowIcon />
          </Button>
        </div>
        <div className={Style.imgCol}>
          <img alt="" className={Style.image} src={NationalCoverageImg} />
        </div>
      </div>
    );
  }
}

export default withRouter(NationalCoverage);
