// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import { PAYMENT_WALLET_OPTIONS } from "./constants";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { ExpansionPanel, Tabs } from "fields";

// Icon
import {
  AppleLogoBlackIcon,
  CreditCardIcon,
  GoogleLogoBlackIcon,
} from "assets/Icons";

// Lib
import { InfoSanitizer } from "lib/functions";

class PaymentWalletSelector extends React.Component {
  constructor(props) {
    super(props);
    const { browser, os } = InfoSanitizer._detectBrowserAndOS();
    // this.state = {
    //   isApplePay:
    //     browser.name === "Safari" ||
    //     browser.name === "iPhone" ||
    //     browser.name === "iPad",
    //   isGooglePay: browser.name === "Chrome" || os.name === "Android"
    // };
    this.state = {
      isApplePay: browser.name === "Safari",
      isGooglePay: browser.name !== "Safari" || os.name === "Android",
    };
  }

  onGetPaymentOptions = () =>
    Object.keys(PAYMENT_WALLET_OPTIONS).reduce(
      (result, optionId) =>
        optionId === "card"
          ? set(result, "card", {
              label: (
                <div className={Style.tabLabel}>
                  <CreditCardIcon />
                  card
                </div>
              ),
            })
          : set(result, "other", { label: this.onGetLabelForOtherPayment() }),
      {}
    );

  onGetLabelForOtherPayment = () => {
    if (this.state.isGooglePay)
      return (
        <div className={Style.tabLabel}>
          <GoogleLogoBlackIcon />
          google pay
        </div>
      );
    else if (this.state.isApplePay)
      return (
        <div className={Style.tabLabel}>
          <AppleLogoBlackIcon />
          apple pay
        </div>
      );
    else return "Other";
  };

  render() {
    return (
      <ExpansionPanel
        moduleTitle="Wallet"
        showExpandableContent
        titleClassName={CommonStyle.expansionPanelTitle}
      >
        <Tabs
          classNames={{
            selected: Style.selected,
            tab: Style.tab,
          }}
          onClick={this.props.onSelectPaymentWalletOption}
          options={this.onGetPaymentOptions()}
          selectedOptionId={this.props.selectedPaymentWalletOption}
        />
      </ExpansionPanel>
    );
  }
}

PaymentWalletSelector.propTypes = {
  onSelectPaymentWalletOption: PropTypes.func.isRequired,
  selectedPaymentWalletOption: PropTypes.oneOf(
    Object.keys(PAYMENT_WALLET_OPTIONS)
  ).isRequired,
};

export default PaymentWalletSelector;
