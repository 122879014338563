// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import { _renderTopBanner } from "../helperFunctions";

// Context
import { CustomerInterfaceConsumer, withContext } from "context";

// Style
import Style from "./style.module.scss";

// Fields
import { Button } from "fields";

// Icons
import { BagFullIcon, MenuIcon } from "assets/Icons";

class MobileNavbar extends React.Component {
  state = {};

  onClickNavItem = (id) => this.props.context.onChangeGlobalNavItem(id);

  renderHeader = () => {
    const { cart = {} } = this.props.context;
    const itemsCount = Object.keys(cart).reduce((result, cartItemID) => {
      const { quantity } = cart[cartItemID];
      return result + quantity;
    }, 0);
    return (
      <div className={Style.header}>
        <div className={Style.headerContent}>
          <div className={Style.menuIcon} onClick={this.props.onShowMainMenu}>
            <MenuIcon />
          </div>
          <Button
            className={Style.cartButton}
            name="Cart Button"
            onClick={() => this.onClickNavItem("cart")}
          >
            <BagFullIcon className={Style.bagIcon} />
            {itemsCount}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div>
          {this.renderHeader()}
          {_renderTopBanner()}
          {/* <Brand logoURL={this.props.logoURL} name={this.props.shopName} /> */}
          {/* <div className={Style.navbarContainer}>
          {CUSTOMER_MOBILE_GLOBAL_NAVBAR_ITEMS.map(this.renderNavItem)}
        </div> */}
        </div>
      </React.Fragment>
    );
  }
}

MobileNavbar.propTypes = {
  activeNavbarItemId: PropTypes.string.isRequired,
  context: PropTypes.shape({
    cart: PropTypes.object,
    onChangeGlobalNavItem: PropTypes.func,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  logoURL: PropTypes.string,
  onShowMainMenu: PropTypes.func.isRequired,
  shopName: PropTypes.string,
};

MobileNavbar.defaultProps = {
  logoURL: "",
};

export default withContext(CustomerInterfaceConsumer)(MobileNavbar);
